import React from 'react'
import withView from '../../components/HOCs/withView'
import requiresAuth from '../../components/HOCs/auth'
import { useHistory } from 'react-router-dom'
import { Text } from 'react-native'
import Button from '@material-ui/core/Button'
import { Container, DepositType } from './elements'

const RedeemScreen = () => {
  const history = useHistory()

  const processInCelo = () => {
    history.push('/redeem/CELO')
  }

  const processInCUSD = () => {
    history.push('/redeem/cUSD')
  }

  const processInCEUR = () => {
    history.push('/redeem/cEUR')
  }

  return (
    <>
      <Container>
        <Text>Which asset would you like to withdraw?</Text>
        <DepositType>  
          <Button color="secondary" variant="contained" onClick={processInCUSD}>Withdraw cUSD</Button>
        </DepositType>
        <DepositType>  
          <Button color="secondary" variant="contained" onClick={processInCEUR}>Withdraw cEUR</Button>
        </DepositType>
        <DepositType>  
          <Button color="secondary" variant="contained" onClick={processInCelo}>Withdraw CELO</Button>
        </DepositType>  
      </Container>
    </>
  )
}

export default requiresAuth(withView(RedeemScreen))
