import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useContractKit } from '@celo-tools/use-contractkit'
import AppContext from '../../components/AppContext'
import { Linking } from 'expo'
import { requestAccountAddress, waitForAccountAuth } from '../../sdk/dappkit'
import { kit } from '../../root'
import { CONTRACT_KIT, MOO_LOGIN } from '../../utils/constants'

const useLogin = () => {
  const context = useContext(AppContext)
  const history = useHistory()
  const { connect: connectWallet, address: walletAddress } = useContractKit()
  const provider = localStorage.getItem('use-contractkit/last-used-wallet')

  useEffect(() => {
    if (walletAddress) {
      walletAddress && setAddress(walletAddress, CONTRACT_KIT) // CeloExtensionWallet
    }
  }, [walletAddress])

  const setAddress = (address, type, phoneNumber) => {
    kit.defaultAccount = address
    context.setUser({ address, walletType: type, phoneNumber, walletName: provider ? provider : 'Valora' })
	if (history.location.pathname !== '/moo' && !localStorage.getItem(MOO_LOGIN)) {
		history.replace('/')
	}
	if (localStorage.getItem(MOO_LOGIN)) {
		localStorage.removeItem(MOO_LOGIN)
		history.replace('/moo')
	}
  }

  const login = async (walletType) => {
    if (context.user) return
    if (walletType === 'VALORA') {
      const dappName = 'Moola'
      const requestId = 'login'
      const callback = Linking.makeUrl('#')

      requestAccountAddress({
        requestId,
        dappName,
        callback
      })

      const { account: address, phoneNumber } = await waitForAccountAuth(requestId)
      setAddress(address, walletType, phoneNumber)
    } else {
      connectWallet()
    }
  }

  return login
}

export default useLogin
