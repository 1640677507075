import { Grid } from '@material-ui/core';
import MuiIcon from '@material-ui/core/Icon';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import BN from 'bignumber.js';
import classNames from "classnames";
import * as PropTypes from 'prop-types';
import React from 'react';
import { useGetExchangeRates } from '../../api';
import NotCheckedIcon from '../../assets/images/not-selected-circle.svg';
import CheckedIcon from '../../assets/images/selected-circle.svg';
import { currencyFormatter, getCurrencyFromFiat } from '../../utils';
import { CURRENCIES } from '../../utils/constants';
import { retrieveExchangeRates } from '../../utils/currency';
import { formatNumber, formatNumberWithoutRounding } from '../../utils/format';

const styles = (theme) => ({
	label: {
		fontFamily: 'Jost-500-Medium'
	},
	clickable: {
		cursor: 'pointer',
		padding: '8px 16px',
		borderRadius: '8px',
		'&:hover': {
			backgroundColor: '#F8F9F9'
		}
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		marginRight: 'auto',
		'& > *': {
			marginRight: '8px'
		}
	},
	marginBottom: {
		marginBottom: '20px',
	},
	contentRightWithOneRow: {
		textAlign: 'right',
		fontSize: '16px',
		lineHeight: '20px',
		fontWeight: 400,
		color: theme.palette.neutral.lightGrey
	},
	contentRightWithTwoRows: {
		textAlign: 'right',
		fontSize: '16px',
		lineHeight: '20px',
		fontWeight: 600,
	},
	contentRight2: {
		textAlign: 'right',
		fontSize: '14px',
		lineHeight: '20px',
		fontWeight: 'normal',
		color: theme.palette.neutral.lightGrey
	},
});

const CurrencyDesktopDropdown = ({ classes, currencies, selectCurrency, currentCurrency }) => {
	const exchangeRatesData = useGetExchangeRates()

	const formattedExchangeRate = retrieveExchangeRates(exchangeRatesData?.data)

	const convertToCurrentCurrency = (currency, amount) => {
		const result = BN(amount).multipliedBy(
			formattedExchangeRate[currency.toLowerCase()][getCurrencyFromFiat(currentCurrency).toLowerCase()]
		)
		return BN(amount).isGreaterThanOrEqualTo(0) ? result : 0
	}

	return (
		<div>
			<Grid
				container
				className={classes.container}
				direction={'column'}
			>
				{currencies?.map((currency, index) => (
					<Grid
						item
						key={index}
						onClick={() => selectCurrency(currency.key)}
					>
						<Grid
							container
							className={classes.clickable}
							alignItems={'center'}
						>
							<div className={classes.row}>
								<Grid item>
									<MuiIcon>
										<img alt={currency.selected ? 'Selected' : 'Not selected'} src={currency.selected ? CheckedIcon : NotCheckedIcon} height={24} width={24} />
									</MuiIcon>
								</Grid>
								<Grid item>
									<MuiIcon>
										<img alt={currency.key} src={currency.icon} height={40} width={40} />
									</MuiIcon>
								</Grid>
								<Grid item>
									<Typography className={classes.label}>
										{currency.text}
									</Typography>
								</Grid>
							</div>
							{currency.value && (
								<Grid item>
									<Grid container direction="column">
										<Grid item className={classNames(getCurrencyFromFiat(currentCurrency) !== currency.key && classes.marginBottom)}>
											<Typography variant="body1" className={classes.contentRightWithTwoRows}
											>
												{formatNumberWithoutRounding(currency.value, CURRENCIES[currency.text.toLowerCase()]?.decimals) + ' ' + currency.key}
											</Typography>
										</Grid>
										{getCurrencyFromFiat(currentCurrency) !== currency.key && (
											<Grid item>
											<Typography variant="body1" className={classNames(classes.contentRight2)}>
												{currencyFormatter(currentCurrency)(convertToCurrentCurrency(currency.key, currency.value))}
											</Typography>
										</Grid>
										)}
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>
				))}
			</Grid></div>
	);
};

CurrencyDesktopDropdown.propTypes = {
	currencies: PropTypes.arrayOf(PropTypes.shape({
		key: PropTypes.string.isRequired,
		text: PropTypes.string.isRequired,
		icon: PropTypes.string.isRequired,
		value: PropTypes.object
	})).isRequired,
	selectCurrency: PropTypes.func,
	currentCurrency: PropTypes.string.isRequired
}

export default withStyles(styles)(CurrencyDesktopDropdown);
