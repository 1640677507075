import React from 'react'
import Slider from '@material-ui/core/Slider'

// Utils
const marks = [
    {
      value: 25,
      label: '25%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];

// Component
const ValueSlider = ({ input, form, maxAmount, isLoading }) => {
  return (
    <Slider
      disabled={isLoading}
      defaultValue={0}
      value={Number(input.value || 0)}
      onChange={(event, value) => { 
        input.onChange(value)
        if (!maxAmount) return 
        const newAmount = maxAmount.multipliedBy(value).dividedBy(100).toString() 
        form.change('amount', newAmount)
      }}
      aria-labelledby="discrete-slider-custom"
      step={5}
      valueLabelDisplay="auto"
      marks={marks}
    />
  )
}

export default ValueSlider
