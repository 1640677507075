import BN from 'bignumber.js'
import { convertoToCelo } from '../api/requests'
import { CURRENCIES, FIAT_CURRENCIES, MIN_VALUE_NUMBER } from './constants'
import { retrieveExchangeRates } from './currency'
import { formatLocaleString, formatNumber } from './format'

export const ether = '1000000000000000000'
const ray = '1000000000000000000000000000'
export const maxUint256 =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

export function print(num) {
  return BN(num).dividedBy(ether).toFixed()
}

export function printRay(num) {
  return BN(num).dividedBy(ray).toFixed()
}

const currenciesMap = {
  cUSD: '$',
  cEUR: '€'
}

export function getValuePlaceholder(type) {
  const currencySymbol = currenciesMap[type] || ''

  return type === 'CELO' ? 'XX.xxxx' : `${currencySymbol}XX.xx`
}

export function formatCurrencyFromApi(currency) {
  return currency === 'Celo' ? 'CELO' : currency
}

export function getHealthFactor(data) {
  return data && data?.healthFactor
    ? !BN(data.healthFactor).isFinite()
      ? ''
      : `${data.healthFactor < 100000 
        ? formatLocaleString(data.healthFactor, 2, 2) 
        : '>100,000'} •`
    : ''
}

export function formatTokenValue(value, type) {
  const amount = BN(value)
  const formattedAmount = formatNumber(amount, 2)
  const currencySymbol = currenciesMap[type] || ''

  return currencySymbol + formattedAmount
}

export function formatCUSD(value) {
  return formatTokenValue(value, 'cUSD')
}

export function formatCEUR(value) {
  return formatTokenValue(value, 'cEUR')
}

export function formatCELO(value) {
  const amount = BN(value)
  return amount.lt(MIN_VALUE_NUMBER.toString())
    ? amount.toFixed()
    : amount.toFixed(4)
}

export function formatCELOWihCurrency(value) {
  let amount = BN(value)
  amount = amount.lt(MIN_VALUE_NUMBER.toString())
    ? amount.toFixed()
    : amount.toLocaleString('en-US', {
        maximumFractionDigits: 4
      })
  return parseFloat(amount).toFixed(2) + ` ${CURRENCIES.celo.text}`
}

export function formatAmount(value, type) {
  return type === 'CELO' ? formatCELO(value) : formatTokenValue(value, type)
}

export const currencyFormatter = (currency) => {
  if (currency === FIAT_CURRENCIES.eur.key) return formatCEUR
  return formatCUSD
}

export const cCurrencyFormatter = (currency) => {
  if (currency === FIAT_CURRENCIES.eur.key || currency === CURRENCIES.ceur.key)
    return formatCEUR
  if (currency === FIAT_CURRENCIES.usd.key || currency === CURRENCIES.cusd.key)
    return formatCUSD
  return formatCELOWihCurrency
}

export const formatByCurrency = (currency, value) => {
  if (currency === 'eur') return `€${value}`
  return `$${value}`
}

export const toMil = (value) => {
  if (value > 1000000) {
    let result = (value / 1000000).toFixed(2)
    result = `${result.toLocaleString()} M`
    return result
  } else {
    return Math.round(value).toLocaleString()
  }
}

export const getCurrencyFromFiat = (currency) => {
  switch (currency) {
    case FIAT_CURRENCIES.usd.key:
      return CURRENCIES.cusd.key
    case FIAT_CURRENCIES.eur.key:
      return CURRENCIES.ceur.key
    default:
      return null
  }
}

export const getMaxWithdrawAmountWithBorrowIn = (accountInfoDeposit, collateralData, totalDebtsCelo, exchangeRates, currentCurrency) => {
	let deposits = []
	accountInfoDeposit?.data?.data?.map((item) => {
		const getCollateralData = collateralData?.accountIsCollateralData.find((x) => x.currency === CURRENCIES[item.currency.toLowerCase()].key)
		if (getCollateralData.isCollateral) {
			deposits.push({
				key: item.currency,
				value: item.amount
			})
		}
	})

	let tempCollateral = deposits.filter((item) => {
		return {
		  ...item,
		  value: BN(item.value)
		}
	})
	
	// convert deposits to CELO anc calculate sum of all collateral
	const totalCollateralCelo =
		tempCollateral && tempCollateral.length
		? tempCollateral
			.map((collateralAsset) =>
				convertoToCelo(
				exchangeRates,
				collateralAsset.key,
				collateralAsset.value
				)
			)
			.reduce((a, b) => BN(a).plus(BN(b)), 0)
		: 0
	const maxValueInCelo = totalCollateralCelo.toFixed() === 0 || totalDebtsCelo.toFixed() === 0 ? 0 : BN(BN(totalCollateralCelo).minus(BN(totalDebtsCelo).dividedBy(BN(0.7499999999999))))

	return convertCELOToCurrentCurrency(currentCurrency, maxValueInCelo, exchangeRates)
}

const convertCELOToCurrentCurrency = (currency, amount, formattedExchangeRate) => {
	const result = BN(amount).multipliedBy(
		formattedExchangeRate[CURRENCIES.celo.key.toLowerCase()][currency.toLowerCase()]
	)
	return BN(amount).isGreaterThanOrEqualTo(0) ? result : 0
}

export const getTotalDebtsInCELO = (accountDebtData, exchangeRates) => {
	let debts = []
	accountDebtData?.accountDebtData?.map((item) => {
		debts.push({
		key: item.currency,
		value: BN(item.originationFee).plus(item.debt)
		})
	})

	// Initialize debts
	let tempDebts = debts.filter((item) => {
		if (BN(item.value).isGreaterThan(0)) {
		return { ...item, value: BN(item.value) }
		}
	})

	// convert debts to CELO anc calculate sum of all debts
	return tempDebts && tempDebts.length
		? tempDebts
			.map((debtAsset) =>
				convertoToCelo(exchangeRates, debtAsset.key, debtAsset.value)
			)
			.reduce((a, b) => BN(a).plus(b), 0)
		: 0
}

// export const getIsCollateralCurrency = (currency, )