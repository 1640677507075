import BN from 'bignumber.js'

/**
 * displays the decimals if there are any
 */
export const withOptionalDecimals = (value, decimals) => {
  return value.toLocaleString('en-US', {
    maximumFractionDigits: decimals
  })
}

export const withMandatoryDecimals = (value, decimals) => {
  return value.toLocaleString('en-US', {
    minimumFractionDigits: decimals
  })
}

/**
 * always display the given number of decimals
 */
export const withDecimals = (value, decimals) => {
  let result = 0
  if (typeof value == 'number') {
    result = value.toFixed(decimals)
  } else if (typeof value == 'object') {
    if (BN.isBigNumber(value)) {
      result = BN(value).decimalPlaces(decimals)
    }
  }
  return result
}

export const formatNumber = (value, decimals = 0) => {
  return withDecimals(parseFloat(value), decimals)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const feesFormat = (value, currency) => {
  if (value === 0) {
    return value.toFixed(2)
  }
  if (value <= 0.01) {
    return '<0.01'
  }
  return value.toFixed(5)
}

export const formatLocaleString = (value, minDecimals = 2, maxDecimals = 10) => {
  return value ? value.toLocaleString("en-US", {minimumFractionDigits: minDecimals, maximumFractionDigits: maxDecimals})
    : "N/A"
}

export const formatNumberWithoutRounding = (value, decimals) => {
  const tempValue = BN(value)
  if (!!tempValue?.c) {
	if (tempValue?.e < -decimals) {
		return tempValue.toFixed()
	}
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (decimals || -1) + '})?');
	let stringValue = value?.toString();
	if (stringValue && stringValue[0] === '.') {
		stringValue = '0' + stringValue;
	}
    return stringValue.match(re)[0];
  } else {
    return 0
  }
}