import BN from 'bignumber.js'
import { useContext, useEffect, useState } from 'react'
import { kit } from '../../root'
import { CURRENCIES } from '../../utils/constants'
import AppContext from '../AppContext'

const useAccountDepositData = () => {
  const { user } = useContext(AppContext)
  const [isLoading, setLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [accountDepositData, setAccountDepositData] = useState(null)

  const loadAllDeposit = async () => {
    try {
      const deposits = await Promise.all(
        Object.values(CURRENCIES).map(
          async (currency) => await loadDeposit(currency.key)
        )
      )
      setAccountDepositData(deposits)
      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsSuccess(false)
    }
    setLoading(false)
  }

  const loadDeposit = async (txType) => {
	const token = txType === 'CELO'
		? await kit.contracts.getGoldToken()
		: await kit.contracts.getStableToken(txType)

	const tokenBalanceBig = await token.balanceOf(kit.defaultAccount)
	const tokenBalance = tokenBalanceBig.dividedBy(BN(10).pow(18)).toString()

    const parsedData = {
      currency: txType,
      deposit: tokenBalance
    }
    return parsedData
  }

  useEffect(() => {
    if (user) {
		loadAllDeposit()
    }
  }, [user])

  return { accountDepositData, isLoading, isSuccess }
}

export default useAccountDepositData
