// COPY of "@celo/dappkit": "1.0.0-beta3" with fix for feeCurrency
// and with modified logic of awating response from mobile app using polling localStorage

import { CeloContract } from '@celo/contractkit'
import {
  AccountAuthRequest,
  serializeDappKitRequestDeeplink,
  DappKitRequestTypes,
  DappKitResponseStatus,
  SignTxRequest
} from '@celo/utils'
import { Linking } from 'expo'
import queryString from 'query-string'
import { CONTRACT_KIT } from '../utils/constants'

const isStorageAvailable = typeof(Storage) !== "undefined"
const localStorageKey = 'moola-app-dappkit';
// hack to get around dappkit issue where new tabs are opened
// and the url hash state is not respected (Note this implementation
// of dappkit doesn't use URL hashes to always force the newtab experience).
if (typeof window !== 'undefined' && isStorageAvailable) {
  localStorage.removeItem(localStorageKey);
  
  function isResponseInUrl() {
    const params = queryString.parse(window.location.hash.split('?')[1])
    if (params.type && params.requestId) {
      window.localStorage.setItem(localStorageKey, window.location.href);
      return true
    }
    return false
  }

  if (isResponseInUrl()) {
    window.close();
  }

  // handle Safari hash change
  window.onhashchange = isResponseInUrl;
}

// custom version instead of one from '@celo/utils'
function parseDappkitResponseDeeplink(url) {
  const params = queryString.parse(url.split('?')[1])
  return params
}

let cancellingRequestId = null
let currentRequestId = null
export function cancelRequest(requestId) {
  if (getCurrentReuqestId() === requestId) {
    cancellingRequestId = requestId;
  }
}

export function getCurrentReuqestId() {
  return currentRequestId;
}

async function waitForResponse(requestId) {
  currentRequestId = requestId
  while (true) {
    if (cancellingRequestId === requestId) {
      cancellingRequestId = null
      currentRequestId = null
      return null
    }
    try {
      const value = isStorageAvailable ? window.localStorage.getItem(localStorageKey) : null;
      console.log('Poll', value);
      if (value && isStorageAvailable) {
        window.localStorage.removeItem(localStorageKey);
        currentRequestId = null
        return value;
      }
    } catch(err) {
      console.log('Ooops', err)
    }
    await new Promise((resolve) => setTimeout(resolve, 600));
  }
}

export async function waitForAccountAuth(requestId: string) {
  const url = await waitForResponse(requestId);
  if (!url) {
    return null
  }
  const dappKitResponse = parseDappkitResponseDeeplink(url);
  if (
    requestId === dappKitResponse.requestId &&
    dappKitResponse.type === DappKitRequestTypes.ACCOUNT_ADDRESS &&
    dappKitResponse.status === DappKitResponseStatus.SUCCESS
  ) {
    return dappKitResponse;
  }

  console.log('Unable to parse url', url);
  throw new Error('Unable to parse Valora response');
}

export async function waitForSignedTxs(requestId) {
  const url = await waitForResponse(requestId);
  if (!url) {
    return null
  }

  const dappKitResponse = parseDappkitResponseDeeplink(url);
  if (
    requestId === dappKitResponse.requestId &&
    dappKitResponse.type === DappKitRequestTypes.SIGN_TX &&
    dappKitResponse.status === DappKitResponseStatus.SUCCESS
  ) {
    return dappKitResponse;
  }

  console.warn('Unable to parse url', url);
  throw new Error('Unable to parse Valora response');
}

export function requestAccountAddress(meta) {
  Linking.openURL(serializeDappKitRequestDeeplink(AccountAuthRequest(meta)))
}

export const FeeCurrency = {
  cUSD: 'cUSD',
  cGLD: 'cGLD',
  cEUR: 'cEUR'
}

async function getFeeCurrencyContractAddress(
  kit,
  feeCurrency
) {
  switch (feeCurrency) {
    case FeeCurrency.cUSD:
      return kit.registry.addressFor(CeloContract.StableToken)
    case FeeCurrency.cGLD:
      return kit.registry.addressFor(CeloContract.GoldToken)
    case FeeCurrency.cEUR: 
      return kit.registry.addressFor(CeloContract.StableTokenEUR)
    default:
      return kit.registry.addressFor(CeloContract.StableToken)
  }
}

export async function requestTxSig(
  kit,
  txParams,
  meta,
  walletType
) {
  // TODO: For multi-tx payloads, we for now just assume the same from address for all txs. We should apply a better heuristic
  const baseNonce = await kit.connection.nonce(txParams[0].from)
  const txs = await Promise.all(
    txParams.map(async (txParam, index) => {
      const feeCurrency = txParam.feeCurrency ? txParam.feeCurrency : FeeCurrency.cGLD
      const feeCurrencyContractAddress = await getFeeCurrencyContractAddress(kit, feeCurrency)
      const value = txParam.value === undefined ? '0' : txParam.value

      const estimatedTxParams = {
        feeCurrency: txParam.feeCurrency && feeCurrencyContractAddress,
        from: txParam.from,
        value,
      }
      const estimatedGas =
        txParam.estimatedGas === undefined
          ? await txParam.tx.estimateGas(estimatedTxParams)
          : txParam.estimatedGas

      return {
        txData: txParam.tx.encodeABI(),
        estimatedGas,
        nonce: baseNonce + index,
        feeCurrencyAddress: txParam.feeCurrency && feeCurrencyContractAddress,
        value,
        ...txParam,
      }
    })
  )
  const request = SignTxRequest(txs, meta)

  // For CeloExtensionWallet we use prepared tx objects for sending transaction manually
  if (walletType === CONTRACT_KIT) return txs;
  // For Valora wallets we open mobile app
  Linking.openURL(serializeDappKitRequestDeeplink(request))
}
