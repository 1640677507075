import styled from 'styled-components'

export const FloatingCard = styled.div`
  background: ${props => props.theme.palette.neutral.white};
  border: 1px solid ${props => props.theme.palette.neutral.grey1};
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 16px rgba(0, 0, 0, 0.04);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  & > *:first-child {
    margin-bottom: 16px;
  }
`
