import { Box, useTheme } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import BN from 'bignumber.js'
import CloseIcon from '../../assets/images/close.svg'
import WithdrawalIcon from '../../assets/images/withdrawal-fill.svg'
import ActionLayer from '../../components/ActionLayer'
import AmountBox from '../../components/AmountBox'
import BottomPopup from '../../components/BottomPopup'
import InfoEntry from '../../components/InfoEntry'
import SwitchPopup from '../../components/SwitchPopup'
import Card from '../../components/Card'
import Confirmation from '../../components/Confirmation'
import AppContext from '../../components/AppContext'
import {
  CURRENCIES,
  DEFAULT_CURRENCY,
  RISK_FACTOR_STATE,
  TRANSACTIONS
} from '../../utils/constants'
import {
  composeValidators,
  maxValue,
  mustBePositiveNumber,
  required
} from '../../utils/validators'
import { cancelRequest, FeeCurrency } from '../../sdk/dappkit'
import useTxRequest from '../../components/hooks/useTxRequest'
import useAccountReserveData from '../../components/hooks/useAccountReserveData'
import useNewAccountStatus from '../../components/hooks/useNewAccountStatus'
import { getContract, kit } from '../../root'
import AToken from '../../abi/AToken.json'
import requiresAuth from '../../components/HOCs/auth'
import {
  useGetFee,
  useGetSettingsCurrency,
  useGetUserAccountInfoBorrow,
  useGetUserAccountLiquidationPrices,
  useGetUserAccountInfoStatus,
  useGetExchangeRates,
  useGetUserAccountInfoDeposit
} from '../../api'
import {
  cCurrencyFormatter,
  getHealthFactor,
  maxUint256,
  formatCurrencyFromApi,
  getMaxWithdrawAmountWithBorrowIn,
  getTotalDebtsInCELO
} from '../../utils'

import {
  SecurityFeesInfo,
  AmountToWithdrawInfo,
  HealthScoreInfo,
  LiquidationPriceInfo
} from '../../components/InfoContent'
import Grid from '@material-ui/core/Grid'
import InfoIcon from '../../components/InfoIcon'
import {
  getCurrentAccountStatusData,
  getNewAccountStatusData
} from '../../utils/accountStatus'
import { WhitePage } from '../../utils/elements'
import {
  feesFormat,
  formatNumber,
  formatNumberWithoutRounding
} from '../../utils/format'
import { isMobile } from '../../utils/browser'
import CurrencyDesktopDropdown from '../../components/CurrencyDesktopDropdown'
import useAccountWithdrawData from '../../components/hooks/useAccountWithdrawData'
import { Alert } from '@material-ui/lab'
import userAccountIsCollateral from '../../components/hooks/userAccountIsCollateral'
import useAccountDebtData from '../../components/hooks/userAccountDebtData'
import { retrieveExchangeRates } from '../../utils/currency'

const styles = (theme) => ({
  container: {
	display: 'flex',
	'& > * ': {
		flexGrow: '1',
		margin: '16px'
	},
	'& > *:last-child': {
	    minWidth: '300px'
	}
  },
  wrapper: {
    paddingBottom: '30px',
    '& > *': {
      marginBottom: '16px'
    }
  },
  title: {
    fontFamily: 'Jost-700-Bold',
    fontSize: '20px',
    lineHeight: '24px'
  },
  infoEntry: {
    width: '100%',
    '& > *': {
      padding: '10px 16px'
    }
  },
  hidden: {
    display: 'none'
  },
  fullHeight: {
    height: '100%'
  },
  bolded: {
    fontFamily: 'Jost-700-Bold'
  },
  link: {
    color: theme.palette.common.link,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  error: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    zIndex: '9999',
    boxSizing: 'border-box'
  }
})

const NewWithdrawalScreen = ({ classes }) => {
  const { t } = useTranslation()
  const context = useContext(AppContext)
  const theme = useTheme()
  const { handleTxRequest } = useTxRequest()

  const [showCurrencyPopup, setShowCurrencyPopup] = useState(false)
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [apiError, setApiError] = useState(false)
  const [currencies, setCurrencies] = useState(
    Object.values(CURRENCIES).map((currency) => ({
      ...currency,
      selected: currency.key === DEFAULT_CURRENCY
    }))
  )
  const [desktopCurrencies, setDesktopCurrencies] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState()
  const [confirmation, setConfirmation] = useState(false)
  const [value, setValue] = useState(0)
  const [hasDebtValue, setHasDebt] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [maxAmount, setMaxAmount] = useState(0)
  const [maxValueSelected, setMaxValueSelected] = useState(false)
  const [customExternalError, setCustomExternalError] = useState(undefined)
  const [maxWithdrawDependencyLoaded, setMaxWithdrawDependencyLoaded] = useState(false)

  useEffect(() => {
    if (value && BN(value).isGreaterThan(0)) {
      if (BN(value).isEqualTo(BN(maxAmount))) {
        setMaxValueSelected(true)
      } else {
        setMaxValueSelected(false)
      }
    } else {
      setMaxValueSelected(false)
    }
  }, [value])
  const formName = useRef('amount')

  const { data: currentCurrency } = useGetSettingsCurrency()
  const accountInfoBorrow = useGetUserAccountInfoBorrow(context.user.address)
  const currentAccountInfoStatus = useGetUserAccountInfoStatus(
    context.user.address,
    currentCurrency
  )
  const userAccountLiquidationPrices = useGetUserAccountLiquidationPrices(
    context.user.address
  )
  const [{ isSuccess: isNewAccountStatusSuccess, data }] = useNewAccountStatus(
    value,
    currentCurrency,
    selectedCurrency,
    TRANSACTIONS.WITHDRAW
  )
  const fee = useGetFee(
    context.user.address,
    selectedCurrency?.text,
    TRANSACTIONS.WITHDRAW,
    parseFloat(value || 0),
    true
  )
  const [
    {
      accountReserveData: celoAccountReserveData,
      isLoading: isCeloAccountReserveLoading
    }
  ] = useAccountReserveData(CURRENCIES.celo.key)
  const [
    {
      accountReserveData: cusdAccountReserveData,
      isLoading: isCusdAccountReserveLoading
    }
  ] = useAccountReserveData(CURRENCIES.cusd.key)
  const [
    {
      accountReserveData: ceurAccountReserveData,
      isLoading: isCeurAccountReserveLoading
    }
  ] = useAccountReserveData(CURRENCIES.ceur.key)
  const [usedLoanTermsForCurrency, setUsedLoanTermsForCurrency] = useState(null)
  let txCurrency = currencies.find((currency) => currency.selected).text

  const collateralData = userAccountIsCollateral()
  const accountDebtData = useAccountDebtData()
  const exchangeRatesData = useGetExchangeRates()
  const accountInfoDeposit = useGetUserAccountInfoDeposit(context.user.address)

  useEffect(() => {
    if (accountInfoBorrow?.isError || userAccountLiquidationPrices?.isError || currentAccountInfoStatus?.isError) {
      setApiError(true)
    }
  }, [accountInfoBorrow, userAccountLiquidationPrices, currentAccountInfoStatus])

  useEffect(() => {
    if (accountInfoBorrow.isSuccess) {
      const hasAnyDebt = accountInfoBorrow.data?.data?.some((borrowInfo) => {
        return borrowInfo.amount !== 0
      })
      setHasDebt(hasAnyDebt)
    } else {
      setHasDebt(false)
    }
  }, [accountInfoBorrow])

  useEffect(() => {
    setSelectedCurrency(currencies.find((currency) => currency.selected))
  }, [currencies])

  useEffect(() => {
    setMaxWithdrawDependencyLoaded(
      accountInfoDeposit.isSuccess &&
        exchangeRatesData.isSuccess &&
        accountDebtData.isSuccess &&
		collateralData.isSuccess
    )
  }, [
    accountInfoDeposit.isSuccess,
    exchangeRatesData.isSuccess,
    accountDebtData.isSuccess,
	collateralData.isSuccess
  ])

  useEffect(() => {
    let tempMaxAmountFromDeposit = 0
    switch (selectedCurrency?.key) {
      case CURRENCIES.celo.key:
        tempMaxAmountFromDeposit = !isCeloAccountReserveLoading
          ? BN(celoAccountReserveData?.deposited || 0)
          : 0
        break
      case CURRENCIES.cusd.key:
        tempMaxAmountFromDeposit = !isCusdAccountReserveLoading
          ? BN(cusdAccountReserveData?.deposited || 0)
          : 0
        break
      case CURRENCIES.ceur.key:
        tempMaxAmountFromDeposit = !isCeurAccountReserveLoading
          ? BN(ceurAccountReserveData?.deposited || 0)
          : 0
        break
    }

	// let tempMaxAmountWithBorrowIn;
	// if (maxWithdrawDependencyLoaded) {
	// 	const exchangeRates = retrieveExchangeRates(exchangeRatesData.data)
	// 	const totalDebtsCelo = getTotalDebtsInCELO(accountDebtData, exchangeRates)
	// 	if (totalDebtsCelo) {
	// 		tempMaxAmountWithBorrowIn = getMaxWithdrawAmountWithBorrowIn(accountInfoDeposit, collateralData, totalDebtsCelo, exchangeRates, selectedCurrency.key)
	// 		setMaxAmount(BN(tempMaxAmountFromDeposit).comparedTo(BN(tempMaxAmountWithBorrowIn)) === -1 ? tempMaxAmountFromDeposit : tempMaxAmountWithBorrowIn)
	// 	} else {
	// 		setMaxAmount(tempMaxAmountFromDeposit)
	// 	}
	// } else {
	// 	setMaxAmount(tempMaxAmountFromDeposit)
	// }
	setMaxAmount(tempMaxAmountFromDeposit)
  }, [
    selectedCurrency,
    isCeloAccountReserveLoading,
    celoAccountReserveData,
    isCusdAccountReserveLoading,
    cusdAccountReserveData,
    isCeurAccountReserveLoading,
    isCeurAccountReserveLoading,
	maxWithdrawDependencyLoaded
  ])

  useEffect(() => {
    loadBorrowedLoanTermForCurrentCurrency()
  }, [selectedCurrency])

  const accountWithdrawData = useAccountWithdrawData()

  useEffect(() => {
	if (accountWithdrawData.isSuccess) {
		setDesktopCurrencies(currencies.map((currency) => ({
			...currency,
			value: getTotalWithdrawForCurrency(accountWithdrawData?.accountWithdrawData?.find((e) => e.currency === currency.text))
		})))
	}
  }, [accountWithdrawData.isSuccess, maxWithdrawDependencyLoaded])

  const getTotalWithdrawForCurrency = (currencyWithdrawInfo) => {
	// let tempMaxAmountWithBorrowIn;
	// if (maxWithdrawDependencyLoaded && currencyWithdrawInfo) {
	// 	const exchangeRates = retrieveExchangeRates(exchangeRatesData.data)
	// 	const totalDebtsCelo = getTotalDebtsInCELO(accountDebtData, exchangeRates)
	// 	if (totalDebtsCelo) {
	// 		tempMaxAmountWithBorrowIn = getMaxWithdrawAmountWithBorrowIn(accountInfoDeposit, collateralData, totalDebtsCelo, exchangeRates, currencyWithdrawInfo.currency)
	// 	} else {
	// 		return currencyWithdrawInfo ? currencyWithdrawInfo.withdraw : undefined
	// 	}
	// }
	// return currencyWithdrawInfo ? BN(currencyWithdrawInfo.withdraw).comparedTo(BN(tempMaxAmountWithBorrowIn)) === -1 ? currencyWithdrawInfo.withdraw : tempMaxAmountWithBorrowIn: undefined
	return currencyWithdrawInfo ? currencyWithdrawInfo.withdraw : undefined
  }

  const loadBorrowedLoanTermForCurrentCurrency = async () => {
    const {lendingPool, reserves} = await getContract()
    let userReserveData = await lendingPool.methods
      .getUserReserveData(reserves[txCurrency], context.user.address)
      .call()
    setUsedLoanTermsForCurrency(parseInt(userReserveData.borrowRateMode))
  }

  const selectCurrency = (key) => {
    const updatedCurrencies = currencies.map((currency) => {
      currency.selected = currency.key === key
      return currency
    })

    setCurrencies(updatedCurrencies)

	const updatedDesktopCurrencies = desktopCurrencies.map((currency) => {
		currency.selected = currency.key === key
		return currency
	})

	setDesktopCurrencies(updatedDesktopCurrencies)
  }

  const submit = () => {
    setConfirmation(true)
  }

  const processOperation = async () => {
    let txCurrency = currencies.find((currency) => currency.selected).text
    const operation = 'redeem'
    const requestId = `${operation}${txCurrency}`
    const amount = value
    cancelRequest(requestId)

    const isCelo = txCurrency === CURRENCIES.celo.text
    const { lendingPool, reserves } = await getContract()
    const isPayAll = maxValueSelected
    const rawAmount = BN(10).pow(18).multipliedBy(amount).toFixed(0)
    const valueToWithdraw = isPayAll ? maxUint256 : rawAmount

    try {
      const mtoken = new kit.web3.eth.Contract(
        AToken,
        (
          await lendingPool.methods.getReserveData(reserves[txCurrency]).call()
        ).aTokenAddress
      )
      const isTransferAllowed = await mtoken.methods
        .isTransferAllowed(context.user.address, rawAmount)
        .call()
      if (!isTransferAllowed) {
        throw Error(
          'You cannot withdraw this amount. Repay your debts or decrease the amount.'
        )
      }
      const txObject = mtoken.methods.redeem(valueToWithdraw)

      const txParam = {
        tx: txObject,
        from: context.user.address,
        to: mtoken.options.address,
        estimatedGas: 2000000,
        feeCurrency: isCelo ? undefined : FeeCurrency[txCurrency]
      }

      const txParams = [txParam]

      handleTxRequest(operation, requestId, txCurrency, amount, txParams)
    } catch (error) {
      console.log(error)
      context.showError(error.message)
    }
  }

  const content = ({ form }) => {
    const name = formName.current
    const fieldState = form.getFieldState(name)
    const currentAccountStatus = currentAccountInfoStatus.data
    const newAccountStatus = data
    const liquidationPrices = userAccountLiquidationPrices?.data
    const newAccountStatusData = getNewAccountStatusData(
      t,
      classes,
      currentCurrency,
      newAccountStatus,
      selectedCurrency,
      usedLoanTermsForCurrency
    )
    const currentAccountStatusData = getCurrentAccountStatusData(
      t,
      classes,
      selectedCurrency?.text,
      currentAccountStatus,
      liquidationPrices,
      usedLoanTermsForCurrency
    )

    if (!!fieldState?.value && newAccountStatus?.currentLTV > 75) {
      if (customExternalError === undefined) {
        setCustomExternalError({overMaximumLTV: true})
      }
    } else {
      setCustomExternalError(undefined)
    }

    return (
	  <div className={!isMobile ? classes.container: ''}>
		<Box className={classes.wrapper}>
			<Grid
				container
				alignItems={'center'}
				onClick={() => {
					setSelectedItem({
					text: t('WITHDRAWAL.AMOUNT_WITHDRAW'),
					info: {
						content: amountToWithdrawInfo
					}
					})
				}}
			>
				<Typography className={classes.title}>
					{t('WITHDRAWAL.AMOUNT_WITHDRAW')}
				</Typography>
				<InfoIcon
					maxRight={isMobile}
					title={t('WITHDRAWAL.AMOUNT_WITHDRAW')}
					text={amountToWithdrawInfo}
				/>
			</Grid>
			{selectedCurrency && (
				<AmountBox
					name={name}
					validators={composeValidators(
					mustBePositiveNumber,
					required,
					maxValue(maxAmount, selectedCurrency.text, TRANSACTIONS.WITHDRAW)
					)}
					formControl={fieldState}
					switchClick={() =>  isMobile ? setShowCurrencyPopup(true) : setShowCurrencyDropdown(true)}
					currency={selectedCurrency}
					onValueChange={setValue}
					inputFirst={true}
					customExternalError={customExternalError}
				>
					{showCurrencyDropdown &&
						(<CurrencyDesktopDropdown
							currencies={desktopCurrencies}
							selectCurrency={(key) => {
								selectCurrency(key)
								form.reset()
								form.resetFieldState(formName.current)
								setShowCurrencyDropdown(false)
							}}
							currentCurrency={currentCurrency}
						>
						</CurrencyDesktopDropdown>)
					}
					<Box className={classes.infoEntry}>
						<InfoEntry
							left={t('GENERAL.MAX_AMOUNT')}
              right={`${formatNumberWithoutRounding(maxAmount,CURRENCIES[selectedCurrency.text.toLowerCase()]?.decimals)} ${selectedCurrency.text}`}
              clicked={() => {
                form.change(
                  name,
                  formatNumberWithoutRounding(maxAmount, CURRENCIES[selectedCurrency.text.toLowerCase()]?.decimals)
                )
                setValue(maxAmount)
              }}
						/>
					</Box>
				</AmountBox>
			)}
		</Box>
		<Box className={classes.wrapper}>
			{hasDebtValue && (
			<>
				{!!fieldState?.value && (
				<>
					<Typography className={classes.title}>
					{t('GENERAL.NEW_ACCOUNT_STATUS')}
					</Typography>

					<Card cardData={newAccountStatusData} />
				</>
				)}
				{!fieldState?.value && (
				<>
					<Typography className={classes.title}>
					{t('GENERAL.CURRENT_ACCOUNT_STATUS')}
					</Typography>

					<Card cardData={currentAccountStatusData} />
				</>
				)}
			</>
			)}

			<BottomPopup
				show={showCurrencyPopup}
				label={t('GENERAL.SWITCH_CURRENCY')}
				onClose={() => setShowCurrencyPopup(false)}
				icon={CloseIcon}
			>
				<SwitchPopup
					currencies={currencies}
					selectCurrency={(key) => {
					selectCurrency(key)
					form.reset()
					form.resetFieldState(formName.current)
					setShowCurrencyPopup(false)
					}}
				/>
			</BottomPopup>
			{selectedItem && (
				<BottomPopup
					show={true}
					label={selectedItem.text}
					onClose={() => setSelectedItem(null)}
					icon={CloseIcon}
					children={selectedItem.info.content}
					primaryButtonTitle={t('GENERAL.DONE')}
					primaryButtonClick={() => setSelectedItem(null)}
				/>
			)}
		</Box>
	  </div>
    )
  }

  const fundingDestinationInfo = (
    <Typography>
      {t('WITHDRAWAL.FUNDING_DESTINATION.INFO', {
        currency: selectedCurrency?.text
      })}
    </Typography>
  )

  const amountToWithdrawInfo = (
    <>
      <Typography component={'p'}>
        {t('WITHDRAWAL.AMOUNT_WITHDRAWAL.INFO.1')}
      </Typography>
      <Typography component={'p'}>
        {t('WITHDRAWAL.AMOUNT_WITHDRAWAL.INFO.2')}
      </Typography>
    </>
  )

  const getConfirmationContent = () => {
    const selectedCurrencyText = selectedCurrency?.text

    let securityFee = 0
    if (fee.isSuccess && fee.data) {
      securityFee = fee?.data?.securityFee
    }
    const amountToWithdraw = value - securityFee

    const content = {
      message: t('WITHDRAWAL.CONFIRM_WITHDRAWAL_AMOUNT', {
        amount: formatNumberWithoutRounding(value, CURRENCIES[selectedCurrency.text.toLowerCase()]?.decimals),
        currency: selectedCurrencyText
      }),
      items: [
        {
          text: t('WITHDRAWAL.FUNDING_DESTINATION'),
          value: context.user.walletName,
          info: fundingDestinationInfo
        },
        {
          text: t('WITHDRAWAL.WITHDRAW_AMOUNT'),
          value: `${formatNumberWithoutRounding(value, CURRENCIES[selectedCurrency.text.toLowerCase()]?.decimals)} ${selectedCurrencyText}`,
          info: <AmountToWithdrawInfo />
        },
        {
          text: t('GENERAL.SECURITY_FEES'),
          value: `${feesFormat(
            securityFee,
            selectedCurrencyText
          )} ${selectedCurrencyText}`,
          info: <SecurityFeesInfo classes={classes} />
        }
        // {
        //   text: t('GENERAL.TOTAL'),
        //   value: `${amountToWithdraw} ${selectedCurrencyText}`,
        //   isTotal: true
        // }
      ]
    }

    if (hasDebtValue) {
      const newData = data
      const healthFactor = getHealthFactor(newData)
      content.additionalItems = [
        {
          text: t('GENERAL.NEW_HEALTH_FACTOR'),
          value: `${healthFactor} ${t('GENERAL.' + newData?.state)}`,
          info: <HealthScoreInfo classes={classes} />,
          scale: RISK_FACTOR_STATE[newData?.state]
        }
      ]

      newData?.collateralAssets?.forEach((collateralAsset) => {
        if (
          collateralAsset.currency.toLowerCase() !==
          collateralAsset.priceCurrency.toLowerCase()
        ) {
          content.additionalItems.push({
            text: t('GENERAL.NEW_CURRENCY_LIQUIDATION_PRICE', {
              currency: formatCurrencyFromApi(collateralAsset.currency)
            }),
            value: cCurrencyFormatter(collateralAsset.priceCurrency)(
              formatNumber(collateralAsset.liquidationPrice, CURRENCIES[collateralAsset.currency?.toLowerCase()]?.decimals)
            ),
            info: <LiquidationPriceInfo />
          })
        }
      })
    }

    return content
  }

  return (
    <WhitePage theme={theme}>
      {apiError && <Alert className={classes.error} severity='error' onClose={() => setApiError(false)}>{t('GENERAL.API_ERROR')}</Alert>}
      <Box
        className={classNames(
          classes.fullHeight,
          confirmation && classes.hidden
        )}
      >
        <ActionLayer
          icon={WithdrawalIcon}
          title={t('WITHDRAWAL.WITHDRAW')}
          subtitle={t('WITHDRAWAL.HELPER_TEXT')}
          homeOnBack={!isMobile}
          content={content}
          buttonClick={submit}
          customExternalError={customExternalError}
        />
      </Box>

      {confirmation && (
        <Confirmation
          content={getConfirmationContent()}
          back={() => setConfirmation(false)}
          confirmation={processOperation}
          isLoading={fee.isLoading}
        />
      )}
    </WhitePage>
  )
}

export default requiresAuth(withStyles(styles)(NewWithdrawalScreen))
