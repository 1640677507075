import { useEffect, useContext, useState } from 'react'
import AppContext from '../AppContext'
import { print } from '../../utils'
import { getContract } from '../../root'
import { CURRENCIES } from '../../utils/constants'

const useAccountDebtData = () => {
  const { user } = useContext(AppContext)
  const [isLoading, setLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [accountDebtData, setAccountDebtData] = useState(null)

  const loadAllDebt = async () => {
    try {
      const debts = await Promise.all(
        Object.values(CURRENCIES).map(
          async (currency) => await loadDebt(currency.key)
        )
      )
      setAccountDebtData(debts)
      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsSuccess(false)
    }
    setLoading(false)
  }

  const loadDebt = async (txType) => {
    const { lendingPool, reserves } = await getContract()
    const data = await lendingPool.methods
      .getUserReserveData(reserves[txType], user.address)
      .call()
    const parsedData = {
      currency: txType,
      borrowed: print(data.principalBorrowBalance),
      debt: print(data.currentBorrowBalance),
      originationFee: print(data.originationFee)
    }
    return parsedData
  }

  useEffect(() => {
    if (user) {
      loadAllDebt()
    }
  }, [user])

  return { accountDebtData, isLoading, isSuccess }
}

export default useAccountDebtData
