import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useGetUserAccountInfoBorrow } from '../../../api'
import FlowButton from '../../../components/FlowButton'
import DepositIcon from '../../../assets/images/deposit.svg'
import WithdrawIcon from '../../../assets/images/withdraw.svg'
import BorrowIcon from '../../../assets/images/borrow.svg'
import MigrateArrow from '../../../assets/images/migrate-arrow.svg'
import RepayIcon from '../../../assets/images/repay.svg'
import Twitter from '../../../assets/images/twitter.svg'
import Discord from '../../../assets/images/discord.svg'
import Language from '../../../assets/images/language.svg'
import Help from '../../../assets/images/help.svg'
import Currency from '../../../assets/images/currency.svg'
import Terms from '../../../assets/images/terms.svg'
import Privacy from '../../../assets/images/privacy.svg'
import MooToken from '../../../components/Moo-token'
import PlusIcon from '../../../assets/images/plus.svg'
import styles from './styles'
import { CONTRACT_KIT } from '../../../utils/constants'
import ConditionalRender from '../../../utils/conditional-render'
import userAccountIsCollateral from '../../../components/hooks/userAccountIsCollateral'
import useLogin from '../../../components/hooks/useLogin'
import PropTypes from 'prop-types'
import config from '../../../config'

const LeftMenu = ({
  t,
  classes,
  user,
  mooToken,
  expanded,
  totalValue,
  state,
  forceRefresh
}) => {
  const history = useHistory()
  const login = useLogin()
  const [hasDebtValue, setHasDebt] = useState(false)
  const accountInfoBorrow = useGetUserAccountInfoBorrow(
    user?.address,
    forceRefresh
  )
  const collateralData = userAccountIsCollateral(forceRefresh)
  const hasCollateralFunds =
    collateralData &&
    collateralData.accountIsCollateralData?.find((item) => item.isCollateral)
  const location = useLocation()

  const url_string = window.location.origin + location.search
  const url = new URL(url_string)
  const [currentPage, setCurrentPage] = useState('')

  useEffect(() => {
    if (accountInfoBorrow.isSuccess) {
      const hasAnyDebt = accountInfoBorrow.data?.data?.some((borrowInfo) => {
        return borrowInfo.amount !== 0
      })
      setHasDebt(hasAnyDebt)
    }
  }, [accountInfoBorrow])

  useEffect(() => {
    setCurrentPage(url.searchParams.get('state') || state)
  }, [location])

  const connectCeloExtensionWallet = () => {
    login(CONTRACT_KIT)
  }

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )

  const redirectToPage = (val) => {
    history.push(`?state=${val}`)
    setCurrentPage(val)
  }

  const extraButtons = (
    <Grid className={classes.extraButtons}>
      <FlowButton
        label={t('APPMENU.LANGUAGE')}
        icon={Language}
        isTransparent={true}
        selected={currentPage === 'languages'}
        onClick={() => redirectToPage('languages')}
      />
      <FlowButton
        label={t('APPMENU.CURRENCY')}
        icon={Currency}
        selected={currentPage === 'currencies'}
        isTransparent={true}
        onClick={() => redirectToPage('currencies')}
      />
    </Grid>
  )

  return (
    <Box className={classes.menuWrapper}>
      <ConditionalRender if={!user}>
        <Grid
          container
          direction={'column'}
          alignItems={'center'}
          className={
            expanded ? classes.desktopMenuExpanded : classes.desktopMenu
          }
        >
          <Grid item className={classes.connectButton}>
            <FlowButton
              label={t('HOME.APPHEADER.CONNECT_WALLET')}
              icon={PlusIcon}
              isTransparent={false}
              onClick={() => {
                if (isMobile) {
                  history.push('?state=connect-wallet')
                } else {
                  connectCeloExtensionWallet()
                }
              }}
            />
            {extraButtons}
          </Grid>
        </Grid>
      </ConditionalRender>

      <ConditionalRender if={user}>
        <Grid
          container
          direction={'column'}
          alignItems={'center'}
          className={
            expanded ? classes.desktopMenuExpanded : classes.desktopMenu
          }
        >
          <Grid className={classes.migrateButton}>
            <FlowButton
              label={t('V2.GO_TO')}
              icon={MigrateArrow}
              isTransparent={false}
              onClick={() => window.open(config.urlV2, '_self')}
            />
          </Grid>
          {/* <FlowButton
            label={t('GENERAL.DEPOSIT')}
            icon={DepositIcon}
            selected={currentPage === 'deposit'}
            isTransparent={!!totalValue}
            onClick={() => redirectToPage('deposit')}
          /> */}

          <ConditionalRender if={totalValue}>
            <FlowButton
              label={t('GENERAL.WITHDRAW')}
              icon={WithdrawIcon}
              selected={currentPage === 'withdrawal'}
              isTransparent={true}
              onClick={() => redirectToPage('withdrawal')}
            />
            {/* <FlowButton
              label={t('GENERAL.BORROW')}
              icon={BorrowIcon}
              isTransparent={true}
              selected={currentPage === 'borrow'}
              onClick={() =>
                redirectToPage(hasCollateralFunds ? 'borrow' : 'no-collateral')
              }
            /> */}
          </ConditionalRender>
          <ConditionalRender if={hasDebtValue}>
            <FlowButton
              label={t('GENERAL.REPAY')}
              icon={RepayIcon}
              isTransparent={true}
              selected={currentPage === 'repay'}
              onClick={() => redirectToPage('repay')}
            />
          </ConditionalRender>
          {/* 
          {mooToken && !mooToken.isClaimed && (
            <MooToken selected={currentPage === 'moo'} mooToken={mooToken} />
          )} */}

          {extraButtons}
        </Grid>
      </ConditionalRender>

      <Grid
        container
        direction={'column'}
        alignItems={'center'}
        className={expanded ? classes.desktopMenuExpanded : classes.desktopMenu}
      >
        <FlowButton
          label={t('APPMENU.HELP')}
          icon={Help}
          isTransparent={true}
          onClick={() => {
            window.open('mailto:info@moola.market', '_blank')
          }}
        />
        <FlowButton
          label={t('APPMENU.TWITTER')}
          icon={Twitter}
          isTransparent={true}
          onClick={() => {
            window.open('https://twitter.com/Moola_Market', '_blank')
          }}
        />
        <FlowButton
          label={t('APPMENU.DISCORD')}
          icon={Discord}
          isTransparent={true}
          onClick={() => {
            window.open('http://discord.gg/NsphyqbESK', '_blank')
          }}
        />
        <FlowButton
          label={t('SETTINGS.TERMS')}
          selected={currentPage === 'terms'}
          icon={Terms}
          isTransparent={true}
          onClick={() => redirectToPage('terms')}
        />
        <FlowButton
          label={t('SETTINGS.PRIVACY')}
          selected={currentPage === 'privacy-policy'}
          icon={Privacy}
          isTransparent={true}
          onClick={() => redirectToPage('privacy-policy')}
        />
      </Grid>
    </Box>
  )
}

export default withTranslation()(withStyles(styles)(LeftMenu))

LeftMenu.propTypes = {
  user: PropTypes.object,
  mooToken: PropTypes.object,
  expanded: PropTypes.bool,
  totalValue: PropTypes.number,
  state: PropTypes.string
}
