import React from 'react'
import { useTheme } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { isMobile } from '../../utils/browser'

const styles = (theme) => ({
  root: {
    color: isMobile ? theme.palette.text.terms : theme.palette.text.termsDesktop,
    fontFamily: 'Jost-400-Book',
    padding: '4% 10%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
    }
  },
  title: {
    fontSize: '30px',
    textAlign: 'center',
    marginBottom: '10px',
    fontWeight: '600',
    fontFamily: 'EBGaramond-600-SemiBold'
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '20px',
    marginBottom: '50px',
    fontWeight: 'Normal',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px'
    }
  },
  list: {
    '& li': {
      fontSize: '30px',
      lineHeight: '56px',
      fontWeight: '600',
      fontFamily: 'EBGaramond-600-SemiBold',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '32px',
      }
    },
    '& p': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: '400',
      fontFamily: 'Jost-400-Book',
      '& a': {
        [theme.breakpoints.down('sm')]: {
          wordBreak: ' break-all'
        },
      },
      '& .bold': {
        fontWeight: 'Bold',
        fontFamily: 'Jost-400-Book'
      }
    }
  },
  italic: {
    fontStyle: 'italic',
    fontFamily: 'Jost-400-Book'
  },
  link: {
    textDecoration: 'underline',
    color: isMobile ? theme.palette.text.terms : theme.palette.text.termsDesktop,
  },
  secondaryList: {
    paddingLeft: '50px',
    '& li': {
      listStyleType: 'disc',
      fontSize: '16px',
      lineHeight: '26px',
      padding: '5px 0'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '30px',
    },
  },
  secondaryOlListRoman: {
    paddingLeft: '50px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '30px'
    },
    '& li': {
      listStyleType: 'lower-roman',
      fontSize: '16px',
      lineHeight: '26px',
      padding: '5px 0'
    }
  },
  secondaryOlList: {
    paddingLeft: '50px',
    '& li': {
      fontSize: '16px',
      lineHeight: '26px',
      padding: '10px 0'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '30px'
    }
  },
  bold: {
    fontWeight: 'Bold !important',
    fontSize: '20px !important',
    fontFamily: 'Jost-400-Book !important'
  }
})

const Terms = ({ t, classes }) => {
  const theme = useTheme()

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>Terms of Service</h1>
      <h2 className={classes.subtitle}>Last Updated: March 21, 2021</h2>
      <ol className={classes.list}>
        <li>
          Overview
          <p>The following Terms govern your use of our Services, including, but not limited to, our web interface
            located at <a className={classes.link} href='https://moola.market/'
                          target='_blank'>https://moola.market/</a> (“Site”) and our corresponding application
            (<a className={classes.link} href='https://app.moola.market/'
                target='_blank'>app.moola.market</a>) that
            integrates with the Valora mobile application (collectively, the “Services”).</p>
          <p>These Terms of Service (the “Terms”) are a binding contract between you and Moola, Inc. (“Moola”). As used
            in these Terms, “we”, “us”, or “our” also refers to Moola.</p>
          <p>You must agree to and accept all of the Terms, or you do not have the right to use the Services. Your use
            of the Services in any way means that you agree to all of these Terms, and these Terms will remain in effect
            while you use the Services. These Terms include the provisions in this document, as well as those in our
            <a className={classes.link} target='_blank'
               href='/#/privacy-policy'> Privacy
              Policy</a>, and any other terms and conditions that Moola may reference or incorporate into these Terms
            from time to time.</p>
          <p><b>THE TERMS CONTAIN AN ARBITRATION PROVISION. BY AGREEING TO THESE TERMS, YOU AGREE
            AND UNDERSTAND THAT DISPUTES ARISING UNDER THESE TERMS SHALL BE SETTLED IN BINDING ARBITRATION. YOU ALSO
            AGREE AND UNDERSTAND THAT ENTERING INTO THIS AGREEMENT CONSTITUTES A WAIVER OF YOUR RIGHT TO A TRIAL BY JURY
            OR PARTICIPATION IN A CLASS ACTION LAWSUIT OR A JURY TRIAL.</b></p>
          <p>You acknowledge and agree that these Terms are the complete and exclusive statement of the mutual
            understanding between you and Moola, and that it supersedes and cancels all previous written and oral
            agreements, communications, and other understandings relating to the subject matter of these Terms. You
            hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of Moola, and
            you do not have any authority of any kind to bind Moola in any respect whatsoever.</p>
        </li>

        <li>
          Eligibility to Use Moola’s Services
          <p>To access or use Moola’s Services, you must be able to form a legally binding contract with Moola. You
            therefore represent and warrant that you are of legal age to form a binding contract.</p>
          <p><b>Ages 18 and Over Only.</b> The Site is intended for those ages 18 and over. If you are under 18 years of
            age, please do not use Moola Services.</p>
          <p>In accordance with the federal Children's Online Privacy Protection Act of 1998 (COPPA), Moola will never
            knowingly solicit nor will it accept personally identifiable information from individuals known to be under
            thirteen (13) years of age. Please see our <a className={classes.link} target='_blank'
                                                          href='/#/privacy-policy'>Privacy
              Policy</a> for more information
            including how to notify Moola of any concerns in this regard.</p>
          <p>You will only use the Services for your own personal use, and not on behalf of or for the benefit of any
            third party, and only in a manner that complies with all laws that apply to you. If your use of the Services
            is prohibited by applicable laws or regulations, or if you are a citizen, resident, or member of any
            jurisdiction or group that is subject to economic sanctions by the United States or other countries, then
            you are not authorized to use the Services. If permits and licenses are required for you to use the
            Services, it is your responsibility to first obtain those prior to such use. Moola is not responsible for
            your using the Services in a way that breaks any law or regulation.</p>
        </li>

        <li>
          Data Privacy
          <p>Moola takes the privacy of our users very seriously. To view our current Privacy Policy, please click <a
            className={classes.link} target='_blank' href='/#/privacy-policy'>here.</a></p>
        </li>

        <li>
          Description of Services
          <p>The Services consist of a web-hosted user interface on the Site and our corresponding application.
            Integrated with the Valora application, Moola (<a className={classes.link} href='https://app.moola.market/'
                                                              target='_blank'>app.moola.market</a>) is a DeFi
            application (dApp) that enables
            holders of cUSD and CELO to earn compound interest with the value that an individual holds in Valora. Moola
            works by providing yield to Celo’s stablecoin, cUSD, and its native asset, CELO. The functions available
            include the deposit, borrowing, repaying, and withdrawal of Celo’s stablecoin, cUSD, and its native asset,
            CELO.</p>
          <p>Celo assets exist on an independent blockchain and cannot be accessed through wallets that connect to the
            Ethereum network. For more information, on cUSD and CELO, please visit the Celo Foundation website at <a
              className={classes.link} target='_blank'
              href='https://docs.celo.org/overview'>https://docs.celo.org/overview</a>.
          </p>
          <p>Our Services may involve accessing certain Virtual Currency markets, boards, or interfaces allowing for
            complex financial transactions. “Virtual Currency” refers to cryptocurrency, digital currency, digital
            asset, crypto asset, or other such similar term. Moola may offer certain scripts, interactions, or other
            modules that allow you to automate or combine some of these complex financial transactions. You understand
            that interacting with any of these systems or code is ultimately your own decision, and Moola has no control
            or responsibility for the results of your transactions.</p>
          <p><b>Through the use of the Valora application (https://valoraapp.com/terms) to use the Moola application
            (<a className={classes.link} href='https://app.moola.market/'
                target='_blank'>app.moola.market</a>),</b> you will be asked to create an Account Key. An Account Key is
            a special kind of
            password. The Account Key is the one and only way to access your account. Without your key, you can lose
            your access forever.</p>
          <p>If you lose your phone, you must have your key to get your account back. Nobody, not even Valora, will be
            able to recover your funds without it. The Valora application will ask you to write down your Account Key
            and then have you verify your Account Key. You should keep your Account Key private and do not save your
            Account Key on your phone. Anyone with your Account Key will have access to your account and all of its
            funds. Do not share it with others. The Valora application requires you to confirm that you understand the
            above by clicking “I Understand.”</p>
          <p>When you visit <a className={classes.link} href='https://app.moola.market/'
                               target='_blank'>app.moola.market</a>, Moola will ask if you would like to connect with
            Valora. The Moola app
            will request and require access to the following information:</p>
          <ul className={classes.secondaryList}>
            <li>Phone Number; and</li>
            <li>Your Valora Wallet Identification.</li>
          </ul>
          <p>Once you provide this information to Moola, you will be asked to click “Allow” to share this information
            with Valora.</p>
          <p>With the use of Moola, you will have the opportunity to add funds and cash out funds. When you request to
            make a transfer of Virtual Currency, in order to initiate such transfer on our Services, you will be
            required to initiate a transfer from the Valora interface that interacts with our Services that may require
            use of your private key or other authentication method for accessing your Celo Wallet (a “Transfer
            Initiation”) through the Valora application. You understand and agree that we are entitled to rely on the
            Transfer Initiation and have no duty to inquire into or investigate the validity or accuracy of any Transfer
            Initiation. You will be responsible for keeping your hardware devices, including your phone, secure and for
            any activity associated with such devices and your Celo Wallet when using our Services. Moola will not be
            responsible if someone else accesses your devices and authorizes a transaction upon receipt of a valid
            Transfer Initiation.</p>
          <p>Regardless of your method of authentication or accessing your Wallet, Moola will never have access to
            Virtual Currency in your Celo Wallet, Moola will not store your access key or similar methods of accessing
            your Wallet, and Moola will never request this information. Moola cannot initiate a transfer of Virtual
            Currency on your behalf.</p>
          <p>Moola is not your broker, intermediary, agent, advisor, or custodian, and Moola does not have a fiduciary
            relationship or obligation to you regarding any other decisions or activities that you effect when using
            Valora or our Services. Moola is not responsible for any activities that you engage in when using your
            Valora, and you should understand the risks associated with Virtual Currency, blockchain technology
            generally, and our Services.</p>
        </li>

        <li>
          Restrictions and Requirements for our Services
          <p>You represent, warrant, and agree that you will not use the Services in any way that:</p>
          <ul className={classes.secondaryList}>
            <li>Violates any law or regulation, including, without limitation, any applicable export control laws;</li>
            <li>Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise
              objectionable;
            </li>
            <li>Jeopardizes the security of your Celo Wallet or anyone else’s;</li>
            <li>Attempts, in any manner, to obtain the private key, password, account, or other security information
              from any other user;
            </li>
            <li>Attempts to access another user’s Celo Wallet, private key or other security information on any third-
              party site or services that provide access to such user’s Wallet or private key on our Services;
            </li>
            <li>Violates the security of any computer network, or cracks any passwords or encryption codes; or</li>
            <li>Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or
              information of or relating to the Services.
            </li>
          </ul>
          <p>A violation of any of the foregoing is grounds for termination of your right to use or access the Services
            to the extent possible. However, in no event do we have the ability to access or suspend your access to your
            own Celo Wallet, and you are always entitled to use your Celo Wallet on other services.</p>
        </li>

        <li>
          Assumption of Risk
          <p>In order to be successfully completed, any transaction created with or sent to your Celo Wallet using our
            Services must be confirmed and recorded on the Celo network. Moola has no control over the Celo network or
            any other Virtual Currency and, therefore, cannot and will not ensure that any transaction details you
            submit or receive via our Services will be confirmed on the Celo network. Moola does not have the ability to
            facilitate any cancellation or modification requests.</p>
          <p>Moola cannot assist with you any financial or technical advice with these transactions. Although certain of
            our Services may combine and automate multiple transactions into more manageable steps, you are responsible
            for understanding each of the underlying transactions if you wish to use these specific Services.</p>
          <p>By using our Services, you acknowledge and accept that there are substantial risks associated with Virtual
            Currency and transactions on the Celo network. In addition to the above risks, you agree and understand that
            you:</p>
          <ol className={classes.secondaryOlList}>
            <li>Have the necessary technical expertise and ability to review and evaluate the security, integrity, and
              operation of Valora and your Celo Wallet;
            </li>
            <li>Have the knowledge, experience, understanding, professional advice, and/or information to make your own
              evaluation of the merits, risks and applicable compliance requirements under applicable laws of any use of
              Valora and your Celo Wallet with our Services and are not relying on us; and
            </li>
            <li>Accept the risks associated with Virtual Currency and blockchain technology generally. You further
              assume and agree that we will have no responsibility or liability for, such risks. You hereby irrevocably
              waive, release, and discharge any and all claims, whether known or unknown to you, against us, our
              affiliates and their respective shareholders, members, directors, officers, employees, agents and
              representatives related to any of the risks set forth in these Terms.
            </li>
          </ol>
          <p>Moola does not assume responsibility for any inherent risks associated with blockchain technology,
            including, but not limited to, design or implementation flaws that affect the operation of the Celo network,
            or cryptographic advances that may undermine the security framework of certain blockchain technologies.</p>
          <p>Moola makes no guarantee as to the functionality or security of the Celo network, which could, among other
            things, lead to loss of funds, hacks, delays, conflicts of interest, or operational decisions by third
            parties that are unfavorable to certain owners of Virtual Currency, or lead to your inability to complete a
            transaction using our Services.</p>
          <p>You acknowledge and accept that the protocols governing the operation of the Celo network may be subject to
            sudden changes in operating rules, which may materially alter the network, affect the value and function of
            a particular Virtual Currency or otherwise render you unable to conduct or complete transactions using our
            Services.</p>
          <p>You take responsibility for all activities and transactions that occur in connection with your use of our
            Services and your Celo Wallet and accept all risks and consequences of your use of our Services and any
            authorized or unauthorized access to your Celo Wallet, to the maximum extent permitted by law.</p>
          <p>Moola makes no warranties as to the markets in which the Virtual Currency are transferred, purchased, and
            traded, or the advisability of buying particular Virtual Currencies.</p>
          <p>Moola is not liable for any hacks or malicious attempts to obtain access to your Celo Wallet, or any loss
            or destruction of security credentials or authentication methods for accessing your Celo Wallet. You alone
            are responsible for protecting your security information.</p>
          <p>The transaction details you submit via the Services may not be completed, or may be substantially delayed
            on the Celo network, and we take no responsibility for the failure of a transaction to be confirmed or
            processed as expected</p>
          <p>The application of existing legal and regulatory requirements to Virtual Currency and our Services is
            developing and evolving. Moola may rely on advice of counsel concerning the application of existing and new
            legal and regulatory requirements to its activities. This advice may require Moola to make sudden changes to
            our Services that may impact your ability to use our Services.</p>
        </li>

        <li>
          Intellectual Property
          <p>The materials displayed or performed or available on or through the Services, including, but not limited
            to, text, graphics, data, articles, photos, images, illustrations, and so forth (all of the foregoing, the
            “Content”) are protected by copyright and/or other intellectual property laws. You promise to abide by all
            copyright notices, trademark rules, information, and restrictions contained in any Content you access
            through the Services, and you will not use, copy, reproduce, modify, translate, publish, broadcast,
            transmit, distribute, perform, upload, display, license, sell, commercialize, or otherwise exploit for any
            purpose any Content not owned by you (i) without the prior consent of the owner of that Content or (ii) in a
            way that violates someone else’s (including Moola’s) rights. You are the sole owner of the Virtual Currency
            in your Celo Wallet and may elect to extract your private key or otherwise transfer your Virtual Currency to
            another Wallet or platform.</p>
          <p>You understand that, except with respect to any open source software or third-party software that the
            Services incorporate, Moola owns the Services, including all technology or Content (including all
            intellectual property rights subsisting therein), and hereby grants you a limited, revocable, transferable
            license to access and use those portions of the Services that are proprietary to Moola.</p>
          <p className={classes.bold}>Moola Has and Retains All Rights in Our Services</p>
          <p>Except for third-party applications, such as Valora, the Service itself, all content, and other subject
            matter included on or within the Service, and all Intellectual Property Rights in or related to the Service
            or any such content or other subject matter (“Moola Content”) are the property of Moola and its licensors.
            Except as expressly provided in these Terms, you agree not to use, modify, reproduce, distribute, sell,
            license, or otherwise exploit the Moola Content without our permission.</p>
          <p>Subject to the terms and conditions of these Terms, we grant you a license to use the Service, including
            accessing and viewing Moola Content, for your personal, noncommercial use and to engage as expressly
            permitted by the features of the Service. Moola may terminate this license at any time for any reason or no
            reason. Except for the rights and licenses expressly granted, Moola reserves all other rights, and no other
            rights are granted by implication or otherwise.</p>
          <p className={classes.bold}>Copyright Policy</p>
          <p>Moola respects the intellectual property rights of others and expects its users to do the same. It is
            Moola’s policy, in appropriate circumstances and at its discretion, to disable and/or terminate access of
            users who repeatedly infringe or are repeatedly charged with infringing the copyrights or other intellectual
            property rights of others.</p>
          <p>In accordance with the Digital Millennium Copyright Act of 1998, the text of which may be found on the U.S.
            Copyright Office website at <a className={classes.link} href='http://www.copyright.gov/legislation/dmca.pdf'
                                           target='_blank'>http://www.copyright.gov/legislation/dmca.pdf/</a>, Moola
            will respond expeditiously
            to claims of copyright infringement committed using the Site that are reported to Moola’s Designated
            Copyright Agent, identified below.</p>
          <p>If you are a copyright owner, or are authorized to act on behalf of one, or authorized to act under any
            exclusive right under copyright, please report alleged copyright infringements taking place on or through
            the Site by completing the following DMCA Notice of Alleged Infringement and delivering it to Moola’s
            Designated Copyright Agent. Upon receipt of the Notice as described below, Moola will take whatever action,
            in its sole discretion, it deems appropriate, including removal of the challenged material from the
            Site. </p>
          <p className={classes.bold}>Procedure for DMCA Notice of Alleged Infringement (“Notice”)</p>
          <p>If you find you have a complaint under the DMCA, please comply with the following procedures in contacting
            Moola.</p>
          <p>(a) Identify the copyrighted work that you claim has been infringed, or - if multiple copyrighted works are
            covered by this Notice - you may provide a representative list of the copyrighted works that you claim have
            been infringed.</p>
          <p>(b) Identify (i) the material that you claim is infringing (or to be the subject of infringing activity)
            and that is to be removed or access to which is to be disabled, and information reasonably sufficient to
            permit us to locate the material, including at a minimum, if applicable, the URL of the link shown on the
            Site or Services where such material may be found, and (ii) the reference or link, to the material or
            activity that you claim to be infringing, that is to be removed or access to which is to be disabled, and
            information reasonably sufficient to permit us to locate that reference or link, including at a minimum, if
            applicable, the URL of the link shown on the Site or Services where such reference or link may be found.</p>
          <p>(c) Provide your mailing address, telephone number, and, if available, email address.
            Include both of the following statements in the body of the Notice:</p>
          <ol className={classes.secondaryOlListRoman}>
            <li>“I hereby state that I have a good faith belief that the disputed use of the copyrighted material or
              reference or link to such material is not authorized by the copyright owner, its agent, or the law (e.g.,
              as a fair use).”
            </li>
            <li>“I hereby state that the information in this Notice is accurate and, under penalty of perjury, that I am
              the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under
              the copyright that is allegedly infringed.”
            </li>
          </ol>
          <p>Provide your full legal name and your electronic or physical signature.</p>
          <p>Deliver this Notice, with all items completed, to Moola’s Designated Copyright Agent:</p>
          <p className={classes.italic}>Moola Copyright Agent<br />
            Carlton Fields LLP<br />
            2029 Century Park East, Suite 1200<br />
            Los Angeles, CA 90067-2913<br />
            <a className={classes.link} href='mailto:cgagnier@carltonfields.com'>cgagnier@carltonfields.com</a>
          </p>
          <p className={classes.bold}>What If I Receive A Copyright Complaint (DMCA) Notification?</p>
          <p>If you receive a notification that your third party content has been removed due to a copyright complaint,
            it means that the content has been deleted from Moola at the request of the content’s owner. If you receive
            too many copyright complaints, you may lose the ability to contribute new content on Moola, and your access
            to Moola may be disabled completely.</p>
          <p>If you believe your third party content was removed in error, you have the option to file a counter-notice
            by following the steps below. When Moola receives a valid counter-notice, we will forward a copy to the
            person who filed the original complaint. If we do not receive notice within ten (10) business days that the
            submitter of the original complaint is seeking a court order to prevent further infringement of the content
            at issue, we will remove the complaint from your record, and we may replace the content that was
            removed.</p>
          <p>There are legal and financial consequences for fraudulent and/or bad faith submissions. Before submitting a
            counter-notice, be sure that you are the actual rights holder of the removed content or that you have a good
            faith belief that the material was removed in error, and understand the repercussions of submitting a false
            claim.</p>
          <p className={classes.bold}>How to File a Counter-Notice</p>
          <p>(a) Reply to the notification you received.</p>
          <p>(b) Include ALL of the following:</p>
          <ol className={classes.secondaryOlListRoman}>
            <li>Your name, address, and telephone number;</li>
            <li>DMCA ID printed at the bottom of the notification email;</li>
            <li>The source address of the content that was removed (copy and paste the link in the notification
              email);
            </li>
            <li>A statement under penalty of perjury that you have a good faith belief that the content was removed in
              error;
            </li>
            <li>A statement that you consent to the jurisdiction of Federal District Court for the judicial district in
              which the address is located, or if your address is outside of the United States, for any judicial
              district in which Moola may be found, and that you will accept service of process from the person who
              provided the original complaint under 17 U.S.C. § 512(c)(1)(C) or an agent of such person; and
            </li>
            <li>A physical or electronic signature (for example, typing your full name).</li>
          </ol>
          <p className={classes.bold}>Trademark Policy</p>
          <p>Moola respects the trademark rights of others. </p>
          <p>Moola, the Moola logo, and other Moola logos and names are trademarks and are the intellectual property of
            Moola. You agree not to display or use this intellectual property in any manner without Moola’s prior,
            written consent. The names and logos of third parties may be their trademarks. You may not infringe on their
            proprietary rights. All rights are reserved.</p>
          <p>If you are concerned that someone may be using your trademark in an infringing way on our Site, you can let
            us know by completing the form below. Moola will review your submission and take whatever action, in its
            sole discretion, it deems appropriate, including temporary or permanent removal of the trademark from the
            Moola site.</p>
          <p className={classes.bold}>Trademark Infringement Claim Form</p>
          <p>(a) Identify the infringing name, the exact word or symbol that is trademarked and details regarding how
            the trademark is or was being infringed. Please also include the following:</p>
          <ol className={classes.secondaryOlListRoman}>
            <li>The trademark registration number; and</li>
            <li>The trademark registration office (e.g., USPTO).</li>
          </ol>

          <p>(b) Provide your full name, email address (preferably a company email address), your relation to the
            trademark holder, your company name, your full mailing address, and your phone number.</p>

          <p>(c) Also helpful, but not mandatory, are the following:</p>
          <ol className={classes.secondaryOlListRoman}>
            <li>Link to the trademark record;</li>
            <li>Date of first use;</li>
            <li>Date of trademark application; and</li>
            <li>Date of Registration.</li>
          </ol>

          <p>(d) Finally, tell us whether you:</p>
          <ol className={classes.secondaryOlListRoman}>
            <li>Want to claim the username at issue;</li>
            <li>Want to blacklist the username from further use; or</li>
            <li>Want Moola to take other action</li>
          </ol>

          <p>(e) Include both of the following statements:</p>
          <ol className={classes.secondaryOlListRoman}>
            <li>“I hereby state that I have not authorized the challenged use, and I have a good-faith belief that the
              challenged use violates my rights.”
            </li>
            <li>“I hereby state under penalty of perjury that all of the information in this notification is accurate
              and that I am the owner of the trademark, or authorized to act on behalf of the owner of the trademark.”
            </li>
          </ol>
          <p>Send the above described notice to:</p>
          <p className={classes.italic}>Moola Copyright Agent<br />
            Carlton Fields LLP<br />
            2029 Century Park East, Suite 1200<br />
            Los Angeles, CA 90067-2913<br />
            <a className={classes.link} href='mailto:cgagnier@carltonfields.com'>cgagnier@carltonfields.com</a>
          </p>
        </li>

        <li>
          Third Party Services and Websites
          <p>Any third-party goods, services, or websites (“Third Party Content”), or the information or content
            publicly posted or privately transmitted or made available through Third Party Content is the sole
            responsibility of the individual or organization from whom such goods, services, or content originated, and
            you access information and content from these goods, services, or websites at your own risk.</p>
          <p>Moola is not liable for any errors or omissions or for any damages or loss you might suffer in connection
            with these goods, services, or websites.</p>
          <p>The Services may contain links or connections to third party websites or services that are not owned or
            controlled by us. In other cases, third party websites or services may integrate with our Services, or we
            may integrate with third party websites or other services. When you access third party websites or use third
            party services, you accept that there are risks in doing so, and that we are not responsible for such risks.
            The fact that our Services may be used with other third party services should not be taken as an endorsement
            of those services. Moola encourages you to be aware when you leave the Services and to read the terms and
            conditions and privacy policy of each third-party website or service that you visit or utilize, as your use
            of those services is subject to those terms and policies.</p>
          <p>Moola has no control over, and assume no responsibility for, the content, accuracy, privacy policies, or
            practices of or opinions expressed in any third-party websites or by any third party that you interact with
            through the Services. In addition, Moola will not and cannot monitor, verify, censor or edit the content of
            any third-party site or service. By using the Services, you release and hold Moola harmless from any and all
            liability arising from your use of any third-party website or service.</p>
          <p>Your interactions with organizations and/or individuals found on or through the Services, including payment
            and delivery of goods or services, financial transactions, and any other terms, conditions, warranties, or
            representations associated with such dealings, are solely between you and such organizations and/or
            individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with
            any online or offline transaction with any of these third parties. You agree that we shall not be
            responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.</p>
          <p>If there is a dispute between participants of the Services, or between users and any third party, you agree
            that Moola is under no obligation to become involved. In the event that you have a dispute with one or more
            other users, you release Moola, its officers, employees, agents, and successors from claims, demands, and
            damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed,
            arising out of or in any way related to such disputes and/or our Services.</p>
          <p>If you are a California resident, you shall and hereby do waive California Civil Code Section 1542, which
            says: "A general release does not extend to claims that the creditor or releasing party does not know or
            suspect to exist in his or her favor at the time of executing the release and that, if known by him or her,
            would have materially affected his or her settlement with the debtor or released party.”</p>
        </li>

        <li>
          Suspension or Termination of Use
          <p>You may terminate your use of our Services at any time. Moola may terminate (or suspend access to) your use
            of the Services for any reason in our discretion, including your breach of these Terms. Moola has the sole
            right to decide whether you are in violation of any of the restrictions set forth in these Terms. If we
            suspend your use of the Services, you may continue to access your Wallet directly or through other services
            not hosted by us.</p>
          <p>Provisions that, by their nature, should survive termination of these Terms shall survive termination. By
            way of example, all of the following will survive termination: any obligation you have to pay us (if
            applicable) or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual
            property rights, and terms regarding disputes between us.</p>
          <p>You agree that Moola shall not be liable to you or any third party for any such modification, suspension,
            or discontinuance of our Services. In addition, we reserve the right to terminate your access to our
            Services for any reason, and to take any other actions that Moola, in its sole discretion, believes to be in
            the interest of our company and of our users as a whole.</p>
        </li>

        <li>
          Disclaimer of Warranty
          <p><b>Your Use of the Services is Subject to Certain Disclaimers. MOOLA SERVICES ARE AVAILABLE “AS IS.” WE
            DO NOT WARRANT THAT OUR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. THERE MAY BE DELAYS, OMISSIONS,
            INTERRUPTIONS, AND INACCURACIES IN THE NEWS, INFORMATION OR OTHER MATERIALS AVAILABLE THROUGH OUR SITE.
            MOOLA DOES NOT MAKE ANY WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THOSE OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO OUR SITE OR ANY INFORMATION, OR GOODS
            THAT ARE AVAILABLE OR ADVERTISED OR SOLD THROUGH OUR SERVICES. WE DO NOT MAKE ANY REPRESENTATIONS, NOR DO WE
            ENDORSE THE ACCURACY, COMPLETENESS, TIMELINESS, OR RELIABILITY OF ANY ADVICE, OPINION, STATEMENT, OR OTHER
            MATERIAL OR DATABASE DISPLAYED, UPLOADED, OR DISTRIBUTED THROUGH MOOLA SERVICES OR AVAILABLE THROUGH OUR
            SITE. MOOLA RESERVES THE RIGHT TO CORRECT ANY ERRORS OR OMISSIONS ON OUR SERVICES. ALTHOUGH WE WILL TAKE
            REASONABLE STEPS TO PREVENT THE INTRODUCTION OF VIRUSES, WORMS, “TROJAN HORSES,” OR OTHER DESTRUCTIVE
            MATERIALS TO OUR SERVICES BY IMPLEMENTING APPROPRIATE SECURITY SAFEGUARDS, MOOLA DOES NOT GUARANTEE OR
            WARRANT THAT OUR SERVICES OR MATERIALS THAT MAY BE DOWNLOADED FROM OUR SITE DO NOT CONTAIN SUCH DESTRUCTIVE
            FEATURES. MOOLA IS NOT LIABLE FOR ANY DAMAGES OR HARM ATTRIBUTABLE TO SUCH FEATURES. IF YOU RELY ON OUR
            SERVICES AND ANY MATERIALS AVAILABLE THROUGH OUR SITE, YOU DO SO SOLELY AT YOUR OWN RISK.</b></p>
          <p><b>OUR SITE MAY CONTAIN VARIOUS COMBINATIONS OF TEXT, IMAGES, AUDIOVISUAL PRODUCTIONS, OPINIONS,
            STATEMENTS,
            FACTS, ARTICLES, DATA, OR OTHER INFORMATION CREATED BY US OR BY THIRD PARTIES. DUE TO THE NUMBER OF SOURCES
            FROM WHICH CONTENT ON OUR SITE IS OBTAINED, AND THE INHERENT HAZARDS OF ELECTRONIC DISTRIBUTION, THERE MAY
            BE DELAYS, OMISSIONS OR INACCURACIES IN SUCH CONTENT. ACCORDINGLY, SUCH CONTENT, INCLUDING DATA, IS FOR YOUR
            REFERENCE ONLY AND SHOULD NOT BE RELIED UPON BY YOU FOR ANY PURPOSE.</b></p>
        </li>

        <li>
          Limitation of Liability
          <p>Moola, its affiliates, and employees are not liable for incidental, indirect, consequential, special,
            punitive or exemplary damages of any kind, including, without limitation, lost revenues or profits, loss of
            business or loss of data, in any way related to this site or for any claim, loss or injury based on errors,
            omissions, interruptions or other inaccuracies in our site (including, without limitation, as a result of
            breach of any warranty or other term of these Terms of Service).</p>
          <p>Despite anything else stated in these Terms, we shall be under no obligation to inquire into and shall not
            be liable for any damages, other liabilities, or harm to any person or entity relating to the ownership,
            validity, or genuineness of any Virtual Currency; the collectability, insurability, effectiveness,
            marketability, or suitability of any Virtual Currency; transactions you conduct or attempt to conduct using
            our Services; or losses, delays, failures, errors, interruptions, or loss of data occurring directly or
            indirectly by reason of circumstances beyond our control. This includes, without limitation: the failure of
            the Celo network ; acts of God; action or inaction of civil or military authority; public enemy; war;
            terrorism; riot; ire; food; sabotage; epidemics or pandemics; labor disputes; civil commotion; interruption,
            loss or malfunction of utilities, transportation, computer or, communications capabilities; insurrection;
            elements of nature; or non-performance by a third party.</p>
          <p><b>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY
            (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL MOOLA BE LIABLE TO YOU
            OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND,
            INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER
            FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR (II)
            THE AMOUNTS PAID BY YOU TO MOOLA IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING
            THIS APPLICABLE CLAIM, OR (C) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.</b>
          </p>
        </li>

        <li>
          Indemnification
          <p>To the fullest extent allowed by applicable law, You agree to indemnify and hold Moola, our affiliates,
            officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages
            (actual and consequential), losses, and expenses (including attorneys’ fees) arising from or in any way
            related to any third party claims relating to (a) your use of the Services (including any actions taken by a
            third party using your Celo Wallet), and (b) your violation of these Terms or applicable law. Moola reserves
            the right to take over the exclusive defense of any claim for which we are entitled to indemnification. In
            such event, you shall provide Moola with such cooperation as is reasonably requested by us.</p>
        </li>

        <li>
          Dispute Resolution
          <p>Any dispute arising from or relating to the subject matter of these Terms shall be settled in accordance
            with the Streamlined Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services, Inc.
            (“JAMS”) then in effect, by one commercial arbitrator with substantial experience in resolving intellectual
            property and commercial contract disputes, who shall be selected from the appropriate list of JAMS
            arbitrators in accordance with such Rules. Judgment upon the award rendered by such arbitrator may be
            entered in any court of competent jurisdiction. Despite the foregoing obligation to arbitrate disputes, each
            party shall have the right to pursue injunctive or other equitable relief at any time, from any court of
            competent jurisdiction. For all purposes of this Agreement, the parties consent to exclusive jurisdiction
            and venue in the state or federal courts located in, respectively, San Francisco County, California, or the
            Northern District of California. Any arbitration under these Terms will take place on an individual basis:
            class arbitrations and class actions are not permitted. YOU UNDERSTAND AND AGREE THAT BY ENTERING INTO THESE
            TERMS, YOU AND MOOLA ARE EACH WAIVING THE RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.</p>
        </li>

        <li>Financial Obligations
          <p>You are responsible for paying, withholding, filing, and reporting all taxes, duties, and other
            governmental assessments associated with your activity in connection with the Services.</p>
        </li>

        <li>
          Changes to These Terms of Service
          <p>Moola is constantly working to improve its Services, and these Terms may need to be updated. Moola reserves
            the right to change these Terms at any time. Updates to these Terms will be made available on the Moola Site
            (<a className={classes.link} href='https://moola.market/'
                target='_blank'>https://moola.market/</a>). Moola may suspend or discontinue any part of the Services,
            or Moola may introduce
            new features or impose limits on certain features or restrict access to parts or all of the Services. Moola
            reserve the right to remove any content from the Site or Services at any time, for any reason in our sole
            discretion, and without notice.</p>
          <p>If you do not agree with the new Terms after any changes, please immediately discontinue your uses of the
            Site and Services. If you use the Services in any way after a change to the Terms is effective, that means
            you agree to all of the changes.</p>
          <p>These Terms of Service, and any rights and licenses granted hereunder, may not be transferred or assigned
            by you, but may be assigned by Moola without restriction. Any attempt to assign or transfer these Terms of
            Use and any rights and licenses will be null and void.</p>
        </li>

        <li>
          Miscellaneous Terms
          <p><b>Entire Agreement.</b> The Terms of Use, including Moola’s Privacy Policy, which are hereby incorporated
            by reference, constitute the entire agreement between you and Moola and governs your use of the site,
            superseding any contemporaneous or prior agreements between you and Moola.</p>
          <p><b>Governing Laws.</b> The Terms of Use will be governed by and construed in accordance with the laws of
            the
            State of California, County of San Francisco, without regard to its conflict of law provisions.</p>
          <p><b>Assignment.</b> You may not assign, delegate, or transfer these Terms or your rights or obligations
            hereunder, in any way (by operation of law or otherwise) without our prior written consent. Moola may
            transfer, assign, or delegate these Terms and our rights and obligations without consent. </p>
          <p><b>Waiver and Severability.</b> The failure of Moola to exercise or enforce any right or provision of the
            Terms of Use shall not constitute a waiver of such right or provision. If any provision of this Agreement is
            invalid, illegal, or unenforceable under applicable law, it shall not affect the validity or enforceability
            of the remainder of the terms of this Agreement, and without further action by the parties to this
            Agreement, such provision shall be reformed to the minimum extent necessary to make such provision valid and
            enforceable.</p>
          <p><b>Notices.</b> Moola may provide you with notices, including, without limitation, those regarding changes
            to the Terms of Service and other site-related announcements, by postings on the site or via email.</p>
          <p><b>Limitation on Claims.</b> Any action concerning the site must be brought within one (1) year after the
            reason for the action arises, or the claim is barred.</p>
          <p><b>Moola May be Legally Compelled to Disclose Certain Information.</b> You agree that in the event Moola
            receives a subpoena issued by a court or from a law enforcement or government agency, Moola may have to
            comply with such subpoenas without your consent or prior notice to you and may need to disclose your IP
            address, name, IP location, or other information in response thereto. </p>
          <p><b>Contact Information.</b> Please send any questions, comments or report any Terms of Servicde violations
            to <a className={classes.link} href='mailto:info@moola.market'>info@moola.market</a>.</p>
        </li>
      </ol>
    </div>
  )
}

export default (withStyles(styles)(Terms))
