import { useEffect, useContext, useState } from 'react'
import AppContext from '../AppContext'
import { getContract } from '../../root'
import BN from 'bignumber.js'
import { print } from '../../utils'

// Hook
const useAccountData = (forceRefresh) => {
  const { user } = useContext(AppContext)
  const [isLoading, setLoading] = useState(true)
  const [accountData, setAccountData] = useState(null)

  const loadData = async () => {
    const { lendingPool } = await getContract()
    const data = await lendingPool.methods
      .getUserAccountData(user.address)
      .call()
    const healthFactor = BN(print(data.healthFactor)).multipliedBy(100)
    const parsedData = {
      totalLiquidity: print(data.totalLiquidityETH),
      totalCollateral: print(data.totalCollateralETH),
      totalBorrow: print(data.totalBorrowsETH),
      totalFees: print(data.totalFeesETH),
      availableBorrow: print(data.availableBorrowsETH),
      liquidationThreshold: `${data.currentLiquidationThreshold}%`,
      loanToValue: `${data.ltv}%`,
      healthFactor: healthFactor.gt(10000) ? 'SAFE' : healthFactor.toFixed(0, 1)
    }

    setAccountData(parsedData)
    setLoading(false)
  }

  useEffect(() => {
    if (user) {
      loadData()
    }
  }, [user, forceRefresh])

  return [{ accountData, isLoading }]
}

export default useAccountData
