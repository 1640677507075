import React, { useContext } from 'react'
import withView from '../../components/HOCs/withView'
import requiresAuth from '../../components/HOCs/auth'
import useBalance from '../../components/hooks/useBalance'
import useAccountData from '../../components/hooks/useAccountData'
import useAccountReserveData from '../../components/hooks/useAccountReserveData'
import useReserveData from '../../components/hooks/useReserveData'
import { useHistory } from 'react-router-dom'
import AppContext from '../../components/AppContext'
import { Text } from 'react-native'
import Button from '@material-ui/core/Button'
import BN from 'bignumber.js'
import { formatCELO, formatCUSD, formatCEUR } from '../../utils'
import { Container, Divider, Info, Label } from './elements'

const HomeScreen = () => {
  const context = useContext(AppContext)
  const history = useHistory()
  const [{ balance: cUSDBalance, isLoading: iscUSDLoading}] = useBalance('cUSD')
  const [{ balance: cEURBalance, isLoading: iscEURLoading}] = useBalance('cEUR')
  const [{ balance: celoBalance, isLoading: isCeloLoading}] = useBalance('CELO')
  const [{ accountData, isLoading: isAccountLoading }] = useAccountData()
  const [{ accountReserveData: accountReserveCUSD, isLoading: isAccountReserveCUSDLoading }] = useAccountReserveData('cUSD')
  const [{ accountReserveData: accountReserveCEUR, isLoading: isAccountReserveCEURLoading }] = useAccountReserveData('cEUR')
  const [{ accountReserveData: accountReserveCelo, isLoading: isAccountReserveCeloLoading }] = useAccountReserveData('CELO')
  const [{ reserveData: reserveCUSD }] = useReserveData('cUSD')
  const [{ reserveData: reserveCEUR }] = useReserveData('cEUR')
  const [{ reserveData: reserveCelo }] = useReserveData('CELO')

  const processInCelo = () => {
    history.push('/deposit/CELO')
  }

  const processInCUSD = () => {
    history.push('/deposit/cUSD')
  }

  const processInCEUR = () => {
    history.push('/deposit/cEUR')
  }
 
  return (
    <Container>
      <Text>{`Wallet cUSD Balance: ${iscUSDLoading ? 'loading...' : formatCUSD(cUSDBalance) }`}</Text>
      <Text>{`Wallet cEUR Balance: ${iscEURLoading ? 'loading...' : formatCEUR(cEURBalance) }`}</Text>
      <Text>{`Wallet CELO Balance: ${isCeloLoading ? 'loading...' : formatCELO(celoBalance) }`}</Text>
      <Divider />
      {isAccountReserveCUSDLoading && <Text>Loading cUSD data...</Text>}
      {accountReserveCUSD && reserveCUSD && (
        <>
          <Text>Moola cUSD Balance: {formatCUSD(accountReserveCUSD.deposited)}</Text>
          <Text>Total cUSD Debt: {formatCUSD(accountReserveCUSD.debt)}</Text>
          <Text>cUSD Deposit APY: {accountReserveCUSD.liquidityRate}%</Text>
          <Text>cUSD Borrow Type: {accountReserveCUSD.rateMode}</Text>
          <Text>cUSD Borrow APR: {Number(accountReserveCUSD.borrowRate) > 0 ? accountReserveCUSD.borrowRate : reserveCUSD.variableRate}%</Text>
          <Text>cUSD Origination Fees Incurred: {formatCUSD(accountReserveCUSD.originationFee)}</Text>
          <Divider />
          <Button color="secondary" variant="contained" onClick={processInCUSD}>Deposit cUSD</Button>
        </>
      )}
      <Divider />
      {isAccountReserveCEURLoading && <Text>Loading cEUR data...</Text>}
      {accountReserveCEUR && reserveCEUR && (
        <>
          <Text>Moola cEUR Balance: {formatCEUR(accountReserveCEUR.deposited)}</Text>
          <Text>Total cEUR Debt: {formatCEUR(accountReserveCEUR.debt)}</Text>
          <Text>cEUR Deposit APY: {accountReserveCEUR.liquidityRate}%</Text>
          <Text>cEUR Borrow Type: {accountReserveCEUR.rateMode}</Text>
          <Text>cEUR Borrow APR: {Number(accountReserveCEUR.borrowRate) > 0 ? accountReserveCEUR.borrowRate : reserveCEUR.variableRate}%</Text>
          <Text>cEUR Origination Fees Incurred: {formatCEUR(accountReserveCEUR.originationFee)}</Text>
          <Divider />
          <Button color="secondary" variant="contained" onClick={processInCEUR}>Deposit cEUR</Button>
        </>
      )}
      <Divider />
      {isAccountReserveCeloLoading && <Text>Loading CELO data...</Text>}
      {accountReserveCelo && reserveCelo && (
        <>
          <Text>Moola CELO Balance: {formatCELO(accountReserveCelo.deposited)}</Text>
          <Text>Total CELO Debt: {formatCELO(accountReserveCelo.debt)}</Text>
          <Text>CELO Deposit APY: {accountReserveCelo.liquidityRate}%</Text>
          <Text>CELO Borrow Type: {accountReserveCelo.rateMode}</Text>
          <Text>CELO Borrow APR: {Number(accountReserveCelo.borrowRate) > 0 ? accountReserveCelo.borrowRate : reserveCelo.variableRate}%</Text>
          <Text>CELO Origination Fees Incurred: {formatCELO(accountReserveCelo.originationFee)}</Text>
          <Divider />
          <Button color="secondary" variant="contained" onClick={processInCelo}>Deposit CELO</Button>
        </>
      )}
      <Divider />
      {isAccountLoading && <Text>Loading account data...</Text>}
      {accountData && (
        <>
          <Text>Total Collateral Value (in units of CELO): {formatCELO(accountData.totalCollateral)}</Text>
          <Text>Total Amount Borrowed (in units of CELO): {formatCELO(accountData.totalBorrow)}</Text>
          <Text>Available to Borrow (in units of CELO): {formatCELO(accountData.availableBorrow)}</Text>
          <Text>Liquidation Threshold: {accountData.liquidationThreshold}</Text>
          <Text>Maximum Loan to Value: 75%</Text>
          <Text>Health Factor: {accountData.healthFactor}</Text>
        </>
      )}
    </Container>
  )
}

export default requiresAuth(withView(HomeScreen))