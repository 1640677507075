import React from 'react'
import Loader from 'react-loader-spinner'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Transactions, StatusesContainer, Status, Timeline, Step, Circle, Line } from './elements'

const renderActionModal = ({
  dappName,
  pendingActionCount,
}) => {
  const approved = pendingActionCount === 2
  const sent = pendingActionCount === 1
  return (
    <div className="tw-px-5 tw-py-6">
      <div className="tw-text-xl tw-text-gray-800 dark:tw-text-gray-200 tw-mb-2">
        Check your wallet
      </div>
      <p className="tw-text-gray-700 dark:tw-text-gray-400 tw-text-sm tw-mb-6">
        {dappName} is requesting to perform an action, please check your connected wallet and confirm this.
      </p>
      <Transactions>
        <StatusesContainer>
          <Status>{approved ? 'Approved' : 'Approving'}</Status>
          <Status>{sent ? 'Sent' : 'Sending'}</Status>
        </StatusesContainer>
        <Timeline>
          <Step>{approved ? <CheckCircleIcon style={{ fill: '#35D07F' }} /> : <Circle>1</Circle>}</Step>
          <Line />
          <Step>{sent ? <CheckCircleIcon style={{ fill: '#35D07F' }} /> : <Circle>2</Circle>}</Step>
        </Timeline>
      </Transactions>
      <div className="tw-flex tw-justify-center tw-items-center">
        <Loader type="TailSpin" color="#4f46e5" height={40} width={40} />
      </div>
    </div>
  )
}

export default renderActionModal
