import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MuiButton from '@material-ui/core/Button'
import MuiIcon from '@material-ui/core/Icon'
import classNames from 'classnames'

const transparent = 'rgba(255, 255, 255, 0.1)'
const selectedColor = 'rgba(255, 255, 255, 0.4)'

const styles = () => ({
  startIcon: {
    position: 'relative',
  },
  root: {
    borderRadius: 8,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 1,
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  desktopButton: {
	height: '54px',
	width: '252px',
    '& label': {
      cursor: 'pointer',
    }
  },
  mobileButton: {
	height: '36px',
	minWidth: '138px'
  }
})

const FlowButton = ({
  classes,
  label,
  icon,
  isTransparent,
  color,
  fullWidth,
  onClick,
  desktopButton,
  selected = false
}) => {
  const buttonIcon = (
    <MuiIcon>
      <img src={icon} height={20} width={20} />
    </MuiIcon>
  )
  const backgroundColor = selected ? selectedColor : isTransparent ? transparent : color
  const buttonBg = {
    backgroundColor: backgroundColor,
    '&:hover': {
      backgroundColor: backgroundColor,
      '@media (hover: none)': {
        backgroundColor: backgroundColor
      }
    }
  }

  return (
    <MuiButton
      variant="contained"
      color="primary"
      className={desktopButton === undefined ? classes.root : classNames(classes.root, desktopButton ? classes.desktopButton :  classes.mobileButton) }
      style={buttonBg}
      startIcon={buttonIcon}
      classes={{ startIcon: classes.startIcon }}
      fullWidth={fullWidth}
      onClick={onClick}
    >
      <label>{label}</label>
    </MuiButton>
  )
}

FlowButton.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  isTransparent: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  desktopButton: PropTypes.bool
}

export default withStyles(styles)(FlowButton)
