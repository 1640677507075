import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import AppContext from '../AppContext'

// Component
const requiresAuth = (WrappedComponent) => {
  return (props) => {
    const context = useContext(AppContext)
    const history = useHistory()

    if (!context.user) {
      history.replace('/')
      return null
    }

    return  <WrappedComponent {...props} />
  }
}

export default requiresAuth
