import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiIcon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useContext, useEffect, useRef, useState } from 'react'
import BN from 'bignumber.js'
import { withTranslation } from 'react-i18next'
import { useSwipeable } from 'react-swipeable'
import CloseIcon from '../../assets/images/close.svg'
import MenuCustomIcon from '../../assets/images/menu.svg'
import CloseMenu from '../../assets/images/x.svg'
import Logo from '../../assets/images/logo.svg'
import WalletIcon from '../../assets/images/wallet.svg'
import AppContext from '../../components/AppContext'
import BottomPopup from '../../components/BottomPopup'
import PopupList from '../../components/BottomPopup/popupList'
import HomeTabs from '../../components/HomeTabs'
import { Container } from './elements'
import { useContractKit } from '@celo-tools/use-contractkit'
import {
  useGetSettingsCurrency,
  useGetUserAccountInfoBalance,
  useGetMooTokenHolder
} from '../../api'
import { getContract } from '../../root'
import MooTokenAlfajores from '../../moo-token/moo-test-merkle.json'
import config from '../../config'
import styles from './styles'
import LeftMenu from './LeftMenu'
import ConditionalRender from '../../utils/conditional-render'
import { useHistory } from 'react-router-dom'
import { EventRegister } from 'react-native-event-listeners'
import {
  DESKTOP_ACTION_SUCCESS,
  REFRESH_DATA_DELAY
} from '../../utils/constants'

const NewHomeScreen = ({ t, classes, state }) => {
  const { user, setUser } = useContext(AppContext)
  const [logoutButtonPopup, setLogoutButtonPopup] = useState(false)
  const [totalValue, setTotalValue] = useState()
  const [claimDetails, setClaimDetails] = useState(null)
  const [tokenClaimed, setTokenClaimed] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [menu, expandMenu] = useState(true)
  const history = useHistory()
  const [forceRefresh, setForceRefresh] = useState(Date.now())

  const { destroy } = useContractKit()
  const { data: currentCurrency } = useGetSettingsCurrency()
  const accountInfoBalance = useGetUserAccountInfoBalance(
    user?.address,
    currentCurrency,
    forceRefresh
  )
  const accountMooTokenHolder = useGetMooTokenHolder(user?.address)
  const innerContent = useRef()
  const header = useRef()
  const provider = localStorage.getItem('use-contractkit/last-used-wallet')

  useEffect(() => {
    const actionSuccessListener = EventRegister.addEventListener(
      DESKTOP_ACTION_SUCCESS,
      () => {
        setTimeout(() => setForceRefresh(Date.now()), REFRESH_DATA_DELAY) // Delay refresh account so that data is available from backend api
      }
    )
    return () => {
      EventRegister.removeEventListener(actionSuccessListener)
    }
  }, [])

  const handlers = useSwipeable({
    onSwipedUp: (e) => {
      setIsScrolled(true)
    },
    onSwipedDown: (e) => {
      const tabs = document.getElementById('tabContent')
      if (tabs) {
        if (tabs.scrollTop <= 170) {
          setIsScrolled(false)
        }
      }
    }
  })

  useEffect(() => {
    const getMooDetails = async () => {
      let mooTokenClaimFound = {}
      if (
        !config.isTestnet &&
        accountMooTokenHolder.isSuccess &&
        accountMooTokenHolder.data.data[0]
      ) {
        const proof = accountMooTokenHolder.data.data[0].Proof.split(',').map(
          (el) => '0x' + el
        )
        const amount = '0x' + accountMooTokenHolder.data.data[0].Amount
        const address = '0x' + accountMooTokenHolder.data.data[0].Address
        mooTokenClaimFound = {
          index: accountMooTokenHolder.data.data[0].Index,
          address: address,
          amount: amount,
          proof: proof
        }
      } else {
        const mooTokenClaims = MooTokenAlfajores
        const map = new Map(Object.entries(mooTokenClaims.claims))
        mooTokenClaimFound = map.get(user?.address)
      }

      if (!user) return setClaimDetails(null)

      const { merkleDistributor, mooToken } = await getContract()

      if (mooTokenClaimFound && !tokenClaimed && mooTokenClaimFound.index) {
        const isClaimed = await merkleDistributor.methods
          .isClaimed(mooTokenClaimFound.index)
          .call()
        mooTokenClaimFound.address = user.address
        mooTokenClaimFound.unclaimedValue = BN(mooTokenClaimFound.amount)
          .dividedBy(BN(10).pow(18))
          .toString()
        const balance = await mooToken.methods.balanceOf(user.address).call()
        mooTokenClaimFound.balance = BN(balance)
          .dividedBy(BN(10).pow(18))
          .toString()
        mooTokenClaimFound.isClaimed = isClaimed
        setTokenClaimed(isClaimed)
        setClaimDetails(mooTokenClaimFound)
      }
    }
    if (user) {
      getMooDetails()
    } else {
      setClaimDetails(null)
    }
  }, [
    user,
    tokenClaimed,
    accountMooTokenHolder.isSuccess,
    accountMooTokenHolder.data
  ])

  useEffect(() => {
    if (accountInfoBalance.isSuccess && accountInfoBalance.data) {
      setTotalValue(accountInfoBalance.data?.balanceGrandTotal?.balance || 0)
    }
  }, [accountInfoBalance])

  const openMenu = () => {
    expandMenu(true)
    // history.push('/settings')
  }

  const closeMenu = () => {
    expandMenu(false)
    // history.push('/settings')
  }

  const handleLogOut = async () => {
    history.push(`/`)
    await destroy()
    setUser('')
    localStorage.removeItem('provider')
    setTokenClaimed(false)
  }

  const formatAddress = (address) => {
    if (!address) {
      return null
    }

    let firstPart = address.substring(0, 2)
    let secondPart = address.substring(2)
    while (secondPart.length >= 3) {
      firstPart += ' ' + secondPart.substr(0, 4)
      secondPart = secondPart.substr(4)
    }
    firstPart += ' ' + secondPart.substr(0)

    return firstPart
  }

  const walletDetails = user && (
    <PopupList
      provider={provider ?? user.walletType}
      status={t('GENERAL.CONNECTED')}
      phoneNumber={user.phoneNumber}
      address={formatAddress(user.address)}
    />
  )

  return (
    <div {...handlers} className={classes.desktopPage}>
	  <div className={classes.migrationBanner}>
		<a href="https://moolamarket.medium.com/moola-migration-from-v1-to-v2-96268238399e" target="_blank">
			<span className={classes.migrationBold}>{t('V2.MIGRATE_BANNER.PART1')}</span>
			{t('V2.MIGRATE_BANNER.PART2')}
		</a>
		<div>{t('V2.MIGRATE_BANNER.PART3')}</div>
	  </div>
      <Container>
        <Box className={classes.header} ref={header}>
          <Grid
            container
            alignItems={'center'}
            justify={'space-between'}
            className={classes.smallHeader}
          >
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={menu ? closeMenu : openMenu}
                className={classes.headerIcon}
              >
                <MuiIcon className={classes.headerIconWrapper}>
                  <img
                    alt={t('GENERAL.WALLET')}
                    src={menu ? CloseMenu : MenuCustomIcon}
                    height={24}
                    width={24}
                  />
                </MuiIcon>
              </IconButton>

              <MuiIcon
                className={classes.headerLogo}
                onClick={() => history.push('/')}
              >
                <img className={classes.logo} alt="logo" src={Logo} />
              </MuiIcon>
            </Grid>

            <ConditionalRender if={user}>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setLogoutButtonPopup(true)}
                  className={classes.headerIcon}
                >
                  <MuiIcon className={classes.headerIconWrapper}>
                    <img
                      alt={t('GENERAL.WALLET')}
                      src={WalletIcon}
                      height={20}
                      width={20}
                    />
                  </MuiIcon>
                </IconButton>
              </Grid>
            </ConditionalRender>
          </Grid>
        </Box>

        <Box className={classes.wrapper}>
          <LeftMenu
            user={user}
            expanded={menu}
            mooToken={claimDetails}
            totalValue={totalValue}
            state={state}
            forceRefresh={forceRefresh}
          />
          {currentCurrency && (
            <Box
              className={
                menu ? classes.wideContentExpanded : classes.wideContent
              }
            >
              <HomeTabs
                contentRef={innerContent}
                mooToken={claimDetails}
                currency={currentCurrency}
                setRiskFactorValue={() => {}}
                currentState={state}
                accountTabSelected={() => setIsScrolled(false)}
                isScrolled={isScrolled}
              />
            </Box>
          )}
        </Box>

        {user && (
          <BottomPopup
            show={logoutButtonPopup}
            label={t('GENERAL.YOUR_WALLET')}
            onClose={() => setLogoutButtonPopup(false)}
            icon={CloseIcon}
            children={walletDetails}
            primaryButtonTitle={t('GENERAL.DISCONNECT')}
            primaryButtonClick={() => {
              setLogoutButtonPopup(false)
              handleLogOut()
            }}
            isLogout={true}
          />
        )}
      </Container>
    </div>
  )
}

export default withTranslation()(withStyles(styles)(NewHomeScreen))
