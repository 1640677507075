import BN from 'bignumber.js'
import { useContext, useEffect, useState } from 'react'
import { getContract } from '../../root'
import { ether } from '../../utils'
import { CURRENCIES } from '../../utils/constants'
import AppContext from '../AppContext'

const useAccountBorrowData = () => {
  const { user } = useContext(AppContext)
  const [isLoading, setLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [accountBorrowData, setAccountBorrowData] = useState(null)
  const context = useContext(AppContext)

  const loadAllBorrow = async () => {
    try {
      const deposits = await Promise.all(
        Object.values(CURRENCIES).map(
          async (currency) => await loadBorrow(currency.key)
        )
      )
      setAccountBorrowData(deposits)
      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsSuccess(false)
    }
    setLoading(false)
  }

  const loadBorrow = async (txType) => {
	const { lendingPool, reserves, priceOracle, tokens } = await getContract()
    const { availableLiquidity } = await lendingPool.methods
      .getReserveData(reserves[txType])
      .call()
    const { availableBorrowsETH } = await lendingPool.methods
      .getUserAccountData(context.user.address)
      .call()

    const token = tokens[txType]

    const celoPerToken = await priceOracle.methods
      .getAssetPrice(token.address)
      .call()

    const availableBorrowConverted = txType === 'CELO'
      ? availableBorrowsETH
      : BN(availableBorrowsETH).multipliedBy(ether).dividedBy(celoPerToken)

    const maxBorrowValue = BN.minimum(
      availableBorrowConverted,
      availableLiquidity
    ).dividedBy(ether)

    const parsedData = {
      currency: txType,
      borrow: maxBorrowValue
    }
    return parsedData
  }

  useEffect(() => {
    if (user) {
		loadAllBorrow()
    }
  }, [user])

  return { accountBorrowData, isLoading, isSuccess }
}

export default useAccountBorrowData
