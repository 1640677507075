import {Typography} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import MuiIcon from '@material-ui/core/Icon'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import { isMobile } from '../../utils/browser'
import {Container} from './elements'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: isMobile ? '14px' : '16px',
    lineHeight: '20px',
    fontFamily: isMobile ? null : 'Jost-400-Book',
    color: isMobile ? theme.palette.text.primary : theme.palette.text.secondary
  },
  value: {
    fontSize: isMobile ? '16px' : '24px',
    fontWeight: isMobile ? '700' : 'bold',
    lineHeight: isMobile ? '20px' : '28px',
    fontFamily: isMobile ? null : 'Jost-700-Bold',
    color: theme.palette.text.primary
  },
  gridContent: {
    width: '123px',
    height: isMobile ? '136px' : '103px',
    paddingLeft: '16px',
    paddingTop: '16px',
    marginTop: isMobile ? '24px' : '8px',
    marginBottom: isMobile ? '24px' : '8px'
  },
  gridText: {
    paddingTop: '16px',
    minWidth: isMobile ? '' : '200px',
    marginLeft: isMobile ? '' : '32px',
  },
  display: {
    display: isMobile ? '' : 'flex'
  }
}))

const StatisticCard = ({ item }) => {

  const classes = useStyles()

  return (
    <Container>
      <Grid
        container
        item
        direction='column'
        className={ classes.gridContent }
      >
        <Grid item className={ classes.display}>
          <Grid item>
            <MuiIcon>
              <img alt={ item.title } src={ item.icon } height={ isMobile ? 46 : 69 } width={ isMobile ? 48 : 72 }/>
            </MuiIcon>
          </Grid>
          <Grid item className={ classes.gridText }>
            <Typography
              className={ classes.value }
            >
              { item.value }
            </Typography>
            <Typography
              className={ classes.title }
            >
              { item.title }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default StatisticCard
