import { CURRENCIES } from './constants'

export const isCelo = (currency) => {
  return currency.toLowerCase() === CURRENCIES.celo.key.toLowerCase()
}

const formatCurrency = (currency) => {
  return currency === 'CELO' ? 'Celo' : currency
}

/*
  Retrieves an object containing the exchange rates between all tokens (including self)
*/
export const retrieveExchangeRates = (exchangeRates) => {
  if (!exchangeRates) return

  let exchange = {}
  Object.values(CURRENCIES).map((fromCurrency) => {
    const from = fromCurrency.key.toLowerCase()
    exchange = { ...exchange, [from]: {} }
    Object.values(CURRENCIES).map((toCurrency) => {
      const to = toCurrency.key.toLowerCase()
      const value =
        exchangeRates[formatCurrency(fromCurrency.key)][
          formatCurrency(toCurrency.key)
        ] ?? 1
      exchange[from][to] = value
    })
  })
  return exchange
}
