import React, { useContext, useState } from 'react'
import withView from '../../components/HOCs/withView'
import requiresAuth from '../../components/HOCs/auth'
import useBalance from '../../components/hooks/useBalance'
import useAccountReserveData from '../../components/hooks/useAccountReserveData'
import useCheckRequiredBalance from '../../components/hooks/useCheckRequiredBalance'
import useAccountData from '../../components/hooks/useAccountData'
import useTxRequest from '../../components/hooks/useTxRequest'
import { useParams } from 'react-router-dom'
import { Text } from 'react-native';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import ValueSlider from '../../components/ValueSlider'
import MuiAlert from '@material-ui/lab/Alert'
import { Form, Field } from 'react-final-form'
import { FeeCurrency, cancelRequest } from '../../sdk/dappkit'
import { web3, kit, getContract } from '../../root'
import { formatAmount, maxUint256, getValuePlaceholder } from '../../utils'
import { required, mustBeNumber, mustBePositiveNumber, composeValidators } from '../../utils/validation'
import { FormContainer } from './elements'
import BN from 'bignumber.js'
import AppContext from '../../components/AppContext'
import AToken from '../../abi/AToken.json'

// Utils
const maxValue = max => (value, { percentage }) => {
  const message = BN(max).gt(0)
    ? BN(value).gt(max) && percentage !== 100 && `Should be less than or equal to ${max}`
    : 'Not enough deposited'
  
  return message;
}

// Component
const RedeemFormScreen = () => {  
  const context = useContext(AppContext)
  const { txCurrency } = useParams()

  const operation = 'redeem'
  const requestId = operation + txCurrency

  const isCelo = txCurrency === 'CELO'

  const [amount, setAmount] = useState('')

  const [{ balance, isLoading: isBalanceLoading }] = useBalance(txCurrency)
  const [{ accountReserveData, isLoading: isAccountReserveLoading }] = useAccountReserveData(txCurrency)
  const [{ isNotEnoughBalance, balanceDiff }, isRequiredBalanceLoading] = useCheckRequiredBalance(amount, txCurrency)
  const [{ accountData }] = useAccountData()
  const { handleTxRequest } = useTxRequest()
  const isLowHealthFactor = accountData && accountData.healthFactor !== 'SAFE' ? parseFloat(accountData.healthFactor) < 125 : false

  const processOperation = async ({ amount, percentage }) => {
    cancelRequest(requestId)

    setAmount(amount)
    const isPayAll = percentage === 100
    const { lendingPool, reserves } = await getContract()
    const rawAmount = BN(10).pow(18).multipliedBy(amount).toFixed(0)
    const value = isPayAll ? maxUint256 : rawAmount

    try {
      const mtoken = new kit.web3.eth.Contract(AToken, (await lendingPool.methods.getReserveData(reserves[txCurrency]).call()).aTokenAddress)
      const isTransferAllowed = await mtoken.methods.isTransferAllowed(context.user.address, rawAmount).call()
      if (!isTransferAllowed) {
        throw Error('You cannot withdraw this amount. Repay your debts or decrease the amount.')
      }
      const txObject = mtoken.methods.redeem(value)

      const txParam = {
        tx: txObject,
        from: context.user.address,
        to: mtoken.options.address,
        estimatedGas: 2000000,
        feeCurrency: isCelo ? undefined : FeeCurrency[txCurrency]
      }
      
      const txParams = [txParam]
      
      handleTxRequest(operation, requestId, txCurrency, amount, txParams)
    } catch (error) {
      console.log(error)
      context.showError(error.message)
    }
  }
  
  const maxAmount = accountReserveData && BN(accountReserveData.deposited)
  const validators = accountReserveData && composeValidators(
    required, 
    mustBeNumber, 
    mustBePositiveNumber,
    maxValue(maxAmount)
  )

  return (
    <Form
      onSubmit={processOperation}
      initialValues={{ }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <FormContainer onSubmit={handleSubmit} $showWarning={isNotEnoughBalance || isLowHealthFactor}>
          <Text>{`How much ${txCurrency} would you like to withdraw?`}</Text>
          <Field 
            name="amount"
            validate={validators}
            render={({ input, meta }) => (
              <TextField 
                inputProps={{
                  ...input,
                  onChange: (event) => {
                    const { value } = event.target
                    input.onChange(event)
                    const newPercentage = value ? BN(value).dividedBy(maxAmount).multipliedBy(100).toFixed(0) : 0
                    form.change('percentage', newPercentage)
                  },
                  inputMode: 'decimal',
                  type: 'number'
                }} 
                helperText={meta.touched ? meta.error : ''} 
                error={meta.error && meta.touched} 
                placeholder={getValuePlaceholder(txCurrency)}
                label="Withdraw value"
                variant="outlined"
              />
            )}
          />
          <Field 
            name="percentage"
            render={({ input }) => (
              <ValueSlider form={form} input={input} maxAmount={maxAmount} isLoading={isAccountReserveLoading} />
            )}
          />
          <Text>{`Wallet ${txCurrency} balance is: ${isBalanceLoading ? 'loading...' : formatAmount(balance, txCurrency)}`}</Text>
          <Text>{`Deposited ${txCurrency}: ${isAccountReserveLoading ? 'loading...' : formatAmount(accountReserveData.deposited, txCurrency)}`}</Text>
          <Button color="secondary" variant="contained" onClick={handleSubmit} disabled={isAccountReserveLoading || pristine || isNotEnoughBalance}>
            {`Withdraw ${txCurrency}`}
          </Button>
          {!isRequiredBalanceLoading && isNotEnoughBalance && (
            <MuiAlert variant="filled" severity="warning">
              {`Missing ${balanceDiff} ${txCurrency} to send transaction. Please fund your wallet.`}
            </MuiAlert>
          )}
          {isLowHealthFactor && (
            <MuiAlert variant="filled" severity="warning">
              Withdrawing any amount puts your position at risk of liquidation.
            </MuiAlert>
          )}
        </FormContainer>
      )}
    />
  )
}

export default requiresAuth(withView(RedeemFormScreen))
