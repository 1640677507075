export default theme => ({
  root: {
    height: '100%'
  },
  smallHeader: {
    height: '66px'
  },
  smallHeaderLabel: {
    textAlign: 'center',
    opacity: 0,
    transition: 'opacity .3s',
    paddingTop: '10px',
    '& > *': {
      fontSize: '24px',
      fontFamily: 'EBGaramond-700-Bold'
    }
  },
  header: {
    padding: '52px 16px 30px',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    '& *': {
      color: theme.palette.neutral.white
    },
  },
  content: {
    zIndex: 1300,
    overflow: 'auto',
    marginTop: '258px',
    transition: 'margin-top .3s',
    height: '100%',
  },
  extendedHeader: {
    transition: 'opacity .3s',
    '& .header': {
      fontFamily: 'EBGaramond-600-SemiBold',
      fontSize: '48px',
      lineHeight: '48px',
      marginBottom: '10px'
    },
    '& .copy': {
      fontFamily: 'Jost-400-Book',
      fontSize: '14px',
      lineHeight: '20px',
      margin: '0 26px 24px',
      textAlign: 'center'
    },
    '& .copy-bold': {
      fontFamily: 'Jost-700-Bold'
    },
    '& [type=button]': {
      minWidth: '138px'
    }
  },
  headerIcon: {
    padding: '0px',
    '& img': {
      width: '36px',
      height: '36px'
    }
  },
  headerIconWrapper: {
    width: '36px',
    height: '36px'
  },
  loader: {
    textAlign: 'center'
  },
  migrationBanner: {
	  zIndex: '1',
	  width: '100%',
	  height: '52px',
	  backgroundColor: theme.palette.common.error,
	  position: 'absolute',
	  top: '0',
	  left: '0',
	  display: 'flex',
	  justifyContent: 'center',
	  alignItems: 'center',
	  fontFamily: 'Jost-400-Book',
	  fontSize: '16px',
	  lineHeight: '20px',
	  color: theme.palette.common.white,
	  '& > a': {
		  color: 'inherit',
		  marginRight: '5px'
	  }
  },
  migrationBold: {
	  fontSize: '16px',
	  lineHeight: '20px',
	  fontFamily: 'Jost-700-Bold',
	  fontWeight: 700
  }
});
