import { Container, List } from './elements'
import React from 'react'
import StatisticCard from '../StatsCard/statsCard'
import { makeStyles } from '@material-ui/core'
import { isMobile } from '../../utils/browser'

const useStyles = makeStyles(() => ({
	desktopContainer: {
		display: isMobile ? 'flex' : 'block',
		'& > *': {
			margin: '0 0 8px 0'
		},
		'& > *:last-child': {
			margin: '0'
		}
	}
}))

const StatisticsList = ({ list }) => {
	const classes = useStyles()

	return (
		<Container>
			<List className={isMobile ? '' : classes.desktopContainer}>
				{list.map((item, index) => (
					<StatisticCard
						item={item}
						key={index}
						listId={index}
					/>
				))}
			</List>
		</Container>
	)
}

export default StatisticsList
