export const BASEURL = 'https://mooapi.herokuapp.com/get'

export const METHODS = {
  GET_RESERVE_DATA: '/get/getReserveData',
  GET_STATISTICS_DATA:
    '/get/getReserveData/total-activeUser-deposited-borrowed',
  GET_USER_ACCOUNT_INFO_BALANCE: '/get/getUserAccountInfo/balance',
  GET_USER_ACCOUNT_INFO_DEPOSIT: '/get/getUserAccountInfo/deposit',
  GET_USER_ACCOUNT_INFO_ACTIVITY: '/get/getUserAccountInfo/activity',
  GET_USER_ACCOUNT_INFO_HEALTH_FACTOR: '/get/getUserAccountInfo/riskData',
  GET_USER_ACCOUNT_INFO_BORROW: '/get/getUserAccountInfo/borrow',
  GET_USER_ACCOUNT_INFO_MAX: '/get/getUserAccountInfo/max',
  GET_USER_ACCOUNT_INFO_DEBT: '/get/getUserAccountInfo/debt',
  GET_USER_ACCOUNT_INFO_CURRENCY_DEBT: '/get/getUserAccountInfo/currencyDebt',
  GET_USER_ACCOUNT_INFO_STATUS: '/get/getUserAccountInfo/status',
  GET_EXCHANGE_RATES: '/get/getExchangeRates',
  GET_FEE: '/get/getFee',
  GET_USER_ACCOUNT_INFO_LIQUIDATION_PRICES: 'get/getLiquidationPrice',
  GET_USER_TOKEN_HOLDER: 'get/getMooTokenHolder',
  GET_USER_RESERVE_DATA: 'get/getUserReserveData'
}

export const QUERY_KEYS = {
  GET_RESERVE_DATA: 'reserveData',
  GET_RESERVE_DATA_ACTIVE_USERS: 'activeUsers',
  GET_RESERVE_DATA_TOTAL_DEPOSITED: 'totalDeposited',
  GET_RESERVE_DATA_TOTAL_BORROWED: 'totalBorrowed',
  GET_STATISTICS_DATA: 'statisticsData',
  GET_SETTINGS_CURRENCY: 'settingsCurrency',
  GET_SETTINGS_LANGUAGE: 'settingsLanguage',
  GET_USER_ACCOUNT_INFO_BALANCE: 'balance',
  GET_USER_ACCOUNT_INFO_DEPOSIT: 'deposit',
  GET_USER_ACCOUNT_INFO_ACTIVITY: 'activity',
  GET_USER_ACCOUNT_INFO_HEALTH_FACTOR: 'healthfactor',
  GET_USER_ACCOUNT_INFO_BORROW: 'borrow',
  GET_USER_ACCOUNT_INFO_MAX: 'max',
  GET_USER_ACCOUNT_INFO_DEBT: 'debt',
  GET_USER_ACCOUNT_INFO_CURRENCY_DEBT: 'currencyDebt',
  GET_USER_ACCOUNT_INFO_STATUS: 'account_status',
  GET_USER_NEW_ACCOUNT_INFO_STATUS: 'account_new_status',
  GET_EXCHANGE_RATES: 'exchange_rates',
  GET_FEE: 'fee',
  GET_USER_ACCOUNT_INFO_LIQUIDATION_PRICES: 'liquidation_prices',
  GET_USER_TOKEN_HOLDER: 'token_holder',
  GET_USER_RESERVE_DATA: 'user_reserveData'
}
