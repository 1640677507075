import React, { useContext, useState } from 'react'
import withView from '../../components/HOCs/withView'
import requiresAuth from '../../components/HOCs/auth'
import useBalance from '../../components/hooks/useBalance'
import useAvailableBorrow from '../../components/hooks/useAvailableBorrow'
import useCheckRequiredBalance from '../../components/hooks/useCheckRequiredBalance'
import useTxRequest from '../../components/hooks/useTxRequest'
import { useParams } from 'react-router-dom'
import { Text } from 'react-native';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import ValueSlider from '../../components/ValueSlider'
import MuiAlert from '@material-ui/lab/Alert'
import { Form, Field } from 'react-final-form'
import { FeeCurrency, cancelRequest } from '../../sdk/dappkit'
import { web3, kit, getContract } from '../../root'
import { formatAmount, getValuePlaceholder } from '../../utils'
import { required, mustBeNumber, mustBePositiveNumber, composeValidators } from '../../utils/validation'
import { FormContainer } from './elements'
import BN from 'bignumber.js'
import AppContext from '../../components/AppContext'

// Utils
const maxValue = max => (value) => {
  const message = max > 0 
    ? Number(value) > max && `Should be less than or equal to ${max}`
    : 'Not enough balance'
  
  return message;
}

// Component
const BorrowFormScreen = () => {  
  const context = useContext(AppContext)
  const { txCurrency } = useParams()
  const operation = 'borrow'
  const requestId = operation + txCurrency

  const isCelo = txCurrency === 'CELO'

  const [amount, setAmount] = useState('')

  const [{ balance, isLoading: isBalanceLoading }] = useBalance(txCurrency)
  const [{ availableBorrow, isLoading: isAvailableBorrowLoading }] = useAvailableBorrow(txCurrency) 
  const [{ isNotEnoughBalance, balanceDiff }, isRequiredBalanceLoading] = useCheckRequiredBalance(0, isCelo ? 'cUSD' : 'CELO')
  const { handleTxRequest } = useTxRequest()

  const processOperation = async ({ amount }) => {
    cancelRequest(requestId)

    setAmount(amount)
    const { lendingPool, reserves } = await getContract()
    const value = BN(10).pow(18).multipliedBy(amount).toFixed(0)

    try {
      const txObject = lendingPool.methods.borrow(reserves[txCurrency], value, 2, 0);

      const txParam = {
        tx: txObject,
        from: context.user.address,
        to: lendingPool.options.address,
        estimatedGas: 2000000,
        feeCurrency: isCelo ? FeeCurrency.cUSD : undefined
      }
      
      const txParams = [txParam]
      
      handleTxRequest(operation, requestId, txCurrency, amount, txParams)
    } catch (error) {
      console.log(error)
      context.showError(error.message)
    }
  }
    
  const validators = composeValidators(
    required, 
    mustBeNumber, 
    mustBePositiveNumber,
    maxValue(availableBorrow)
  )

  return (
    <Form
      onSubmit={processOperation}
      initialValues={{ }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <FormContainer onSubmit={handleSubmit} $showWarning={isNotEnoughBalance}>
          <Text>{`How much ${txCurrency} would you like to borrow?`}</Text>
          <Field 
            name="amount"
            validate={validators}
            render={({ input, meta }) => (
              <TextField 
                inputProps={{
                  ...input,
                  onChange: (event) => {
                    const { value } = event.target
                    input.onChange(event)
                    const newPercentage = value ? BN(value).dividedBy(availableBorrow).multipliedBy(100).toFixed(0) : 0
                    form.change('percentage', newPercentage)
                  },
                  inputMode: 'decimal',
                  type: 'number'
                }} 
                helperText={meta.touched ? meta.error : ''} 
                error={meta.error && meta.touched} 
                placeholder={getValuePlaceholder(txCurrency)}
                label="Borrow value"
                variant="outlined"
              />
            )}
          />
          <Field 
            name="percentage"
            render={({ input }) => (
              <ValueSlider form={form} input={input} maxAmount={availableBorrow} isLoading={isAvailableBorrowLoading} />
            )}
          />
          <Text>{`Wallet ${txCurrency} balance is: ${isBalanceLoading ? 'loading...' : formatAmount(balance, txCurrency)}`}</Text>
          <Button color="secondary" variant="contained" onClick={handleSubmit} disabled={isAvailableBorrowLoading || pristine|| isNotEnoughBalance}>
            {`Borrow ${txCurrency}`}
          </Button>
          {!isRequiredBalanceLoading && isNotEnoughBalance && (
            <MuiAlert variant="filled" severity="warning">
              {`Missing ${balanceDiff} ${isCelo ? 'cUSD' : 'CELO'} to send transaction. Please fund your wallet.`}
            </MuiAlert>
          )}
        </FormContainer>
      )}
    />
  )
}

export default requiresAuth(withView(BorrowFormScreen))
