import { Box } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { EventRegister } from 'react-native-event-listeners'
import { getSettingsCurrency } from '../../api/requests'
import Moo from '../../screens/Moo'
import NewBorrowScreen from '../../screens/NewBorrow'
import NewCurrenciesScreen from '../../screens/NewCurrencies'
import NewDepositScreen from '../../screens/NewDeposit'
import NewLanguagesScreen from '../../screens/NewLanguages'
import NewRepayScreen from '../../screens/NewRepay'
import NewSettingsScreen from '../../screens/NewSettings'
import NewWithdrawalScreen from '../../screens/NewWithdrawal'
import NoCollateral from '../../screens/NoCollateral'
import Privacy from '../../screens/PrivacyPolicy'
import Terms from '../../screens/Terms'
import { isMobile } from '../../utils/browser'
import ConditionalRender from '../../utils/conditional-render'
import {
	DESKTOP_ACTION_SUCCESS,
	DESKTOP_CURRENCY_CHANGED,
	REFRESH_DATA_DELAY,
	REFRESH_DATA_EXTRA_DELAY,
  	ACCOUNT_PRICES_TAB_SWITCH
} from '../../utils/constants'
import ConfirmationSuccess from '../ConfirmationSuccess'
import ConnectWallet from '../ConnectWallet'
import HomeAccount from '../HomeAccount'
import HomeMarket from '../HomeMarket'
import { Layout } from './elements'

const styles = (theme) => ({
  desktopWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '100%'
  },
  desktopContainer: {
    width: '100%',
    margin: '10px 0 10px 1%',
    height: 'calc(100vh - 128px)',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '16px',
    position: 'relative',
    padding: '16px 16px 2px',
    boxSizing: 'border-box',
    '&:nth-child(2)': {
      maxWidth: '405px'
    }
  },
  fullHeight: {
    height: '100%',
    boxSizing: 'border-box',
	overflow: 'auto'
  },
  containerHeader: {
    borderBottom: '2px solid #EDEEEF',
    fontSize: '24px',
    lineHeight: '28px',
    fontFamily: 'Jost-700-Bold',
    fontWeight: '700',
    color: theme.palette.text.primary,
    padding: '18px 16px',
    background: theme.palette.neutral.white
  }
})

const DesktopLayout = ({
  t,
  classes,
  user,
  mooToken,
  currency,
  currentState,
  setRiskFactorValue
}) => {
  const url_string = window.location.href.replace(/#/, '')
  const url = new URL(url_string)
  const currentPage = url.searchParams.get('state') || currentState
  const [forceRefresh, setForceRefresh] = useState(Date.now())
  const [localCurrency, setLocalCurrency] = useState(undefined)

  useEffect(() => {
    const actionSuccessListener = EventRegister.addEventListener(
      DESKTOP_ACTION_SUCCESS,
      () => {
        // Delay refresh account so that data is available from backend api
        setTimeout(() => {
			setForceRefresh(Date.now()), REFRESH_DATA_DELAY
			// Redo refresh account with a bigger delay so that data is available from backend api
			setTimeout(() => setForceRefresh(Date.now()), REFRESH_DATA_EXTRA_DELAY)
		})
      }
    )
    return () => {
      EventRegister.removeEventListener(actionSuccessListener)
    }
  }, [])

  useEffect(() => {
    setLocalCurrency(currency)
    const currencyChangedListener = EventRegister.addEventListener(
      DESKTOP_CURRENCY_CHANGED,
      () => {
        getSettingsCurrency().then((result) => setLocalCurrency(result))
      }
    )
    return () => {
      EventRegister.removeEventListener(currencyChangedListener)
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isMobile) {
        EventRegister.emit(DESKTOP_ACTION_SUCCESS)
      }
    }, 300000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const accountPricesTabSwitchListener = EventRegister.addEventListener(
		ACCOUNT_PRICES_TAB_SWITCH,
		() => setForceRefresh(Date.now())
    )
    return () => {
      EventRegister.removeEventListener(accountPricesTabSwitchListener)
    }
  }, [])

  return (
    <Layout className={classes.desktopWrapper}>
      <Box
        className={`${classes.desktopContainer} ${
          currentPage === 'terms' ||
          currentPage === 'privacy-policy' ||
          currentPage === 'moo'
            ? classes.termsContent
            : ''
        }`}
      >
        <ConditionalRender if={currentPage === 'connect-wallet'}>
          <ConnectWallet />
        </ConditionalRender>

        <ConditionalRender if={user && currentPage === 'deposit'}>
          <NewDepositScreen />
        </ConditionalRender>

        <ConditionalRender if={user && currentPage === 'repay'}>
          <NewRepayScreen />
        </ConditionalRender>

        <ConditionalRender if={user && currentPage === 'withdrawal'}>
          <NewWithdrawalScreen />
        </ConditionalRender>

        <ConditionalRender if={user && currentPage === 'borrow'}>
          <NewBorrowScreen />
        </ConditionalRender>

        <ConditionalRender if={user && currentPage === 'no-collateral'}>
          <NoCollateral />
        </ConditionalRender>

        <ConditionalRender if={user && currentPage === 'confirm-success'}>
          <ConfirmationSuccess />
        </ConditionalRender>

        <ConditionalRender if={currentPage === 'settings'}>
          <NewSettingsScreen />
        </ConditionalRender>

        <ConditionalRender if={currentPage === 'moo'}>
          <div className={classes.fullHeight}>
            <Moo />
          </div>
        </ConditionalRender>

        <ConditionalRender if={currentPage === 'languages'}>
          <NewLanguagesScreen />
        </ConditionalRender>

        <ConditionalRender if={currentPage === 'terms'}>
          <div className={classes.fullHeight}>
            <Terms />
          </div>
        </ConditionalRender>

        <ConditionalRender if={currentPage === 'privacy-policy'}>
          <div className={classes.fullHeight}>
            <Privacy />
          </div>
        </ConditionalRender>

        <ConditionalRender if={currentPage === 'currencies'}>
          <NewCurrenciesScreen />
        </ConditionalRender>

        <ConditionalRender if={!currentPage}>
          <Box className={classes.fullHeight}>
            <Box className={classes.containerHeader}>
              {t('HOME.MARKET_TAB.MARKET_TITLE')}
            </Box>
            <HomeMarket currency={localCurrency} forceRefresh={forceRefresh} />
          </Box>
        </ConditionalRender>
      </Box>

      <ConditionalRender if={user}>
        <Box
          className={`${classes.desktopContainer} ${classes.accountContainer}`}
        >
          <Box className={classes.fullHeight}>
            <Box className={classes.containerHeader}>
              {t('HOME.MARKET_TAB.ACCOUNT_TITLE')}
            </Box>
            <HomeAccount
              user={user}
              forceRefresh={forceRefresh}
              mooToken={mooToken}
              currency={localCurrency}
              setRiskFactorValue={setRiskFactorValue}
            />
          </Box>
        </Box>
      </ConditionalRender>
    </Layout>
  )
}

export default withTranslation()(withStyles(styles)(DesktopLayout))

DesktopLayout.propTypes = {
  marketTab: PropTypes.object,
  accountTab: PropTypes.object,
  user: PropTypes.object,
  tabIndex: PropTypes.number,
  handleChange: PropTypes.func,
  currentState: PropTypes.string,
  isScrolled: PropTypes.bool,
  setRiskFactorValue: PropTypes.func,
  currency: PropTypes.string
}
