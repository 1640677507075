import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, useTheme } from '@material-ui/core'
import Settings from '../../components/Settings'
import { useGetSettingsCurrency, useGetSettingsLanguage } from '../../api'
import { FIAT_CURRENCIES, LANGUAGES } from '../../utils/constants'
import { withTranslation } from 'react-i18next'
import { WhitePage } from '../../utils/elements'

const NewSettingsScreen = ({ t }) => {
  const history = useHistory()
  const theme = useTheme()
  const currency = useGetSettingsCurrency()
  const language = useGetSettingsLanguage()
  const applicationSettings = []

  const contactSettings = [
    {
      text: t('SETTINGS.HELP_SUPPORT'),
      url: 'mailto:info@moola.market'
    },
    {
      text: t('SETTINGS.FOLLOW_TWITTER'),
      url: 'https://twitter.com/moola_market'
    },
    {
      text: t('SETTINGS.JOIN_DISCORD'),
      url: 'https://discord.gg/NsphyqbESK'
    },
    {
      text: t('SETTINGS.TERMS'),
      url: '/#/terms'
    },
    {
      text: t('SETTINGS.PRIVACY'),
      url: '/#/privacy-policy'
    }
  ]

  if (language.isSuccess && LANGUAGES[language.data]) {
    applicationSettings.push({
      leftText: t('SETTINGS.LANGUAGE'),
      rightText: LANGUAGES[language.data].text,
      onClick: () => history.push('/languages')
    })
  }

  if (currency.isSuccess && FIAT_CURRENCIES[currency.data]) {
    const currentCurrency = FIAT_CURRENCIES[currency.data].text
    applicationSettings.push({
      leftText: t('SETTINGS.CURRENCY'),
      rightText: `${currentCurrency}`,
      onClick: () => history.push('/currencies')
    })
  }

  return (
    <WhitePage theme={theme}>
      <Box>
        <Settings
          title={t('SETTINGS.TITLE')}
          applicationSettings={applicationSettings}
          contactSettings={contactSettings}
        />
      </Box>
    </WhitePage>
  )
}

export default withTranslation()(NewSettingsScreen)
