import { Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core/styles'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import BN from 'bignumber.js'
import classNames from 'classnames'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
	useGetFee, useGetSettingsCurrency,
	useGetUserAccountInfoBorrow, useGetUserAccountInfoStatus, useGetUserAccountLiquidationPrices
} from '../../api'
import BorrowIcon from '../../assets/images/borrow-fill.svg'
import CloseIcon from '../../assets/images/close.svg'
import ActionLayer from '../../components/ActionLayer'
import AmountBox from '../../components/AmountBox'
import AppContext from '../../components/AppContext'
import BottomPopup from '../../components/BottomPopup'
import ButtonSwitch from '../../components/ButtonSwitch'
import Card from '../../components/Card'
import Confirmation from '../../components/Confirmation'
import CurrencyDesktopDropdown from '../../components/CurrencyDesktopDropdown'
import { FloatingCard } from '../../components/FloatingCard/elements'
import useAccountBorrowData from '../../components/hooks/useAccountBorrowData'
import useAvailableBorrow from '../../components/hooks/useAvailableBorrow'
import useAvailableBorrowStable from '../../components/hooks/useAvailableBorrowStable'
import useNewAccountStatus from '../../components/hooks/useNewAccountStatus'
import useReserveConfigurationData from '../../components/hooks/useReserveConfigurationData'
import useReserveData from '../../components/hooks/useReserveData'
import useTxRequest from '../../components/hooks/useTxRequest'
import {
	BorrowAmountToDestinationInfo, DebtInfo,
	FundingDestinationInfo,
	HealthScoreInfo,
	InterestRateInfo, InterestRateModeInfo, LiquidationPriceInfo, OriginalFeesInfo, SecurityFeesInfo
} from '../../components/InfoContent'
import InfoIcon from '../../components/InfoIcon'
import ScaleSlider from '../../components/ScaleSlider'
import SwitchPopup from '../../components/SwitchPopup'
import { getContract } from '../../root'
import { cancelRequest, FeeCurrency } from '../../sdk/dappkit'
import {
	cCurrencyFormatter,
	currencyFormatter, formatCurrencyFromApi, getHealthFactor
} from '../../utils'
import {
	getCurrentAccountStatusData,
	getNewAccountStatusData
} from '../../utils/accountStatus'
import { isMobile } from '../../utils/browser'
import {
	CURRENCIES,
	DEFAULT_CURRENCY,
	LOAN_TERMS,
	RISK_FACTOR_STATE,
	TRANSACTIONS
} from '../../utils/constants'
import { WhitePage } from '../../utils/elements'
import {
	feesFormat,
	formatNumber,
  formatNumberWithoutRounding
} from '../../utils/format'
import {
	composeValidators,
	maxValue,
	mustBePositiveNumber,
	required
} from '../../utils/validators'
import { Alert } from '@material-ui/lab'

const styles = (theme) => ({
  container: {
	display: 'flex',
	'& > * ': {
		flexGrow: '1',
		margin: '16px'
	},
	'& > *:last-child': {
	    minWidth: '300px'
	}
  },
  wrapper: {
    paddingBottom: '40px',
    '& > *': {
      marginBottom: '16px'
    }
  },
  title: {
    fontFamily: 'Jost-700-Bold',
    fontSize: '20px',
    lineHeight: '24px'
  },
  hidden: {
    display: 'none'
  },
  fullHeight: {
    height: '100%'
  },
  bolded: {
    fontFamily: 'Jost-700-Bold'
  },
  link: {
    color: theme.palette.common.link,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  error: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    zIndex: '9999',
    boxSizing: 'border-box'
  }
})

// Maximum stable rate borrow size, in percentage of the available liquidity.
const MAX_STABLE_RATE_BORROW_SIZE_PERCENT = 25

const NewBorrowScreen = ({ t, classes }) => {
  const context = useContext(AppContext)

  const [showCurrencyPopup, setShowCurrencyPopup] = useState(false)
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [currencies, setCurrencies] = useState(
    Object.values(CURRENCIES).map((currency) => ({
      ...currency,
      selected: currency.key === DEFAULT_CURRENCY
    }))
  )
  const [desktopCurrencies, setDesktopCurrencies] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState()
  const [confirmation, setConfirmation] = useState(false)
  const [value, setValue] = useState(0)
  const [loanTerms, setLoanTerms] = useState(LOAN_TERMS.VARIABLE)
  const [hasDebtValue, setHasDebt] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [usedLoanTermsForCurrency, setUsedLoanTermsForCurrency] = useState(null)
  const [isStableRateDisabled, setIsStableRateDisabled] = useState(null)
  const [apiError, setApiError] = useState(false)

  const amountFormName = useRef('amount')
  const loanTermsFormName = useRef('loanTerms')
  const theme = useTheme()

  const { handleTxRequest } = useTxRequest()

  const { data: currentCurrency } = useGetSettingsCurrency()
  const accountInfoBorrow = useGetUserAccountInfoBorrow(context.user.address)
  const currentAccountInfoStatus = useGetUserAccountInfoStatus(
    context.user.address,
    currentCurrency
  )
  const userAccountLiquidationPrices = useGetUserAccountLiquidationPrices(
    context.user.address
  )

  const [{ isSuccess: isNewAccountStatusSuccess, data }] = useNewAccountStatus(
    value,
    currentCurrency,
    selectedCurrency,
    TRANSACTIONS.BORROW
  )


  useEffect(() => {
    if (accountInfoBorrow?.isError || userAccountLiquidationPrices?.isError || currentAccountInfoStatus?.isError) {
      setApiError(true)
    }
  }, [accountInfoBorrow, userAccountLiquidationPrices, currentAccountInfoStatus])

  useEffect(() => {
    if (accountInfoBorrow.isSuccess) {
      const hasAnyDebt = accountInfoBorrow.data?.data?.some((borrowInfo) => {
        return borrowInfo.amount !== 0
      })
      setHasDebt(hasAnyDebt)
    } else {
      setHasDebt(false)
    }
  }, [accountInfoBorrow])

  useEffect(() => {
    setSelectedCurrency(currencies.find((currency) => currency.selected))
  }, [currencies])

  useEffect(() => {
    loadBorrowedLoanTermForCurrentCurrency()
  }, [selectedCurrency])

  const accountBorrowData = useAccountBorrowData()

  useEffect(() => {
	if (accountBorrowData.isSuccess) {
		setDesktopCurrencies(currencies.map((currency) => ({
			...currency,
			value: getTotalBorrowForCurrency(accountBorrowData?.accountBorrowData?.find((e) => e.currency === currency.text))
		})))
	}
  }, [accountBorrowData.isSuccess])

  const getTotalBorrowForCurrency = (currencyBorrowInfo) => {
	return currencyBorrowInfo ? currencyBorrowInfo.borrow : undefined
  }

  useEffect(() => {
    if (isStableRateDisabled && loanTerms === LOAN_TERMS.STABLE) {
      setLoanTerms(!usedLoanTermsForCurrency ? LOAN_TERMS.VARIABLE : undefined)
    } else if (
      !isStableRateDisabled &&
      loanTerms === undefined &&
      usedLoanTermsForCurrency === LOAN_TERMS.STABLE
    ) {
      setLoanTerms(LOAN_TERMS.STABLE)
    }
  }, [isStableRateDisabled])

  const loadBorrowedLoanTermForCurrentCurrency = async () => {
    const { lendingPool, reserves } = await getContract()
    let userReserveData = await lendingPool.methods
      .getUserReserveData(reserves[txCurrency], context.user.address)
      .call()
    const usedLoanTerms = parseInt(userReserveData.borrowRateMode)
    setUsedLoanTermsForCurrency(usedLoanTerms)
    setLoanTerms(usedLoanTerms ? usedLoanTerms : LOAN_TERMS.VARIABLE)
    setValue(0)
  }

  const selectCurrency = (key) => {
    const updatedCurrencies = currencies.map((currency) => {
      currency.selected = currency.key === key
      return currency
    })

    setCurrencies(updatedCurrencies)

	const updatedDesktopCurrencies = desktopCurrencies.map((currency) => {
		currency.selected = currency.key === key
		return currency
	})
	setDesktopCurrencies(updatedDesktopCurrencies)
  }

  const submit = () => {
    setConfirmation(true)
  }

  const processOperation = async () => {
    let txCurrency = selectedCurrency.text
    const operation = `borrow`
    const requestId = `${operation}${txCurrency}`
    const amount = value

    cancelRequest(requestId)

    const isCelo = txCurrency === CURRENCIES.celo.text

    const { lendingPool, reserves } = await getContract()
    const valueToBorrow = BN(10).pow(18).multipliedBy(amount).toFixed(0)

    try {
      const txObject = lendingPool.methods.borrow(
        reserves[txCurrency],
        valueToBorrow,
        loanTerms,
        0
      )

      const txParam = {
        tx: txObject,
        from: context.user.address,
        to: lendingPool.options.address,
        estimatedGas: 2000000,
        feeCurrency: isCelo ? FeeCurrency.cUSD : undefined
      }

      const txParams = [txParam]

      handleTxRequest(operation, requestId, txCurrency, amount, txParams)
    } catch (error) {
      console.log(error)
      context.showError(error.message)
    }
  }

  let txCurrency = currencies.find((currency) => currency.selected).text

  const fee = useGetFee(
    context.user.address,
    selectedCurrency?.text,
    TRANSACTIONS.BORROW,
    parseFloat(value || 0),
    confirmation
  )

  const [{ availableBorrow }] = useAvailableBorrow(txCurrency)

  const [{ reserveConfigurationData }] = useReserveConfigurationData(txCurrency)

  const [{ availableBorrowStable }] = useAvailableBorrowStable(
    txCurrency,
    value,
    reserveConfigurationData?.isStableBorrowRateEnabled
  )

  const [{ reserveData }] = useReserveData(txCurrency)

  const canBorrowStableAmount = () => {
    const availableLiquidity = reserveData?.availableLiquidity
    const maxLoanSizeStable = BN(availableLiquidity)
      ?.multipliedBy(MAX_STABLE_RATE_BORROW_SIZE_PERCENT)
      .dividedBy(100)
    const result = maxLoanSizeStable?.isGreaterThan(value)
    return result
  }

  /**
   * Following conditions need to be met if the user is borrowing at a stable rate:
   * 1. Reserve must be enabled for stable rate borrowing
   * 2. Users cannot borrow from the reserve if their collateral is (mostly) the same currency
   *    they are borrowing, to prevent abuses.
   * 3. Users will be able to borrow only a relatively small, configurable amount of the total
   *    liquidity
   **/
  const stableRateDisabled = () => {
    const localIsStableRateDisabled = !(
      availableBorrowStable === true && canBorrowStableAmount()
    )
    if (localIsStableRateDisabled !== isStableRateDisabled) {
      setIsStableRateDisabled(localIsStableRateDisabled)
    }
    return localIsStableRateDisabled
  }

  const content = ({ form }) => {
    const name = amountFormName.current
    const loanTermsName = loanTermsFormName.current
    const maxAmount = availableBorrow
    const fieldState = form.getFieldState(name)
    const currentData = currentAccountInfoStatus.data
    const liquidationPrices = userAccountLiquidationPrices?.data
    const newAccountStatusData = getNewAccountStatusData(
      t,
      classes,
      currentCurrency,
      data,
      selectedCurrency,
      loanTerms
    )
    newAccountStatusData.content.unshift({
      text: t('GENERAL.DEBT'),
      value: currencyFormatter(currentCurrency)(data?.remainingDebt),
      info: {
        content: <DebtInfo />
      }
    })

    const currentAccountStatusData = getCurrentAccountStatusData(
      t,
      classes,
      selectedCurrency?.text,
      currentData,
      liquidationPrices,
      loanTerms
    )
    currentAccountStatusData.content.unshift({
      text: t('GENERAL.DEBT'),
      value: currencyFormatter(currentCurrency)(currentData?.remainingDebt),
      info: {
        content: <DebtInfo />
      }
    })
    return (
	  <div className={!isMobile ? classes.container: ''}>
		<Box className={classes.wrapper}>
			<Grid
				container
				alignItems={'center'}
				onClick={() => {
					setSelectedItem({
					text: t('BORROW.AMOUNT_BORROW'),
					info: {
						content: amountToBorrowInfo
					}
					})
				}}
				>
				<Typography className={classes.title}>
					{t('BORROW.AMOUNT_BORROW')}
				</Typography>
				<InfoIcon
					maxRight={isMobile}
					title={t('BORROW.AMOUNT_BORROW')}
					text={amountToBorrowInfo}
				/>
			</Grid>
			{selectedCurrency && isNewAccountStatusSuccess && (
				<AmountBox
					name={name}
					validators={composeValidators(
					mustBePositiveNumber,
					required,
					maxValue(maxAmount, selectedCurrency.text, TRANSACTIONS.BORROW)
					)}
					formControl={fieldState}
					switchClick={() =>  isMobile ? setShowCurrencyPopup(true) : setShowCurrencyDropdown(true)}
					currency={selectedCurrency}
					onValueChange={setValue}
				>
					{showCurrencyDropdown &&
						(<CurrencyDesktopDropdown
							currencies={desktopCurrencies}
							selectCurrency={(key) => {
								selectCurrency(key)
								fieldState.change(undefined)
								form.resetFieldState(amountFormName.current)
								setShowCurrencyDropdown(false)
							}}
							currentCurrency={currentCurrency}
						>
						</CurrencyDesktopDropdown>)
					}
          <ScaleSlider
            selectedCurrency={selectedCurrency}
            max={maxAmount}
            name={name}
            leftLabel={t('BORROW.SLIDER.LEFT')}
            rightLabel={t('BORROW.SLIDER.RIGHT')}
            onValueChange={setValue}
          />
				</AmountBox>
			)}

			{loanTermsForm(loanTermsName)}
		</Box>
		<Box className={classes.wrapper}>
			{fieldState?.value?.toString() !== '0' && !!fieldState?.value && (
				<>
				<Typography className={classes.title}>
					{t('GENERAL.NEW_ACCOUNT_STATUS')}
				</Typography>

				<Card cardData={newAccountStatusData} />
				</>
			)}
			{(fieldState?.value?.toString() === '0' || !fieldState?.value) && hasDebtValue && (
				<>
				<Typography className={classes.title}>
					{t('GENERAL.CURRENT_ACCOUNT_STATUS')}
				</Typography>

				<Card cardData={currentAccountStatusData} />
				</>
			)}

			<BottomPopup
				show={showCurrencyPopup}
				label={t('GENERAL.SWITCH_CURRENCY')}
				onClose={() => setShowCurrencyPopup(false)}
				icon={CloseIcon}
			>
				<SwitchPopup
					currencies={currencies}
					selectCurrency={(key) => {
					selectCurrency(key)
					fieldState.change(undefined)
					form.resetFieldState(amountFormName.current)
					setShowCurrencyPopup(false)
					}}
				/>
			</BottomPopup>
			{selectedItem && (
				<BottomPopup
					show={true}
					label={selectedItem.text}
					onClose={() => setSelectedItem(null)}
					icon={CloseIcon}
					children={selectedItem.info.content}
					primaryButtonTitle={t('GENERAL.DONE')}
					primaryButtonClick={() => setSelectedItem(null)}
				/>
			)}
		</Box>
	  </div>
    )
  }

  const amountToBorrowInfo = (
    <>
      <Typography component={'p'}>
        {t('BORROW.AMOUNT_BORROW.INFO.1')}
      </Typography>
      <Typography component={'p'}>
        {t('BORROW.AMOUNT_BORROW.INFO.2')}
      </Typography>
    </>
  )

  const loanTermsInfo = (
    <>
      <Typography component={'p'}>{t('BORROW.LOAN_TERMS.INFO.1')}</Typography>
      <Typography component={'p'}>{t('BORROW.LOAN_TERMS.INFO.2')}</Typography>
    </>
  )
  const loanTermsForm = (loanTermsName) => (
    <>
      <Grid
        container
        onClick={() => {
          setSelectedItem({
            text: t('BORROW.LOAN_TERMS'),
            info: {
              content: loanTermsInfo
            }
          })
        }}
      >
        <Typography className={classes.title}>
          {t('BORROW.LOAN_TERMS')}
        </Typography>
        <InfoIcon
			    maxRight={isMobile}
          title={t('BORROW.LOAN_TERMS')}
          text={loanTermsInfo}
        />
      </Grid>

      <FloatingCard theme={theme}>
        <ButtonSwitch
          name={loanTermsName}
          options={[
            {
              label: t('BORROW.LOAN_TERMS.VARIABLE'),
              value: LOAN_TERMS.VARIABLE,
              disabled:
                usedLoanTermsForCurrency !== 0 &&
                LOAN_TERMS.VARIABLE !== usedLoanTermsForCurrency,
              selectedLoanTerms: loanTerms === LOAN_TERMS.VARIABLE
            },
            {
              label: t('BORROW.LOAN_TERMS.STABLE'),
              value: LOAN_TERMS.STABLE,
              disabled:
                (usedLoanTermsForCurrency !== 0 &&
                  LOAN_TERMS.STABLE !== usedLoanTermsForCurrency) ||
                stableRateDisabled(),
              selectedLoanTerms: loanTerms === LOAN_TERMS.STABLE
            }
          ]}
          onValueChange={(value) => {
            setLoanTerms(value)
          }}
        />
        {loanTerms === LOAN_TERMS.VARIABLE && (
          <Typography>
            <Typography component={'span'} className={classes.bolded}>
              {t('BORROW.LOAN_TERMS.VARIABLE.INFO.1')}
            </Typography>
            {t('BORROW.LOAN_TERMS.VARIABLE.INFO.2')}
          </Typography>
        )}
        {loanTerms === LOAN_TERMS.STABLE && (
          <Typography>
            <Typography component={'span'} className={classes.bolded}>
              {t('BORROW.LOAN_TERMS.STABLE.INFO.1')}
            </Typography>
            {t('BORROW.LOAN_TERMS.STABLE.INFO.2')}
          </Typography>
        )}
      </FloatingCard>
    </>
  )

  const getConfirmationContent = () => {
    const selectedCurrencyText = selectedCurrency?.text
    const amountToBorrow = value

    let securityFee = 0
    let loanOriginationFee = 0

    if (fee.isSuccess && fee?.data) {
      securityFee = fee?.data?.securityFee
      loanOriginationFee = fee?.data?.loanOriginationFee
    }
    const total = BN(amountToBorrow)
      .minus(securityFee)
      .minus(loanOriginationFee)

    const content = {
      message: t('BORROW.CONFIRM_BORROW_AMOUNT', {
        amount: formatNumberWithoutRounding(value, CURRENCIES[selectedCurrency.text.toLowerCase()]?.decimals),
        currency: selectedCurrencyText
      }),
      items: [
        {
          text: t('GENERAL.FUNDING_DESTINATION'),
          value: context.user.walletName,
          info: <FundingDestinationInfo />
        },
        {
          text: t('BORROW.AMOUNT_BORROWED'),
          value: `${formatNumberWithoutRounding(
            amountToBorrow,
            CURRENCIES[selectedCurrency.text.toLowerCase()]?.decimals)
          } ${selectedCurrencyText}`,
          info: <BorrowAmountToDestinationInfo />
        },
        {
          text: t('GENERAL.ORIGINAL_FEES'),
          value: `${loanOriginationFee.toFixed(5)} ${selectedCurrencyText}`,
          info: <OriginalFeesInfo classes={classes} />
        },
        {
          text: t('GENERAL.SECURITY_FEES'),
          value: `${feesFormat(
            securityFee,
            selectedCurrencyText
          )} ${selectedCurrencyText}`,
          info: <SecurityFeesInfo classes={classes} />
        }
        // {
        //   text: t('GENERAL.TOTAL_TO_DESTINATION'),
        //   value: `${total} ${selectedCurrencyText}`,
        //   isTotal: true
        // }
      ]
    }

    const newData = data
    const healthFactor = getHealthFactor(newData)
    content.additionalItems = [
      {
        text: t('GENERAL.DEBT'),
        value: currencyFormatter(currentCurrency)(
          newData?.remainingDebt || currentAccountInfoStatus.data.remainingDebt
        ),
        info: <DebtInfo />
      },
      {
        text: t('GENERAL.NEW_HEALTH_FACTOR'),
        value: `${healthFactor} ${t('GENERAL.' + newData?.state)}`,
        info: <HealthScoreInfo classes={classes} />,
        scale: RISK_FACTOR_STATE[newData?.state]
      }
    ]

    newData?.collateralAssets?.forEach((collateralAsset) => {
      if (
        collateralAsset.currency.toLowerCase() !==
        collateralAsset.priceCurrency.toLowerCase()
      ) {
        content.additionalItems.push({
          text: t('GENERAL.NEW_CURRENCY_LIQUIDATION_PRICE', {
            currency: formatCurrencyFromApi(collateralAsset.currency)
          }),
          value: cCurrencyFormatter(collateralAsset.priceCurrency)(
            formatNumber(collateralAsset.liquidationPrice, CURRENCIES[collateralAsset.currency?.toLowerCase()]?.decimals)
          ),
          info: <LiquidationPriceInfo />
        })
      }
    })
    if (loanTerms) {
      content.additionalItems.push({
        text: t('GENERAL.INTEREST_RATE_MODE'),
        value: t(`GENERAL.INTEREST_RATE_MODE.${loanTerms}`),
        info: <InterestRateModeInfo />
      })
    }
    if (newData?.interestRates?.length) {
      const rateValue = newData.interestRates.find(
        (rate) =>
          rate.currency.toLowerCase() === selectedCurrencyText.toLowerCase()
      )
      content.additionalItems.push({
        text: t('GENERAL.INTEREST_RATE'),
        value: `${
          loanTerms === LOAN_TERMS.STABLE
            ? rateValue?.stableInterestRate
            : rateValue?.variableInterestRate
        }% ${t('GENERAL.APR')}`,
        info: <InterestRateInfo />
      })
    }

    return content
  }
  return (
    <WhitePage theme={theme}>
      {apiError && <Alert className={classes.error} severity='error' onClose={() => setApiError(false)}>{t('GENERAL.API_ERROR')}</Alert>}
      <Box
        className={classNames(
          classes.fullHeight,
          confirmation && classes.hidden
        )}
      >
        <ActionLayer
          icon={BorrowIcon}
          title={t('GENERAL.BORROW')}
          subtitle={t('BORROW.HELPER_TEXT')}
          homeOnBack={!isMobile}
          disabledButton={
            !loanTerms ||
            (usedLoanTermsForCurrency !== 0 &&
              LOAN_TERMS.VARIABLE !== usedLoanTermsForCurrency &&
              ((usedLoanTermsForCurrency !== 0 &&
                LOAN_TERMS.STABLE !== usedLoanTermsForCurrency) ||
                stableRateDisabled()))
          }
          content={content}
          buttonClick={submit}
        />
      </Box>
      {confirmation && (
        <Confirmation
          content={getConfirmationContent()}
          back={() => setConfirmation(false)}
          confirmation={processOperation}
          isLoading={fee.isLoading}
        />
      )}
    </WhitePage>
  )
}

export default withTranslation()(withStyles(styles)(NewBorrowScreen))
