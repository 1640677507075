import React, { useContext, useState } from 'react'
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import FlowButton from "../FlowButton";
import MooIcon from "../../assets/images/moo.svg";
import MooFillIcon from "../../assets/images/moo-fill.svg";
import { ClaimMooInfo } from "../InfoContent";
import { getContract } from "../../root";
import AppContext from "../AppContext";
import useTxRequest from "../hooks/useTxRequest";
import { withTranslation } from "react-i18next";
import BottomPopup from "../BottomPopup";
import CloseIcon from "../../assets/images/close.svg";


const MooToken = ({ t, mooToken, isTransparent, desktopButton, selected }) => {
    const { handleTxRequest } = useTxRequest()
    const context = useContext(AppContext)
    const [mooPopUp, setMooPopUp] = useState(false)

    const processOperation = async ({ index, address, amount, proof }) => {
        const { merkleDistributor } = await getContract()
        const operation = 'claim'
        const requestId = operation + index
        try {
            const txObject = merkleDistributor.methods.claim(
                index,
                address,
                amount,
                proof
            )
            const txParamCelo = {
                tx: txObject,
                from: context.user.address,
                to: merkleDistributor._address,
                value: 0,
                estimatedGas: 2000000
            }

            let txParams = [txParamCelo]
            handleTxRequest(operation, requestId, 'cGLD', amount, txParams)
        } catch (error) {
            console.log(error)
            context.showError(error.message)
        }
    }

    const mooClaim = async () => {
        const { index, address, amount, proof } = mooToken
        await processOperation({ index, address, amount, proof })
        setMooPopUp(false)
    }

    return (
        <>

            <Grid item>
                <FlowButton
                    label={t('MOO.CLAIM_MOO')}
                    icon={MooIcon}
                    isTransparent={isTransparent !== undefined ? isTransparent : true}
                    onClick={() => setMooPopUp(true)}
                    desktopButton={desktopButton}
                    selected={selected}
                />
            </Grid>
            {mooPopUp && (
                <BottomPopup
                    show={true}
                    label={t('MOO.CLAIM_MOO')}
                    primaryButtonTitle={t('MOO.CLAIM')}
                    onClose={() => setMooPopUp(false)}
                    icon={CloseIcon}
                    popUpIcon={MooFillIcon}
                    primaryButtonClick={() => mooClaim()}
                >
                    <ClaimMooInfo
                        unclaimedValue={mooToken.unclaimedValue}
                        balance={mooToken.balance}
                    />
                </BottomPopup>
            )}
        </>
    );
};

MooToken.propTypes = {
    mooToken: PropTypes.object,
	  isTransparent: PropTypes.bool,
    selected: PropTypes.bool,
	  desktopButton: PropTypes.bool
}

export default withTranslation()(MooToken)
