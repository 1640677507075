import { useTheme } from '@material-ui/core'
import MuiIcon from '@material-ui/core/Icon'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useContext, useState, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import BackIcon from '../../assets/images/back-white.svg'
import MooIcon from '../../assets/images/moo-thin.svg'
import FlowButton from '../../components/FlowButton'
import useLogin from '../../components/hooks/useLogin'
import { CONTRACT_KIT, MOO_LOGIN } from '../../utils/constants'
import PlusIcon from '../../assets/images/plus.svg'
import { getContract } from '../../root'
import config from '../../config'
import { useGetMooTokenHolder } from '../../api'
import MooTokenAlfajores from '../../moo-token/moo-test-merkle.json'
import MooToken from '../../components/Moo-token'
import AppContext from '../../components/AppContext'
import BN from 'bignumber.js'
import MuiButton from '@material-ui/core/Button'
import classNames from 'classnames'

const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )

const styles = (theme) => ({
  root: {
    color: theme.palette.text.terms,
    fontFamily: 'Jost-400-Book'
  },
  rootDesktop: {
    fontSize: '24px',
    lineHeight: '35px',
    padding: '4% 10%'
  },
  rootMobile: {
    fontSize: '16px',
    lineHeight: '20px',
    padding: '16px 32px'
  },
  title: {
    textAlign: 'center',
    fontFamily: 'EBGaramond-600-SemiBold',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '600'
  },
  titleDesktop: {
    lineHeight: '84px',
    fontSize: '64px'
  },
  titleMobile: {
    lineHeight: '42px',
    fontSize: '24px',
    marginBottom: '24px',
    marginTop: '0'
  },
  mooIcon: {
    display: 'block',
    marginBottom: 'auto',
    marginTop: 'auto'
  },
  mooIconDesktop: {
    marginRight: '16px',
    width: '60px',
    height: '60px'
  },
  mooIconMobile: {
    marginRight: '8px',
    width: '28px',
    height: '28px'
  },
  launchAppButton: {
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 600
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginBottom: '16px'
  },
  desktopButton: {
    height: '54px',
    width: '252px'
  },
  mobileButton: {
    height: '36px',
    width: '130px'
  },
  subtitle: {
    fontSize: isMobile ? '20px' : '30px',
    marginTop: '30px',
    fontWeight: 'Bold',
    marginBottom: '8px'
  },
  content: {
    fontSize: isMobile ? '16px' : '24px',
    margin: '0',
    marginBottom: '10px',
    wordBreak: 'break-word'
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.text.terms
  }
})

const Moo = ({ t, classes }) => {
  const theme = useTheme()
  const history = useHistory()
  const { user } = useContext(AppContext)
  const [claimDetails, setClaimDetails] = useState(null)
  const accountMooTokenHolder = useGetMooTokenHolder(user?.address)
  const [tokenClaimed, setTokenClaimed] = useState(false)

  const login = useLogin()

  const connectCeloExtensionWallet = () => {
    login(CONTRACT_KIT)
  }

  useEffect(() => {
    const getMooDetails = async () => {
      let mooTokenClaimFound = {}
      if (
        !config.isTestnet &&
        accountMooTokenHolder.isSuccess &&
        accountMooTokenHolder.data.data[0]
      ) {
        const proof = accountMooTokenHolder.data.data[0].Proof.split(',').map(
          (el) => '0x' + el
        )
        const amount = '0x' + accountMooTokenHolder.data.data[0].Amount
        const address = '0x' + accountMooTokenHolder.data.data[0].Address
        mooTokenClaimFound = {
          index: accountMooTokenHolder.data.data[0].Index,
          address: address,
          amount: amount,
          proof: proof
        }
      } else {
        const mooTokenClaims = MooTokenAlfajores
        const map = new Map(Object.entries(mooTokenClaims.claims))
        mooTokenClaimFound = map.get(user?.address)
      }

      if (!user) return setClaimDetails(null)

      const { merkleDistributor, mooToken } = await getContract()
      if (mooTokenClaimFound && !tokenClaimed && mooTokenClaimFound.index) {
        const isClaimed = await merkleDistributor.methods
          .isClaimed(mooTokenClaimFound.index)
          .call()
        mooTokenClaimFound.address = user.address
        mooTokenClaimFound.unclaimedValue = BN(mooTokenClaimFound.amount)
          .dividedBy(BN(10).pow(18))
          .toString()
        const balance = await mooToken.methods.balanceOf(user.address).call()
        mooTokenClaimFound.balance = BN(balance)
          .dividedBy(BN(10).pow(18))
          .toString()
        mooTokenClaimFound.isClaimed = isClaimed
        setTokenClaimed(isClaimed)
        setClaimDetails(mooTokenClaimFound)
      }
    }
    if (user) {
      getMooDetails()
    } else {
      setClaimDetails(null)
    }
  }, [
    user,
    tokenClaimed,
    accountMooTokenHolder.isSuccess,
    accountMooTokenHolder.data
  ])

  return (
    <>
      <div
        className={classNames(
          classes.root,
          isMobile ? classes.rootMobile : classes.rootDesktop
        )}
      >
        <h1
          className={classNames(
            classes.title,
            isMobile ? classes.titleMobile : classes.titleDesktop
          )}
        >
          <img
            src={MooIcon}
            className={classNames(
              classes.mooIcon,
              isMobile ? classes.mooIconMobile : classes.mooIconDesktop
            )}
          />
          <span>MOO Token</span>
        </h1>
        <p className={classes.subtitle}>What is MOO?</p>
        <p className={classes.content}>
          MOO is a cERC20 token that was minted on the Celo blockchain. It’s
          purpose is to coordinate governance decision making and incentivize
          behavior that benefits the Moola ecosystem.
        </p>
        <p className={classes.subtitle}>What can I do with MOO?</p>
        <p className={classes.content}>
          Currently, you can stake your MOO in Ubeswap pools to earn trading
          fees and additional MOO as part of the liquidity mining token
          incentive. You can transfer MOO to other addresses on the Celo
          network. It is expected that control over Moola protocol governance
          decisions and the community treasury will be transferred to MOO
          owners.
        </p>
        <p className={classes.subtitle}>What is Moola protocol governance?</p>
        <p className={classes.content}>
          Moola protocol governance is the process in which decisions that
          influence Moola market parameters are made and the process in which
          those decisions are enacted. Examples include: deciding which assets
          to add, loan-to-value ratios, liquidation thresholds, fee amounts, and
          where to allocate collected fees.
        </p>
        <p className={classes.subtitle}>
          If I hold MOO tokens, does that impact my Health Factor?
        </p>
        <p className={classes.content}>
          No, MOO tokens are not collateral assets and they will not impact your
          Health Factor. As of now, MOO tokens cannot be borrowed from Moola.{' '}
        </p>
        <p className={classes.subtitle}>How many MOO are there?</p>
        <p className={classes.content}>100,000,000</p>
        <p className={classes.subtitle}>What is MOO’s token address? </p>
        <p className={classes.content}>
          <a
            className={classes.link}
            href="https://explorer.celo.org/tokens/0x17700282592D6917F6A73D0bF8AcCf4D578c131e/token-holders"
            target="_blank"
          >
            0x17700282592D6917F6A73D0bF8AcCf4D578c131e
          </a>
        </p>
        <p className={classes.subtitle}>
          <a
            className={classes.link}
            href="https://docs.google.com/document/d/18IFDq3PqAKOddBB5DrqwQSQzDnUDGC9hFzSLTMdi3II"
            target="_blank"
          >
            DISCLAIMER
          </a>
        </p>
        <p className={classes.content}>
          The information contained herein are provided solely for information
          purposes, and are not a solicitation of an offer to buy/sell any
          Moo/Moola coins, tokens, or financial instruments. No purchase
          decision relating to any Moo/Moola coins, tokens, or financial
          instruments should be made on the basis of this document. No
          representation or warranty is made or implied concerning, and Moola
          assumes no responsibility for, the accuracy, completeness, reliability
          or comparability of the information contained herein relating to third
          parties. Moola undertakes no obligation to update the information
          contained herein.
        </p>
      </div>
      <div className={classes.buttonContainer}>
        {!user && (
          <FlowButton
            label={t('HOME.APPHEADER.CONNECT_WALLET')}
            icon={PlusIcon}
            isTransparent={false}
            desktopButton={!isMobile}
            onClick={() => {
			  localStorage.setItem(MOO_LOGIN, 'true')
              if (isMobile) {
                history.push('/connect-wallet')
              } else {
                connectCeloExtensionWallet()
              }
            }}
          />
        )}
        {user && claimDetails && !claimDetails.isClaimed && (
          <MooToken
            mooToken={claimDetails}
            isTransparent={false}
            desktopButton={!isMobile}
          />
        )}
        {user && (!claimDetails || claimDetails.isClaimed) && (
          <MuiButton
            variant="contained"
            color="primary"
            className={classNames(
              classes.launchAppButton,
              isMobile ? classes.mobileButton : classes.desktopButton
            )}
            onClick={() => history.replace('/')}
          >
            {t('MOO.LAUNCH_APP')}
          </MuiButton>
        )}
      </div>
    </>
  )
}

export default withTranslation()(withStyles(styles)(Moo))
