import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import * as PropTypes from 'prop-types'
import React, { useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import DepositIcon from '../../../assets/images/deposit.svg'
import RepayIcon from '../../../assets/images/repay.svg'
import WithdrawIcon from '../../../assets/images/withdraw.svg'
import BorrowIcon from '../../../assets/images/borrow.svg'
import { currencyFormatter } from '../../../utils'
import FlowButton from '../../FlowButton'
import { useGetSettingsCurrency } from '../../../api'
import { withTranslation } from 'react-i18next'
import AppContext from '../../AppContext'
import InfoIcon from '../../InfoIcon'
import MooToken from "../../Moo-token";
import HeaderHealthFactor from "../../Header-Health-Factor";
import userAccountIsCollateral from '../../hooks/userAccountIsCollateral'
import MigrateArrow from '../../../assets/images/migrate-arrow.svg'
import config from '../../../config'

const styles = () => ({
  borrowed: {
    '& .copy-container': {
      position: 'relative',
      '& .directed-icon': {
        position: 'absolute',
        top: '14px',
        right: '12px',
        width: 'auto',
        height: 'auto'
      }
    }
  },
  gotoV2: {
	  width: '290px',
	  '& > *': {
		width: '100%'
	}
  }
})

const BorrowedState = ({ t, classes, totalValue, mooToken, riskFactor }) => {
  const history = useHistory()
  const { data: currentCurrency } = useGetSettingsCurrency()
  const collateralData = userAccountIsCollateral()
  const hasCollateralFunds = collateralData && collateralData.accountIsCollateralData?.find(item => item.isCollateral)

  return (
    <Grid
      container
      direction={'column'}
      alignItems={'center'}
      className={classes.borrowed}
    >
      <Grid item>
        <Typography className={'header'}>
          {totalValue != null && currencyFormatter(currentCurrency)(totalValue)}
        </Typography>
      </Grid>

      {riskFactor && <HeaderHealthFactor riskFactor={riskFactor} />}

      <Grid item>
        <Grid container spacing={1} justify={'center'}>
          <Grid item className={classes.gotoV2}>
		 	<FlowButton
				label={t('V2.GO_TO')}
				icon={MigrateArrow}
				isTransparent={false}
				onClick={() => window.open(config.urlV2, "_self")}
			/>
            {/* <FlowButton
              label={t('GENERAL.DEPOSIT')}
              icon={DepositIcon}
              isTransparent={true}
              onClick={() => {
                history.push('/deposit')
              }}
            /> */}
          </Grid>
          <Grid item>
            <FlowButton
              label={t('GENERAL.WITHDRAW')}
              icon={WithdrawIcon}
              isTransparent={true}
              onClick={() => {
                history.push('/withdrawal')
              }}
            />
          </Grid>
          {/* <Grid item>
            <FlowButton
              label={t('GENERAL.BORROW')}
              icon={BorrowIcon}
              isTransparent={true}
              onClick={() => {
                history.push(hasCollateralFunds ? '/borrow' : '/no-collateral')
              }}
            />
          </Grid> */}
          <Grid item>
            <FlowButton
              label={t('GENERAL.REPAY')}
              icon={RepayIcon}
              isTransparent={true}
              onClick={() => {
                history.push('/repay')
              }}
            />
          </Grid>
          {/* {mooToken && !mooToken.isClaimed && <MooToken mooToken={mooToken}/>} */}
        </Grid>
      </Grid>
    </Grid>
  )
}

BorrowedState.propTypes = {
  totalValue: PropTypes.number.isRequired,
  mooToken: PropTypes.object,
  riskFactor: PropTypes.array
}

export default withTranslation()(withStyles(styles)(BorrowedState))
