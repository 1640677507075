import moment from "moment";

const TODAY = 'Today'
const YESTERDAY = 'Yesterday'
const DAY = 'day'
const YEAR = 'year'

const getMonthFullName = month => {
  switch (month) {
    case '01':
      return 'January'
    case '02':
      return 'February'
    case '03':
      return 'March'
    case '04':
      return 'April'
    case '05':
      return 'May'
    case '06':
      return 'June'
    case '07':
      return 'July'
    case '08':
      return 'August'
    case '09':
      return 'September'
    case '10':
      return 'October'
    case '11':
      return 'November'
    case '12':
      return 'December'
  }
}

export const getActivityDate = timestamp => {
  const startOfToday = moment()
    .startOf(DAY)
    .toDate()
    .getTime() / 1000
  const startOfYesterday = moment()
    .subtract(1, DAY)
    .startOf(DAY)
    .toDate()
    .getTime() / 1000
  const startOfCurrentYear = moment()
    .startOf(YEAR)
    .toDate()
    .getTime() / 1000

  if (timestamp && timestamp > startOfToday) {
    return TODAY
  }

  if (timestamp && timestamp > startOfYesterday && timestamp < startOfToday) {
    return YESTERDAY
  }

  if (timestamp && timestamp < startOfYesterday) {
    // convert unix timestamp to milliseconds & initialize new Date object
    const date_ob = new Date(timestamp * 1000)
    const year = date_ob.getFullYear();
    const month = getMonthFullName(('0' + (date_ob.getMonth() + 1)).slice(-2))
    const date = ('0' + date_ob.getDate()).slice(-2);
    return timestamp > startOfCurrentYear ? `${month} ${date}` : `${month} ${date}, ${year}`
  }
}

export const getActivityDetailsDate = timestamp => {
  // convert unix timestamp to milliseconds & initialize new Date object
  const date_ob = new Date(timestamp * 1000)
  // year as 4 digits (YYYY)
  const year = date_ob.getFullYear();

  // month as 2 digits (MM)
  const month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

  // date as 2 digits (DD)
  const date = ("0" + date_ob.getDate()).slice(-2);

  let hours = date_ob.getHours()
  const isAm = hours < 12
  hours = hours % 12;

  if (hours === 0) {
    hours = '12';
  }

  // minutes as 2 digits (mm)
  const minutes = ("0" + date_ob.getMinutes()).slice(-2);

  // seconds as 2 digits (ss)
  const seconds = ("0" + date_ob.getSeconds()).slice(-2);

  return `${month}/${date}/${year}, ${hours}:${minutes}:${seconds} ${isAm ? 'AM' : 'PM'}`
}
