import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MuiButton from "@material-ui/core/Button";
import MuiIcon from "@material-ui/core/Icon";

const styles = () => ({
  endIcon: {
    position: "relative",
    bottom: "3px",
  },
  root: {
    borderRadius: "12px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: '16px',
    fontFamily: 'Jost-600-Semi',
	maxWidth: '400px',
	display: 'flex',
	marginRight: 'auto',
	marginLeft: 'auto'
  },
});

const Button = ({ classes, label, onClick, disabled, variant, endIcon }) => {
  const endIconBtn = (
    <MuiIcon>
      <img src={endIcon} height={23} width={20} />
    </MuiIcon>
  );
  return (
    <MuiButton
      disabled={disabled}
      variant={variant ? variant : "contained"}
      color="primary"
      className={classes.root}
      classes={{ endIcon: classes.endIcon }}
      fullWidth
      onClick={onClick}
      endIcon={endIcon ? endIconBtn : null}
    >
      {label}
    </MuiButton>
  );
};

Button.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["contained", "outlined"]),
  disabled: PropTypes.bool,
  endIcon: PropTypes.elementType,
};

export default withStyles(styles)(Button);
