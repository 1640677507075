import React from 'react'
import {Box, Divider, withStyles} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import MuiButton from '@material-ui/core/Button'
import MuiIcon from '@material-ui/core/Icon'

import config from '../../config'
import PopupRow from './popupRow'
import ExternalLinkIcon from '../../assets/images/external-link.svg'

const styles = () => ({
  detailsButton: {
    borderRadius: '12px',
    marginTop: '16px',
	fontSize: '16px'
  },
  buttonLineHeight: {
    fontStyle: 'SemiBold',
    fontSize: '16px',
    lineHeight: '20px',
  }
})

export const CONNECTED_STATUS = 'connected'
export const SUCCESSFUL_STATUS = 'successful'
export const PROCESSING_STATUS = 'processing'
export const ERROR_STATUS = 'error'

const PopupList = ({
                     t,
                     classes,
                     type,
                     sourceTitle,
                     source,
                     amountText,
                     amount,
                     value,
                     originationFeeAmount,
                     originationFeeValue,
                     liquidatorClaimedAmount,
                     liquidatorClaimedValue,
                     liquidationPenalty,
                     liquidationPrice,
                     healthFactor,
                     time,
                     status,
                     transactionId,
                     provider,
                     phoneNumber,
                     address,
                   }) => {

  const statusColor = (status) => {
    const connectedColor = '#3488EC'
    const successfulColor = '#35D07F'
    const processingColor = '#FBCC5C'
    const errorColor = '#FB7C6D'

    switch (status.toLowerCase()) {
      case CONNECTED_STATUS:
        return {
          color: connectedColor
        }
      case SUCCESSFUL_STATUS:
        return {
          color: successfulColor
        }
      case PROCESSING_STATUS:
        return {
          color: processingColor
        }
      case ERROR_STATUS:
        return {
          color: errorColor
        }
    }
  }

  const openTransaction = (txHash) => {
    window.open(config.explorerLink + txHash, '_blank')
  }

  const shouldHideLiquidationAndHf = type === 'borrow' || type === 'repay'

  return (
    <Box>
      {source && <PopupRow
        title={sourceTitle}
        upperTextValue={source}
      />}
      {source && <Divider/> }
      {amount && <PopupRow
        title={amountText}
        upperTextValue={amount}
        lowerTextValue={value}/>}
      {amount && <Divider/> }
      {/*For Repay Info*/}
      {originationFeeAmount && originationFeeValue && <PopupRow
        title={t('HOME.ACCOUNT_TAB.ACTIVITY.LOAN_ORIGINATION_FEE')}
        upperTextValue={originationFeeAmount}
        lowerTextValue={originationFeeValue}
      />}
      {originationFeeAmount && originationFeeValue && <Divider/> }
      {/*For Liquidation Info*/}
      {liquidatorClaimedAmount && liquidatorClaimedValue && <PopupRow
        title={t('GENERAL.LIQUIDATOR_CLAIMED')}
        upperTextValue={liquidatorClaimedAmount}
        lowerTextValue={liquidatorClaimedValue}
      />}
      {liquidatorClaimedAmount && liquidatorClaimedValue && <Divider/> }
      {liquidationPenalty && <PopupRow
        title={t('HOME.ACCOUNT_TAB.ACTIVITY.LIQUIDATION_PENALTY')}
        upperTextValue={liquidationPenalty}
      />}
      {liquidationPenalty && <Divider/> }
      {liquidationPrice && <PopupRow
        title={t('GENERAL.LIQUIDATION_PRICE')}
        upperTextValue={liquidationPrice}
      />}
      {liquidationPrice && <Divider/> }
      {!!healthFactor && !shouldHideLiquidationAndHf && <PopupRow
          title={t('GENERAL.HEALTH_FACTOR')}
          upperTextValue={healthFactor < 100000 ? `${healthFactor.toFixed(2)}` : '>100,000%'}
      />}
      {!!healthFactor && !shouldHideLiquidationAndHf && <Divider/> }
      {time && <PopupRow
        title={t('GENERAL.TIME')}
        upperTextValue={time}
      />}
      {time && <Divider/> }

      {/*Account info*/}
      {provider && <PopupRow
        title={t('GENERAL.PROVIDER')}
        upperTextValue={provider}
      />}
      {provider && <Divider/> }
      {status && <PopupRow
          title={t('GENERAL.STATUS')}
          upperTextValue={status}
          statusColor={statusColor(status)}
          semiBold={true}
      />}
      {status && <Divider/> }
      {phoneNumber && <PopupRow
        title={t('GENERAL.PHONE_NUMBER')}
        upperTextValue={phoneNumber}
      />}
      {phoneNumber && <Divider/> }
      {address && <PopupRow
        title={t('GENERAL.ADDRESS')}
        upperTextValue={address}

      />}
      { source && amount && <MuiButton
        variant="outlined"
        color="primary"
        fullWidth
        className={classes.detailsButton}
        endIcon={
          <MuiIcon className={classes.buttonLineHeight}>
            <img src={ ExternalLinkIcon } height={ 20 } width={ 20 }/>
          </MuiIcon>
        }
        onClick={() => openTransaction(transactionId)}
      >
        {t('HOME.ACCOUNT_TAB.ACTIVITY.VIEW_ON')}
      </MuiButton> }
    </Box>
  )
}

PopupList.prototype = {
  classes: PropTypes.string,
  source: PropTypes.string,
  sourceTitle: PropTypes.string,
  time: PropTypes.string,
  trigger: PropTypes.string,
  penalty: PropTypes.string,
  amount: PropTypes.string,
  amountText: PropTypes.string,
  value: PropTypes.string,
  healthFactor: PropTypes.string,
  liquidatorClaimed: PropTypes.string,
  liquidationPrice: PropTypes.string,
  status: PropTypes.string,
  detail: PropTypes.string,
  provider: PropTypes.string,
  phoneNumber: PropTypes.string,
  address: PropTypes.string,
  type: PropTypes.string
}

export default withTranslation()(withStyles(styles)(PopupList))
