import React from 'react'
import MuiIcon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, useTheme } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import { View } from 'react-native-web'
import { useTranslation, Trans } from 'react-i18next'

import AmountInput from '../AmountInput'
import AlertIcon from '../../assets/images/alert-circle.svg'
import { FloatingCard } from '../FloatingCard/elements'
import { TRANSACTIONS } from '../../utils/constants'

const styles = (theme) => ({
  error: {
    color: theme.palette.common.error,
    marginTop: '15px',
    fontSize: '14px',
    '& > *:first-child': {
      marginRight: '10px'
    }
  }
})

const AmountBox = ({
  children,
  classes,
  validators,
  formControl,
  name,
  switchClick,
  currency,
  onValueChange,
  inputFirst,
  customExternalError
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const currentErrorKey =
    formControl && formControl.error && Object.keys(formControl.error)[0]

  const errorLabels = {
    maxValue: ({ max, action }) => {
      switch (action) {
        case TRANSACTIONS.DEPOSIT:
          return (
            <Trans i18nKey="GENERAL.ERROR.DEPOSIT_MAX_VALUE">
              <span style={{ fontSize: '14px' }}>
                You only have
                <span style={{ fontFamily: 'Jost-700-Bold' }}>{{ max }}</span>
                available to deposit
              </span>
            </Trans>
          )
        case TRANSACTIONS.WITHDRAW:
          return (
            <Trans i18nKey="GENERAL.ERROR.WITHDRAW_MAX_VALUE">
              <span style={{ fontSize: '14px' }}>
                You only have
                <span style={{ fontFamily: 'Jost-700-Bold' }}>{{ max }}</span>
                available to withdraw
              </span>
            </Trans>
          )
        case TRANSACTIONS.BORROW:
          return (
            <Trans i18nKey="GENERAL.ERROR.BORROW_MAX_VALUE">
              <span style={{ fontSize: '14px' }}>
                You can only borrow up to
                <span style={{ fontFamily: 'Jost-700-Bold' }}>{{ max }}</span>
              </span>
            </Trans>
          )
        case TRANSACTIONS.REPAY:
          return (
            <Trans i18nKey="GENERAL.ERROR.REPAY_MAX_VALUE">
              <span style={{ fontSize: '14px' }}>
                The maximum amount you can repay is
                <span style={{ fontFamily: 'Jost-700-Bold' }}>{{ max }}</span>
              </span>
            </Trans>
          )
      }
    },
    noBalance: () => t('GENERAL.ERROR.NO_BALANCE'),
    required: () => t('GENERAL.ERROR.REQUIRED'),
    mustBePositiveNumber: () => t('GENERAL.ERROR.MUST_BE_POSITIVE_NUMBER'),
    overMaximumLTV: () => t('GENERAL.ERROR.MAXIMUM_LTV_EXCEEDED')
  }

  return (
    <View>
      <FloatingCard theme={theme}>
        <AmountInput
          name={name}
          currency={currency}
          switchClick={switchClick}
          validators={validators}
          onValueChange={onValueChange}
          inputFirst={inputFirst}
		  customExternalError={customExternalError}
        />
        {children}
      </FloatingCard>
      {(customExternalError !== undefined || (currentErrorKey && (formControl.touched || formControl.dirty))) && (
        <Grid container className={classes.error} wrap={'nowrap'}>
          <MuiIcon>
            <img
              alt={formControl.error}
              src={AlertIcon}
              height={20}
              width={20}
            />
          </MuiIcon>
          <Typography>
            { 
				currentErrorKey ?
					errorLabels[currentErrorKey]?.(formControl.error[currentErrorKey].params)
					: customExternalError !== undefined ? 
						errorLabels[Object.keys(customExternalError)[0]]?.() : ''
            }
          </Typography>
        </Grid>
      )}
    </View>
  )
}

AmountBox.propTypes = {
  validators: PropTypes.func,
  name: PropTypes.string.isRequired,
  formControl: PropTypes.object,
  switchClick: PropTypes.func,
  currency: PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
  }).isRequired,
  onValueChange: PropTypes.func,
  customExternalError: PropTypes.object
}

export default withStyles(styles)(AmountBox)
