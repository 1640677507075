import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useHistory } from 'react-router-dom'
import PlusIcon from '../../../assets/images/plus.svg'
import FlowButton from '../../FlowButton'
import useLogin from '../../hooks/useLogin'
import { withTranslation } from 'react-i18next'
import { CONTRACT_KIT } from '../../../utils/constants'
import { isMobile } from '../../../utils/browser'

const styles = () => ({
  unconnected: {
    '& .header': {
      fontSize: '40px !important',
      lineHeight: '42px !important',
      paddingBottom: '26px'
    }
  },
  connectButton: {
    '& .material-icons': {
      position: 'relative'
    }
  }
})

const UnconnectedState = ({ t, classes }) => {
  const history = useHistory()
  const login = useLogin()

  const connectCeloExtensionWallet = () => {
    login(CONTRACT_KIT)
  }

  return (
    <Grid
      container
      direction={'column'}
      alignItems={'center'}
      className={classes.unconnected}
    >
      <Grid item>
        <Typography className={'header'}>
          {t('HOME.APPHEADER.WELCOME_MOOLA')}
        </Typography>
      </Grid>
      <Grid item className={classes.connectButton}>
        <FlowButton
          label={t('HOME.APPHEADER.CONNECT_WALLET')}
          icon={PlusIcon}
          isTransparent={false}
          onClick={() => {
            // if (isMobile) {
            //   history.push('/connect-wallet')
            // } else {
              connectCeloExtensionWallet()
            // }
          }}
        />
      </Grid>
    </Grid>
  )
}

export default withTranslation()(withStyles(styles)(UnconnectedState))
