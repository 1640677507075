import React from "react";
import { Grid, Typography, withStyles } from "@material-ui/core";
import classNames from "classnames";

const styles = (theme) => ({
  contentItem: {
    height: '56px'
  },
  contentLeft: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Jost-500-Medium',
    fontWeight: 500
  },
  contentRightWithOneRow: {
    textAlign: 'right',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
    color: theme.palette.neutral.lightGrey
  },
  contentRightWithTwoRows: {
    textAlign: 'right',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
  },
  contentRight2: {
    textAlign: 'right',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'normal',
    color: theme.palette.neutral.lightGrey
  },
  marginBottom: {
    marginBottom: '20px',
  },
  paddingTop8: {
    paddingTop: '8px'

  },
  paddingTop18: {
    paddingTop: '18px'
  }
})

const AccountTabCurrencyRow = ({classes, data, alwaysBold}) => {
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      className={ classes.contentItem }
    >
      <Grid item className={
        classNames(!!data.lowerTextValue ? classes.paddingTop8 : classes.paddingTop18) }>
        <Typography variant="body2" className={ classes.contentLeft }>
          { data.text }
        </Typography>
      </Grid>
      <Grid item className={
        classNames(!!data.lowerTextValue ? classes.paddingTop8 : classes.paddingTop18) }>
        <Grid container direction="column">
          <Grid item className={ classNames(!!!data.lowerTextValue && classes.marginBottom) }>
            <Typography variant="body1" className={
              classNames(
                !!data.lowerTextValue || alwaysBold ?
                  classes.contentRightWithTwoRows :
                  classes.contentRightWithOneRow
              ) }
            >
              { data.upperTextValue }
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={ classNames(classes.contentRight2) }>
              { data.lowerTextValue }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(AccountTabCurrencyRow)
