import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiIcon from '@material-ui/core/Icon'
import {Drawer, Grid} from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { isMobile } from '../../utils/browser'

const styles = () => ({
  endIcon: {
    position: 'relative',
    bottom: '3px',
  },
  headerPopup: {
    padding: '0px 0px 12px',
    display: 'flex'
  },
  paper: {
    borderRadius: '16px 16px 0px 0px',
  },
  container: {
    flexWrap: 'nowrap'
  },
  children: {
    padding: '16px 0px 0px 0px',
    fontFamily: 'Jost-500-Medium',
    fontWeight: 500,
  },
  title: {
    fontFamily: 'Jost-700-Bold',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '33px',
    color: '#2E3338',
  },
  closeButton: {
    backgroundColor: 'transparent',
    display: 'contents',
    position: 'absolute',
    top: '0',
    right: '0'
  },
  primaryButton: {
    alignItems: 'center',
    borderRadius: '12px',
    boxShadow: 'none',
    fontSize: '16px',
    '&:disabled': {
      backgroundColor: '#efefef !important',
    }
  },
  secondaryButton: {
    alignItems: 'center',
    borderRadius: '12px',
    boxShadow: 'none',
    border: '1px solid',
    marginBottom: '10px',
    fontSize: '16px',
    '&:disabled': {
      backgroundColor: '#efefef !important',
    }
  },
  popUpIcon: {
    width: '40px',
    height: '40px',
    marginRight: '8px',
    marginTop: '-3px'
  },
  bottomPopupDesktop: {
    borderRadius: '16px',
    width: '540px',
    top: 'calc(50% - 50px)',
    margin: '0 auto',
    position: 'absolute',
    height: 'fit-content',
    display: 'block',
    transform: 'translateY(-50%) !important'
  },
})

const BottomPopup = ({
                       show, label, classes, onClose, icon, popUpIcon, children,
                       primaryButtonTitle, primaryButtonClick,
                       secondaryButtonTitle, secondaryButtonClick,
                       endIconSecondaryButton, isLogout, disabled
                     }) => {
  const buttonIcon = (
    <MuiIcon>
      <img src={ icon } height={ 28 } width={ 28 } alt='' />
    </MuiIcon>
  )

  const titleIconPoUp = popUpIcon && <MuiIcon className={classes.popUpIcon}>
    <img src={popUpIcon} height={40} width={40} alt='' />
  </MuiIcon>


  const endIconSecondaryBtn = (
    <MuiIcon>
      <img src={ endIconSecondaryButton } height={ 23 } width={ 20 }/>
    </MuiIcon>
  )

  const backgroundColor = isLogout ? '#FB7C6D' : '#3488EC'
  const buttonBg = {
    backgroundColor: backgroundColor
  }
  const primaryButton = (
    <Grid item>
      <MuiButton
        variant='contained'
        color='primary'
        style={ buttonBg }
        className={ classes.primaryButton }
        disabled={disabled}
        fullWidth
        onClick={ () => primaryButtonClick() }
      >
        { primaryButtonTitle }
      </MuiButton>
    </Grid>
  )

  const secondaryButton = (
    <Grid item>
      <MuiButton
        variant='outlined'
        color='primary'
        className={ classes.secondaryButton }
        fullWidth
        onClick={ event => secondaryButtonClick(event) }
        endIcon={ endIconSecondaryButton ? endIconSecondaryBtn : null }
        classes={ {endIcon: classes.endIcon} }
      >
        { secondaryButtonTitle }
      </MuiButton>
    </Grid>
  )


  const closeBottomPopup = <MuiButton
    className={ classes.closeButton }
    startIcon={ buttonIcon }
    onClick={ onClose }
  >
  </MuiButton>

  return (
    <Drawer
      key={ label }
      classes={ {paper: !isMobile ? classes.bottomPopupDesktop: classes.paper} }
      anchor='bottom'
      open={ show }
      onClose={ event => onClose(event) }>
      <Grid container justify='center' className={`${!isMobile ? classes.desktopPopup : ''}`}>
        <Box p={ 2 } width={ '100%' } maxWidth={ '540px' }>
          <Grid container className={ classes.container } justify='space-between'>
            <Grid item className={ classes.headerPopup }>
              { titleIconPoUp }
              <Typography className={ classes.title }>
                { label }
              </Typography>
            </Grid>
            <Grid item>
              { closeBottomPopup }
            </Grid>
          </Grid>
          <Box p={ 0 }>
            <Grid item className={ classes.children }>
              { children }
            </Grid>
            { (primaryButtonTitle || secondaryButtonTitle) &&
            <Box p={ 0 } paddingTop={ '24px' }>
              { secondaryButtonTitle && secondaryButton }
              { primaryButtonTitle && primaryButton }
            </Box>
            }
          </Box>
        </Box>
      </Grid>
    </Drawer>
  )
}

BottomPopup.prototype = {
  show: PropTypes.bool,
  label: PropTypes.string.isRequired,
  classes: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.elementType.isRequired,
  popUpIcon: PropTypes.elementType,
  children: PropTypes.elementType.isRequired || PropTypes.string,
  primaryButtonTitle: PropTypes.string,
  primaryButtonClick: PropTypes.func,
  secondaryButtonTitle: PropTypes.string,
  secondaryButtonClick: PropTypes.func,
  endIconSecondaryButton: PropTypes.elementType.isRequired,
  isLogout: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default withStyles(styles)(BottomPopup)
