import React from 'react'
import * as PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import MuiIcon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import CloseIcon from '../../assets/images/close.svg'
import ChevronRightArrow from '../../assets/images/chevron-right-arrow.svg'

const styles = (theme) => ({
  wrapper: {
    height: '100%',
    padding: '16px',
    overflow: 'auto',
  },
  backIcon: {
    marginBottom: '10px'
  },
  title: {
    fontFamily: 'Jost-700-Bold',
    fontSize: '24px',
    lineHeight: '28px',
    marginBottom: '8px'
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    marginBottom: '24px'
  },
  grid: {
    padding: '8px',
    paddingLeft: 0,
    paddingRight: 0,
    cursor: 'pointer'
  },
  leftText: {
    weight: '500',
    Style: 'normal',
    Size: '16px',
    lineHeight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  rightText: {
    weight: '500',
    Style: 'normal',
    Size: '16px',
    lineHeight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '8px',
    color: theme.palette.neutral.lightGrey
  },
  rightIcon: {
    paddingTop: '8px',
    paddingLeft: '4px',
    position: 'relative',
    background: theme.palette.neutral.white,
    width: 'auto',
    height: 'auto'
  },
  divider: {
    marginTop: '16px',
    marginBottom: '16px'
  }
})

const Settings = ({
  classes,
  title,
  applicationSettings,
  contactSettings
}) => {
  const history = useHistory()

  return (
    <Grid
      className={classes.wrapper}
      container
      direction={'column'}
      wrap={'nowrap'}
    >
      <Box className={classes.backIcon}>
        <MuiIcon onClick={() => history.goBack()}>
          <img alt={'Go back'} src={CloseIcon} width={32} height={32}></img>
        </MuiIcon>
      </Box>
      <Box className={classes.content}>
        <Typography variant={'body2'} className={classes.title}>
          {title}
        </Typography>
      </Box>
      <Box className={classes.root}>
        {applicationSettings.map((item, index) => (
          <Grid
            key={`f${index}`}
            container
            direction="row"
            justify="space-between"
            className={classes.grid}
            onClick={() => item.onClick()}
          >
            <Grid container item justify="space-between">
              <Grid item>
                <Grid container>
                  <Typography
                    align="center"
                    variant="body1"
                    className={classes.leftText}
                  >
                    {item.leftText}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Typography
                    align="center"
                    variant="body1"
                    className={classes.rightText}
                  >
                    {item.rightText}
                  </Typography>
                  <MuiIcon className={classes.rightIcon}>
                    <img src={ChevronRightArrow} height={24} width={24} />
                  </MuiIcon>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.root}>
        {contactSettings.map((item, index) => (
          <Box
            key={`f${index}`}
            className={classes.grid}
            onClick={() => window.open(item.url, '_blank')}
          >
            <Typography variant="body1" className={classes.leftText}>
              {item.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Grid>
  )
}

Settings.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.any,
  buttonClick: PropTypes.func
}

export default withStyles(styles)(Settings)
