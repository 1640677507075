import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import MuiIcon from '@material-ui/core/Icon'
import Box from '@material-ui/core/Box'

const ActionRow = ({
  classes,
  index,
  leftIcon,
  leftText,
  rightText,
  rightIcon,
  onClick
}) => {
  return (
    <div key={index} style={{ width: '100%' }}>
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.grid}
        onClick={onClick}
      >
        <Box display="flex" alignItems="center">
          {leftIcon && (
            <Box>
              <MuiIcon className={classes.leftIcon}>
                <img src={leftIcon} height={40} width={40} />
              </MuiIcon>
            </Box>
          )}
          {leftText && (
            <Box className={classes.leftBox}>
              <Typography variant="body1" className={classes.leftText}>
                {leftText}
              </Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center" flexDirection="row-reverse">
          {rightIcon && (
            <Box>
              <MuiIcon className={classes.rightIcon}>
                <img src={rightIcon} height={24} width={24} />
              </MuiIcon>
            </Box>
          )}
          {rightText && (
            <Box>
              <Typography variant="body1" className={classes.rightText}>
                {rightText}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  )
}

ActionRow.prototype = {
  className: PropTypes.object,
  index: PropTypes.number,
  leftIcon: PropTypes.element,
  leftText: PropTypes.string,
  rightText: PropTypes.element,
  rightIcon: PropTypes.object
}

export default ActionRow
