import Typography from '@material-ui/core/Typography'
import React from 'react'
import { withTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import PopupRow from '../BottomPopup/popupRow'
export const FundingDestinationInfo = withTranslation()(({ t }) => (
  <Typography>{t('BORROW.FUNDING_DESTINATION.INFO')}</Typography>
))

export const FundingSourceInfo = withTranslation()(({ t, currency }) => (
  <Typography>
    {t('GENERAL.FUNDING_SOURCE.INFO', {
      currency
    })}
  </Typography>
))

export const BorrowAmountToDestinationInfo = withTranslation()(({ t }) => (
  <Typography>{t('BORROW.WITHDRAW_AMOUNT.INFO')}</Typography>
))

export const AmountToRepayInfo = withTranslation()(({ t }) => (
  <Typography>{t('GENERAL.AMOUNT_REPAY.INFO')}</Typography>
))

export const AmountToDepositInfo = withTranslation()(({ t }) => (
  <Typography>{t('GENERAL.AMOUNT_DEPOSIT.INFO')}</Typography>
))

export const AmountToWithdrawInfo = withTranslation()(({ t }) => (
  <Typography>{t('WITHDRAWAL.AMOUNT_WITHDRAW.INFO')}</Typography>
))

export const LiquidationPriceInfo = withTranslation()(({ t }) => (
  <Typography>{t('GENERAL.LIQUIDATION_PRICE.INFO')}</Typography>
))

export const DebtInfo = withTranslation()(({ t }) => (
  <Typography>{t('GENERAL.DEBT.INFO')}</Typography>
))

export const MaximumLTVInfo = withTranslation()(({ t }) => (
  <Typography>{t('GENERAL.MAXIMUM_LTV.INFO')}</Typography>
))

export const CurrentLTVInfo = withTranslation()(({ t, classes }) => (
  <>
    <Typography>{t('GENERAL.CURRENT_LTV.INFO.1')}</Typography>
    <Typography className={classes.bolded}>
      {t('GENERAL.CURRENT_LTV.INFO.2')}
    </Typography>
  </>
))

export const NewLTVInfo = withTranslation()(({ t, classes }) => (
  <>
    <Typography>{t('GENERAL.NEW_LTV.INFO.1')}</Typography>
    <Typography className={classes.bolded}>
      {t('GENERAL.NEW_LTV.INFO.2')}
    </Typography>
  </>
))

export const LiquidationThresholdInfo = withTranslation()(({ t }) => (
  <>
    <Typography>{t('GENERAL.LIQUIDATION_THRESHOLD.INFO.1')}</Typography>
    <Typography>{t('GENERAL.LIQUIDATION_THRESHOLD.INFO.2')}</Typography>
  </>
))

export const InterestRateInfo = withTranslation()(({ t }) => (
  <Typography>{t('GENERAL.INTEREST_RATE.INFO')}</Typography>
))

export const InterestRateModeInfo = withTranslation()(({ t }) => (
  <Typography>{t('GENERAL.INTEREST_RATE_MODE.INFO')}</Typography>
))

export const CurrentPriceInfo = withTranslation()(({ t }) => (
  <Typography>{t('GENERAL.CURRENT_PRICE.INFO')}</Typography>
))

export const CollateralInfo = withTranslation()(({ t }) => (
  <Typography>{t('GENERAL.COLLATERAL.INFO')}</Typography>
))

export const DebtAssetsInfo = withTranslation()(({ t }) => (
  <Typography>{t('GENERAL.DEBT_ASSETS.INFO')}</Typography>
))

export const SecurityFeesInfo = withTranslation()(({ classes, t }) => (
  <Typography>
    {t('GENERAL.SECURITY_FEES.INFO')}&nbsp;
    <Typography
      component={'span'}
      className={classes.link}
      onClick={() =>
        window.open(
          'https://docs.celo.org/celo-codebase/protocol/transactions/erc20-transaction-fees'
        )
      }
    >
      {t('GENERAL.LEARN_MORE')}
    </Typography>
  </Typography>
))

export const OriginalFeesInfo = withTranslation()(({ classes, t }) => (
  <Typography>
    {t('GENERAL.ORIGINAL_FEES.INFO')}&nbsp;
    <Typography
      component={'span'}
      className={classes.link}
      onClick={() =>
        window.open(
          'https://docs.celo.org/celo-codebase/protocol/transactions/erc20-transaction-fees'
        )
      }
    >
      {t('GENERAL.LEARN_MORE')}
    </Typography>
  </Typography>
))
export const HealthScoreInfo = withTranslation()(({ classes, t }) => (
  <>
    <Typography>{t('GENERAL.HEALTH_FACTOR.INFO')}</Typography>
    <Typography>
      <b>{`${t('GENERAL.HEALTH_FACTOR')} = (${t(
        'GENERAL.TOTAL_COLLATERAL_DEPOSITS'
      )} * ${t('GENERAL.LIQUIDATION_THRESHOLD')}) / ${t('GENERAL.TOTAL_DEBT')}`}</b>
    </Typography>
  </>
))

export const MooTokenInfo = withTranslation()(({ classes, t, value }) => <></>)

export const ClaimMooInfo = withTranslation()(
  ({ classes, t, unclaimedValue, balance }) => (
    <>
      <Grid container direction="row" justify="space-between">
        <PopupRow title={t('MOO.UNCLAIMED')} upperTextValue={`${unclaimedValue} MOO`} semiBold={true} statusColor={{color: '#000000'}}/>
        <PopupRow title={t('MOO.BALANCE')} upperTextValue={`${balance} MOO`} />
      </Grid>
    </>
  )
)

