import { withTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import AppContext from '../../components/AppContext'
import {
  CURRENCIES,
  DEFAULT_CURRENCY,
  RISK_FACTOR_STATE,
  TRANSACTIONS,
  SECURITY_FEE,
  LOAN_TERMS
} from '../../utils/constants'
import Typography from '@material-ui/core/Typography'
import { Box, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import ActionLayer from '../../components/ActionLayer'
import RepayIcon from '../../assets/images/repay-fill.svg'
import Confirmation from '../../components/Confirmation'
import ConfirmationAndroid from '../../components/ConfirmationAndroid'
import {
  useGetSettingsCurrency,
  useGetUserAccountInfoBorrow,
  useGetUserAccountLiquidationPrices,
  useGetUserAccountInfoStatus,
  useGetFee
} from '../../api'
import {
  cCurrencyFormatter,
  currencyFormatter,
  getHealthFactor,
  formatCurrencyFromApi
} from '../../utils'
import AmountBox from '../../components/AmountBox'
import {
  composeValidators,
  maxValue,
  mustBePositiveNumber,
  required
} from '../../utils/validators'
import Card from '../../components/Card'
import BottomPopup from '../../components/BottomPopup'
import CloseIcon from '../../assets/images/close.svg'
import SwitchPopup from '../../components/SwitchPopup'
import InfoEntry from '../../components/InfoEntry'
import Grid from '@material-ui/core/Grid'
import { cancelRequest, FeeCurrency } from '../../sdk/dappkit'
import { getContract } from '../../root'
import BN from 'bignumber.js'
import useBalance from '../../components/hooks/useBalance'
import useAccountReserveData from '../../components/hooks/useAccountReserveData'
import useNewAccountStatus from '../../components/hooks/useNewAccountStatus'
import useTxRequest from '../../components/hooks/useTxRequest'
import InfoIcon from '../../components/InfoIcon'
import {
  SecurityFeesInfo,
  OriginalFeesInfo,
  AmountToRepayInfo,
  FundingSourceInfo,
  HealthScoreInfo,
  InterestRateInfo,
  InterestRateModeInfo,
  LiquidationPriceInfo
} from '../../components/InfoContent'
import {
  getCurrentAccountStatusData,
  getNewAccountStatusData
} from '../../utils/accountStatus'
import { WhitePage } from '../../utils/elements'
import {
  feesFormat,
  formatNumber,
  formatNumberWithoutRounding
} from '../../utils/format'
import { isMobile, isValoraAndroid } from '../../utils/browser'
import CurrencyDesktopDropdown from '../../components/CurrencyDesktopDropdown'
import useAccountDebtData from '../../components/hooks/userAccountDebtData'
import { Alert } from '@material-ui/lab'

const styles = (theme) => ({
  container: {
    display: 'flex',
    '& > * ': {
      flexGrow: '1',
      margin: '16px'
    },
    '& > *:last-child': {
      minWidth: '300px'
    }
  },
  wrapper: {
    paddingBottom: '30px',
    '& > *': {
      marginBottom: '16px'
    }
  },
  title: {
    fontFamily: 'Jost-700-Bold',
    fontSize: '20px',
    lineHeight: '24px'
  },
  infoEntry: {
    border: '1px solid',
    borderColor: theme.palette.neutral.grey1,
    borderRadius: '6px',
    '& > *': {
      padding: '10px 16px',
      '&:not(:last-child)': {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.neutral.grey1
      }
    }
  },
  hidden: {
    display: 'none'
  },
  fullHeight: {
    height: '100%'
  },
  bolded: {
    fontFamily: 'Jost-700-Bold'
  },
  link: {
    color: theme.palette.common.link,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  error: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    zIndex: '9999',
    boxSizing: 'border-box'
  }
})

const NewRepayScreen = ({ t, classes }) => {
  const context = useContext(AppContext)
  const theme = useTheme()
  const [apiError, setApiError] = useState(false)
  const history = useHistory()
  const redirectUrl = 'repay'
  const [approvedTransaction, setApprovedTransaction] = useState(false)
  const { txHash } = useParams()
  useEffect(() => {
    if (txHash) {
      setApprovedTransaction(true)
      history.replace(`/${redirectUrl}`)
    }
  }, [txHash])

  const [showCurrencyPopup, setShowCurrencyPopup] = useState(false)
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false)
  const [currencies, setCurrencies] = useState(
    Object.values(CURRENCIES).map((currency) => ({
      ...currency,
      selected: currency.key === DEFAULT_CURRENCY
    }))
  )
  const [desktopCurrencies, setDesktopCurrencies] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState()
  const [confirmation, setConfirmation] = useState(false)
  const [value, setValue] = useState(0)
  const [hasDebtValue, setHasDebt] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [usedLoanTermsForCurrency, setUsedLoanTermsForCurrency] = useState(null)

  const [maxValueSelected, setMaxValueSelected] = useState(false)
  useEffect(() => {
    if (!confirmation) {
      setApprovedTransaction(false)
    }
  }, [confirmation])
  useEffect(() => {
    if (value && BN(value).isGreaterThan(0)) {
      if (
        BN(value).isEqualTo(BN(maxDebt)) ||
        (BN(value).isEqualTo(BN(maxAmount)) &&
          BN(maxAmount).isGreaterThanOrEqualTo(BN(maxDebt)))
      ) {
        setMaxValueSelected(true)
      } else {
        setMaxValueSelected(false)
      }
    } else {
      setMaxValueSelected(false)
    }
  }, [value])

  const formName = useRef('amount')

  const { handleTxRequest } = useTxRequest()

  const { data: currentCurrency } = useGetSettingsCurrency()
  const accountInfoBorrow = useGetUserAccountInfoBorrow(context.user.address)
  const currentAccountInfoStatus = useGetUserAccountInfoStatus(
    context.user.address,
    currentCurrency
  )
  const userAccountLiquidationPrices = useGetUserAccountLiquidationPrices(
    context.user.address
  )
  const [{ data }] = useNewAccountStatus(
    value,
    currentCurrency,
    selectedCurrency,
    TRANSACTIONS.REPAY
  )

  useEffect(() => {
    if (
      accountInfoBorrow?.isError ||
      userAccountLiquidationPrices?.isError ||
      currentAccountInfoStatus?.isError
    ) {
      setApiError(true)
    }
  }, [
    accountInfoBorrow,
    userAccountLiquidationPrices,
    currentAccountInfoStatus
  ])

  const fee = useGetFee(
    context.user.address,
    selectedCurrency?.text,
    TRANSACTIONS.REPAY,
    parseFloat(value || 0),
    true
  )

  let txCurrency = currencies.find((currency) => currency.selected).text

  const [{ balance, isLoading: isBalanceLoading }] = useBalance(txCurrency)
  const [{ accountReserveData, isLoading: isAccountReserveLoading }] =
    useAccountReserveData(txCurrency)

  const maxDebt =
    accountReserveData &&
    BN(accountReserveData.originationFee)
      .plus(accountReserveData.debt)
      .multipliedBy('1.001')
  let securityFee = 0
  let loanOriginationFee = 0

  if (fee.isSuccess && fee.data) {
    securityFee = fee.data.securityFee
    loanOriginationFee = fee.data.loanOriginationFee
  }

  const maxAmount =
    !isBalanceLoading &&
    !isAccountReserveLoading &&
    accountReserveData &&
    BN(balance)
      .minus(SECURITY_FEE)
      .minus(accountReserveData?.originationFee)
      .gt(maxDebt)
      ? BN.maximum(
          BN(balance)
            .minus(SECURITY_FEE)
            .minus(accountReserveData?.originationFee),
          0
        )
      : BN.maximum(BN(balance).minus(SECURITY_FEE), 0)

  useEffect(() => {
    if (accountInfoBorrow.isSuccess) {
      const hasAnyDebt = accountInfoBorrow.data?.data?.some((borrowInfo) => {
        return borrowInfo.amount !== 0
      })
      setHasDebt(hasAnyDebt)
    } else {
      setHasDebt(false)
    }
  }, [accountInfoBorrow])

  useEffect(() => {
    setSelectedCurrency(currencies.find((currency) => currency.selected))
  }, [currencies])

  useEffect(() => {
    loadBorrowedLoanTermForCurrentCurrency()
  }, [selectedCurrency])

  const accountDebtData = useAccountDebtData()

  useEffect(() => {
    if (accountDebtData.isSuccess) {
      setDesktopCurrencies(
        currencies.map((currency) => ({
          ...currency,
          value: getTotalDebtForCurrency(
            accountDebtData?.accountDebtData?.find(
              (e) => e.currency === currency.text
            )
          )
        }))
      )
    }
  }, [accountDebtData.isSuccess])

  const getTotalDebtForCurrency = (currencyDebtInfo) => {
    return currencyDebtInfo
      ? BN(currencyDebtInfo.originationFee)
          .plus(currencyDebtInfo.debt)
          .multipliedBy('1.001')
      : undefined
  }

  const loadBorrowedLoanTermForCurrentCurrency = async () => {
    const { lendingPool, reserves } = await getContract()
    let userReserveData = await lendingPool.methods
      .getUserReserveData(reserves[txCurrency], context.user.address)
      .call()
    setUsedLoanTermsForCurrency(parseInt(userReserveData.borrowRateMode))
  }

  const selectCurrency = (key) => {
    const updatedCurrencies = currencies.map((currency) => {
      currency.selected = currency.key === key
      return currency
    })

    setCurrencies(updatedCurrencies)

    const updatedDesktopCurrencies = desktopCurrencies.map((currency) => {
      currency.selected = currency.key === key
      return currency
    })

    setDesktopCurrencies(updatedDesktopCurrencies)
  }

  const submit = () => {
    setConfirmation(true)
  }

  const processOperation = async (isValoraAndroid, approve, redirectUrl) => {
    let txCurrency = selectedCurrency.text
    const operation = `repay`
    const requestId = `${operation}${txCurrency}`
    const amount = value
    cancelRequest(requestId)

    const isCelo = txCurrency === CURRENCIES.celo.text
    const isPayAll = maxValueSelected
    const { lendingPool, lendingPoolCore, reserves, tokens } =
      await getContract()

    let userReserveData = null
    if (isPayAll) {
      userReserveData = await lendingPool.methods
        .getUserReserveData(reserves[txCurrency], context.user.address)
        .call()
    }

    const valueToRepay = isPayAll
      ? BN(userReserveData.currentBorrowBalance)
          .multipliedBy('1.001')
          .plus(userReserveData.originationFee)
          .toFixed(0)
      : BN(10).pow(18).multipliedBy(amount).toFixed(0)

    try {
      const txObject = lendingPool.methods.repay(
        reserves[txCurrency],
        valueToRepay,
        context.user.address
      )

      const txParamCelo = {
        tx: txObject,
        from: context.user.address,
        to: lendingPool.options.address,
        // hello valora. Fix for rounding issues in mobile app
        // https://github.com/celo-org/celo-monorepo/issues/6830
        value: isCelo ? BN(valueToRepay).plus('1000000000').toFixed(0) : 0,
        estimatedGas: 2000000,
        feeCurrency: isCelo ? undefined : FeeCurrency[txCurrency]
      }

      let txParams = []

      if (!isCelo) {
        const stableToken = tokens[txCurrency]
        const approveTxObj = await stableToken.approve(
          lendingPoolCore.options.address,
          valueToRepay
        ).txo
        const txParamStableToken = {
          tx: approveTxObj,
          from: context.user.address,
          to: stableToken.contract.options.address,
          estimatedGas: 1000000,
          feeCurrency: FeeCurrency[txCurrency]
        }
        txParams = [txParamStableToken, txParamCelo]
      } else {
        txParams = [txParamCelo]
      }
      if (!isValoraAndroid || (isValoraAndroid && isCelo)) {
        handleTxRequest(operation, requestId, txCurrency, amount, txParams)
      } else {
        if (approve) {
          handleTxRequest(
            operation,
            requestId,
            txCurrency,
            amount,
            [txParams[0]],
            redirectUrl
          )
        } else {
          handleTxRequest(
            operation,
            requestId,
            txCurrency,
            amount,
            [txParams[1]],
            null
          )
        }
      }
    } catch (error) {
      console.log(error)
      context.showError(error.message)
    }
  }

  const content = ({ form }) => {
    const name = formName.current
    const totalDebt = maxDebt
    const maximumPayableAmount = BN.min(maxAmount, totalDebt)

    const fieldState = form.getFieldState(name)

    const newData = data
    const currentData = currentAccountInfoStatus.data
    const liquidationPrices = userAccountLiquidationPrices?.data

    const newAccountStatusData = getNewAccountStatusData(
      t,
      classes,
      currentCurrency,
      newData,
      selectedCurrency,
      usedLoanTermsForCurrency
    )
    newAccountStatusData.content.unshift({
      text: t('REPAY.DEBT'),
      value: currencyFormatter(currentCurrency)(newData?.remainingDebt),
      info: {
        content: remainingDebtInfo
      }
    })

    const currentAccountStatusData = getCurrentAccountStatusData(
      t,
      classes,
      selectedCurrency?.text,
      currentData,
      liquidationPrices,
      usedLoanTermsForCurrency
    )
    currentAccountStatusData.content.unshift({
      text: t('REPAY.DEBT'),
      value: currencyFormatter(currentCurrency)(currentData?.remainingDebt),
      info: {
        content: remainingDebtInfo
      }
    })

    return (
      <div className={!isMobile ? classes.container : ''}>
        <Box className={classes.wrapper}>
          <Grid
            container
            alignItems={'center'}
            onClick={() => {
              setSelectedItem({
                text: t('REPAY.AMOUNT_REPAY'),
                info: {
                  content: amountToRepayInfo
                }
              })
            }}
          >
            <Typography className={classes.title}>
              {t('REPAY.AMOUNT_REPAY')}
            </Typography>
            <InfoIcon
              maxRight={isMobile}
              title={t('REPAY.AMOUNT_REPAY')}
              text={amountToRepayInfo}
            />
          </Grid>

          {selectedCurrency && (
            <AmountBox
              name={name}
              validators={composeValidators(
                mustBePositiveNumber,
                required,
                maxValue(
                  maximumPayableAmount,
                  selectedCurrency.text,
                  TRANSACTIONS.REPAY
                )
              )}
              formControl={fieldState}
              switchClick={() =>
                isMobile
                  ? setShowCurrencyPopup(true)
                  : setShowCurrencyDropdown(true)
              }
              currency={selectedCurrency}
              onValueChange={setValue}
            >
              {showCurrencyDropdown && (
                <CurrencyDesktopDropdown
                  currencies={desktopCurrencies}
                  selectCurrency={(key) => {
                    selectCurrency(key)
                    form.reset()
                    form.resetFieldState(formName.current)
                    setShowCurrencyDropdown(false)
                  }}
                  currentCurrency={currentCurrency}
                ></CurrencyDesktopDropdown>
              )}
              <Box
                className={`${classes.infoEntry}`}
                onClick={() => {
                  form.change(
                    name,
                    formatNumberWithoutRounding(
                      maximumPayableAmount,
                      CURRENCIES[selectedCurrency.text.toLowerCase()]?.decimals
                    )
                  )
                  setValue(maximumPayableAmount)
                }}
              >
                <InfoEntry
                  left={t('GENERAL.TOTAL_DEBT')}
                  right={`${formatNumberWithoutRounding(
                    totalDebt,
                    CURRENCIES[selectedCurrency.text.toLowerCase()]?.decimals
                  )} ${selectedCurrency.text}`}
                />
                <InfoEntry
                  left={t('GENERAL.MAX_AMOUNT')}
                  right={`${formatNumberWithoutRounding(
                    maxAmount,
                    CURRENCIES[selectedCurrency.text.toLowerCase()]?.decimals
                  )} ${selectedCurrency.text}`}
                />
              </Box>
            </AmountBox>
          )}
        </Box>
        <Box className={classes.wrapper}>
          {hasDebtValue && (
            <>
              {!!fieldState?.value && BN(fieldState.value).isGreaterThan(0) && (
                <>
                  <Typography className={classes.title}>
                    {t('GENERAL.NEW_ACCOUNT_STATUS')}
                  </Typography>

                  <Card cardData={newAccountStatusData} />
                </>
              )}
              {(!fieldState?.value ||
                BN(fieldState.value).isLessThanOrEqualTo(0)) && (
                <>
                  <Typography className={classes.title}>
                    {t('GENERAL.CURRENT_ACCOUNT_STATUS')}
                  </Typography>

                  <Card cardData={currentAccountStatusData} />
                </>
              )}
            </>
          )}

          <BottomPopup
            show={showCurrencyPopup}
            label={t('GENERAL.SWITCH_CURRENCY')}
            onClose={() => setShowCurrencyPopup(false)}
            icon={CloseIcon}
          >
            <SwitchPopup
              currencies={currencies}
              selectCurrency={(key) => {
                selectCurrency(key)
                form.reset()
                form.resetFieldState(formName.current)
                setShowCurrencyPopup(false)
              }}
            />
          </BottomPopup>
          {selectedItem && (
            <BottomPopup
              show={true}
              label={selectedItem.text}
              onClose={() => setSelectedItem(null)}
              icon={CloseIcon}
              children={selectedItem.info.content}
              primaryButtonTitle={t('GENERAL.DONE')}
              primaryButtonClick={() => setSelectedItem(null)}
            />
          )}
        </Box>
      </div>
    )
  }

  const remainingDebtInfo = <Typography>{t('REPAY.DEBT.INFO')}</Typography>

  const amountToRepayInfo = (
    <>
      <Typography component={'p'}>{t('REPAY.AMOUNT_REPAY.INFO')}</Typography>
    </>
  )

  const getConfirmationContent = () => {
    const selectedCurrencyText = selectedCurrency?.text

    let securityFee = 0
    let loanOriginationFee = 0
    if (fee.isSuccess && fee.data) {
      securityFee = fee?.data?.securityFee
      loanOriginationFee = fee?.data?.loanOriginationFee
    }

    const total = BN(value).plus(securityFee).plus(loanOriginationFee)

    const content = {
      message: t('REPAY.CONFIRM_REPAY_AMOUNT', {
        amount: formatNumberWithoutRounding(
          value,
          CURRENCIES[selectedCurrency.text.toLowerCase()]?.decimals
        ),
        currency: selectedCurrencyText
      }),
      items: [
        {
          text: t('GENERAL.FUNDING_SOURCE'),
          value: context.user.walletName,
          info: <FundingSourceInfo currency={selectedCurrencyText} />
        },
        {
          text: t('REPAY.AMOUNT_REPAY'),
          value: `${formatNumberWithoutRounding(
            value,
            CURRENCIES[selectedCurrency.text.toLowerCase()]?.decimals
          )} ${selectedCurrencyText}`,
          info: <AmountToRepayInfo />
        },
        {
          text: t('GENERAL.ORIGINAL_FEES'),
          value: `${loanOriginationFee.toFixed(5)} ${selectedCurrencyText}`,
          info: <OriginalFeesInfo classes={classes} />
        },
        {
          text: t('GENERAL.SECURITY_FEES'),
          value: `${feesFormat(
            securityFee,
            selectedCurrencyText
          )} ${selectedCurrencyText}`,
          info: <SecurityFeesInfo classes={classes} />
        }
        // {
        //   text: t('GENERAL.TOTAL'),
        //   value: `${total} ${selectedCurrencyText}`,
        //   isTotal: true
        // }
      ]
    }

    if (hasDebtValue) {
      const newData = data
      const healthFactor = getHealthFactor(newData)
      content.additionalItems = [
        {
          text: t('GENERAL.REMAINING_DEBT'),
          value: currencyFormatter(currentCurrency)(
            newData?.remainingDebt ||
              currentAccountInfoStatus.data.remainingDebt
          ),
          info: <Typography>{t('REPAY.DEBT.INFO')}</Typography>
        },
        {
          text: t('GENERAL.NEW_HEALTH_FACTOR'),
          value: `${healthFactor} ${t('GENERAL.' + newData?.state)}`,
          info: <HealthScoreInfo classes={classes} />,
          scale: RISK_FACTOR_STATE[newData?.state]
        }
      ]

      newData?.collateralAssets?.forEach((collateralAsset) => {
        if (
          collateralAsset.currency.toLowerCase() !==
          collateralAsset.priceCurrency.toLowerCase()
        ) {
          content.additionalItems.push({
            text: t('GENERAL.NEW_CURRENCY_LIQUIDATION_PRICE', {
              currency: formatCurrencyFromApi(collateralAsset.currency)
            }),
            value: cCurrencyFormatter(collateralAsset.priceCurrency)(
              formatNumber(
                collateralAsset.liquidationPrice,
                CURRENCIES[collateralAsset.currency?.toLowerCase()]?.decimals
              )
            ),
            info: <LiquidationPriceInfo />
          })
        }
      })

      if (usedLoanTermsForCurrency) {
        content.additionalItems.push({
          text: t('GENERAL.INTEREST_RATE_MODE'),
          value: t(`GENERAL.INTEREST_RATE_MODE.${usedLoanTermsForCurrency}`),
          info: <InterestRateModeInfo />
        })
      }

      if (newData?.interestRates?.length) {
        const rateValue = newData.interestRates.find(
          (rate) =>
            rate.currency.toLowerCase() === selectedCurrencyText.toLowerCase()
        )
        content.additionalItems.push({
          text: t('GENERAL.INTEREST_RATE'),
          value: `${
            usedLoanTermsForCurrency === LOAN_TERMS.STABLE
              ? rateValue?.stableInterestRate
              : rateValue?.variableInterestRate
          }% ${t('GENERAL.APR')}`,
          info: <InterestRateInfo />
        })
      }
    }

    return content
  }
  const isCELO = txCurrency === CURRENCIES.celo.text
  const splitMultipleTransacitions = isValoraAndroid && !isCELO

  return (
    <WhitePage theme={theme}>
      {apiError && (
        <Alert
          className={classes.error}
          severity="error"
          onClose={() => setApiError(false)}
        >
          {t('GENERAL.API_ERROR')}
        </Alert>
      )}
      <Box
        className={classNames(
          classes.fullHeight,
          confirmation && classes.hidden
        )}
      >
        <ActionLayer
          icon={RepayIcon}
          title={t('GENERAL.REPAY')}
          subtitle={t('REPAY.HELPER_TEXT')}
          content={content}
          homeOnBack={!isMobile}
          buttonClick={submit}
        />
      </Box>

      {confirmation && !splitMultipleTransacitions && (
        <Confirmation
          content={getConfirmationContent()}
          back={() => setConfirmation(false)}
          confirmation={processOperation}
          isLoading={fee.isLoading}
        />
      )}
      {confirmation && splitMultipleTransacitions && (
        <ConfirmationAndroid
          content={getConfirmationContent()}
          back={() => setConfirmation(false)}
          confirmation={() =>
            !approvedTransaction
              ? processOperation(isValoraAndroid, true, redirectUrl)
              : processOperation(isValoraAndroid, false, null)
          }
          isLoading={fee.isLoading}
          approvedTransaction={approvedTransaction}
        />
      )}
    </WhitePage>
  )
}

export default withTranslation()(withStyles(styles)(NewRepayScreen))
