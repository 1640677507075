import { Box } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { View } from 'react-native-web'
import { useHistory } from 'react-router-dom'
import AmountBox from '../../components/AmountBox'
import Typography from '@material-ui/core/Typography'
import PopupList from '../../components/BottomPopup/popupList'
import InfoEntry from '../../components/InfoEntry'
import ScaleSlider from '../../components/ScaleSlider'
import SwitchPopup from '../../components/SwitchPopup'
import {
  composeValidators,
  maxValue,
  mustBePositiveNumber
} from '../../utils/validators'
import { AmountContainer, Container, ContainerWhite, Layout } from './elements'
import FlowButton from '../../components/FlowButton'
import Button from '../../components/Button'
import ActivityEntry from '../../components/ActivityEntry'
import DepositIcon from '../../assets/images/deposit.svg'
import WithdrawIcon from '../../assets/images/withdraw.svg'
import BorrowIcon from '../../assets/images/borrow.svg'
import RepayIcon from '../../assets/images/repay.svg'
import ExternalLinkIcon from '../../assets/images/external-link.svg'
import StatisticsList from '../../components/StatsList/statsList'
import Card from '../../components/Card'
import UserIcon from '../../assets/images/user.svg'
import MoneyStackIcon from '../../assets/images/money-stack.svg'
import VolumeIcon from '../../assets/images/volume.svg'
import BottomPopup from '../../components/BottomPopup'
import CloseIcon from '../../assets/images/close.svg'
import { CURRENCIES, DEFAULT_CURRENCY } from '../../utils/constants'
import ActionBox, {
  ACTION_LIST_FOUR_RINGS_ICON,
  ACTION_LIST_ONE_RING_ICON,
  ACTION_LIST_RIGHT_ARROW,
  ACTION_LIST_THREE_RINGS_ICON,
  ACTION_LIST_TWO_RINGS_ICON
} from '../../components/ActionBox'

const styles = (theme) => ({
  infoEntry: {
    width: '100%',
    border: '1px solid',
    borderColor: theme.palette.neutral.grey1,
    borderRadius: '6px',
    '& > *': {
      padding: '10px 16px',
      '&:not(:last-child)': {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.neutral.grey1
      }
    }
  }
})

const PlaygroundScreen = ({ classes }) => {
  const history = useHistory()

  const statistics = [
    {
      icon: UserIcon,
      title: 'Active users',
      value: '2,500'
    },
    {
      icon: MoneyStackIcon,
      title: 'Total Saved',
      value: '$1.2 M'
    },
    {
      icon: VolumeIcon,
      title: 'Volume Today',
      value: '$120,290'
    }
  ]

  const [showOneButtonPopup, setShowOneButtonPopup] = useState(false)
  const [showTwoButtonPopup, setShowTwoButtonPopup] = useState(false)
  const [showListPopup, setShowListPopup] = useState(false)
  const [logoutButtonPopup, setLogoutButtonPopup] = useState(false)
  const [actionListPopup, setActionListPopup] = useState(false)
  const [showCurrencyPopup, setShowCurrencyPopup] = useState(false)
  const [currencies, setCurrencies] = useState(
    Object.values(CURRENCIES).map((currency) => ({
      ...currency,
      selected: currency.key === DEFAULT_CURRENCY
    }))
  )

  const selectCurrency = (key) => {
    const updatedCurrencies = currencies.map((currency) => {
      currency.selected = currency.key === key
      return currency
    })

    setCurrencies(updatedCurrencies)
  }

  const submit = ({ amount }) => console.log('Selected amount: ', amount)

  const oneButtonPopupMessage = (
    <Typography>
      Your CELO will be moved from this location on to Moola Market.
    </Typography>
  )
  const twoButtonPopupMessage = (
    <Typography>
      The Current mid-market price of Celo on the Mento exchange, in CELO / uUSD
      terms
    </Typography>
  )
  const actionListInfo = (
    <Typography>
      This is the content of an action list button Lorem Ipsum Lorem Ipsum Lorem
      Ipsum{' '}
    </Typography>
  )

  const liquidationInfo = (
    <PopupList
      time={'3/17/2021, 3:16:04 PM'}
      trigger={'$ 3.49 uUSD / CELO'}
      penalty={'15%'}
      amount={'15.00 CELO'}
      value={'$90.00 USD'}
      collateralRatio={'200%'}
      liquidationPrice={'$3.49 cUSD / CELO'}
      status={'Processing'}
      details={'View on celo.org'}
    />
  )

  const walletDetails = (
    <PopupList
      provider={'Valora'}
      status={'Connected'}
      phoneNumber={'+1 203 449 9835'}
      address={'0x 293e 23dd 323d 0943 0e32 424d 2989 324c 02ca'}
    />
  )

  const actionList = [
    {
      leftIcon: ACTION_LIST_ONE_RING_ICON,
      leftText: 'What is Moola market?',
      rightIcon: ACTION_LIST_RIGHT_ARROW,
      onClick: () => setActionListPopup(true)
    },
    {
      leftIcon: ACTION_LIST_TWO_RINGS_ICON,
      leftText: 'How can I start earning?',
      rightIcon: ACTION_LIST_RIGHT_ARROW,
      onClick: () => setActionListPopup(true)
    },
    {
      leftIcon: ACTION_LIST_THREE_RINGS_ICON,
      leftText: 'What returns can I expect?',
      rightIcon: ACTION_LIST_RIGHT_ARROW,
      onClick: () => setActionListPopup(true)
    },
    {
      leftIcon: ACTION_LIST_FOUR_RINGS_ICON,
      leftText: 'What is borrowing?',
      rightIcon: ACTION_LIST_RIGHT_ARROW,
      onClick: () => setActionListPopup(true)
    },
    {
      leftText: 'Language',
      rightText: 'English',
      rightIcon: ACTION_LIST_RIGHT_ARROW,
      onClick: () => setLogoutButtonPopup(true)
    }
  ]

  return (
    <Layout>
      <Container>
        <button onClick={sendMethodNotExistToSentry()}>Test</button>
        <br />
        <FlowButton
          label="Deposit"
          icon={DepositIcon}
          isTransparent={true}
          onClick={() => {
            history.push('/confirm-deposit')
          }}
        />
        <br />
        <br />
        <FlowButton
          label="Withdraw"
          icon={WithdrawIcon}
          isTransparent={false}
          color="red"
          onClick={() => {
            history.push('/confirm-deposit')
          }}
        />
        <br />
        <br />
        <FlowButton
          label="Borrow"
          icon={BorrowIcon}
          isTransparent={false}
          fullWidth
          onClick={() => {
            history.push('/confirm-deposit')
          }}
        />
        <br />
        <br />
        <FlowButton
          label="Repay"
          icon={RepayIcon}
          isTransparent={true}
          onClick={() => {
            history.push('/confirm-deposit')
          }}
        />
        <br />
        <br />
        <Button
          label={'One Button popup'}
          onClick={() => setShowOneButtonPopup(true)}
        />
        <br />
        <br />
        <BottomPopup
          show={showOneButtonPopup}
          label={'Funding Source'}
          onClose={() => setShowOneButtonPopup(false)}
          icon={CloseIcon}
          children={oneButtonPopupMessage}
          primaryButtonTitle={'Primary Button'}
          primaryButtonClick={() => setShowOneButtonPopup(false)}
        />
        <Button
          label={'Two Buttons popup'}
          onClick={() => setShowTwoButtonPopup(true)}
        />
        <br />
        <br />
        <BottomPopup
          show={showTwoButtonPopup}
          label={'Current Price'}
          onClose={() => setShowTwoButtonPopup(false)}
          icon={CloseIcon}
          children={twoButtonPopupMessage}
          primaryButtonTitle={'Done'}
          primaryButtonClick={() => setShowTwoButtonPopup(false)}
          secondaryButtonTitle={'View on Mento'}
          secondaryButtonClick={() => setShowTwoButtonPopup(false)}
          endIconSecondaryButton={ExternalLinkIcon}
        />
        <Button label={'List popup'} onClick={() => setShowListPopup(true)} />
        <br />
        <br />
        <BottomPopup
          show={showListPopup}
          label={'Liquidation Info'}
          onClose={() => setShowListPopup(false)}
          icon={CloseIcon}
          children={liquidationInfo}
        />
        <Button
          label={'Logout popup'}
          onClick={() => setLogoutButtonPopup(true)}
        />
        <br />
        <br />
        <BottomPopup
          show={logoutButtonPopup}
          label={'Your Wallet'}
          onClose={() => setLogoutButtonPopup(false)}
          icon={CloseIcon}
          children={walletDetails}
          primaryButtonTitle={'Disconnect'}
          primaryButtonClick={() => setLogoutButtonPopup(false)}
          isLogout={true}
        />
        <br />
        <br />
        <BottomPopup
          show={actionListPopup}
          label={'Lorem Ipsum Title'}
          onClose={() => setActionListPopup(false)}
          icon={CloseIcon}
          children={actionListInfo}
          primaryButtonTitle={'Lorem Ipsum button'}
          primaryButtonClick={() => setActionListPopup(false)}
        />
        <br />
        <br />
      </Container>
      <ContainerWhite>
        <br />
        <br />
        <br />
        <Card
          cardData={{
            header: {
              icon: CURRENCIES.celo.icon,
              text: CURRENCIES.celo.text,
              value: '9.32% APY'
            },
            content: [
              { text: 'Total Borrowed', value: '$743,433' },
              { text: 'Total Saved', value: '$22,000' },
              { text: 'Borrow APR', value: '22.74%' }
            ]
          }}
        />
        <br />
        <br />
        <Card
          cardData={{
            header: {
              icon: CURRENCIES.cusd.icon,
              text: CURRENCIES.cusd.text,
              value: '10.32% APY'
            },
            content: [
              { text: 'Total Saved', value: '$133,123' },
              { text: 'Total Borrowed', value: '$12,32' },
              { text: 'Borrow APR', value: '12.75%' }
            ]
          }}
        />
        <br />
        <br />
        <Card
          cardData={{
            header: { text: 'Balance', value: '10.32% APY' },
            content: [
              { text: 'Deposit Amount', value: '$250.00' },
              { text: 'Deposit Interest', value: '$25.10', divider: true },
              { text: 'Borrow Amount', value: '- $25.10' },
              { text: 'Borrow Interest', value: '- $1.43' }
            ]
          }}
        />
        <br />
        <br />
        <Card
          cardData={{
            content: [
              { text: 'Collateral Ratio', value: '250%', green: true },
              { text: 'Liquidation Price', value: '$2.45', green: true }
            ]
          }}
        />
        <br />
        <br />
        <Button
          label="Select an amount"
          disabled
          onClick={() => {
            console.log('Click!')
          }}
        />
        <br />
        <br />
        <Button
          label="Next"
          onClick={() => {
            console.log('Click!')
          }}
        />
        <br />
        <br />
        <Button
          label="Confirm on Valora"
          variant="outlined"
          onClick={() => {
            history.push('/confirmation')
          }}
        />
        <br />
        <br />
        <Button
          label="View on celo.org"
          variant="outlined"
          endIcon={ExternalLinkIcon}
          onClick={() => {
            console.log('Click!')
          }}
        />
        <br />
        <br />
        <Button
          label="Done"
          onClick={() => {
            console.log('Click!')
          }}
        />
        <br />
        <br />
        <ActivityEntry
          activityData={{
            type: 'deposit',
            currency: CURRENCIES.celo.key,
            currencyAmount: 11,
            cUsdAmount: 44,
            timeStamp: new Date()
          }}
        />

        <ActivityEntry
          activityData={{
            type: 'deposit',
            currency: CURRENCIES.cusd.key,
            currencyAmount: 10,
            cUsdAmount: 40,
            timeStamp: new Date()
          }}
        />
        <ActivityEntry
          activityData={{
            type: 'withdraw',
            currency: CURRENCIES.celo.key,
            currencyAmount: 5,
            cUsdAmount: 20,
            timeStamp: new Date()
          }}
        />
        <ActivityEntry
          activityData={{
            type: 'withdraw',
            currency: CURRENCIES.cusd.key,
            currencyAmount: 5,
            cUsdAmount: 20,
            timeStamp: new Date()
          }}
        />
        <ActivityEntry
          activityData={{
            type: 'deposit',
            currency: CURRENCIES.celo.key,
            currencyAmount: 11,
            cUsdAmount: 44,
            timeStamp: new Date()
          }}
        />
        <ActivityEntry
          activityData={{
            type: 'withdraw',
            currency: CURRENCIES.celo.key,
            currencyAmount: 5,
            cUsdAmount: 20,
            timeStamp: new Date()
          }}
        />
        <ActivityEntry
          activityData={{
            type: 'deposit',
            currency: CURRENCIES.cusd.key,
            currencyAmount: 10,
            cUsdAmount: 40,
            timeStamp: new Date()
          }}
        />
        <ActivityEntry
          activityData={{
            type: 'withdraw',
            currency: CURRENCIES.cusd.key,
            currencyAmount: 5,
            cUsdAmount: 20,
            timeStamp: new Date()
          }}
        />
        <ActivityEntry
          activityData={{
            type: 'withdraw',
            currency: CURRENCIES.cusd.key,
            currencyAmount: 5,
            cUsdAmount: 20,
            timeStamp: new Date()
          }}
        />
      </ContainerWhite>
      <ActionBox actionList={actionList} />
      <StatisticsList list={statistics} />
      <Form
        onSubmit={submit}
        initialValues={{}}
        render={({ handleSubmit, form, values, errors }) => (
          <View>
            <AmountContainer>
              <AmountBox
                name={'amount1'}
                validators={composeValidators(
                  mustBePositiveNumber,
                  maxValue(150)
                )}
                error={errors['amount1']}
                switchClick={() => setShowCurrencyPopup(true)}
                currency={currencies.find((currency) => currency.selected)}
              >
                {/*TODO: get max value from balance*/}
                <Box className={classes.infoEntry}>
                  <InfoEntry
                    left={'Max Amount'}
                    right={
                      '150 ' +
                      currencies.find((currency) => currency.selected)?.text
                    }
                    clicked={() => form.change('amount1', 150)}
                  />
                </Box>
              </AmountBox>
              <Button
                label="Select an amount"
                onClick={handleSubmit}
                disabled={
                  form.getFieldState('amount1')?.pristine ||
                  form.getFieldState('amount1')?.invalid ||
                  !values.amount1
                }
              />
            </AmountContainer>
            <AmountContainer>
              <AmountBox
                name={'amount2'}
                validators={composeValidators(
                  mustBePositiveNumber,
                  maxValue(150)
                )}
                error={errors['amount2']}
                switchClick={() => setShowCurrencyPopup(true)}
                currency={currencies.find((currency) => currency.selected)}
              >
                {/*TODO: get max value from balance*/}
                <ScaleSlider
                  max={150}
                  splitCount={3}
                  name={'amount2'}
                  leftLabel={'Safer'}
                  rightLabel={'Riskier'}
                />
              </AmountBox>
              <Button
                label="Select an amount"
                onClick={handleSubmit}
                disabled={
                  form.getFieldState('amount2')?.pristine ||
                  form.getFieldState('amount2')?.invalid ||
                  !values.amount2
                }
              />
            </AmountContainer>
            <AmountContainer>
              <AmountBox
                name={'amount3'}
                validators={composeValidators(
                  mustBePositiveNumber,
                  maxValue(25)
                )}
                error={errors['amount3']}
                switchClick={() => setShowCurrencyPopup(true)}
                currency={currencies.find((currency) => currency.selected)}
              >
                {/*TODO: get max value from balance*/}
                <Box className={classes.infoEntry}>
                  <InfoEntry
                    left={'Total Debt'}
                    right={
                      '75 ' +
                      currencies.find((currency) => currency.selected)?.text
                    }
                  />
                  <InfoEntry
                    left={'Max Amount'}
                    right={
                      '25 ' +
                      currencies.find((currency) => currency.selected)?.text
                    }
                    clicked={() => form.change('amount3', 25)}
                  />
                </Box>
              </AmountBox>
              <Button
                label="Select an amount"
                onClick={handleSubmit}
                disabled={
                  form.getFieldState('amount3')?.pristine ||
                  form.getFieldState('amount3')?.invalid ||
                  !values.amount3
                }
              />
            </AmountContainer>
            <BottomPopup
              show={showCurrencyPopup}
              label={'Switch Currency'}
              onClose={() => setShowCurrencyPopup(false)}
              icon={CloseIcon}
            >
              <SwitchPopup
                currencies={currencies}
                selectCurrency={(key) => {
                  selectCurrency(key)
                  setShowCurrencyPopup(false)
                }}
              />
            </BottomPopup>
          </View>
        )}
      />
    </Layout>
  )
}

export default withStyles(styles)(PlaygroundScreen)
