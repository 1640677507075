import React from 'react'
import { useTheme } from '@material-ui/core'
import Languages from '../../components/Languages'
import { useGetSettingsLanguage } from '../../api'
import { withTranslation } from 'react-i18next'
import { LANGUAGES } from '../../utils/constants'
import { WhitePage } from '../../utils/elements'

const NewLanguagesScreen = ({ t }) => {
  const theme = useTheme()
  const language = useGetSettingsLanguage()
  let currenLanguage
  let languages = []

  if (language.isSuccess) {
    currenLanguage = LANGUAGES[language.data].key
  }

  Object.values(LANGUAGES).map((item) => {
    item.active &&
    languages.push({
      text: t(item.lngKey),
      key: item.key,
      icon: item.icon
    })
  })

  return (
    <WhitePage theme={theme}>
      <Languages
        title={t('LANGUAGES.TITLE')}
        languages={languages}
        language={currenLanguage}
      />
    </WhitePage>
  )
}

export default withTranslation()(NewLanguagesScreen)
