import { useEffect, useState } from 'react'
import { getContract } from '../../root'

const useReserveConfigurationData = (txType = 'CELO') => {
  const [isLoading, setLoading] = useState(true)
  const [reserveConfigurationData, setReserveConfigurationData] = useState()

  const loadData = async () => {
    const { lendingPool, reserves } = await getContract()
    const data = await lendingPool.methods
      .getReserveConfigurationData(reserves[txType])
      .call()

    const parsedData = {
      isStableBorrowRateEnabled: data.stableBorrowRateEnabled
    }

    setReserveConfigurationData(parsedData)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [txType])

  return [{ reserveConfigurationData, isLoading }]
}

export default useReserveConfigurationData
