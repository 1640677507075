import styled from 'styled-components'

export const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin: 0 auto 0px;
  max-width: 540px;
  height: 100%;
`
