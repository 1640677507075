import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Container, Overlay, LoadingWrapper } from './elements'


const LoadingOverlay = ({ opened, transparent }) => (
  <Container $opened={opened} $transparent={transparent}>
    <Overlay $transparent={transparent} />
    <LoadingWrapper>
      <CircularProgress size={70} />
    </LoadingWrapper>
  </Container>
)

export default LoadingOverlay
