import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import MuiCard from '@material-ui/core/Card'
import MuiCardContent from '@material-ui/core/CardContent'
import MuiIcon from '@material-ui/core/Icon'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Motion, spring } from 'react-motion'

import ArrowIcon from '../../assets/images/card-arrow.svg'
import AccountTabCurrencyRow from '../AccountTabCurrencyRow'
import { SIGNS } from '../../utils/constants'

const styles = (theme) => ({
  root: {
    background: '#FFFFFF',
    border: '1px solid #EDEEEF',
    borderRadius: '8px'
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  contentHeader: {
    backgroundColor: '#F8F9F9',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  header: {
    height: '100%'
  },
  headerText: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Jost-700-Bold',
    fontWeight: 700
  },
  headerValue1: {
    textAlign: 'right',
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Jost-500-Medium',
    fontWeight: 600,
  },
  headerValue1Red: {
    color: theme.palette.neutral.lightRed
  },
  headerValue2: {
    textAlign: 'right',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: theme.palette.neutral.lightGrey,
  },
  headerIcon: {
    marginRight: '16px'
  },
  headerIconNonCollapsable: {
    marginRight: '16px',
    paddingLeft: '23px',
  },
  headerImage: {
    position: 'relative',
    top: '3px'
  },
  arrowImage: {
    position: 'relative'
  },
  marginBottom: {
    marginBottom: '20px',
  },
  contentBox: {
	paddingTop: '8px',
	borderTop: '1px rgba(0, 0, 0, 0.12) solid'
  }
})

const AccountTabCurrencyCard = ({ classes, cardData, collapsable }) => {
  const {
    header, walletBalance, depositAmount, apy, forwardingInterest,
    borrowedAmount, debtAmount, borrowMode, borrowApr, originationFee,
    availableToBorrow, isCollateral
  } = cardData || {}
  const { icon, text, upperTextValue, lowerTextValue } = header || {}
  const [collapsed, setCollapsed] = useState(true)

  const toggle = () => {
    setCollapsed(!collapsed)
  }
  return (
    <>
      {cardData && (
        <MuiCard className={classes.root} variant="outlined">
          <MuiCardContent className={classes.content}>
            {header && (
              <Box
                className={classes.contentHeader}
                px={2}
                py={2}
                onClick={() => {
                  if (collapsable) toggle()
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  className={classes.header}
                >
                  <Grid item>
                    <Grid container direction="row" alignItems="center">
                      {collapsable && (
                        <Grid item className={classes.headerIcon}>
                          <MuiIcon style={{ paddingBottom: 0 }}>
                            <Motion style={{ x: spring(collapsed ? 0 : 10) }}>
                              {({ x }) => (
                                <img
                                  src={ArrowIcon}
                                  height={12}
                                  width={6}
                                  className={classes.arrowImage}
                                  style={{
                                    transform: `rotate(${x * 9}deg)`
                                  }}
                                />
                              )}
                            </Motion>
                          </MuiIcon>
                        </Grid>
                      )}
                      {icon && (
                        <Grid item className={
                          classNames(!!collapsable ? classes.headerIcon : classes.headerIconNonCollapsable)
                        }>
                          <MuiIcon>
                            <img
                              src={icon}
                              height={40}
                              width={40}
                              className={classes.headerImage}
                            />
                          </MuiIcon>
                        </Grid>
                      )}
                      <Grid item className={classes.marginBottom}>
                        <Typography variant="body2" className={classes.headerText}>
                          {text}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item className={ classNames( !!!lowerTextValue && classes.marginBottom) }>
                        <Typography variant="body2" className={classNames(
                          classes.headerValue1,
                          upperTextValue.startsWith(SIGNS.MINUS) && classes.headerValue1Red
                        )}>
                          {upperTextValue}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" className={classes.headerValue2}>
                          {lowerTextValue}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
            { !collapsed && <Box pl={ 2 } pb={ 0 } pr={ 2 } className={classes.contentBox}>
              { walletBalance && <AccountTabCurrencyRow data={walletBalance} alwaysBold={true}/> }
              { walletBalance?.divider && <Divider/> }

              { depositAmount && <AccountTabCurrencyRow data={depositAmount} alwaysBold={true}/> }
              { depositAmount?.divider && <Divider/> }

              { apy && <AccountTabCurrencyRow data={apy} /> }
              { apy?.divider && <Divider/> }

              { forwardingInterest && <AccountTabCurrencyRow data={forwardingInterest} /> }
              { forwardingInterest?.divider && <Divider/> }

              { borrowedAmount && <AccountTabCurrencyRow data={borrowedAmount} alwaysBold={true}/> }
              { borrowedAmount?.divider && <Divider/> }

              { debtAmount && <AccountTabCurrencyRow data={debtAmount} alwaysBold={true}/> }
              { debtAmount?.divider && <Divider/> }

              { borrowMode && <AccountTabCurrencyRow data={borrowMode} /> }
              { borrowMode?.divider && <Divider/> }

              { borrowApr && <AccountTabCurrencyRow data={borrowApr} /> }
              { borrowApr?.divider && <Divider/> }

              { originationFee && <AccountTabCurrencyRow data={originationFee} alwaysBold={true}/> }
              { originationFee?.divider && <Divider/> }

              { availableToBorrow && <AccountTabCurrencyRow data={availableToBorrow} alwaysBold={true}/> }
              { availableToBorrow?.divider && <Divider/> }

              { isCollateral && <AccountTabCurrencyRow data={isCollateral} /> }

            </Box> }
          </MuiCardContent>
        </MuiCard>
      )}
    </>
  )
}

AccountTabCurrencyCard.propTypes = {
  classes: PropTypes.object,
  cardData: PropTypes.object
}

export default withStyles(styles)(AccountTabCurrencyCard)
