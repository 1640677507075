import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import * as PropTypes from 'prop-types';
import React from 'react';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  left: {
    fontFamily: 'Jost-600-Semi'
  },
  right: {
    color: theme.palette.neutral.lightGrey
  },
  clickable: {
    cursor: 'pointer',
	border: '1px solid',
	borderColor: theme.palette.neutral.grey1,
	borderRadius: '6px',
  },
});

const InfoEntry = ({classes, left, right, clicked}) => {
  return (
    <Grid
      container
      className={clicked ? classes.clickable : ''}
      justify={'space-between'}
      onClick={clicked}
    >
      <Grid item>
        <Typography className={classes.left}>
          {left}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.right}>
          {right}
        </Typography>
      </Grid>
    </Grid>
  );
};

InfoEntry.propTypes = {
  left: PropTypes.string.isRequired,
  right: PropTypes.string.isRequired,
  clicked: PropTypes.func
}

export default withStyles(styles)(InfoEntry);
