import { Grid } from '@material-ui/core';
import MuiIcon from '@material-ui/core/Icon';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import React from 'react';
import ChevronRight from '../../assets/images/chevron-right.svg';

const styles = () => ({
  container: {
    '& > *:not(:last-child)': {
      marginBottom: '22px'
    }
  },
  row: {
    '& > *:not(:last-child)': {
      marginRight: '16px'
    }
  },
  label: {
    fontFamily: 'Jost-500-Medium'
  },
  selectedIcon: {
    marginLeft: 'auto'
  },
  clickable: {
    cursor: 'pointer'
  },
});

const SwitchPopup = ({ classes, currencies, selectCurrency }) => {
  return (
    <Grid
      container
      className={classes.container}
      direction={'column'}
    >
      {currencies.map((currency, index) => (
        <Grid
          item
          key={index}
          onClick={() => selectCurrency(currency.key)}
        >
          <Grid
            container
            className={`${classes.row} ${classes.clickable}`}
            alignItems={'center'}
          >
            <Grid item>
              <MuiIcon>
                <img alt={currency.key} src={currency.icon} height={40} width={40}/>
              </MuiIcon>
            </Grid>
            <Grid item>
              <Typography className={classes.label}>
                {currency.text}
              </Typography>
            </Grid>
            {currency.selected &&
            <Grid
              item
              className={classes.selectedIcon}
            >
              <MuiIcon>
                <img alt={'Selected'} src={ChevronRight} height={24} width={24}/>
              </MuiIcon>
            </Grid>
            }
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

SwitchPopup.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
  })).isRequired,
  selectCurrency: PropTypes.func
}

export default withStyles(styles)(SwitchPopup);
