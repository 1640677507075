export default theme => ({
	desktopPage: {
		height: '100vh',
		width: '100vw',
		minWidth: '100%',
		minHeight: '100%',
		position: 'fixed',
		padding: '16px',
		boxSizing: 'border-box',
		paddingTop: '68px'
	},
	wideContent: {
		width: 'calc(100% - 40px)',
	},
	wideContentExpanded: {
		width: 'calc(100% - 200px)',
	},
	wrapper: {
		display: 'flex',
		flexDirection: 'row'
	},
	headerIconWrapper: {
		width: '36px',
		height: '36px'
	},
	headerIcon: {
		padding: '0px',
		'& img': {
			width: '36px',
			height: '36px'
		}
	},
	headerLogo: {
		width: '88px',
		height: '36px',
		display: 'inline-block',
		marginBottom: '-10px',
		marginLeft: '50px',
		cursor: 'pointer'
	},
	logo: {
		width: '88px',
		height: '36px',
	},
	migrationBanner: {
		width: '100%',
		height: '52px',
		backgroundColor: theme.palette.common.error,
		position: 'absolute',
		top: '0',
		left: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontFamily: 'Jost-400-Book',
		fontSize: '16px',
		lineHeight: '20px',
		color: theme.palette.common.white,
		'& > a': {
			color: 'inherit',
			marginRight: '5px'
		}
	},
	migrationBold: {
		fontSize: '16px',
		lineHeight: '20px',
		fontFamily: 'Jost-700-Bold',
		fontWeight: 700
	}
});
