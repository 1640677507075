import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiIcon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useContext, useEffect, useRef, useState } from 'react'
import BN from 'bignumber.js'
import { Text } from 'react-native'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import CloseIcon from '../../assets/images/close.svg'
import MenuCustomIcon from '../../assets/images/menu.svg'
import WalletIcon from '../../assets/images/wallet.svg'
import AppContext from '../../components/AppContext'
import BorrowedState from '../../components/AppHeader/BorrowedState'
import ConnectedState from '../../components/AppHeader/ConnectedState'
import FundedState from '../../components/AppHeader/FundedState'
import UnconnectedState from '../../components/AppHeader/UnconnectedState'
import BottomPopup from '../../components/BottomPopup'
import PopupList from '../../components/BottomPopup/popupList'
import HomeTabs from '../../components/HomeTabs'
import { Container } from './elements'
import { useContractKit } from '@celo-tools/use-contractkit'
import { currencyFormatter } from '../../utils'
import {
  useGetSettingsCurrency,
  useGetUserAccountInfoBalance,
  useGetUserAccountInfoBorrow,
  useGetMooTokenHolder
} from '../../api'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getContract } from '../../root'
import MooTokenAlfajores from '../../moo-token/moo-test-merkle.json'
import config from '../../config'
import HeaderHealthFactor from "../../components/Header-Health-Factor"
import styles from "./styles"
import { Alert } from '@material-ui/lab'

const NewHomeScreen = ({ t, classes }) => {
  const history = useHistory()
  const { user, setUser } = useContext(AppContext)
  const [logoutButtonPopup, setLogoutButtonPopup] = useState(false)
  const [totalValue, setTotalValue] = useState()
  const [hasDebtValue, setHasDebt] = useState(false)
  const [claimDetails, setClaimDetails] = useState(null)
  const [riskFactor, setRiskFactor] = useState(null)
  const [tokenClaimed, setTokenClaimed] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [apiError, setApiError] = useState(false)

  const { destroy } = useContractKit()
  const { data: currentCurrency } = useGetSettingsCurrency()
  const accountInfoBorrow = useGetUserAccountInfoBorrow(user?.address)
  const accountInfoBalance = useGetUserAccountInfoBalance(
    user?.address,
    currentCurrency
  )
  const accountMooTokenHolder = useGetMooTokenHolder(user?.address)

  const content = useRef()
  const innerContent = useRef()
  const extendedHeader = useRef()
  const headerLabel = useRef()
  const header = useRef()
  const provider = localStorage.getItem('use-contractkit/last-used-wallet')

  const handlers = useSwipeable({
    onSwipedUp: (e) => {
      hideExpandedHeader()
      setIsScrolled(true)
    },
    onSwipedDown: (e) => {
      const tabs = document.getElementById('tabContent')
      if (tabs) {
        if (tabs.scrollTop <= 170) {
          showExpandedHeader()
          setIsScrolled(false)
        }
      }
    }
  })

  useEffect(() => {
    if (accountInfoBorrow?.isError || accountInfoBalance?.isError || accountMooTokenHolder?.isError) {
      setApiError(true)
    }
  }, [accountInfoBorrow, accountInfoBalance, accountMooTokenHolder])

  useEffect(() => {
    const getMooDetails = async () => {
      let mooTokenClaimFound = {}

      if (!config.isTestnet && accountMooTokenHolder.isSuccess && accountMooTokenHolder.data.data[0]) {
        const proof = accountMooTokenHolder.data.data[0].Proof.split(',').map(el => '0x' + el)
        const amount = '0x' + accountMooTokenHolder.data.data[0].Amount
        const address = '0x' + accountMooTokenHolder.data.data[0].Address
        mooTokenClaimFound = {
          'index': accountMooTokenHolder.data.data[0].Index,
          'address': address,
          'amount': amount,
          'proof': proof
        }
      } else {
        const mooTokenClaims = MooTokenAlfajores
        const map = new Map(Object.entries(mooTokenClaims.claims))
        mooTokenClaimFound = map.get(user?.address)
      }

      if (!user) return setClaimDetails(null)

      const { merkleDistributor, mooToken } = await getContract()

      if (mooTokenClaimFound && !tokenClaimed && mooTokenClaimFound.index) {
        const isClaimed = await merkleDistributor.methods
          .isClaimed(mooTokenClaimFound.index)
          .call()
        mooTokenClaimFound.address = user.address
        mooTokenClaimFound.unclaimedValue = BN(mooTokenClaimFound.amount)
          .dividedBy(BN(10).pow(18))
          .toString()
        const balance = await mooToken.methods.balanceOf(user.address).call()
        mooTokenClaimFound.balance = BN(balance)
          .dividedBy(BN(10).pow(18))
          .toString()
        mooTokenClaimFound.isClaimed = isClaimed
        setTokenClaimed(isClaimed)
        setClaimDetails(mooTokenClaimFound)
      }
    }
    if (user) {
      getMooDetails()
    } else {
      setClaimDetails(null)
    }
  }, [
    user,
    tokenClaimed,
    accountMooTokenHolder.isSuccess,
    accountMooTokenHolder.data
  ])

  useEffect(() => {
    const handleWindowWheel = (event) => {
      setIsScrolled(!(innerContent.current.scrollTop === 0 && event.deltaY < 0) && event.deltaY + innerContent.current.scrollTop !== 0)
      if (event.deltaY > 0) {
        hideExpandedHeader()
      } else {
        if (innerContent.current.scrollTop === 0) {
          showExpandedHeader()
        }
      }
    }

    window.addEventListener('wheel', handleWindowWheel)

    return () => {
      window.removeEventListener('wheel', handleWindowWheel)
    }
  }, [])


  useEffect(() => {
    if (accountInfoBalance.isSuccess && accountInfoBalance.data) {
      setTotalValue(accountInfoBalance.data?.balanceGrandTotal?.balance || 0)
    }
  }, [accountInfoBalance])

  useEffect(() => {
    if (accountInfoBorrow.isSuccess) {
      const hasAnyDebt = accountInfoBorrow.data?.data?.some((borrowInfo) => {
        return borrowInfo.amount !== 0
      })
      setHasDebt(hasAnyDebt)
    } else {
      setHasDebt(false)
    }
  }, [accountInfoBorrow])


  const showExpandedHeader = () => {
    if (
      extendedHeader.current &&
      headerLabel.current &&
      content.current &&
      innerContent.current
    ) {
      extendedHeader.current.style.opacity = '1'
      headerLabel.current.style.opacity = '0'
      content.current.style.marginTop = `${header.current.offsetHeight}px`
      innerContent.current.style.maxHeight = 'calc(100vh - 290px)'
      innerContent.current.style.maxHeight = 'calc(var(--vh, 1vh) * 100 - 290px)'
    }
  }

  const hideExpandedHeader = () => {
    if (
      extendedHeader.current &&
      headerLabel.current &&
      content.current &&
      innerContent.current
    ) {
      extendedHeader.current.style.opacity = '0'
      headerLabel.current.style.opacity = '1'
      content.current.style.marginTop = '66px'
      innerContent.current.style.maxHeight = 'calc(100vh - 120px)'
      innerContent.current.style.maxHeight = 'calc(var(--vh, 1vh) * 100 - 120px)'
    }
  }


  if (content.current && header.current) {
    content.current.style.marginTop = `${header.current.offsetHeight}px`
    showExpandedHeader()
  }

  const openMenu = () => {
    history.push('/settings')
  }

  const handleLogOut = async () => {
    await destroy()
    setUser('')
    localStorage.removeItem('provider')
    setTokenClaimed(false)
  }

  const formatAddress = (address) => {
    if (!address) {
      return null
    }

    let firstPart = address.substring(0, 2)
    let secondPart = address.substring(2)
    while (secondPart.length >= 3) {
      firstPart += ' ' + secondPart.substr(0, 4)
      secondPart = secondPart.substr(4)
    }
    firstPart += ' ' + secondPart.substr(0)

    return firstPart
  }

  const walletDetails = user && (
    <PopupList
      provider={provider ?? user.walletType}
      status={t('GENERAL.CONNECTED')}
      phoneNumber={user.phoneNumber}
      address={formatAddress(user.address)}
    />
  )

  const getConnectedState = () => {
    if (accountInfoBalance.isSuccess && accountInfoBalance.data) {
      if (totalValue === 0) {
        return (
          <ConnectedState
            mooToken={claimDetails}
            totalValue={totalValue || 0}
          />
        )
      } else {
        if (hasDebtValue) {
          return (
            <BorrowedState
              mooToken={claimDetails}
              totalValue={totalValue || 0}
              riskFactor={riskFactor}
            />
          )
        }
        return (
          <FundedState
            mooToken={claimDetails}
            totalValue={totalValue || 0}
          />
        )
      }
    } else {
      return (
        <Grid className={classes.loader}>
          <CircularProgress size={70} />
        </Grid>
      )
    }
  }
  return (
    <div {...handlers} className={classes.root}>
	  <div className={classes.migrationBanner}>
		<a href="https://moolamarket.medium.com/moola-migration-from-v1-to-v2-96268238399e" target="_blank">
			<span className={classes.migrationBold}>{t('V2.MIGRATE_BANNER.PART1')}</span>
			{t('V2.MIGRATE_BANNER.PART2')}
		</a>
		<div>{t('V2.MIGRATE_BANNER.PART3')}</div>
	  </div>
      <Container className={classes.wrapper}>
        <Box className={classes.header} ref={header}>
          <Grid
            container
            alignItems={'center'}
            justify={'space-between'}
            className={classes.smallHeader}
          >
            <Grid item>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={openMenu}
                className={classes.headerIcon}
              >
                <MuiIcon className={classes.headerIconWrapper}>
                  <img
                    alt={t('GENERAL.WALLET')}
                    src={MenuCustomIcon}
                    height={24}
                    width={24}
                  />
                </MuiIcon>
              </IconButton>
            </Grid>
            <Grid item className={classes.smallHeaderLabel} ref={headerLabel}>
              <Text>
                {user &&
                totalValue != null &&
                currencyFormatter(currentCurrency)(totalValue)}
              </Text>
              {riskFactor && hasDebtValue && <HeaderHealthFactor riskFactor={riskFactor} />}
            </Grid>
            <Grid item>
              {user && (
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='open drawer'
                  onClick={() => setLogoutButtonPopup(true)}
                  className={classes.headerIcon}
                >
                  <MuiIcon className={classes.headerIconWrapper}>
                    <img
                      alt={t('GENERAL.WALLET')}
                      src={WalletIcon}
                      height={20}
                      width={20}
                    />
                  </MuiIcon>
                </IconButton>
              )}
            </Grid>
          </Grid>

          <Box ref={extendedHeader} className={classes.extendedHeader}>
            {!user && <UnconnectedState />}
            {user && getConnectedState()}
          </Box>
        </Box>

        {currentCurrency && (
          <Box className={classes.content} ref={content}>
            <HomeTabs
              contentRef={innerContent}
              mooToken={claimDetails}
              currency={currentCurrency}
              setRiskFactorValue={setRiskFactor}
              accountTabSelected={() => setIsScrolled(false)}
              isScrolled={isScrolled}
            />
          </Box>
        )}

        {user && (
          <BottomPopup
            show={logoutButtonPopup}
            label={t('GENERAL.YOUR_WALLET')}
            onClose={() => setLogoutButtonPopup(false)}
            icon={CloseIcon}
            children={walletDetails}
            primaryButtonTitle={t('GENERAL.DISCONNECT')}
            primaryButtonClick={() => {
              setLogoutButtonPopup(false)
              handleLogOut()
            }}
            isLogout={true}
          />
        )}
        {apiError && <Alert className={classes.error} severity='error' onClose={() => setApiError(false)}>{t('GENERAL.API_ERROR')}</Alert>}
      </Container>
    </div>
  )
}

export default withTranslation()(withStyles(styles)(NewHomeScreen))
