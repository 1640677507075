import { Box, Grid, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useGetExchangeRates, useGetUserAccountInfo } from '../../api'
import { CURRENCIES, MOO_CURRENCY, SIGNS } from '../../utils/constants'
import { retrieveExchangeRates } from '../../utils/currency'
import { formatNumber } from '../../utils/format'
import AccountTabCurrencyCard from '../AccountTabCurrencyCard'
import IsCollateralSwitch from '../IsCollateralSwitch'
import { Alert } from '@material-ui/lab'

const styles = () => ({
  marginBottom: {
    marginBottom: '16px'
  },
  error: {
    marginBottom: '16px'
  }
})

const APY = 'APY'

const AccountWallet = ({
  t,
  classes,
  mooToken,
  user,
  currency,
  showNoDeposits = true,
  walletBalances,
  forceRefresh
}) => {
  const [celoAccountTabInfo, setCeloAccountTabInfo] = useState(null)
  const [cUsdAccountTabInfo, setCUsdAccountTabInfo] = useState(null)
  const [cEurAccountTabInfo, setCEurAccountTabInfo] = useState(null)
  const [apiError, setApiError] = useState(false)
  const exchangeRatesData = useGetExchangeRates(forceRefresh)
  const accountInfoStatus = useGetUserAccountInfo(
    user?.address,
    currency,
    forceRefresh
  )

  useEffect(() => {
    if (exchangeRatesData?.isError || accountInfoStatus?.isError) {
      setApiError(true)
    }
  }, [exchangeRatesData, accountInfoStatus])

  useEffect(() => {
    if (
      accountInfoStatus.isSuccess &&
      accountInfoStatus.data &&
      accountInfoStatus.data.data.length !== 0
    ) {
      const result = accountInfoStatus.data
      const celoAccountInfo = result.data.find(
        (element) =>
          element.currency.toLowerCase() === CURRENCIES.celo.text.toLowerCase()
      )
      const cUsdAccountInfo = result.data.find(
        (element) =>
          element.currency.toLowerCase() === CURRENCIES.cusd.text.toLowerCase()
      )
      const cEurAccountInfo = result.data.find(
        (element) =>
          element.currency.toLowerCase() === CURRENCIES.ceur.text.toLowerCase()
      )
      const availableToBorrowConvertedValue = result.data.find(
        (element) =>
          element.currency.toLowerCase() === result.currency.toLowerCase()
      ).availableToBorrow
      setCeloAccountTabInfo(
        getAccountCurrencyCardInformation(
          CURRENCIES.celo.icon,
          CURRENCIES.celo.text,
          result.currency,
          celoAccountInfo,
          walletBalances?.find((e) => e.currency === CURRENCIES.celo.text)
            ?.walletBalance,
          exchangeRatesData,
          availableToBorrowConvertedValue
        )
      )

      setCUsdAccountTabInfo(
        getAccountCurrencyCardInformation(
          CURRENCIES.cusd.icon,
          CURRENCIES.cusd.text,
          result.currency,
          cUsdAccountInfo,
          walletBalances?.find((e) => e.currency === CURRENCIES.cusd.text)
            ?.walletBalance,
          exchangeRatesData,
          availableToBorrowConvertedValue
        )
      )

      setCEurAccountTabInfo(
        getAccountCurrencyCardInformation(
          CURRENCIES.ceur.icon,
          CURRENCIES.ceur.text,
          result.currency,
          cEurAccountInfo,
          walletBalances?.find(
            (e) =>
              e.currency.toLowerCase() === CURRENCIES.ceur.text.toLowerCase()
          )?.walletBalance,
          exchangeRatesData,
          availableToBorrowConvertedValue
        )
      )
    }
  }, [
    accountInfoStatus.isSuccess,
    accountInfoStatus.data,
    exchangeRatesData.isSuccess,
    exchangeRatesData.data,
    t
  ])

  const getAccountCurrencyRowInformation = (
    text,
    upperTextValue,
    lowerTextValue,
    divider
  ) => {
    return {
      text: text,
      upperTextValue: upperTextValue,
      lowerTextValue: lowerTextValue,
      divider: divider,
      collapsed: true
    }
  }

  const getAccountCurrencyApyText = (currency) => {
    switch (currency) {
      case CURRENCIES.celo.text:
        return `${CURRENCIES.celo.text} ${APY}`
      case CURRENCIES.cusd.key:
        return `${CURRENCIES.cusd.text} ${APY}`
      case CURRENCIES.ceur.key:
        return `${CURRENCIES.ceur.text} ${APY}`
    }
  }

  let mooCard
  if (mooToken?.balance) {
    mooCard = {
      header: {
        icon: MOO_CURRENCY.icon,
        text: MOO_CURRENCY.text,
        upperTextValue: `${formatNumber(Math.abs(mooToken.balance), 2)} ${
          MOO_CURRENCY.text
        }`
      }
    }
  }

  const getAccountCurrencyCardInformation = (
    headerIcon,
    currency,
    conversionCurrency,
    data,
    walletBalance,
    exchangeRatesData,
    availableToBorrowConvertedValue
  ) => {
    const collateralHidden = !data.depositAmount.value > 0
    return {
      header: {
        icon: headerIcon,
        text: currency,
        upperTextValue: getAccountCurrencyValueFormatted(
          currency,
          data.depositAmount.value - data.borrowAmount.value
        ),
        lowerTextValue:
          currency.toLowerCase() === conversionCurrency.toLowerCase()
            ? null
            : getAccountCurrencyValueFormatted(
                conversionCurrency,
                data.depositAmount.convertedValue -
                  data.borrowAmount.convertedValue
              )
      },
      walletBalance: getAccountCurrencyRowInformation(
        t('HOME.ACCOUNT_TAB.WALLET_BALANCE_TITLE'),
        getAccountCurrencyValueFormatted(currency, walletBalance),
        null,
        true
      ),
      depositAmount: getAccountCurrencyRowInformation(
        t('HOME.ACCOUNT_TAB.DEPOSITED_AMOUNT_TITLE'),
        getAccountCurrencyValueFormatted(currency, data.depositAmount.value),
        currency.toLowerCase() === conversionCurrency.toLowerCase()
          ? null
          : getAccountCurrencyValueFormatted(
              conversionCurrency,
              data.depositAmount.convertedValue
            ),
        true
      ),
      showIfNoDeposit: showNoDeposits || data.depositAmount.value !== 0,
      apy:
        data.depositAmount.value === 0 && data.borrowAmount.value > 0
          ? null
          : getAccountCurrencyRowInformation(
              getAccountCurrencyApyText(currency),
              `${data.depositInterest}%`,
              null,
              true
            ),
      borrowedAmount:
        data.borrowAmount.value > 0
          ? getAccountCurrencyRowInformation(
              t('HOME.ACCOUNT_TAB.BORROWED_AMOUNT_TITLE'),
              getAccountCurrencyValueFormatted(
                currency,
                data.borrowAmount.value,
                true
              ),
              currency.toLowerCase() === conversionCurrency.toLowerCase()
                ? null
                : getAccountCurrencyValueFormatted(
                    conversionCurrency,
                    data.borrowAmount.convertedValue,
                    true
                  ),
              true
            )
          : null,
      debtAmount:
        data.borrowAmount.value > 0
          ? getAccountCurrencyRowInformation(
              t('HOME.ACCOUNT_TAB.DEBT_AMOUNT_TITLE'),
              getAccountCurrencyValueFormatted(
                currency,
                data.debtAmount.value,
                true
              ),
              currency.toLowerCase() === conversionCurrency.toLowerCase()
                ? null
                : getAccountCurrencyValueFormatted(
                    conversionCurrency,
                    data.debtAmount.convertedValue,
                    true
                  ),
              true
            )
          : null,
      borrowMode:
        data.borrowAmount.value > 0
          ? getAccountCurrencyRowInformation(
              t('HOME.ACCOUNT_TAB.BORROW_MODE_TITLE'),
              t('BORROW.LOAN_TERMS.' + data.borrowMode),
              null,
              true
            )
          : null,
      borrowApr:
        data.borrowAmount.value > 0
          ? getAccountCurrencyRowInformation(
              t('HOME.ACCOUNT_TAB.BORROW_APR_TITLE'),
              `${data.borrowInterest.toFixed(2)}%`,
              null,
              true
            )
          : null,
      originationFee:
        data.borrowAmount.value > 0
          ? getAccountCurrencyRowInformation(
              t('HOME.ACCOUNT_TAB.ORIGINATION_FEE_TITLE'),
              getAccountCurrencyValueFormatted(
                currency,
                data.originationFee.value
              ),
              currency.toLowerCase() === conversionCurrency.toLowerCase()
                ? null
                : getAccountCurrencyValueFormatted(
                    conversionCurrency,
                    data.originationFee.convertedValue
                  ),
              true
            )
          : null,
      availableToBorrow: getAccountCurrencyRowInformation(
        t('HOME.ACCOUNT_TAB.AVAILABLE_TO_BORROW_TITLE'),
        getAccountCurrencyValueFormatted(currency, data.availableToBorrow),
        currency.toLowerCase() === conversionCurrency.toLowerCase()
          ? null
          : getAccountCurrencyValueFormatted(
              conversionCurrency,
              availableToBorrowConvertedValue
            ),
        !collateralHidden
      ),
      isCollateral:
        !collateralHidden &&
        getAccountCurrencyRowInformation(
          t('HOME.ACCOUNT_TAB.IS_COLLATERAL_TITLE'),
          <IsCollateralSwitch
            userAddress={user?.address}
            currency={currency}
            exchangeRates={retrieveExchangeRates(exchangeRatesData?.data)}
            forceRefresh={forceRefresh}
          />
        )
    }
  }

  const getAccountCurrencyValueFormatted = (currencyType, value, negative) => {
    switch (currencyType.toLowerCase()) {
      case CURRENCIES.celo.text.toLowerCase():
        return `${value < 0 ? SIGNS.MINUS : ''}${
          negative ? SIGNS.MINUS : ''
        }${formatNumber(Math.abs(value), CURRENCIES.celo.decimals)} ${CURRENCIES.celo.text}`
      case CURRENCIES.cusd.text.toLowerCase():
        return `${value < 0 ? SIGNS.MINUS : ''}${
          negative ? SIGNS.MINUS : ''
        }$${formatNumber(Math.abs(value), CURRENCIES.cusd.decimals)}`
      case CURRENCIES.ceur.text.toLowerCase():
        return `${value < 0 ? SIGNS.MINUS : ''}${
          negative ? SIGNS.MINUS : ''
        }€${formatNumber(Math.abs(value), CURRENCIES.ceur.decimals)}`
    }
  }
  return (
    <Box pt={'16px'} pb={'16px'}>
      {apiError && <Alert severity='error' onClose={() => setApiError(false)}>{t('GENERAL.API_ERROR')}</Alert>}
      {mooCard && (
        <Grid item className={classes.marginBottom}>
          <AccountTabCurrencyCard cardData={mooCard} />
        </Grid>
      )}
      {celoAccountTabInfo && celoAccountTabInfo.showIfNoDeposit && (
        <Grid item className={classes.marginBottom}>
          <AccountTabCurrencyCard cardData={celoAccountTabInfo} collapsable />
        </Grid>
      )}
      {cUsdAccountTabInfo && cUsdAccountTabInfo.showIfNoDeposit && (
        <Grid item className={classes.marginBottom}>
          <AccountTabCurrencyCard cardData={cUsdAccountTabInfo} collapsable />
        </Grid>
      )}
      {cEurAccountTabInfo && cEurAccountTabInfo.showIfNoDeposit && (
        <AccountTabCurrencyCard cardData={cEurAccountTabInfo} collapsable />
      )}
    </Box>
  )
}

export default withTranslation()(withStyles(styles)(AccountWallet))

AccountWallet.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  currency: PropTypes.string,
  mooToken: PropTypes.object,
  showNoDeposits: PropTypes.bool
}
