import { Box, Grid, Typography, withStyles } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'
import MuiIcon from '@material-ui/core/Icon'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { EventRegister } from 'react-native-event-listeners'
import { useGetUserAccountInfoActivity } from '../../api'
import NoActivity from '../../assets/images/no-activity.svg'
import { CURRENCIES, DESKTOP_CURRENCY_CHANGED, SIGNS, TRANSACTIONS } from '../../utils/constants'
import { getActivityDate, getActivityDetailsDate } from '../../utils/dateUtils'
import { formatNumber } from '../../utils/format'
import ActivityEntry from '../ActivityEntry'

const styles = (theme) => ({
  noActivityText: {
    lineHeight: '40px',
    paddingLeft: '16px',
    fontWeight: '500',
    fontSize: '16px',
    color: theme.palette.text.secondary
  }
})

const AccountActivities = ({
  t,
  classes,
  setSelectedActivity,
  currency,
  user,
  forceRefresh
}) => {
  const itemsPerPage = 10
  const [activities, setActivities] = useState([])
  const [pagination, setPagination] = useState({
    pageNo: 0,
    pageSize: itemsPerPage
  })

  let accountInfoActivity = useGetUserAccountInfoActivity(
    user?.address,
    currency,
    pagination,
    forceRefresh
  )

  const showMore = (val) => {
    setPagination({ pageNo: activities.length > 0 && pagination.pageNo === 0 ? activities.length / itemsPerPage : pagination.pageNo + 1, pageSize: itemsPerPage })
  }

  const formattingActivities = (activities) => {
    if (Array.isArray(activities)) {
      return activities.map((activity) => ({
        ...activity,
        currency: CURRENCIES[activity.currency.toLowerCase()]?.text ?? '',
        sourceTitle: getSourceTitle(activity?.type) ?? '',
        source: getSourceValue(activity?.source),
        amountText: getAmountTitle(activity?.type) ?? '',
        amount:
          activity?.type === TRANSACTIONS.LIQUIDATE
            ? activity.amountOfDebtRepaid
            : activity.amount,
        value:
          activity?.type === TRANSACTIONS.LIQUIDATE
            ? activity.amountOfDebtRepaidValue
            : activity.value,
        originationFeeAmount: shouldShowOriginationFee(activity?.type)
          ? activity.originationFeeAmount?.toFixed(5) ??
            t('GENERAL.NOT_APPLICABLE')
          : null,
        originationFeeValue: shouldShowOriginationFee(activity?.type)
          ? activity.originationFeeValue?.toFixed(5)
          : null,
        liquidatorClaimedAmount: activity?.liquidatorClaimed
          ? `${formatNumber(activity?.liquidatorClaimed, CURRENCIES[activity.claimedCurrency?.toLowerCase()]?.decimals)} ${
              CURRENCIES[activity.claimedCurrency?.toLowerCase()]?.text ?? ''
            }`
          : null,
        liquidatorClaimedValue: activity?.liquidatorClaimedValue
          ? formatNumber(activity?.liquidatorClaimedValue, 2)
          : null,
        liquidationPenalty: activity?.penaltyPercentage
          ? `${activity?.penaltyPercentage}%`
          : null,
        liquidationPrice:
          activity?.type === TRANSACTIONS.LIQUIDATE
            ? formatNumber(activity?.liquidationPrice, 2)
            : null,
        healthFactor: activity?.healthFactor,
        timestampDetails: getActivityDetailsDate(activity?.timestamp) ?? '',
        timestamp: getActivityDate(activity?.timestamp) ?? '',
        status: activity?.status ? activity.status : 'successful'
      }))
    } else {
      // throw new Error('Unable to parse API response')
    }
  }

  const totalActivities = (pagination.pageNo + 1) * itemsPerPage

  useEffect(() => {
    const currencyChangedListener = EventRegister.addEventListener(
      DESKTOP_CURRENCY_CHANGED,
      () => {
        setPagination({
          pageNo: 0,
          pageSize: itemsPerPage
        })
      }
    )
    return () => {
      EventRegister.removeEventListener(currencyChangedListener)
    }
  }, [])

  useEffect(() => {
    let newActivities = []
    if (accountInfoActivity.isSuccess && accountInfoActivity.data) {
      newActivities = formattingActivities(accountInfoActivity.data?.activity)
    }
    const updatedActivities = activities.concat(newActivities)
    setActivities(updatedActivities)
  }, [
    accountInfoActivity.isFetched,
    accountInfoActivity.isSuccess,
    accountInfoActivity.data,
    pagination,
  ])

  useEffect(() => {
    const activitiesCopy = activities
	activitiesCopy.forEach(activity => {
		activity.sourceTitle = getSourceTitle(activity?.type) ?? ''
        activity.amountText = getAmountTitle(activity?.type) ?? ''
	})
    setActivities(activitiesCopy)
  }, [t])

  useEffect(() => {
	if (activities.length) {
	  setPagination({
		  pageNo: 0,
		  pageSize: activities.length
	  })
	}
    setActivities([])
  }, [user, forceRefresh, currency])

  const getSourceTitle = (type) => {
    switch (type) {
      case TRANSACTIONS.DEPOSIT:
      case TRANSACTIONS.REPAY:
        return t('GENERAL.FROM')
      case TRANSACTIONS.WITHDRAW:
      case TRANSACTIONS.BORROW:
      case TRANSACTIONS.LIQUIDATE:
        return t('GENERAL.TO')
    }
  }

  const getAmountTitle = (type) => {
    switch (type) {
      case TRANSACTIONS.DEPOSIT:
      case TRANSACTIONS.WITHDRAW:
        return t('GENERAL.AMOUNT')
      case TRANSACTIONS.BORROW:
        return t('HOME.ACCOUNT_TAB.ACTIVITY.PRINCIPLE_AMOUNT')
      case TRANSACTIONS.REPAY:
        return t('HOME.ACCOUNT_TAB.ACTIVITY.AMOUNT_TO_PRINCIPLE')
      case TRANSACTIONS.LIQUIDATE:
        return t('GENERAL.AMOUNT_DEBT_REPAID')
    }
  }

  const getSourceValue = (source) => {
    if (!source) return ''
    const sourceLength = source.length
    return `0x${source.substring(0, 5)}...${source.substring(sourceLength - 5)}`
  }

  const shouldShowOriginationFee = (type) => {
    return type === TRANSACTIONS.BORROW || type === TRANSACTIONS.REPAY
  }

  return (
    <>
      {activities && activities.length > 0 ? (
        <Box>
          <Grid item>
            {activities.map((item, index) => (
              <React.Fragment key={`f${index}`}>
                <ActivityEntry
                  activityData={{
                    type: item.type,
                    currency:
                      item.type !== TRANSACTIONS.LIQUIDATE ? item.currency : '',
                    currencyAmount:
                      item.type === TRANSACTIONS.LIQUIDATE
                        ? `${SIGNS.MINUS} ${item.liquidatorClaimedAmount}`
                        : formatNumber(item.amount, CURRENCIES[item.currency?.toLowerCase()]?.decimals),
                    cUsdAmount:
                      item.type === TRANSACTIONS.LIQUIDATE
                        ? item.liquidatorClaimedValue
                        : formatNumber(item.value, 2),
                    timestamp: item.timestamp
                  }}
                  onClick={() => setSelectedActivity(item)}
                  selectedCurrency={currency}
                />
              </React.Fragment>
            ))}
          </Grid>
          {totalActivities <= activities.length && (
            <MuiButton
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.root}
              classes={{ startIcon: classes.startIcon }}
              style={{ borderRadius: 12 }}
              onClick={() => showMore()}
            >
              {t('GENERAL.SHOW_MORE')}
            </MuiButton>
          )}
        </Box>
      ) : (
        <Grid item container>
          <MuiIcon style={{ width: 'auto', height: 'auto' }}>
            <img
              alt={t('HOME.ACCOUNT_TAB.NO_ACTIVITY')}
              src={NoActivity}
              height={42}
              width={42}
            />
          </MuiIcon>
          <Typography className={classes.noActivityText}>
            {t('HOME.ACCOUNT_TAB.NO_ACTIVITY')}
          </Typography>
        </Grid>
      )}
    </>
  )
}

export default withTranslation()(withStyles(styles)(AccountActivities))
