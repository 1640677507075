import { useQuery, useMutation } from 'react-query'
import { QUERY_KEYS } from './constants'
import {
  getReserveData,
  getStatisticsData,
  getSettingsCurrency,
  getSettingsLanguage,
  setSettingsCurrency,
  setSettingsLanguage,
  getUserAccountInfoBorrow,
  getUserAccountInfoBalance,
  getUserAccountInfoRiskFactor,
  getUserAccountInfoDeposit,
  getUserAccountInfoMax,
  getUserAccountInfoStatus,
  getUserNewAccountInfoStatus,
  getUserAccountLiquidationPrices,
  getExchangeRates,
  getFee,
  getMooTokenHolder,
  getUserAccountInfo,
  getUserAccountInfoActivity
} from './requests'
import {
  getUserAccountInfoDebt,
  getUserAccountInfoCurrencyDebt,
  getUserAccountCurrenciesCurrentPrice
} from './mockRequests' // "./requests"
import { isMobile } from '../utils/browser'
import { EventRegister } from 'react-native-event-listeners'
import { DESKTOP_CURRENCY_CHANGED } from '../utils/constants'

const queryOptions = {
  retry: false // If request throws error, it doesn't retry; default option is to retry 3 times
}

export function formatKey(userPublicKey) {
  return userPublicKey?.substring(2)
}

export function useGetReserveData(currency) {
  return useQuery(
    [QUERY_KEYS.GET_RESERVE_DATA, currency],
    () => getReserveData(currency),
    queryOptions
  )
}

export function useGetStatisticsData(currency) {
  return useQuery(
    [QUERY_KEYS.GET_STATISTICS_DATA, currency],
    () => getStatisticsData(currency),
    queryOptions
  )
}

export function useGetSettingsCurrency() {
  return useQuery(
    QUERY_KEYS.GET_SETTINGS_CURRENCY,
    getSettingsCurrency,
    queryOptions
  )
}

export function useGetSettingsLanguage() {
  return useQuery(
    QUERY_KEYS.GET_SETTINGS_LANGUAGE,
    getSettingsLanguage,
    queryOptions
  )
}

export const useGetUserAccountInfoBalance = (
  userPublicKey,
  currency,
  forceRefresh
) => {
  return useQuery(
    [
      QUERY_KEYS.GET_USER_ACCOUNT_INFO_BALANCE,
      userPublicKey,
      currency,
      forceRefresh
    ],
    () => getUserAccountInfoBalance(formatKey(userPublicKey), currency),
    queryOptions
  )
}

export const useGetUserAccountInfoDeposit = (userPublicKey) => {
  return useQuery(
    [QUERY_KEYS.GET_USER_ACCOUNT_INFO_DEPOSIT, userPublicKey],
    () => getUserAccountInfoDeposit(formatKey(userPublicKey)),
    queryOptions
  )
}

export const useGetUserAccountInfoActivity = (
  userPublicKey,
  currency,
  pagination,
  forceRefresh
) => {
  return useQuery(
    [
      QUERY_KEYS.GET_USER_ACCOUNT_INFO_ACTIVITY,
      userPublicKey,
      currency,
      pagination,
      forceRefresh
    ],
    () =>
      getUserAccountInfoActivity(
        formatKey(userPublicKey),
        currency,
        pagination
      ),
    queryOptions
  )
}

export const useGetUserAccountInfoRiskFactor = (
  userPublicKey,
  currency,
  forceRefresh
) => {
  return useQuery(
    [
      QUERY_KEYS.GET_USER_ACCOUNT_INFO_HEALTH_FACTOR,
      userPublicKey,
      currency,
      forceRefresh
    ],
    () => getUserAccountInfoRiskFactor(formatKey(userPublicKey), currency),
    queryOptions
  )
}

export const useGetUserAccountInfoBorrow = (userPublicKey, forceRefresh) => {
  return useQuery(
    [QUERY_KEYS.GET_USER_ACCOUNT_INFO_BORROW, userPublicKey, forceRefresh],
    () => getUserAccountInfoBorrow(formatKey(userPublicKey)),
    queryOptions
  )
}

export const useSetSettingsCurrency = ({ onSuccess: callback }) => {
  return useMutation((value) => setSettingsCurrency(value), {
    onSuccess: (data) => {
      callback(data)
      if (!isMobile) EventRegister.emit(DESKTOP_CURRENCY_CHANGED)
    }
  })
}

export const useSetSettingsLanguage = ({ onSuccess: callback }) => {
  return useMutation((value) => setSettingsLanguage(value), {
    onSuccess: (data) => {
      callback(data)
    }
  })
}

export const useGetUserAccountInfoMax = (
  userPublicKey,
  currency,
  activityType
) => {
  return useQuery(
    [
      QUERY_KEYS.GET_USER_ACCOUNT_INFO_MAX,
      userPublicKey,
      currency,
      activityType
    ],
    () =>
      getUserAccountInfoMax(formatKey(userPublicKey), currency, activityType),
    queryOptions
  )
}

export const useGetUserAccountInfoDebt = (userPublicKey, currency) => {
  return useQuery(
    [QUERY_KEYS.GET_USER_ACCOUNT_INFO_DEBT, userPublicKey, currency],
    () => getUserAccountInfoDebt(userPublicKey, currency),
    queryOptions
  )
}

export const useGetUserAccountInfoCurrencyDebt = (userPublicKey, currency) => {
  return useQuery(
    [QUERY_KEYS.GET_USER_ACCOUNT_INFO_CURRENCY_DEBT, userPublicKey, currency],
    () => getUserAccountInfoCurrencyDebt(userPublicKey, currency),
    queryOptions
  )
}

export const useGetUserAccountInfoStatus = (userPublicKey, currentCurrency) => {
  return useQuery(
    [QUERY_KEYS.GET_USER_ACCOUNT_INFO_STATUS, userPublicKey, currentCurrency],
    () => getUserAccountInfoStatus(formatKey(userPublicKey), currentCurrency),
    queryOptions
  )
}

export const useGetNewUserAccountInfoStatus = (
  userPublicKey,
  currentCurrency,
  selectedCurrency,
  activityType,
  amount,
  loanTerms,
  collateral,
  debts,
  collateralsDebts,
  loans,
  sums,
  currenAccountStatus,
  exchangeRates
) => {
  return getUserNewAccountInfoStatus(
    formatKey(userPublicKey),
    currentCurrency,
    selectedCurrency,
    activityType,
    amount,
    loanTerms,
    collateral,
    debts,
    collateralsDebts,
    loans,
    sums,
    currenAccountStatus,
    exchangeRates
  )
}

export const useGetExchangeRates = (forceRefresh) => {
  return useQuery(
    [QUERY_KEYS.GET_EXCHANGE_RATES, forceRefresh],
    () => getExchangeRates(),
    queryOptions
  )
}

export const useGetUserAccountLiquidationPrices = (
  userPublicKey,
  forceRefresh
) => {
  return useQuery(
    [
      QUERY_KEYS.GET_USER_ACCOUNT_INFO_LIQUIDATION_PRICES,
      userPublicKey,
      forceRefresh
    ],
    () => getUserAccountLiquidationPrices(formatKey(userPublicKey)),
    queryOptions
  )
}

export const useGetFee = (
  userPublicKey,
  currency,
  activityType,
  amount,
  confirmation
) => {
  return useQuery(
    [QUERY_KEYS.GET_FEE, currency, activityType, amount, confirmation],
    () =>
      getFee(
        formatKey(userPublicKey),
        currency,
        activityType,
        amount,
        confirmation
      ),
    queryOptions
  )
}

export const useGetMooTokenHolder = (userPublicKey) => {
  return useQuery(
    [QUERY_KEYS.GET_USER_TOKEN_HOLDER, userPublicKey],
    () => getMooTokenHolder(formatKey(userPublicKey)),
    queryOptions
  )
}

export const useGetUserAccountInfo = (
  userPublicKey,
  currency,
  forceRefresh
) => {
  return useQuery(
    [QUERY_KEYS.GET_USER_RESERVE_DATA, userPublicKey, currency, forceRefresh],
    () => getUserAccountInfo(formatKey(userPublicKey), currency),
    queryOptions
  )
}
