import { getContract } from '../root';
import BN from 'bignumber.js'
import { ether } from '../utils'

// params preparing code taked from Valora app and adjusted to set gasPrice
// https://github.com/celo-org/wallet/blob/cf713e4235966ebe6099a608ce58fee1edd243ad/packages/mobile/src/dappkit/dappkit.ts
export const produceTxParams = async (kit, txs) => {
  const rawTxs = Promise.all(
    txs.map(async (tx) => {
      // TODO offload this logic to walletkit or contractkit, otherwise they
      // could diverge again and create another bug
      // See https://github.com/celo-org/celo-monorepo/issues/3045

      // In walletKit we use web3.eth.getCoinbase() to get gateway fee recipient
      // but that's throwing errors here. Not sure why, but txs work without it.
      const gatewayFeeRecipient = undefined
      const gatewayFee = undefined
      const gas = Math.round(tx.estimatedGas * 1.5)

      // calculate gasPrice
      const isCELO = tx.feeCurrency === undefined
      const gasPriceCelo = await kit.web3.eth.getGasPrice()
      let celoPerToken = null
      if (!isCELO) {
        const { priceOracle, tokens } = await getContract()
        const token = tokens[tx.feeCurrency]
        celoPerToken = await priceOracle.methods.getAssetPrice(token.address).call()
      }
    
      const gasPrice = isCELO ? gasPriceCelo : BN(gasPriceCelo).multipliedBy(ether).dividedBy(celoPerToken).multipliedBy('1.1').toFixed(0)
      // end gasPrice calculation

      const params: any = {
        from: tx.from,
        gasPrice,
        gas,
        data: tx.txData,
        nonce: tx.nonce,
        value: tx.value,
        feeCurrency: tx.feeCurrencyAddress,
        gatewayFeeRecipient,
        gatewayFee,
      }
      if (tx.to) {
        params.to = tx.to
      }
      
      return params
    })
  )

  return rawTxs;
}