import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'i18next'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import MuiIcon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '../../assets/images/close.svg'
import BackIcon from '../../assets/images/back.svg'
import CheckMark from '../../assets/images/check-mark.svg'
import { useSetSettingsLanguage } from '../../api'
import { withTranslation } from 'react-i18next'
import { isMobile } from '../../utils/browser'

const styles = (theme) => ({
  wrapper: {
    height: '100%',
    padding: '16px',
    overflow: 'auto'
  },
  backIcon: {
    marginBottom: '10px'
  },
  title: {
    fontFamily: 'Jost-700-Bold',
    fontSize: '24px',
    lineHeight: '28px',
    marginBottom: '8px'
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    marginBottom: '24px'
  },
  divider: {
    marginBottom: '16px'
  },
  grid: {
    padding: '8px',
    paddingLeft: 0,
    paddingRight: 0,
    cursor: 'pointer'
  },
  leftText: {
    weight: '500',
    Style: 'normal',
    Size: '16px',
    lineHeight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  rightText: {
    weight: '500',
    Style: 'normal',
    Size: '16px',
    lineHeight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '8px',
    color: theme.palette.neutral.lightGrey
  },
  rightIcon: {
    paddingTop: '8px',
    paddingLeft: '4px',
    position: 'relative',
    background: theme.palette.neutral.white,
    width: 'auto',
    height: 'auto'
  },
  leftIcon: {
    paddingTop: '8px',
    paddingLeft: '4px',
    position: 'relative',
    marginRight: '16px',
    width: 'auto',
    height: 'auto'
  }
})

const Languages = ({ t, classes, title, languages, language }) => {
  const history = useHistory()
  const [selectedLanguage, setSelectedLanguage] = useState(language)

  useEffect(() => {
    setSelectedLanguage(language)
  }, [language])

  const mutation = useSetSettingsLanguage({ onSuccess: setSelectedLanguage })

  const setLanguage = (language) => {
    mutation.mutate(language)
    i18n.changeLanguage(language)
  }

  return (
    <Grid
      className={classes.wrapper}
      container
      direction={'column'}
      wrap={'nowrap'}
    >
      <Box className={classes.backIcon}>
        <MuiIcon onClick={() => !isMobile ? history.push('/') : history.goBack()}>
          <img alt={'Go back'} src={BackIcon} width={32} height={32}></img>
        </MuiIcon>
      </Box>
      <Box className={classes.content}>
        <Typography variant={'body2'} className={classes.title}>
          {title}
        </Typography>
      </Box>
      <Box className={classes.root}>
        {languages &&
          languages.map((item, index) => (
            <Grid
              key={`f${index}`}
              container
              direction="row"
              justify="space-between"
              className={classes.grid}
              onClick={() => setLanguage(item.key)}
            >
              <Grid container item justify="space-between">
                <Grid item>
                  <Grid container>
                    <MuiIcon className={classes.leftIcon}>
                      <img src={item.icon} height={24} width={24} />
                    </MuiIcon>
                    <Typography
                      align="center"
                      variant="body1"
                      className={classes.leftText}
                    >
                      {item.text}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    {item.key === selectedLanguage && (
                      <MuiIcon className={classes.rightIcon}>
                        <img src={CheckMark} height={24} width={24} />
                      </MuiIcon>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Box>
    </Grid>
  )
}

Languages.propTypes = {
  title: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.object).isRequired,
  language: PropTypes.string
}

export default withTranslation()(withStyles(styles)(Languages))
