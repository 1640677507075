import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationEN from './translations/en.json'
import translationES from './translations/es.json'
import translationRO from './translations/ro.json'
import translationKO from './translations/ko.json'
import translationPT from './translations/pt.json'
import translationVIE from './translations/vie.json'
import translationCN from './translations/cn.json'

const resources = {
  en: {
    translation: translationEN
  },
  // es: {
  //   translation: translationES
  // },
  // ro: {
  //   translation: translationRO
  // },
  ko: {
    translation: translationKO
  },
  pt: {
    translation: translationPT
  },
  vie: {
    translation: translationVIE
  },
  cn: {
    translation: translationCN
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      lookupLocalStorage: 'language'
    },
    resources,
    fallbackLng: 'en',
    // supportedLngs: ['en', 'es', 'ro', 'ko'],
    supportedLngs: ['cn', 'en', 'ko', 'pt', 'vie'],
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })
export default i18n
