import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import { Layout } from './elements'
import { Box, Tab, Tabs } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classNames from 'classnames'

const styles = (theme) => ({
  root: {
    width: 'inherit',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px 12px 0px 0px',
    position: 'relative'
  },
  tabs: {
    padding: '16px 16px 0px 16px',
    borderRadius: '12px 12px 0px 0px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 100
  },
  tab: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    padding: '0px 0px 12px 0px',
    color: theme.palette.text.primary
  },
  privateTabIndicator: {
    backgroundColor: theme.palette.primary.main,
    height: '4px'
  },
  selectedTab: {
    color: theme.palette.primary.main
  },
  disabledTab: {
    color: theme.palette.text.secondary
  },
  marginBottom: {
    marginBottom: '16px'
  },
  content: {
    marginTop: '64px',
    overflow: 'auto'
  },
  bolded: {
    fontFamily: 'Jost-700-Bold'
  },
  boxShadow: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.10), 0 2px 4px 0 rgba(0, 0, 0, 0.10)'
  },
  desktopWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '100%'
  },
  desktopContainer: {
    width: '48%',
    margin: '10px 1%',
    height: 'calc(100vh - 56px)',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px',
    position: 'relative'
  }
})

const MobileLayout = ({t, classes, marketTab, accountTab, user, contentRef, tabIndex, handleChange, isScrolled }) => {
  return (
    <Layout>
        <div className={classes.root}>
          <Tabs
            className={classNames(
              classes.tabs,
              isScrolled ? classes.boxShadow : null
            )}
            variant='fullWidth'
            value={tabIndex}
            onChange={handleChange}
            classes={{ indicator: classes.privateTabIndicator }}
          >
            <Tab
              className={classes.tab}
              classes={{ selected: classes.selectedTab }}
              label={t('HOME.MARKET_TAB.MARKET_TITLE')}
            />
            <Tab
              className={classes.tab}
              classes={{
                selected: classes.selectedTab,
                disabled: classes.disabledTab
              }}
              disabled={!user}
              label={t('HOME.MARKET_TAB.ACCOUNT_TITLE')}
            />
          </Tabs>
          <Box className={classes.content} ref={contentRef} id='tabContent'>
            {marketTab}
            {user && accountTab}
          </Box>
        </div>
      </Layout>
  )
}

export default withTranslation()(withStyles(styles)(MobileLayout))


MobileLayout.propTypes = {
  marketTab: PropTypes.object,
  accountTab: PropTypes.object,
  user: PropTypes.object,
  tabIndex: PropTypes.number,
  handleChange: PropTypes.func,
  isScrolled: PropTypes.bool,
}
