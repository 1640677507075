import React, { useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { Grid, Typography, withStyles } from '@material-ui/core'
import classNames from 'classnames'

const styles = (theme) => ({
  contentRight: {
    fontSize: '16px',
    lineHeight: '20px',
    color: `${theme.palette.neutral.lightGrey}!important`
  },
  contentRightGreen: {
    color: `${theme.palette.neutral.lightGreen}!important`,
    fontFamily: 'Jost-600-Semi'
  },
  contentRightRed: {
    color: `${theme.palette.neutral.lightRed}!important`,
    fontFamily: 'Jost-600-Semi'
  },
  contentRightYellow: {
    color: `${theme.palette.common.gold}!important`,
    fontFamily: 'Jost-600-Semi'
  },
  contentRightGreyOut: {
    color: `${theme.palette.text.placeholder}!important`
  },
  healthFactor: {
    paddingBottom: '15px',
  }
})

const HeaderHealthFactor = ({ t, classes, riskFactor }) => {

  const scaleToClass = useRef({
    0: 'contentRightGreen',
    1: 'contentRightYellow',
    2: 'contentRightRed',
    3: 'contentRightGreyOut'
  })

  return (
      <Grid item className={classes.healthFactor}>
        <Typography
            variant="body1"
            className={classNames(
                classes.contentRight,
                riskFactor[0].scale >= 0 &&
                classes[scaleToClass.current[riskFactor[0].scale]]
            )}
        >
          {riskFactor[0].value}
        </Typography>
      </Grid>
  )
}

export default withTranslation()(withStyles(styles)(HeaderHealthFactor))
