import React, { useContext } from 'react'
import { withTranslation } from 'react-i18next'
import Drawer from '@material-ui/core/Drawer'
import withStyles from '@material-ui/core/styles/withStyles'
import AppContext from '../AppContext'
import { useContractKit } from '@celo-tools/use-contractkit'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@material-ui/core'
import { Link } from 'react-router-dom'

const styles = (theme) => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary
  }
})

const AppMenu = (props) => {
  const { t, classes } = props

  const { user, setUser } = useContext(AppContext)
  const { destroy } = useContractKit()

  const handleLogOut = async () => {
    await destroy()
    setUser('')
  }

  const renderMenu = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={props.closeMenu}
      onKeyDown={props.closeMenu}
    >
      <List>
        <Link to="/" className={classes.link}>
          <ListItem button>
            <ListItemText primary={t('APPMENU.HOME')} />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/deposit" className={classes.link}>
          <ListItem button>
            <ListItemText primary={t('APPMENU.DEPOSIT')} />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/borrow" className={classes.link}>
          <ListItem button>
            <ListItemText primary={t('APPMENU.BORROW')} />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/repay" className={classes.link}>
          <ListItem button>
            <ListItemText primary={t('APPMENU.REPAY')} />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/redeem" className={classes.link}>
          <ListItem button>
            <ListItemText primary={t('APPMENU.WITHDRAW')} />
          </ListItem>
        </Link>
        <Divider />
        <Link to="/settings" className={classes.link}>
          <ListItem button>
            <ListItemText primary={t('APPMENU.SETTINGS')} />
          </ListItem>
        </Link>
        <Divider />
        {user && user.walletType === 'VALORA' && (
          <Link to="/about-valora" className={classes.link}>
            <ListItem button>
              <ListItemText primary={t('APPMENU.ABOUT_VALORA')} />
            </ListItem>
          </Link>
        )}
        <Divider />
        {user && (
          <ListItem button onClick={handleLogOut}>
            <ListItemText primary={t('APPMENU.LOGOUT')} />
          </ListItem>
        )}
      </List>
    </div>
  )

  return (
    <Drawer open={props.opened} onClose={props.closeMenu}>
      {renderMenu()}
    </Drawer>
  )
}

export default withTranslation()(withStyles(styles)(AppMenu))
