import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Dimensions } from 'react-native'

const styles = (theme) => ({
  contentItem: {
    height: '56px'
  },
  contentLeft: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Jost-500-Medium',
    fontWeight: 500
  },
  contentRightWithOneRow: {
    textAlign: 'right',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400,
    color: theme.palette.neutral.lightGrey
  },
  contentRightWithTwoRows: {
    textAlign: 'right',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
  },
  contentRight2: {
    textAlign: 'right',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'normal',
    color: theme.palette.neutral.lightGrey
  },
  marginBottom: {
    marginBottom: '20px',
  },
  paddingTop8: {
    paddingTop: '8px'

  },
  paddingTop18: {
    paddingTop: '18px'
  },
  maxWidthForMobile: {
	maxWidth: '230px'
  },
  semiBold: {
    fontFamily: 'Jost-600-Semi',
    fontWeight: 600
  }
})

const PopupRow = ({ classes, title, upperTextValue, lowerTextValue, valueIcon, statusColor, semiBold }) => {
  const deviceWidth = Dimensions.get('window').width

  return (
    <Grid
      container
      direction='row'
      justify='space-between'
      className={classes.contentItem}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        className={ classes.contentItem }
      >
        <Grid item className={
          classNames(!!lowerTextValue ? classes.paddingTop8 : classes.paddingTop18) }>
          <Typography variant="body2" className={ classes.contentLeft }>
            { title }
          </Typography>
        </Grid>
        <Grid item className={
          classNames(!!lowerTextValue ? classes.paddingTop8 : classes.paddingTop18) }>
          <Grid container direction="column">
            <Grid item className={ classNames(!!!upperTextValue && classes.marginBottom) }>
              <Typography variant="body1"
                          style={statusColor}
                          className={
                            classNames(
                              !!lowerTextValue ?
                                classes.contentRightWithTwoRows :
                                classes.contentRightWithOneRow,
							  deviceWidth < 500 ? classes.maxWidthForMobile : '',
                              semiBold && classes.semiBold,
                            ) }
              >
                { upperTextValue }
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" className={ classNames(classes.contentRight2) }>
                { lowerTextValue }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

PopupRow.prototype = {
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  valueIcon: PropTypes.elementType,
  statusColor: PropTypes.object,
  semiBold: PropTypes.bool
}

export default withStyles(styles)(PopupRow)
