import CeloBorrowIcon from '../assets/images/borrowed-CELO.svg'
import { default as cEurBorrowIcon, default as cUsdBorrowIcon } from '../assets/images/borrowed-cUSD.svg'
import CeloIcon from '../assets/images/celo.svg'
import cEurIcon from '../assets/images/ceur.svg'
import cUsdIcon from '../assets/images/cusd.svg'
import CeloDepositIcon from '../assets/images/deposited-CELO.svg'
import { default as cEurDepositIcon, default as cUsdDepositIcon } from '../assets/images/deposited-cUSD.svg'
import EurIcon from '../assets/images/eur-icon.svg'
import ChineseLanguageIcon from '../assets/images/lang-cn.svg'
import EnglishLanguageIcon from '../assets/images/lang-en.svg'
import SpanishLanguageIcon from '../assets/images/lang-es.svg'
import KoreanLanguageIcon from '../assets/images/lang-ko.svg'
import PortugueseLanguageIcon from '../assets/images/lang-pt.svg'
import VietnameseLanguageIcon from '../assets/images/lang-vie.svg'
import MooIcon from '../assets/images/moo-fill.svg'
import CeloRepayIcon from '../assets/images/repay-CELO.svg'
import { default as cEurRepayIcon, default as cUsdRepayIcon } from '../assets/images/repay-cUSD.svg'
import UsdIcon from '../assets/images/usd-icon.svg'
import ValoraCircle from '../assets/images/valora-circle.png'
import CeloWithdrawIcon from '../assets/images/withdrew-CELO.svg'
import { default as cEurWithdrawIcon, default as cUsdWithdrawIcon } from '../assets/images/withdrew-cUSD.svg'

export const DEFAULT_CURRENCY = 'CELO'

export const CURRENCIES = {
  celo: {
    key: DEFAULT_CURRENCY,
    text: 'CELO',
    icon: CeloIcon,
    depositIcon: CeloDepositIcon,
    withdrawIcon: CeloWithdrawIcon,
    borrowIcon: CeloBorrowIcon,
    repayIcon: CeloRepayIcon,
	decimals: 3
  },
  cusd: {
    key: 'cUSD',
    text: 'cUSD',
    icon: cUsdIcon,
    depositIcon: cUsdDepositIcon,
    withdrawIcon: cUsdWithdrawIcon,
    borrowIcon: cUsdBorrowIcon,
    repayIcon: cUsdRepayIcon,
	decimals: 2
  },
  ceur: {
    key: 'cEUR',
    text: 'cEUR',
    icon: cEurIcon,
    depositIcon: cEurDepositIcon,
    withdrawIcon: cEurWithdrawIcon,
    borrowIcon: cEurBorrowIcon,
    repayIcon: cEurRepayIcon,
	decimals: 2
  }
}

export const MOO_CURRENCY = {
  key: 'MOO',
  text: 'MOO',
  icon: MooIcon
}

export const MOOLA_CURRENCIES = {
  CELO: 'mCELO',
  cUSD: 'mcUSD',
  cEUR: 'mcEUR'
}

export const FIAT_CURRENCIES = {
  usd: {
    key: 'usd',
    text: 'USD',
    description: 'United States Dollar (USD)',
    icon: UsdIcon,
    lngKey: 'CURRENCIES.USD'
  },
  eur: {
    key: 'eur',
    text: 'EUR',
    description: 'Euro (EUR)',
    icon: EurIcon,
    lngKey: 'CURRENCIES.EUR'
  }
}

export const LANGUAGES = {
  en: {
    key: 'en',
    text: 'English',
    icon: EnglishLanguageIcon,
    active: true,
    lngKey: 'LANGUAGES.ENGLISH'
  },
  // es: {
  //   key: 'es',
  //   text: 'Español',
  //   icon: SpanishLanguageIcon,
  //   active: true,
  //   lngKey: 'LANGUAGES.SPANISH'
  // },
  // ro: {
  //   key: 'ro',
  //   text: 'Romanian',
  //   icon: SpanishLanguageIcon,
  //   active: true,
  //   lngKey: 'LANGUAGES.ROMANIAN'
  // },
  ko: {
    key: 'ko',
    text: 'Korean',
    icon: KoreanLanguageIcon,
    active: true,
    lngKey: 'LANGUAGES.KOREAN'
  },
  pt: {
    key: 'pt',
    text: 'Portuguese',
    icon: PortugueseLanguageIcon,
    active: true,
    lngKey: 'LANGUAGES.PORTUGUESE'
  },
  vie: {
    key: 'vie',
    text: 'Vietnamese',
    icon: VietnameseLanguageIcon,
    active: true,
    lngKey: 'LANGUAGES.VIETNAMESE'
  },
  cn: {
    key: 'cn',
    text: 'Chinese',
    icon: ChineseLanguageIcon,
    active: true,
    lngKey: 'LANGUAGES.CHINESE'
  }
}

export const PROVIDERS = [
  {
    name: 'Valora',
    icon: ValoraCircle,
    selectHandler: () => {}
  }
]

export const TRANSACTIONS = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  BORROW: 'borrow',
  REPAY: 'repay',
  LIQUIDATE: 'liquidate'
}

export const RISK_FACTOR_STATE = {
  HEALTHY: 0,
  RISKY: 1,
  LIQUIDATED: 2
}

export const TRANSACTION_STATUS = {
  ERROR: 'error',
  PROCESSING: 'processing',
  SUCCESSFUL: 'successful'
}

export const SIGNS = {
  EURO: '€',
  DOLLAR: '$',
  PLUS: '+',
  MINUS: '-',
  PERCENTAGE: '%'
}

export const LOAN_TERMS = {
  STABLE: 1,
  VARIABLE: 2
}

export const MIN_VALUE_NUMBER = 0.0001

/*
  The security fee will be substracted for user's wallet balance for deposit and repay action
  to ensure that user has enough tokens to execute the transaction
  This is used to calculate the Max Amount values in UI
*/
export const SECURITY_FEE = 0.01

/*
  The maximum procent that a user can borrow from it's collateral
*/
export const MAX_LTV = 75

export const CONTRACT_KIT = 'contractkit'

export const MOO_LOGIN = 'MOO_login'

export const DESKTOP_ACTION_SUCCESS = 'desktopActionSuccess'

export const ACCOUNT_PRICES_TAB_SWITCH = 'accountPricesTabSwitch'

export const DESKTOP_CURRENCY_CHANGED = 'desktopCurrencyChanged'

/*
  The value in miliseconds after which we refresh account data on desktop version to ensure
  that data is retrieved correctly from backend API.
  If this value is set too low, after the user performs an action, the API could return data that doesn't contain
  the action's result
*/
export const REFRESH_DATA_DELAY = 2000

export const REFRESH_DATA_EXTRA_DELAY = 5000
