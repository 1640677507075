import React, { useContext, useEffect, useState } from 'react'
import Switch from '@material-ui/core/Switch'
import withStyles from '@material-ui/core/styles/withStyles'
import { getContract } from '../../root'
import PropTypes from 'prop-types'
import useAccountReserveData from '../hooks/useAccountReserveData'
import AppContext from '../AppContext'
import useTxRequest from '../hooks/useTxRequest'
import useAccountData from '../hooks/useAccountData'
import { isCollateralDisabled } from '../../api/requests'
import BottomPopup from '../BottomPopup'
import CloseIcon from '../../assets/images/close.svg'
import { withTranslation } from 'react-i18next'
import { isMobile } from '../../utils/browser'
import { DESKTOP_ACTION_SUCCESS } from '../../utils/constants'
import { EventRegister } from 'react-native-event-listeners'

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: '#fff',
    '&$checked': {
      transform: 'translateX(21px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none'
  },
  track: {
    borderRadius: 20 / 2,
    opacity: 0.3,
    backgroundColor: '#2E3338'
  },
  checked: {}
}))(Switch)

const styles = () => ({
  disabledState: {
    opacity: 0.5
  }
})

const IsCollateralSwitch = ({
  t,
  classes,
  currency,
  userAddress,
  exchangeRates,
  forceRefresh
}) => {
  const { handleTxRequest } = useTxRequest()
  const [confirmPopup, setConfirmPopup] = useState(false)
  const [isChecked, setIsChecked] = useState(true)
  const context = useContext(AppContext)

  const [{ accountReserveData, isLoading: isAccountReserveLoading }] =
    useAccountReserveData(currency, forceRefresh)
  const [{ accountData, isLoading: isAccountDataLoading }] =
    useAccountData(forceRefresh)

  useEffect(() => {
    if (!isAccountReserveLoading) {
      setIsChecked(accountReserveData.isCollateral)
    }
  }, [isAccountReserveLoading])
  const [isDisabled, setIsDisabled] = useState(true)

  const handleSwitch = async () => {
    setConfirmPopup(false)
    await processOperation(!accountReserveData?.isCollateral)
	if (!isMobile) EventRegister.emit(DESKTOP_ACTION_SUCCESS)
  }

  const processOperation = async (type) => {
    const { lendingPool, reserves } = await getContract()
    try {
      const txObject = lendingPool.methods.setUserUseReserveAsCollateral(
        reserves[currency],
        type
      )

      const txParamCelo = {
        tx: txObject,
        from: userAddress,
        to: lendingPool.options.address,
        estimatedGas: 2000000,
        type: isChecked ? t('COLLATERAL.REMOVE') : t('COLLATERAL.ADD')
      }

      let txParams = [txParamCelo]

      await handleTxRequest(
        !isChecked ? 'setCollateral' : 'removeCollateral',
        !isChecked ? t('COLLATERAL.REMOVE') : t('COLLATERAL.ADD'),
        currency,
        0,
        txParams
      )
    } catch (error) {
      console.log(error)
      context.showError(error.message)
    }
  }

  useEffect(() => {
    if (
      !isAccountReserveLoading &&
      !isAccountDataLoading &&
      accountReserveData &&
      accountData &&
      exchangeRates
    ) {
      const isDisabled = isCollateralDisabled({
        currency,
        isCollateral: accountReserveData.isCollateral,
        amount: accountReserveData.deposited,
        totalCollateral: accountData.totalCollateral,
        totalBorrow: accountData.totalBorrow,
        totalFees: accountData.totalFees,
        exchangeRates
      })
      setIsDisabled(isDisabled)
    }
  }, [
    isAccountReserveLoading,
    isAccountDataLoading,
    accountReserveData,
    accountData,
    exchangeRates
  ])

  return (
    <>
      <AntSwitch
        className={isDisabled ? classes.disabledState : ''}
        disabled={false}
        checked={isChecked}
        onChange={() => setConfirmPopup(true)}
        name="isCollateral"
      />
      {confirmPopup && (
        <BottomPopup
          show={true}
          label={t(
            isDisabled ? 'COLLATERAL.REMOVE_INFO' : 'COLLATERAL.CONFIRM_INFO',
            {
              state: isChecked ? t('COLLATERAL.REMOVE') : t('COLLATERAL.ADD'),
              currency: currency
            }
          )}
          onClose={() => setConfirmPopup(false)}
          icon={CloseIcon}
          children={
            !isDisabled &&
            t('COLLATERAL.FEE', {
              fee: '<0.01'
            })
          }
          secondaryButtonTitle={t('GENERAL.BACK')}
          secondaryButtonClick={() => setConfirmPopup(false)}
          primaryButtonTitle={!isDisabled && t('GENERAL.CONFIRM')}
          primaryButtonClick={() => handleSwitch(false)}
          disabled={isDisabled}
        />
      )}
    </>
  )
}

IsCollateralSwitch.propTypes = {
  currency: PropTypes.string,
  balance: PropTypes.any,
  userAddress: PropTypes.string,
  disabled: PropTypes.bool
}

export default withTranslation()(withStyles(styles)(IsCollateralSwitch))
