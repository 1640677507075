import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import MuiCard from '@material-ui/core/Card'
import MuiCardContent from '@material-ui/core/CardContent'
import MuiIcon from '@material-ui/core/Icon'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Motion, spring } from 'react-motion'
import { withTranslation } from 'react-i18next'

import ArrowIcon from '../../assets/images/card-arrow.svg'
import BottomPopup from '../BottomPopup'
import CloseIcon from '../../assets/images/close.svg'
import ExternalLinkIcon from '../../assets/images/external-link.svg'
import InfoIcon from '../InfoIcon'

const styles = (theme) => ({
  root: {
    background: '#FFFFFF',
    border: '1px solid #EDEEEF',
    borderRadius: '8px',
    overflow: 'inherit',
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  contentHeader: {
    backgroundColor: '#F8F9F9'
  },
  header: {
    height: '100%'
  },
  headerText: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Jost-700-Bold',
    fontWeight: 700
  },
  headerValue: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Jost-700-Bold',
    fontWeight: 700
  },
  headerIcon: {
    marginRight: '16px'
  },
  headerImage: {
    position: 'relative',
    top: '3px'
  },
  arrowImage: {
    position: 'relative'
  },
  contentItem: {
    marginBottom: '16px'
  },
  contentLeft: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Jost-600-Semi'
  },
  contentRight: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.neutral.lightGrey
  },
  contentRightGreen: {
    color: theme.palette.neutral.lightGreen,
    fontFamily: 'Jost-600-Semi'
  },
  contentRightRed: {
    color: theme.palette.neutral.lightRed,
    fontFamily: 'Jost-600-Semi'
  },
  contentRightYellow: {
    color: theme.palette.common.gold,
    fontFamily: 'Jost-600-Semi'
  },
  contentRightGreyOut: {
    color: theme.palette.text.placeholder
  },
  bolded: {
    fontFamily: 'Jost-700-Bold'
  },
})

const Card = ({ t, classes, cardData, collapsable }) => {
  const { header, content } = cardData || {}
  const { icon, text, value, info } = header || {}
  const [collapsed, setCollapsed] = useState(true)
  const [selectedItem, setSelectedItem] = useState(null)
  const scaleToClass = useRef({
    0: 'contentRightGreen',
    1: 'contentRightYellow',
    2: 'contentRightRed',
    3: 'contentRightGreyOut'
  })

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  return (
    <>
      {cardData && (
        <MuiCard className={classes.root} variant="outlined">
          <MuiCardContent className={classes.content}>
            {header && (
              <Box
                className={classes.contentHeader}
                px={2}
                py={2}
                onClick={() => {
                  if (collapsable) toggle()
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  className={classes.header}
                  onClick={() => {
                    if (info) {
                      setSelectedItem(header)
                    }
                  }}
                >
                  <Grid item>
                    <Grid container direction="row" alignItems="center">
                      {collapsable && (
                        <Grid item className={classes.headerIcon}>
                          <MuiIcon style={{ paddingBottom: 0 }}>
                            <Motion style={{ x: spring(collapsed ? 0 : 10) }}>
                              {({ x }) => (
                                <img
                                  src={ArrowIcon}
                                  height={12}
                                  width={6}
                                  className={classes.arrowImage}
                                  style={{
                                    transform: `rotate(${x * 9}deg)`
                                  }}
                                />
                              )}
                            </Motion>
                          </MuiIcon>
                        </Grid>
                      )}
                      {icon && (
                        <Grid item className={classes.headerIcon}>
                          <MuiIcon>
                            <img
                              src={icon}
                              height={40}
                              width={40}
                              className={classes.headerImage}
                            />
                          </MuiIcon>
                        </Grid>
                      )}
                      <Grid item>
                        <Grid container alignItems={'center'}>
                          <Typography
                            variant="body2"
                            className={classes.headerText}
                          >
                            {text}
                          </Typography>
                          {info && <InfoIcon/>}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" className={classes.headerValue}>
                      {value}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box p={2} pb={0}>
              {content.map((item, index) => {
                // item is displayed if:
                // - card is not collapsible
                // - item doesn't have the collapsed flag (meaning it should always be displayed)
                // - card is collapsed (closed) and item has collapsed flag to true
                // - card is not collapsed (opened) and item has collapsed flag to false
                return (
                  ((collapsable &&
                    (item.collapsed == null || (!collapsed && item.collapsed === false) || (collapsed && item.collapsed === true))) ||
                    !collapsable) && (
                    <React.Fragment key={`f${index}`}>
                      <Grid
                        key={index}
                        container
                        direction="row"
                        justify="space-between"
                        className={classes.contentItem}
                        onClick={() => {
                          if (item.info?.content) {
                            setSelectedItem(item)
                          }
                        }}
                      >
                        <Grid item>
                          <Grid
                            container
                            direction={'row'}
                            alignItems={'center'}
                            onClick={() => {
                              if (item?.info) {
                                setSelectedItem(item)
                              }
                            }}
                          >
                            <Typography
                              variant="body2"
                              className={classes.contentLeft}
                            >
                              {item.text}
                            </Typography>
                            {item.info && <InfoIcon
                              title={item.text}
                              text={item.info?.content}
                            />}
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body1"
                            className={classNames(
                              classes.contentRight,
                              item.scale >= 0 &&
                              classes[scaleToClass.current[item.scale]]
                            )}
                          >
                            {item.value}
                          </Typography>
                        </Grid>
                      </Grid>
                      {item.divider && (content.length - 1 > index) &&(
                        <Box mb={2}>
                          <Divider key={`divider${index}`}/>
                        </Box>
                      )}
                    </React.Fragment>
                  )
                )
              })}
            </Box>
          </MuiCardContent>

          {selectedItem && (
            <BottomPopup
              show={true}
              label={selectedItem.text}
              onClose={() => setSelectedItem(null)}
              icon={CloseIcon}
              children={selectedItem.info.content}
              primaryButtonTitle={t('GENERAL.DONE')}
              primaryButtonClick={() => setSelectedItem(null)}
              secondaryButtonTitle={selectedItem.info.secondaryButtonTitle}
              secondaryButtonClick={selectedItem.info.secondaryButtonClick}
              endIconSecondaryButton={ExternalLinkIcon}
            />
          )}
        </MuiCard>
      )}
    </>
  )
}

Card.propTypes = {
  classes: PropTypes.object,
  cardData: PropTypes.shape({
    header: PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
      info: PropTypes.shape({
        content: PropTypes.any,
        secondaryButtonTitle: PropTypes.string,
        secondaryButtonClick: PropTypes.func
      })
    }),
    content: PropTypes.arrayOf(
      PropTypes.shape({
        collapsed: PropTypes.bool,
        text: PropTypes.string,
        value: PropTypes.string,
        divider: PropTypes.bool,
        scale: PropTypes.number,
        info: PropTypes.shape({
          content: PropTypes.any,
          secondaryButtonTitle: PropTypes.string,
          secondaryButtonClick: PropTypes.func
        })
      })
    )
  })
}

export default withTranslation()(withStyles(styles)(Card))
