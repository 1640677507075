import React from 'react'
import { StyleSheet, View } from 'react-native';
import styled from 'styled-components';

// Utils
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexFlow: 'column nowrap',
    backgroundColor: '#fff',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  }
});

export const Wrapper = styled.div`
  max-width: 620px;
  width: 100%;
`

// Component
const withView = (WrappedComponent) => {
  return React.memo((props) => (
    <View style={styles.container}>
      <Wrapper>
        <WrappedComponent {...props} key={Date().toString()} />
      </Wrapper>
    </View>
  ))
}

export default withView
