import Grid from '@material-ui/core/Grid'
import MuiIcon from '@material-ui/core/Icon'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import * as PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import DepositIcon from '../../../assets/images/deposit.svg'
import DirectedArrowsIcon from '../../../assets/images/directed-arrows.svg'
import { currencyFormatter } from '../../../utils'
import { useGetSettingsCurrency } from '../../../api'
import MigrateArrow from '../../../assets/images/migrate-arrow.svg'
import FlowButton from '../../FlowButton'
import { withTranslation } from 'react-i18next'
import MooToken from "../../Moo-token";
import config from '../../../config'

const styles = () => ({
  connected: {
    '& .copy-container': {
      position: 'relative',
      '& .directed-icon': {
        position: 'absolute',
        top: '14px',
        right: '12px',
        width: 'auto',
        height: 'auto'
      }
    },
    '& .copy-bold': {
      fontFamily: 'Jost-700-Bold'
    }
  },
  mooClaim: {
    marginTop: '10px'
  },
  gotoV2: {
	width: '290px',
	'& > *': {
	  width: '100%'
  	}
  }
})

const ConnectedState = ({ t, classes, totalValue, mooToken }) => {
  const history = useHistory()
  const { data: currentCurrency } = useGetSettingsCurrency()

  return (
    <Grid
      container
      direction={'column'}
      alignItems={'center'}
      className={classes.connected}
    >
      <Grid item>
        <Typography className={'header'}>
          {totalValue != null && currencyFormatter(currentCurrency)(totalValue)}
        </Typography>
      </Grid>
      {/* <Grid item className={'copy-container'}>
        <Typography className={'copy'}>
          {t('HOME.APPHEADER.FIRST_DEPOSIT')}&nbsp;
          <Typography component={'span'} className={'copy-bold'}>
            {t('HOME.APPHEADER.FIRST_DEPOSIT.DEPOSIT')}
          </Typography>
        </Typography>
        <MuiIcon className={'directed-icon'}>
          <img
            alt={t('HOME.APPHEADER.HOW_ABOUT_FIRST_DEPOSIT')}
            src={DirectedArrowsIcon}
          />
        </MuiIcon>
      </Grid> */}
      <Grid item className={classes.gotoV2}>
	  	<FlowButton
			label={t('V2.GO_TO')}
			icon={MigrateArrow}
			isTransparent={false}
			onClick={() => window.open(config.urlV2, "_self")}
		/>
        {/* <FlowButton
          label={t('GENERAL.DEPOSIT')}
          icon={DepositIcon}
          isTransparent={false}
          onClick={() => {
            history.push('/deposit')
          }}
        /> */}
      </Grid>

      {/* <Grid item className={classes.mooClaim}>
        {mooToken && !mooToken.isClaimed && <MooToken mooToken={mooToken}/>}
      </Grid> */}
    </Grid>
  )
}

ConnectedState.propTypes = {
  totalValue: PropTypes.number.isRequired,
  mooToken: PropTypes.object,
}

export default withTranslation()(withStyles(styles)(ConnectedState))
