import { useEffect, useContext, useState } from 'react'
import AppContext from '../AppContext'
import { getContract } from '../../root'
import { CURRENCIES } from '../../utils/constants'

const userAccountIsCollateral = (forceRefresh) => {
  const { user } = useContext(AppContext)
  const [isLoading, setLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [accountIsCollateralData, setAccountIsCollateralData] = useState(null)

  const loadAllCollateral = async () => {
    setIsSuccess(false)
    try {
      const collaterals = await Promise.all(
        Object.values(CURRENCIES).map(
          async (currency) => await getIsCollateral(currency.key)
        )
      )
      setAccountIsCollateralData(collaterals)
      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsSuccess(false)
    }
    setLoading(false)
  }

  const getIsCollateral = async (txType) => {
    const { lendingPool, reserves } = await getContract()
    const data = await lendingPool.methods
      .getUserReserveData(reserves[txType], user.address)
      .call()

    return {
      currency: txType,
      isCollateral: data?.usageAsCollateralEnabled
    }
  }

  useEffect(() => {
    if (user) {
      loadAllCollateral()
    }
  }, [user, forceRefresh])

  return { accountIsCollateralData, isLoading, isSuccess }
}

export default userAccountIsCollateral
