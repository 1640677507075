import { Box, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import BN from 'bignumber.js'
import { formatNumberWithoutRounding } from '../../utils/format'
import { CURRENCIES } from "../../utils/constants";

const sliderHeight = '5px'

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  sliderContainer: {
    height: sliderHeight,
    '& .MuiSlider-rail': {
      height: sliderHeight,
      background: 'linear-gradient(90deg, #35d07f, #fbcc5c, #fb7c6d)',
      opacity: 1,
      borderRadius: '3px'
    },
    '& .MuiSlider-mark': {
      height: sliderHeight,
      color: theme.palette.common.white
    },
    '& .MuiSlider-thumb': {
      height: '26px',
      width: '26px',
      marginTop: '-11px',
      marginLeft: '-13px',
      backgroundColor: theme.palette.common.white,
      boxShadow:
        '0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 16px rgba(0, 0, 0, 0.04)',
      '&.MuiSlider-active': {
        boxShadow: '0px 0px 0px 14px rgba(0, 0, 0, 0.04)'
      }
    }
  },
  label: {
    '& *': {
      fontSize: '14px',
      fontWeight: 700,
      fontFamily: 'Jost-700-Bold'
    }
  },
  leftLabel: {
    color: theme.palette.neutral.lightGreen
  },
  rightLabel: {
    color: theme.palette.neutral.lightRed
  }
})

const ScaleSlider = ({
                       classes,
                       selectedCurrency,
                       max,
                       splitCount,
                       name,
                       leftLabel,
                       rightLabel,
                       onValueChange
                     }) => {

  const [marks, setMarks] = useState([])
  const [step, setStep] = useState(1)

  useEffect(() => {
    if (max) {
      const newStep = BN(max).dividedBy(100)
      setStep(newStep)

      if (splitCount) {
        const newMarks = []
        const splitAmount = max / (splitCount + 1)
        for (let i = 1; i <= splitCount; i++) {
          marks.push({
            value: splitAmount * i
          })
        }
        setMarks(newMarks)
      }
    }

  }, [splitCount, max])

  return (
    <Box className={classes.root}>
      <Field
        name={name}
        render={({ input }) => (
          <Slider
            className={classes.sliderContainer}
            min={0}
            max={Number(max)}
            step={Number(step)}
            marks={marks}
            value={Number(input.value)}
            track={false}
            onChange={(event, value) => {
              // make sure no value greater than max is allowed, in case of number with a lot of decimals
              const tempValue = BN(value).isGreaterThan(max) ? max : value
              if (onValueChange) {
                onValueChange(tempValue)
              }
              input.onChange(formatNumberWithoutRounding(tempValue, CURRENCIES[selectedCurrency.text.toLowerCase()]?.decimals))
            }}
          />
        )}
      />
      {(leftLabel || rightLabel) && (
        <Grid container justify={'space-between'} className={classes.label}>
          <Grid item>
            <Typography className={classes.leftLabel}>{leftLabel}</Typography>
          </Grid>
          {rightLabel && (
            <Grid item>
              <Typography className={classes.rightLabel}>
                {rightLabel}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

ScaleSlider.propTypes = {
  classes: PropTypes.object,
  max: PropTypes.any,
  splitCount: PropTypes.number,
  name: PropTypes.string.isRequired,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  onValueChange: PropTypes.func
}

export default withStyles(styles)(ScaleSlider)
