import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { EventRegister } from 'react-native-event-listeners'
import { useParams } from 'react-router-dom'
import AppContext from '../../components/AppContext'
import HomeAccount from '../../components/HomeAccount'
import HomeMarket from '../../components/HomeMarket'
import { isMobile } from '../../utils/browser'
import { ACCOUNT_PRICES_TAB_SWITCH } from '../../utils/constants'
import DesktopLayout from './desktopLayout'
import MobileLayout from './mobileLayout'

const HomeTabs = ({
  t,
  classes,
  contentRef,
  currency,
  mooToken,
  setRiskFactorValue,
  accountTabSelected,
  isScrolled,
  currentState
}) => {
  const { user } = useContext(AppContext)
  const { tab } = useParams()
  const [tabIndex, setTabIndex] = useState(0)
  const [forceRefresh, setForceRefresh] = useState(Date.now())

  useEffect(() => {
    if (!user) {
      setTabIndex(0)
    } else {
      setTabIndex(1)
    }
  }, [user])

  useEffect(() => {
    if (tab === 'account' && user) {
      setTabIndex(1)
    }
  }, [tab])

  useEffect(() => {
    const accountPricesTabSwitchListener = EventRegister.addEventListener(
		ACCOUNT_PRICES_TAB_SWITCH,
		() => setForceRefresh(Date.now())
    )
    return () => {
      EventRegister.removeEventListener(accountPricesTabSwitchListener)
    }
  }, [])

  const marketTab = (
    <TabPanel value={tabIndex} index={0}>
      <HomeMarket currency={currency} />
    </TabPanel>
  )

  const accountTab = (
    <TabPanel value={tabIndex} index={1}>
      <HomeAccount
        user={user}
		forceRefresh={forceRefresh}
        mooToken={mooToken}
        currency={currency}
        classes={classes}
        setRiskFactorValue={setRiskFactorValue}
      />
    </TabPanel>
  )

  const handleChange = (event, newValue) => {
    setTabIndex(newValue)
    if (!isMobile && newValue === 1) accountTabSelected()
  }

  return isMobile ? (
    <MobileLayout
      marketTab={marketTab}
      accountTab={accountTab}
      user={user}
      contentRef={contentRef}
      tabIndex={tabIndex}
      handleChange={handleChange}
      isScrolled={isScrolled}
    />
  ) : (
    <DesktopLayout
      user={user}
      currentState={currentState}
      currency={currency}
      mooToken={mooToken}
      classes={classes}
      setRiskFactorValue={setRiskFactorValue}
    />
  )
}

export default withTranslation()(HomeTabs)

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={'16px'}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  mooToken: PropTypes.object,
  setRiskFactorValue: PropTypes.func,
  accountTabSelected: PropTypes.func,
  currentState: PropTypes.string,
  isScrolled: PropTypes.bool
}
