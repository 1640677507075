import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import * as PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import DepositIcon from '../../../assets/images/deposit.svg'
import BorrowIcon from '../../../assets/images/borrow.svg'
import WithdrawIcon from '../../../assets/images/withdraw.svg'
import { currencyFormatter } from '../../../utils'
import { useGetSettingsCurrency } from '../../../api'
import FlowButton from '../../FlowButton'
import { withTranslation } from 'react-i18next'
import AppContext from '../../AppContext'
import MooToken from '../../Moo-token'
import withStyles from "@material-ui/core/styles/withStyles";
import userAccountIsCollateral from '../../hooks/userAccountIsCollateral'
import MigrateArrow from '../../../assets/images/migrate-arrow.svg'
import config from '../../../config'

const styles = () => ({
  paddingBottom: {
    paddingBottom: '20px'
  },
  gotoV2: {
	  width: '290px',
	  '& > *': {
		width: '100%'
	}
  }
})
const FundedState = ({ t, classes, totalValue, mooToken }) => {
  const history = useHistory()
  const { data: currentCurrency } = useGetSettingsCurrency()
  const collateralData = userAccountIsCollateral()
  const hasCollateralFunds = collateralData && collateralData.accountIsCollateralData?.find(item => item.isCollateral)

  return (
    <Grid container direction={'column'} alignItems={'center'}>
      <Grid item className={classes.paddingBottom}>
        <Typography className={'header'}>
          {totalValue != null && currencyFormatter(currentCurrency)(totalValue)}
        </Typography>
      </Grid>

      <Grid item>
        <Grid container spacing={1} justify={'center'}>
		<Grid item className={classes.gotoV2}>
		 	<FlowButton
				label={t('V2.GO_TO')}
				icon={MigrateArrow}
				isTransparent={false}
				onClick={() => window.open(config.urlV2, "_self")}
			/>
		</Grid>
          {/* <Grid item>
            <FlowButton
              label={t('GENERAL.DEPOSIT')}
              icon={DepositIcon}
              isTransparent={true}
              onClick={() => {
                history.push('/deposit')
              }}
            />
          </Grid> */}
          <Grid item>
            <FlowButton
              label={t('GENERAL.WITHDRAW')}
              icon={WithdrawIcon}
              isTransparent={true}
              onClick={() => {
                history.push('/withdrawal')
              }}
            />
          </Grid>
          {/* <Grid item>
            <FlowButton
              label={t('GENERAL.BORROW')}
              icon={BorrowIcon}
              isTransparent={true}
              onClick={() => {
                history.push(hasCollateralFunds ? '/borrow' : '/no-collateral')
              }}
            />
          </Grid>
          {mooToken && !mooToken.isClaimed && (<MooToken mooToken={mooToken}/>
          )} */}
        </Grid>
      </Grid>
    </Grid>
  )
}

FundedState.propTypes = {
  totalValue: PropTypes.number.isRequired,
  mooToken: PropTypes.object
}

export default withTranslation()(withStyles(styles)(FundedState))
