import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${'' /* justify-content: space-between; */}
  ${'' /* height: 200px; */}
  margin: 60px;
`
export const Divider = styled.div`
  margin-top: 20px;
`
export const Label = styled.span`
  margin-top: 20px;
  font-weight: 500;
`
export const Info = styled.div`
  margin-top: 20px;
`