import React from 'react'
import withView from '../../components/HOCs/withView'
import requiresAuth from '../../components/HOCs/auth'
import { useHistory, useParams } from 'react-router-dom'
import { Text } from 'react-native'
import Button from '@material-ui/core/Button'
import { Container, TextBlock } from './elements'
import config from '../../config'


const TransactionConfrimedScreen = () => {
  const history = useHistory()
  const matchParams = useParams()

  const { txType, txCurrency, txValue, txHash } = matchParams

  const getMessage = () => {
    switch(txType) {
      case 'deposit': return `You just deposited ${txValue} ${txCurrency}`
      case 'borrow': return `You just borrowed ${txValue} ${txCurrency}`
      case 'repay': return `You just repayed ${txValue} ${txCurrency}`
      case 'redeem': return `You just withdrew ${txValue} ${txCurrency}`
      default: return 'Error'
    }
  }

  const viewTransaction = () => {
    window.open(config.explorerLink + txHash, '_blank')
  }

  const goHome = () => {
    history.push('/home/account')
  }

  return (
    <Container>
      <TextBlock>
        <Text>Confirmed.</Text>
        <Text>{getMessage()}</Text>
      </TextBlock>
      <Button color="secondary" variant="contained" onClick={viewTransaction}>View Transaction</Button>
      <Button color="secondary" variant="contained" onClick={goHome}>Home</Button>
    </Container>
  )
}

export default requiresAuth(withView(TransactionConfrimedScreen))
