import React from 'react'
import './i18n'
import { YellowBox } from 'react-native'
import * as Font from 'expo-font'
import { StatusBar } from 'expo-status-bar'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Alfajores, Mainnet } from '@celo-tools/use-contractkit'
import '@celo-tools/use-contractkit/lib/styles.css'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import ConnectWallet from './components/ConnectWallet'
import { QueryClient, QueryClientProvider } from 'react-query'

import AboutValoraScreen from './screens/AboutValora'
import BorrowFormScreen from './screens/BorrowForm'
import NewDepositScreen from './screens/NewDeposit'
import NewWithdrawalScreen from './screens/NewWithdrawal'
import RepayFormScreen from './screens/RepayForm'
import RedeemScreen from './screens/Redeem'
import RedeemFormScreen from './screens/RedeemForm'
import TransactionConfrimedScreen from './screens/TransactionConfirmed'
import WelcomeScreen from './screens/Welcome'
import Playground from './screens/Playground'
import HomeScreen from './screens/Home'
import NewHomeScreen from './screens/NewHome'
import NewHomeDesktopScreen from './screens/NewHomeDesktop'
import NewSettingsScreen from './screens/NewSettings'
import NewCurrenciesScreen from './screens/NewCurrencies'
import NewLanguagesScreen from './screens/NewLanguages'

import AppMenu from './components/AppMenu'
import AppContext from './components/AppContext'
import LoadingOverlay from './components/LoadingOverlay'
import ConfirmationSuccess from './components/ConfirmationSuccess'
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'

import { kit } from './root'
import config from './config'

import NewBorrowScreen from './screens/NewBorrow'
import NewRepayScreen from './screens/NewRepay'
import NoCollateral from './screens/NoCollateral'
import Terms from './screens/Terms'
import Privacy from './screens/PrivacyPolicy'
import Moo from './screens/Moo'
import {
  styles as ContractKitStyles,
  renderProvider,
  renderActionModal
} from './sdk/useContractKit'
import {
  useContractKit,
  ContractKitProvider
} from '@celo-tools/use-contractkit'
import '@celo-tools/use-contractkit/lib/styles.css'

import BottomPopup from './components/BottomPopup'
import CloseIcon from './assets/images/close.svg'
import Helmet from 'react-helmet'
import FavIcon from './assets/images/favicon.svg'
import { isMobile } from './utils/browser'

Sentry.init({
  dsn: config.sentryDsn,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

YellowBox.ignoreWarnings([
  "Warning: The provided value 'moz",
  "Warning: The provided value 'ms-stream"
])

// Utils
const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />

const history = createBrowserHistory()
history.listen((location) => {
  ReactGA.set({ page: location.location.pathname + location.location.hash })
  ReactGA.pageview(location.location.pathname + location.location.hash)
})

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Jost-400-Book'],
    button: {
      textTransform: 'none'
    }
  },
  breakpoints: {
    values: {
      tablet: 640,
      laptop: 1024,
      desktop: 1280
    }
  },
  palette: {
    common: {
      CELO: '#EEB82C',
      cUSD: '#35D07F',
      cEUR: '#35D07F',
      error: '#FB7C6D',
      gold: '#FBCC5C',
      link: '#3587ec'
    },
    primary: {
      main: '#3488EC'
    },
    neutral: {
      lightGrey: '#888888',
      lightGreen: '#35D07F',
      lightRed: '#FB7C6D',
      grey1: '#EDEEEF',
      white: '#FFFFFF'
    },
    text: {
      primary: '#2E3338',
      secondary: '#888888',
      lightGreen: '#35D07F',
      placeholder: '#DDDDDD',
      terms: '#EDEEEF',
      termsDesktop: '#2E3338'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 600,
        fontFamily: 'Jost-400-Book'
      }
    },
    MuiTypography: {
      body2: {
        fontFamily: 'Jost-500-Medium'
      }
    }
  }
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

// Component
export default class App extends React.Component {
  state = {
    showMenu: false,
    showError: false,
    showLoading: false,
    errorMessage: null,
    bottomPopupItem: null,
    user: JSON.parse(localStorage.getItem('valora_user'))
  }

  constructor(props) {
    super(props)
    if (this.state.user) {
      kit.defaultAccount = this.state.user.address
    }
  }

  async loadFonts() {
    await Font.loadAsync({
      'Jost-100-Hairline': require('./assets/fonts/Jost-100-Hairline.ttf'),
      'Jost-100-HairlineItalic': require('./assets/fonts/Jost-100-HairlineItalic.ttf'),
      'Jost-200-Thin': require('./assets/fonts/Jost-200-Thin.ttf'),
      'Jost-200-ThinItalic': require('./assets/fonts/Jost-200-ThinItalic.ttf'),
      'Jost-300-Light': require('./assets/fonts/Jost-300-Light.ttf'),
      'Jost-300-LightItalic': require('./assets/fonts/Jost-300-LightItalic.ttf'),
      'Jost-400-Book': require('./assets/fonts/Jost-400-Book.ttf'),
      'Jost-400-BookItalic': require('./assets/fonts/Jost-400-BookItalic.ttf'),
      'Jost-500-Medium': require('./assets/fonts/Jost-500-Medium.ttf'),
      'Jost-500-MediumItalic': require('./assets/fonts/Jost-500-MediumItalic.ttf'),
      'Jost-600-Semi': require('./assets/fonts/Jost-600-Semi.ttf'),
      'Jost-600-SemiItalic': require('./assets/fonts/Jost-600-SemiItalic.ttf'),
      'Jost-700-Bold': require('./assets/fonts/Jost-700-Bold.ttf'),
      'Jost-700-BoldItalic': require('./assets/fonts/Jost-700-BoldItalic.ttf'),
      'Jost-800-Heavy': require('./assets/fonts/Jost-800-Heavy.ttf'),
      'Jost-800-HeavyItalic': require('./assets/fonts/Jost-800-HeavyItalic.ttf'),
      'Jost-900-Black': require('./assets/fonts/Jost-900-Black.ttf'),
      'Jost-900-BlackItalic': require('./assets/fonts/Jost-900-BlackItalic.ttf'),
      'EBGaramond-600-SemiBold': require('./assets/fonts/EBGaramond-600-SemiBold.ttf'),
      'EBGaramond-700-Bold': require('./assets/fonts/EBGaramond-700-Bold.ttf')
    })
  }

  componentDidMount() {
    this.loadFonts()
    this.vhUpdate()

    // We listen to the resize event
    window.addEventListener('resize', this.vhUpdate)
    ReactGA.pageview(window.location.pathname + window.location.hash)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.vhUpdate)
  }

  vhUpdate = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  closeMenu = () => {
    this.setState({ showMenu: false })
  }

  openMenu = () => {
    this.setState({
      showMenu: true
    })
  }

  showErrorAlert = (errorMessage) => {
    const defaultError = 'Something went wrong. Please try again.'
    this.setState({
      showError: true,
      errorMessage: errorMessage || defaultError
    })
  }

  closeErrorAlert = () => {
    this.setState({ showError: false, errorMessage: null })
  }

  showLoading = () => {
    this.setState({ showLoading: true })
  }

  hideLoading = () => {
    this.setState({ showLoading: false })
  }

  setUser = (user) => {
    this.setState({ user })
    localStorage.setItem('valora_user', JSON.stringify(user))
  }

  setBottomPopupItem = (item) => {
    this.setState({ bottomPopupItem: item })
  }

  render() {
    ReactGA.initialize(config.gtmId)

    return (
      <>
        <Helmet>
          <link rel="icon" type="image/png" sizes="16x16" href={FavIcon} />
        </Helmet>
        <ContractKitStyles />
        <div
          style={{
            position: 'relative',
            height: '100%',
            background:
              'linear-gradient(180deg, rgba(46,51,56,1) 0%, rgba(25,28,31,1) 100%)'
          }}
        >
          <ContractKitProvider
            dapp={{
              name: 'Moola',
              description: 'Moola - Democratizing access to credit and yield',
              url: 'https://app.moola.market'
            }}
            connectModal={{ RenderProvider: renderProvider }}
            actionModal={{ render: renderActionModal }}
            networks={[config.isTestnet ? Alfajores : Mainnet]}
          >
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={theme}>
                <AppContext.Provider
                  value={{
                    showError: this.showErrorAlert,
                    showLoading: this.showLoading,
                    hideLoading: this.hideLoading,
                    user: this.state.user,
                    setUser: this.setUser,
                    bottomPopupItem: this.state.bottomPopupItem,
                    setBottomPopupItem: this.setBottomPopupItem
                  }}
                >
                  <Router history={history}>
                    <LoadingOverlay
                      opened={this.state.showLoading}
                      transparent
                    />
                    <Snackbar
                      open={this.state.showError}
                      autoHideDuration={5000}
                      onClose={this.closeErrorAlert}
                    >
                      <Alert onClose={this.closeErrorAlert} severity="error">
                        {this.state.errorMessage}
                      </Alert>
                    </Snackbar>
                    <Switch>
                      <Route
                        path={[
                          '/deposit/:txCurrency/:txValue/:txHash',
                          '/deposit/:txCurrency/:txValue',
                          '/deposit/:txCurrency'
                        ]}
                      >
                        <NewDepositScreen />
                      </Route>
                      <Route path="/deposit">
                        {isMobile ? (
                          <NewDepositScreen />
                        ) : (
                          <NewHomeDesktopScreen state="deposit" />
                        )}
                      </Route>
                      <Route path="/new-deposit">
                        <NewDepositScreen />
                      </Route>
                      <Route path="/withdrawal">
                        {isMobile ? (
                          <NewWithdrawalScreen />
                        ) : (
                          <NewHomeDesktopScreen state="withdrawal" />
                        )}
                      </Route>
                      <Route
                        path={[
                          '/borrow/:txCurrency/:txValue',
                          '/borrow/:txCurrency'
                        ]}
                      >
                        {isMobile ? (
                          <NewBorrowScreen />
                        ) : (
                          <NewHomeDesktopScreen state="borrow" />
                        )}
                      </Route>
                      <Route path="/borrow">
                        {isMobile ? (
                          <NewBorrowScreen />
                        ) : (
                          <NewHomeDesktopScreen state="borrow" />
                        )}
                      </Route>
                      <Route path="/no-collateral">
                        {isMobile ? (
                          <NoCollateral />
                        ) : (
                          <NewHomeDesktopScreen state="no-collateral" />
                        )}
                      </Route>
                      <Route
                        path={[
                          '/repay/:txCurrency/:txValue/:txHash',
                          '/repay/:txCurrency/:txValue',
                          '/repay/:txCurrency'
                        ]}
                      >
                        <NewRepayScreen />
                      </Route>
                      <Route path="/repay">
                        {isMobile ? (
                          <NewRepayScreen />
                        ) : (
                          <NewHomeDesktopScreen state="repay" />
                        )}
                      </Route>
                      <Route
                        path={[
                          '/redeem/:txCurrency/:txValue',
                          '/redeem/:txCurrency'
                        ]}
                      >
                        <RedeemFormScreen />
                      </Route>
                      <Route path="/redeem">
                        <RedeemScreen />
                      </Route>
                      <Route path="/about-valora">
                        <AboutValoraScreen />
                      </Route>
                      <Route path="/transaction-confirmed/:txType/:txCurrency/:txValue/:txHash">
                        <TransactionConfrimedScreen />
                      </Route>
                      <Route path="/confirm-success/:txType/:txCurrency/:txValue/:txHash">
                        {isMobile ? (
                          <ConfirmationSuccess />
                        ) : (
                          <NewHomeDesktopScreen state="confirm-success" />
                        )}
                      </Route>
                      <Route path="/connect-wallet">
                        {isMobile ? (
                          <ConnectWallet />
                        ) : (
                          <NewHomeDesktopScreen state="connect-wallet" />
                        )}
                      </Route>
                      <Route path="/settings">
                        {isMobile ? (
                          <NewSettingsScreen />
                        ) : (
                          <NewHomeDesktopScreen state="settings" />
                        )}
                      </Route>
                      <Route path="/languages">
                        {isMobile ? (
                          <NewLanguagesScreen />
                        ) : (
                          <NewHomeDesktopScreen state="languages" />
                        )}
                      </Route>
                      <Route path="/currencies">
                        {isMobile ? (
                          <NewCurrenciesScreen />
                        ) : (
                          <NewHomeDesktopScreen state="currencies" />
                        )}
                      </Route>
                      <Route path="/welcome">
                        <WelcomeScreen />
                      </Route>
                      <Route path="/playground">
                        <Playground />
                      </Route>
                      <Route path="/old-home">
                        <HomeScreen />
                      </Route>
                      <Route path="/home/:tab">
                        {isMobile ? (
                          <NewHomeScreen />
                        ) : (
                          <NewHomeDesktopScreen state="" />
                        )}
                      </Route>
                      <Route path="/terms">
                        {isMobile ? (
                          <Terms />
                        ) : (
                          <NewHomeDesktopScreen state="terms" />
                        )}
                      </Route>
                      <Route path="/privacy-policy">
                        {isMobile ? (
                          <Privacy />
                        ) : (
                          <NewHomeDesktopScreen state="privacy-policy" />
                        )}
                      </Route>
                      <Route path="/moo">
                        <Moo />
                      </Route>
                      <Route path="/">
                        {isMobile ? (
                          <NewHomeScreen />
                        ) : (
                          <NewHomeDesktopScreen />
                        )}
                      </Route>
                    </Switch>
                    <AppMenu
                      opened={this.state.showMenu}
                      closeMenu={this.closeMenu}
                    />
                    <StatusBar style="auto" />
                  </Router>

                  {this.state.bottomPopupItem && (
                    <BottomPopup
                      show={true}
                      label={this.state.bottomPopupItem.text}
                      onClose={() => this.setBottomPopupItem(null)}
                      icon={CloseIcon}
                      popUpIcon={this.state.bottomPopupItem.info.popUpIcon}
                      children={this.state.bottomPopupItem.info.content}
                      disabled={this.state.bottomPopupItem.info.isDisabled}
                      primaryButtonTitle={
                        this.state.bottomPopupItem.info.primaryButtonTitle
                      }
                      primaryButtonClick={
                        this.state.bottomPopupItem.info.primaryButtonClick
                      }
                      secondaryButtonTitle={
                        this.state.bottomPopupItem.info.secondaryButtonTitle
                      }
                      secondaryButtonClick={
                        this.state.bottomPopupItem.info.secondaryButtonClick
                      }
                      endIconSecondaryButton={
                        this.state.bottomPopupItem.info.endIconSecondaryButton
                      }
                    />
                  )}
                </AppContext.Provider>
              </ThemeProvider>
            </QueryClientProvider>
          </ContractKitProvider>
        </div>
      </>
    )
  }
}

// const Comp = () => {
//   const {
//     connect: connectWallet,
//     address: walletAddress,
//     destroy: logout
//   } = useContractKit()

//   return (
//     <div>
//       <div>{walletAddress}</div>
//       <button onClick={connectWallet}>Connect</button>
//       {walletAddress ? <button onClick={logout}>Disconnect</button> : ''}
//     </div>
//   )
// }

// export default class App extends React.Component {
//   constructor(props) {
//     super(props)
//   }
//   render() {
//     return (
//       <ContractKitProvider
//         dapp={{
//           name: 'Moola',
//           description: 'Moola - Democratizing access to credit and yield',
//           url: 'https://app.moola.market'
//         }}
//       >
//         <Comp></Comp>
//       </ContractKitProvider>
//     )
//   }
// }
