import axios from 'axios'
import BN from 'bignumber.js'
import { METHODS } from './constants'
import {
  CURRENCIES,
  FIAT_CURRENCIES,
  MAX_LTV,
  TRANSACTIONS
} from '../utils/constants'
import { getCurrencyFromFiat } from '../utils'

const formatCurrency = (currency) => {
  return currency === 'Celo' ? 'CELO' : currency
}

const requestCurrency = (currency) => {
  return currency === FIAT_CURRENCIES.eur.key ? 'cEUR' : 'cUSD'
}

const requestUserSelectedCurrency = (currency) => {
  return currency?.toLowerCase() === CURRENCIES.celo.key.toLowerCase()
    ? 'Celo'
    : currency
}

const calculateState = (healthFactor) => {
  if (healthFactor < 100) {
    return 'LIQUIDATED'
  }
  if (healthFactor >= 100 && healthFactor <= 125) {
    return 'RISKY'
  }
  if (healthFactor > 125) {
    return 'HEALTHY'
  }
}

export const convertoToCelo = (exchangeRates, currency, amount) => {
  const result = BN(amount).multipliedBy(
    exchangeRates[currency.toLowerCase()].celo
  )
  return BN(amount).isGreaterThanOrEqualTo(0) ? result : 0
}

/*
  LTV = (totalBorrow + totalFee) / totalCollateral
*/
const calculateLTV = ({ totalCollateral, totalBorrow, totalFees }) => {
  const totalCollateralBN = BN(totalCollateral)
  const totalBorrowBN = BN(totalBorrow)
  const totalFeesBN = BN(totalFees)
  return totalBorrowBN.plus(totalFeesBN).dividedBy(totalCollateralBN)
}

/*
  Determines if collateral user can change collateral status for currency
*/
export const isCollateralDisabled = ({
  currency,
  isCollateral,
  amount,
  totalCollateral,
  totalBorrow,
  totalFees,
  exchangeRates
}) => {
  const amountCelo = convertoToCelo(exchangeRates, currency, amount)
  let newCollateral = isCollateral
    ? BN(totalCollateral).minus(BN(amountCelo))
    : BN(totalCollateral).plus(BN(amountCelo))

  // Due to exchange rates, the newCollateral can became less than 0
  // If that happens, we set the newCollateral to 0, so that the "minus" value won't effect new LTV calculations
  if (newCollateral.isLessThanOrEqualTo(0)) {
    newCollateral = BN(0)
  }

  const newLtv = calculateLTV({
    totalCollateral: newCollateral,
    totalBorrow,
    totalFees
  })
  const result = newLtv > MAX_LTV / 100
  return result
}

/*
 * Health Factor = (Total Collateral in Celo * Liquidation Threshold) / Total Debt in Celo
 * LTV = (Loan / Collateral) * 100
 * Liquidation Threshold = 80
 * Ex.  deposits = [{key: "CELO", value: 0.5}]
 *      debts = [{key: "cUSD", value: 0.50002323232},{key: "cEUR", value: 0.200003212323}]
 */
const calculateRiskParams = (
  deposits,
  debts,
  collateralsDebts,
  loans,
  sums,
  liquidationThreshold,
  activityType,
  userCurrency,
  currency,
  amount,
  exchangeRates
) => {
  if (!currency || !amount)
    return { healthFactor: 0, ltv: 0, tempCollaterals: [] }
  // Initialize collaterals
  let tempCollateral = deposits.filter((item) => {
    return {
      ...item,
      value: BN(item.value)
    }
  })

  // Initialize debts
  let tempDebts = debts.filter((item) => {
    if (BN(item.value).isGreaterThan(0)) {
      return { ...item, value: BN(item.value) }
    }
  })

  if (activityType === TRANSACTIONS.DEPOSIT) {
    const collateralFound = tempCollateral.find(
      (item) => item.key.toLowerCase() === currency.toLowerCase()
    )
    if (collateralFound) {
      // increase collateral value if user deposits
      collateralFound.value = BN(collateralFound.value).plus(BN(amount))
    } else {
      let collateralArray = collateralsDebts.map((a) => a.priceCurrency)
      // add asset only if is set as collateral
      if (collateralArray.includes(currency)) {
        tempCollateral.push({ key: currency, value: BN(amount) })
      }
    }
  }

  if (activityType === TRANSACTIONS.WITHDRAW) {
    const collateralFound = tempCollateral.find(
      (item) => item.key.toLowerCase() === currency.toLowerCase()
    )
    if (collateralFound) {
      collateralFound.value = BN(collateralFound.value).minus(BN(amount))

      if (BN(0).isGreaterThanOrEqualTo(collateralFound.value)) {
        tempCollateral = tempCollateral.filter(
          (item) => item.key.toLowerCase() !== currency.toLowerCase()
        )
      }
    }
  }

  if (activityType === TRANSACTIONS.BORROW) {
    const debtFound = tempDebts.find(
      (item) => item.key.toLowerCase() === currency.toLowerCase()
    )
    if (debtFound) {
      debtFound.value = BN(debtFound.value).plus(BN(amount))
    } else {
      tempDebts.push({ key: currency, value: BN(amount) })
    }
  }

  if (activityType === TRANSACTIONS.REPAY) {
    const debtFound = tempDebts.find(
      (item) => item.key.toLowerCase() === currency.toLowerCase()
    )

    if (debtFound) {
      if (BN(amount).isGreaterThanOrEqualTo(BN(debtFound.value))) {
        // delete debt
        tempDebts = tempDebts.filter(
          (debtAsset) => debtAsset.key.toLowerCase() !== currency.toLowerCase()
        )
      } else {
        // substract from existing debt
        debtFound.value = BN(debtFound.value).minus(BN(amount))
        // loanFound.value = debtFound.value
      }
    }
  }

  // convert deposits to CELO anc calculate sum of all collateral
  const totalCollateralCelo =
    tempCollateral && tempCollateral.length
      ? tempCollateral
          .map((collateralAsset) =>
            convertoToCelo(
              exchangeRates,
              collateralAsset.key,
              collateralAsset.value
            )
          )
          .reduce((a, b) => BN(a).plus(BN(b)), 0)
      : 0

  // convert debts to CELO anc calculate sum of all debts
  const totalDebtsCelo =
    tempDebts && tempDebts.length
      ? tempDebts
          .map((debtAsset) =>
            convertoToCelo(exchangeRates, debtAsset.key, debtAsset.value)
          )
          .reduce((a, b) => BN(a).plus(b), 0)
      : 0

  if (BN(totalDebtsCelo).isLessThan(0))
    throw new Error('Total Debts must be greater than 0')

  const healthFactor = BN(totalCollateralCelo)
    .multipliedBy(liquidationThreshold)
    .dividedBy(totalDebtsCelo)

  let liquidationPrices = []
  if (
    tempCollateral &&
    tempCollateral.length > 0 &&
    tempDebts &&
    tempDebts.length > 0
  ) {
    tempCollateral.map((collateralAsset) => {
      tempDebts.map((debtAsset) => {
        const currentPrice =
          exchangeRates[collateralAsset.key.toLowerCase()][
            debtAsset.key.toLowerCase()
          ]
        const liquidationPrice = BN(currentPrice).dividedBy(
          BN(healthFactor).dividedBy(100)
        )
        liquidationPrices.push({
          currency: collateralAsset.key,
          currentPrice: currentPrice,
          liquidationPrice: liquidationPrice,
          priceCurrency: debtAsset.key
        })
      })
    })
  }

  const collaterals = tempCollateral
    ?.map((collateralAsset) => formatCurrency(collateralAsset.key))
    .join(' & ')

  const totalDebt = BN(totalDebtsCelo).multipliedBy(
    exchangeRates['celo'][getCurrencyFromFiat(userCurrency).toLowerCase()]
  )
  const ltv = BN(totalDebtsCelo)
    .dividedBy(totalCollateralCelo)
    .multipliedBy(100)

  const state = calculateState(healthFactor)

  return {
    healthFactor: healthFactor.dividedBy(100).toNumber(),
    ltv: ltv.toNumber(),
    tempCollaterals: liquidationPrices,
    state,
    totalDebt,
    collaterals: collaterals
  }
}

export const getReserveData = async (currency) => {
  if (!currency) {
    return null
  }

  let requestCurrency
  switch (currency) {
    case CURRENCIES.celo.key:
      requestCurrency = 'Celo'
      break
    case FIAT_CURRENCIES.usd.key:
      requestCurrency = 'cUSD'
      break
    case FIAT_CURRENCIES.eur.key:
      requestCurrency = 'cEUR'
      break
  }
  const { data } = await axios.get(
    `${METHODS.GET_RESERVE_DATA}?currency=${requestCurrency}`
  )
  return data
}

export const getReserveDataActiveUsers = async () => {
  const { data } = await axios.get(`${METHODS.GET_RESERVE_DATA_ACTIVE_USERS}`)
  return data
}

export const getStatisticsData = async (currency) => {
  const { data } = await axios.get(
    `${METHODS.GET_STATISTICS_DATA}?currency=${requestCurrency(currency)}`
  )
  return data
}

export const getSettingsCurrency = async () => {
  let currency = localStorage.getItem('currency')
  if (!currency) {
    currency = FIAT_CURRENCIES.usd.key
  }
  return currency
}

export const setSettingsCurrency = async (currency) => {
  localStorage.setItem('currency', currency)
  return currency
}

export const getSettingsLanguage = async () => {
  let language = localStorage.getItem('language')
  if (!language) {
    language = 'en'
  }
  return language
}

export const setSettingsLanguage = async (language) => {
  localStorage.setItem('language', language)
  return language
}

export const getUserAccountInfoBalance = async (userPublicKey, currency) => {
  if (!userPublicKey) {
    return null
  }

  const { data } = await axios.get(
    `${
      METHODS.GET_USER_ACCOUNT_INFO_BALANCE
    }?userPublicKey=${userPublicKey}&currency=${requestCurrency(currency)}`
  )
  return data
}

export const getUserAccountInfoDeposit = async (userPublicKey) => {
  if (!userPublicKey) {
    return null
  }

  const { data } = await axios.get(
    `${METHODS.GET_USER_ACCOUNT_INFO_DEPOSIT}?userPublicKey=${userPublicKey}`
  )
  return data
}

export const getUserAccountInfoActivity = async (
  userPublicKey,
  currency,
  pagination
) => {
  if (!userPublicKey) {
    return null
  }

  const { data } = await axios.get(
    `${
      METHODS.GET_USER_ACCOUNT_INFO_ACTIVITY
    }?userPublicKey=${userPublicKey}&currency=${requestCurrency(
      currency
    )}&pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`
  )
  return data
}

export const getUserAccountInfoRiskFactor = async (userPublicKey, currency) => {
  if (!userPublicKey) {
    return null
  }

  const url = METHODS.GET_USER_ACCOUNT_INFO_HEALTH_FACTOR
  const { data } = await axios.get(
    `${url}?userPublicKey=${userPublicKey}&currency=${requestCurrency(
      currency
    )}`
  )
  return data
}

export const getUserAccountInfoBorrow = async (userPublicKey) => {
  if (!userPublicKey) {
    return null
  }

  const url = METHODS.GET_USER_ACCOUNT_INFO_BORROW
  if (userPublicKey) {
    const { data } = await axios.get(`${url}?userPublicKey=${userPublicKey}`)
    return data
  }
  return null
}

export const getUserAccountInfoMax = async (
  userPublicKey,
  currency,
  activityType
) => {
  if (!userPublicKey) {
    return null
  }

  let url = METHODS.GET_USER_ACCOUNT_INFO_MAX
  url = `${url}?userPublicKey=${userPublicKey}&currency=${currency}&activityType=${activityType}`
  const { data } = await axios.get(url)
  return data
}

export const getUserAccountInfoDebt = async (userPublicKey, currency) => {
  if (!userPublicKey) {
    return null
  }

  let url = METHODS.GET_USER_ACCOUNT_INFO_DEBT
  url = `${url}?userPublicKey=${userPublicKey}&currency=${currency}`
  const { data } = await axios.get(url)
  return data
}

export const getUserAccountInfoCurrencyDebt = async (
  userPublicKey,
  currency
) => {
  if (!userPublicKey) {
    return null
  }

  let url = METHODS.GET_USER_ACCOUNT_INFO_CURRENCY_DEBT
  url = `${url}?userPublicKey=${userPublicKey}&currency=${currency}`
  const { data } = await axios.get(url)
  return data
}

export const getUserAccountInfoStatus = async (userPublicKey, currency) => {
  if (!userPublicKey) {
    return null
  }

  let url = METHODS.GET_USER_ACCOUNT_INFO_STATUS
  url = `${url}?userPublicKey=${userPublicKey}&currency=${requestCurrency(
    currency
  )}`
  const { data } = await axios.get(url)
  return data
}

export const getUserNewAccountInfoStatus = (
  userPublicKey,
  currentCurrency,
  selectedCurrency,
  activityType,
  amount,
  loanTerms,
  collateral,
  debts,
  collateralsDebts,
  loans,
  sums,
  currentAccountStatus,
  exchangeRates
) => {
  const data = calculateRiskParams(
    collateral,
    debts,
    collateralsDebts,
    loans,
    sums,
    currentAccountStatus.liquidationThreshold,
    activityType,
    currentCurrency,
    selectedCurrency,
    amount,
    exchangeRates
  )
  const result = { ...currentAccountStatus }
  result.healthFactor = data.healthFactor
  result.currentLTV = data.ltv
  result.collateralAssets = data.tempCollaterals
  result.state = data.state
  result.remainingDebt = data.totalDebt
  return data
}

export const getExchangeRates = async () => {
  const url = METHODS.GET_EXCHANGE_RATES
  const { data } = await axios.get(url)
  return data
}

export const getFee = async (
  userPublicKey,
  currency,
  activityType,
  amount,
  confirmation
) => {
  if (confirmation === true && currency && amount) {
    let url = METHODS.GET_FEE
    url = `${url}?userPublicKey=${userPublicKey}&currency=${requestUserSelectedCurrency(
      currency
    )}&activityType=${activityType}&amount=${amount}`
    const { data } = await axios.get(url)

    /// TEMPORARY SOLUTION
    /// REMOVE THIS WHEN SECURITY FEE IS IMPLEMENTED CORRECTLY
    if (data) {
      data.securityFee = 0.01
    }
    //////////
    //////////

    return data
  }
  return null
}

export const getUserAccountLiquidationPrices = async (userPublicKey) => {
  let url = METHODS.GET_USER_ACCOUNT_INFO_LIQUIDATION_PRICES
  url = `${url}?userPublicKey=${userPublicKey}`
  const { data } = await axios.get(url)
  return data
}

export const getUserAccountCurrenciesCurrentPrice = async (userPublicKey) => {
  let url = METHODS.GET_USER_ACCOUNT_INFO_CURRENCIES_CURRENT_PRICE
  url = `${url}?userPublicKey=${userPublicKey}`
  const { data } = await axios.get(url)
  return data
}

export const getMooTokenHolder = async (userPublicKey) => {
  let url = METHODS.GET_USER_TOKEN_HOLDER
  url = `${url}?userPublicKey=${userPublicKey}`
  const { data } = await axios.get(url)
  return data
}

export const getUserAccountInfo = async (userPublicKey, currency) => {
  if (!userPublicKey) {
    return null
  }

  let url = METHODS.GET_USER_RESERVE_DATA
  url = `${url}?userPublicKey=${userPublicKey}&currency=${requestCurrency(
    currency
  )}`
  const { data } = await axios.get(url)
  return data
}
