import styled from 'styled-components'

export const Container = styled.div`
  position: ${props => props.$transparent ? 'absolute' : 'initial'};
  display: ${props => props.$opened ? 'flex' : 'none'};
  width: 100%;
  height: 100%;
  z-index: 2100;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`

export const Overlay = styled.div`
  opacity: ${props => props.$transparent ? '0.5' : '1'};
  background: ${props => props.$transparent ? 'gray' : 'white'};;
  width: 100%;
  height: 100%;
  position: absolute;
`

export const LoadingWrapper = styled.div`
  align-self: center;
`

