import styled from 'styled-components'

export const Transactions = styled.div`
  width: 80%;
  margin: auto;
`

export const StatusesContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  color: rgba(229,231,235);
`

export const Status = styled.div`
  display: flex;
  flex-basis: 25%;
  justify-content: center;
`

export const Timeline = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(229,231,235);
  margin-top: 4px;
  margin-bottom: 40px;
`

export const Step = styled.div`
  display: flex;
  flex-basis: 15%;
  justify-content: flex-start;
  :first-child {
    justify-content: flex-end;
  }
`

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 1px solid rgba(229,231,235) !important;
  border-radius: 11px;
`

export const Line = styled.div`
  flex: auto;
  border-bottom: 1px solid rgba(229,231,235) !important;
  margin-left: 3px;
  margin-right: 3px;
`