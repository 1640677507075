import { CURRENCIES } from './constants'
import { formatNumber } from './format'

/**
 * get reserved Data Formatted
 */
export const getReservedData = (t, item, currency) => {
  const APY = 'APY'
  const APR = 'APR'
  const currencyName = currency.toLowerCase()
  return {
    header: {
      icon: CURRENCIES[currencyName].icon,
      text: CURRENCIES[currencyName].text,
      value:
        item.apy !== null && item.apy >= 0
          ? t('HOME.MARKET_TAB.CURRENCY_CARD_TITLE', { apyValue: item.apy })
          : t('GENERAL.NOT_APPLICABLE')
    },
    content: [
      {
        text: t('HOME.MARKET_TAB.TOTAL_DEPOSITED_TITLE'),
        value: `${
          item.totalDeposited !== null && item.totalDeposited >= 0
            ? formatNumber(item.totalDeposited)
            : t('GENERAL.NOT_APPLICABLE')
        } ${CURRENCIES[currencyName].text}`
      },
      {
        text: t('HOME.MARKET_TAB.AVAILABLE_LIQUIDITY'),
        value: `${
          item.availableLiquidity !== null && item.availableLiquidity >= 0
            ? formatNumber(item.availableLiquidity)
            : t('GENERAL.NOT_APPLICABLE')
        } ${CURRENCIES[currencyName].text}`,
        collapsed: false
      },
      {
        text: t('HOME.MARKET_TAB.TOTAL_BORROWED_TITLE'),
        value: `${
          (item.totalBorrowStable !== null ||
            item.totalBorrowVariable !== null) &&
          item.totalBorrowStable >= 0 &&
          item.totalBorrowVariable >= 0
            ? formatNumber(item.totalBorrowStable + item.totalBorrowVariable)
            : t('GENERAL.NOT_APPLICABLE')
        } ${CURRENCIES[currencyName].text}`,
        collapsed: true
      },
      {
        text: `${t('HOME.MARKET_TAB.TOTAL_BORROWED_TITLE')} - ${t(
          'HOME.MARKET_TAB.STABLE'
        )}`,
        value: `${
          item.totalBorrowStable !== null && item.totalBorrowStable >= 0
            ? formatNumber(item.totalBorrowStable)
            : t('GENERAL.NOT_APPLICABLE')
        } ${CURRENCIES[currencyName].text}`,
        collapsed: false
      },
      {
        text: `${t('HOME.MARKET_TAB.TOTAL_BORROWED_TITLE')} - ${t(
          'HOME.MARKET_TAB.VARIABLE'
        )}`,
        value: `${
          item.totalBorrowVariable !== null && item.totalBorrowVariable >= 0
            ? formatNumber(item.totalBorrowVariable)
            : t('GENERAL.NOT_APPLICABLE')
        } ${CURRENCIES[currencyName].text}`,
        collapsed: false
      },
      {
        text: `${t('HOME.MARKET_TAB.STABLE_BORROW_APR_TITLE')} ${APR}`,
        value:
          item.stableBorrowAPR !== null && item.stableBorrowAPR >= 0
            ? `${formatNumber(item.stableBorrowAPR, 2)}%`
            : `${t('GENERAL.NOT_APPLICABLE')}`,
        collapsed: false
      },
      {
        text: `${t('HOME.MARKET_TAB.VARIABLE_BORROW_APR_TITLE')} ${APR}`,
        value:
          item.variableBorrowAPR !== null && item.variableBorrowAPR >= 0
            ? `${formatNumber(item.variableBorrowAPR, 2)}%`
            : `${t('GENERAL.NOT_APPLICABLE')}`
      },
      {
        text: `${t('HOME.MARKET_TAB.UTILIZATION_RATE_TITLE')}`,
        value:
          item.utilizationRate !== null && item.utilizationRate >= 0
            ? `${formatNumber(item.utilizationRate, 2)}%`
            : `${t('GENERAL.NOT_APPLICABLE')}`,
        collapsed: false
      }
    ]
  }
}
