import React from 'react'

const AppContext = React.createContext({
  showLoading: null,
  showError: null,
  setUser: null,
  user: null,
  bottomPopupItem: null,
  setBottomPopupItem: null
})

export default AppContext
