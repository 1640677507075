export default theme => ({
  menuWrapper: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '32px',
    padding: '16px 0'
  },
  desktopMenuExpanded: {
    display: 'flex',
    width: '200px',
    paddingTop: '10px',
    '& button': {
      width: '100%',
      margin: '4px 0',
      justifyContent: 'left',
      '& label': {
        cursor: 'pointer',
      },
      '& > span': {
        marginLeft: '-3px !important'
      }
    },
    '& > div': {
      width: '100%'
    }
  },
  desktopMenu: {
    display: 'flex',
    width: '36px',
    paddingTop: '10px',
    '& button': {
      width: '100%',
      margin: '4px 0',
      justifyContent: 'left',
      minWidth: 'auto',
      boxSizing: 'border-box',
      '& label': {
        display: 'none',
      },
      '& > span': {
        marginLeft: '-3px !important'
      }
    },
    '& > div': {
      width: '100%'
    },
  },
  extraButtons: {
    marginTop: '22px'
  },
  migrateButton: {
    marginBottom: '22px'
  },
});
