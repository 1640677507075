import React from 'react'
import { Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import ChevronRightArrow from '../../assets/images/chevron-right-arrow.svg'
import ActionOneRing from '../../assets/images/action-one-ring.svg'
import ActionTwoRings from '../../assets/images/action-two-rings.svg'
import ActionThreeRings from '../../assets/images/action-three-rings.svg'
import ActionFourRings from '../../assets/images/action-four-rings.svg'
import ActionRow from './actionRow'
import { isMobile } from '../../utils/browser'

const styles = () => ({
  root: {
    paddingTop: '8px',
    paddingBottom: '8px',
    background: '#FFFFFF',
    border: '1px solid #EDEEEF',
    borderRadius: '8px',
	marginTop: isMobile ? '0' : '16px',
	marginLeft: 'auto',
    marginRight: 'auto',
	maxWidth: '1000px'
  },
  grid: {
    padding: '8px',
    paddingBottom: '4px',
    paddingTop: '4px',
    cursor: 'pointer'
  },
  leftText: {
    weight: '500',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '20px'
  },
  leftIcon: {
    position: 'relative',
    background: '#FFFFFF',
    top: '2px'
  },
  rightText: {
    weight: '500',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#888888'
  },
  rightIcon: {
    position: 'relative',
    top: '2px',
    paddingLeft: '8px'
  },
  leftBox: {
    paddingLeft: '8px'
  }
})

export const ACTION_LIST_ONE_RING_ICON = ActionOneRing
export const ACTION_LIST_TWO_RINGS_ICON = ActionTwoRings
export const ACTION_LIST_THREE_RINGS_ICON = ActionThreeRings
export const ACTION_LIST_FOUR_RINGS_ICON = ActionFourRings
export const ACTION_LIST_RIGHT_ARROW = ChevronRightArrow

const ActionBox = ({ classes, actionList, clicked }) => {
  return (
    <Box className={classes.root}>
      {actionList.map((item, index) => (
        <React.Fragment key={`f${index}`}>
          <ActionRow
            classes={classes}
            index={index}
            leftIcon={item.leftIcon}
            leftText={item.leftText}
            rightText={item.rightText}
            rightIcon={item.rightIcon}
            onClick={() => {
              item.onClick ? item.onClick() : clicked(item.info)
            }}
          />
        </React.Fragment>
      ))}
    </Box>
  )
}

ActionBox.prototype = {
  classes: PropTypes.string,
  actionList: PropTypes.array.isRequired
}

export default withStyles(styles)(ActionBox)
