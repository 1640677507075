import { Grid, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useGetReserveData, useGetStatisticsData } from '../../api'
import CloseIcon from '../../assets/images/close.svg'
import MoneyStackIcon from '../../assets/images/money-stack.svg'
import UserIcon from '../../assets/images/user.svg'
import VolumeIcon from '../../assets/images/volume.svg'
import { formatByCurrency, toMil } from '../../utils'
import { isMobile } from '../../utils/browser'
import { CURRENCIES } from '../../utils/constants'
import { isCelo } from '../../utils/currency'
import { getReservedData } from '../../utils/getReservedData'
import ActionBox, {
  ACTION_LIST_FOUR_RINGS_ICON,
  ACTION_LIST_ONE_RING_ICON,
  ACTION_LIST_RIGHT_ARROW,
  ACTION_LIST_THREE_RINGS_ICON,
  ACTION_LIST_TWO_RINGS_ICON
} from '../ActionBox'
import BottomPopup from '../BottomPopup'
import Card from '../Card'
import StatisticsList from '../StatsList/statsList'
import { Alert } from '@material-ui/lab'

const styles = () => ({
  root: {
    overflow: 'auto',
    height: 'calc(100% - 66px)',
    padding: '0 16px'
  },
  marginBottom: {
    marginBottom: '16px'
  },
  desktopContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '32px',
    marginTop: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1000px'
  }
})

const HomeMarket = ({ t, currency, classes, forceRefresh }) => {
  const statisticsData = useGetStatisticsData(currency)
  const [celoCardData, setCeloCardData] = useState(null)
  const [cusdCardData, setCusdCardData] = useState(null)
  const [ceurCardData, setCeurCardData] = useState(null)
  const reserveData = useGetReserveData(currency)
  const [apiError, setApiError] = useState(false)

  useEffect(() => {
    if (reserveData?.isError) {
      setApiError(true)
    }
  }, [reserveData])

  useEffect(() => {
    let celoReserveData = {}
    let cusdReserveData = {}
    let ceurReserveData = {}

    if (reserveData.isSuccess) {
      celoReserveData = reserveData.data?.data?.find((element) =>
        isCelo(element.currency)
      )
      if (celoReserveData) {
        setCeloCardData(getReservedData(t, celoReserveData, 'celo'))
      }

      cusdReserveData = reserveData.data?.data?.find(
        (element) =>
          element.currency.toLowerCase() === CURRENCIES.cusd.key.toLowerCase()
      )
      if (cusdReserveData) {
        setCusdCardData(getReservedData(t, cusdReserveData, 'cusd'))
      }

      ceurReserveData = reserveData.data?.data?.find(
        (element) =>
          element.currency.toLowerCase() === CURRENCIES.ceur.key.toLowerCase()
      )
      if (ceurReserveData) {
        setCeurCardData(getReservedData(t, ceurReserveData, 'ceur'))
      }
    }
  }, [reserveData.isSuccess, reserveData.data, t, forceRefresh])

  let statistics = []
  if (statisticsData && statisticsData.isSuccess) {
    statistics = [
      {
        icon: UserIcon,
        title: t('HOME.MARKET_TAB.STATISTICS_ACTIVE_USERS'),
        value: statisticsData.data.activeUser.toLocaleString()
      },
      {
        icon: MoneyStackIcon,
        title: t('HOME.MARKET_TAB.STATISTICS_TOTAL_DEPOSITED'),
        value: formatByCurrency(
          currency,
          toMil(statisticsData.data.totalDeposited)
        )
      },
      {
        icon: VolumeIcon,
        title: t('HOME.MARKET_TAB.STATISTICS_TOTAL_BORROWED'),
        value: formatByCurrency(
          currency,
          toMil(statisticsData.data.totalBorrowed)
        )
      }
    ]
  }

  const marketTabData = {
    actionList: [
      {
        leftIcon: ACTION_LIST_ONE_RING_ICON,
        leftText: t('HOME.MARKET_TAB.ACTION_LIST_TEXT_1'),
        rightIcon: ACTION_LIST_RIGHT_ARROW,
        info: {
          label: t('HOME.MARKET_TAB.ACTION_LIST_TEXT_1'),
          content: t('HOME.MARKET_TAB.ACTION_LIST_CONTENT_1')
        }
      },
      {
        leftIcon: ACTION_LIST_TWO_RINGS_ICON,
        leftText: t('HOME.MARKET_TAB.ACTION_LIST_TEXT_2'),
        rightIcon: ACTION_LIST_RIGHT_ARROW,
        info: {
          label: t('HOME.MARKET_TAB.ACTION_LIST_TEXT_2'),
          content: t('HOME.MARKET_TAB.ACTION_LIST_CONTENT_2')
        }
      },
      {
        leftIcon: ACTION_LIST_THREE_RINGS_ICON,
        leftText: t('HOME.MARKET_TAB.ACTION_LIST_TEXT_3'),
        rightIcon: ACTION_LIST_RIGHT_ARROW,
        info: {
          label: t('HOME.MARKET_TAB.ACTION_LIST_TEXT_3'),
          content: t('HOME.MARKET_TAB.ACTION_LIST_CONTENT_3')
        }
      },
      {
        leftIcon: ACTION_LIST_FOUR_RINGS_ICON,
        leftText: t('HOME.MARKET_TAB.ACTION_LIST_TEXT_4'),
        rightIcon: ACTION_LIST_RIGHT_ARROW,
        info: {
          label: t('HOME.MARKET_TAB.ACTION_LIST_TEXT_4'),
          content: ''
        },
        onClick: () => window.open('http://docs.moola.market', '_blank')
      }
    ]
  }
  const { actionList } = marketTabData

  const [selectedAction, setSelectedAction] = useState(null)
  return (
    <Grid className={!isMobile ? classes.root : ''}>
      {apiError && (
        <Alert
          className={classes.error}
          severity="error"
          onClose={() => setApiError(false)}
        >
          {t('GENERAL.API_ERROR')}
        </Alert>
      )}
      {selectedAction && (
        <BottomPopup
          show={true}
          label={selectedAction.label}
          onClose={() => setSelectedAction(null)}
          icon={CloseIcon}
          children={<Typography>{selectedAction.content}</Typography>}
          primaryButtonTitle={t('GENERAL.CLOSE')}
          primaryButtonClick={() => setSelectedAction(null)}
        />
      )}
      <div className={!isMobile ? classes.desktopContainer : ''}>
        <Grid item className={classes.marginBottom}>
          <Card cardData={cusdCardData} collapsable={isMobile} />
        </Grid>
        <Grid item className={classes.marginBottom}>
          <Card cardData={ceurCardData} collapsable={isMobile} />
        </Grid>
        <Grid item className={classes.marginBottom}>
          <Card cardData={celoCardData} collapsable={isMobile} />
        </Grid>
        <StatisticsList list={statistics} />
      </div>
      <ActionBox actionList={actionList} clicked={setSelectedAction} />
    </Grid>
  )
}

export default withTranslation()(withStyles(styles)(HomeMarket))

HomeMarket.propTypes = {
  currency: PropTypes.string,
  classes: PropTypes.object,
  forceRefresh: PropTypes.number
}
