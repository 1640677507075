import BN from 'bignumber.js'
import { withMandatoryDecimals } from './format'

/**
 * checks the given value is a positive number
 * it passes if the value is not defined
 */
export const mustBePositiveNumber = value => (!value || value > 0 ? undefined : {
  mustBePositiveNumber: true
})

/**
 * creates a validator function for the given max value
 * the validator passes if the value to check against is not defined
 */
export const maxValue = (max, unit, action) => (value) => {
  const maxLabel = withMandatoryDecimals(max, 2)
  const maxParam = unit ? `${maxLabel} ${unit}` : maxLabel

  return BN(max).gt(0)
    ? value && BN(value).gt(max) && {
    maxValue: {
      params: {
        max: maxParam,
        action: action
      }
    }
  }
    : {noBalance: true}
}

/**
 * checks if the value is defined
 */
export const required = value => (value ? undefined : {required: true})

export const composeValidators = (...validators) => (value, values, meta) => {
  return validators.reduce((error, validator) => error || validator(value, values, meta), undefined)
}

