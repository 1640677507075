import React from 'react'
import { useTheme } from '@material-ui/core'
import Currencies from '../../components/Currencies'
import { useGetSettingsCurrency } from '../../api'
import { FIAT_CURRENCIES } from '../../utils/constants'
import { withTranslation } from 'react-i18next'
import { WhitePage } from '../../utils/elements'

const NewCurrenciesScreen = ({ t }) => {
  const theme = useTheme()
  const currency = useGetSettingsCurrency()
  let currentCurrency = null
  let currencies = []

  if (currency.isSuccess) {
    currentCurrency = FIAT_CURRENCIES[currency.data].key
  }

  Object.values(FIAT_CURRENCIES).map((item) => {
    currencies.push({
      text: t(item.lngKey),
      key: item.key,
      icon: item.icon
    })
  })

  return (
    <WhitePage theme={theme}>
      <Currencies
        title={t('CURRENCIES.TITLE')}
        currencies={currencies}
        currency={currentCurrency}
      />
    </WhitePage>
  )
}

export default withTranslation()(NewCurrenciesScreen)
