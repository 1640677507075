import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import MuiIcon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'

import {
  CURRENCIES,
  FIAT_CURRENCIES,
  SIGNS,
  TRANSACTIONS
} from '../../utils/constants'
import liquidatedIcon from '../../assets/images/liquidated.svg'
import { withTranslation } from 'react-i18next'

const styles = () => ({
  itemStyle: {
    flexGrow: 1
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#2E3338'
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#888888'
  },
  alignRight: {
    textAlign: 'right'
  }
})

const ActivityEntry = ({
  t,
  classes,
  activityData,
  onClick,
  selectedCurrency
}) => {
  const { type, currency, currencyAmount, cUsdAmount, timestamp } = activityData

  const getTypeString = (type, currency) => {
    switch (type) {
      case TRANSACTIONS.DEPOSIT:
        return `${t('HOME.ACCOUNT_TAB.DEPOSITED')} ${currency}`
      case TRANSACTIONS.WITHDRAW:
        return `${t('HOME.ACCOUNT_TAB.WITHDREW')} ${currency}`
      case TRANSACTIONS.BORROW:
        return `${t('HOME.ACCOUNT_TAB.BORROWED')} ${currency}`
      case TRANSACTIONS.REPAY:
        return `${t('HOME.ACCOUNT_TAB.REPAID')} ${currency}`
      case TRANSACTIONS.LIQUIDATE:
        return t('HOME.ACCOUNT_TAB.LIQUIDATED')
    }
  }

  const getActivityIcon = (type, currency) => {
    switch (type) {
      case TRANSACTIONS.DEPOSIT:
        return currency === CURRENCIES.celo.text
          ? CURRENCIES.celo.depositIcon
          : CURRENCIES.cusd.depositIcon
      case TRANSACTIONS.WITHDRAW:
        return currency === CURRENCIES.celo.text
          ? CURRENCIES.celo.withdrawIcon
          : CURRENCIES.cusd.withdrawIcon
      case TRANSACTIONS.BORROW:
        return currency === CURRENCIES.celo.text
          ? CURRENCIES.celo.borrowIcon
          : CURRENCIES.cusd.borrowIcon
      case TRANSACTIONS.REPAY:
        return currency === CURRENCIES.celo.text
          ? CURRENCIES.celo.repayIcon
          : CURRENCIES.cusd.repayIcon
      case TRANSACTIONS.LIQUIDATE:
        return liquidatedIcon
    }
  }

  const getActivityOperator = (type) => {
    return type === TRANSACTIONS.DEPOSIT || type === TRANSACTIONS.REPAY ? SIGNS.PLUS : SIGNS.MINUS
  }

  const getCurrencySymbol = (cvalue) => {
    if (cvalue === FIAT_CURRENCIES.eur.key) {
      return SIGNS.EURO
    }
    return SIGNS.DOLLAR
  }

  return (
    <Box mb={2} onClick={onClick}>
      <Grid container direction="row">
        <Grid item>
          <Box marginRight={2}>
            <MuiIcon>
              <img
                src={getActivityIcon(type, currency)}
                height={42}
                width={42}
              />
            </MuiIcon>
          </Box>
        </Grid>
        <Grid item className={classes.itemStyle}>
          <Grid container direction="row" justify="space-between">
            <Grid item container direction="column" xs={6}>
              <Grid item>
                <Typography className={classes.title}>
                  {getTypeString(type, currency)}
                </Typography>
              </Grid>
              <Grid item>
                {/*TODO After the localisations is implemented, display the timestamp value in the selected language */}
                <Typography className={classes.subtitle}>
                  {timestamp}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container direction="column" xs={6}>
              <Box className={classes.alignRight}>
                <Grid item>
                  <Typography className={classes.title}>
                    {currencyAmount} {currency}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.subtitle}>
                    {getActivityOperator(type)}{' '}
                    {getCurrencySymbol(selectedCurrency)}
                    {cUsdAmount}
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

ActivityEntry.propTypes = {}

export default withTranslation()(withStyles(styles)(ActivityEntry))
