import { useEffect, useContext, useState } from 'react'
import AppContext from '../AppContext'
import { CURRENCIES } from '../../utils/constants'
import BN from 'bignumber.js'
import { kit } from '../../root'

const useAccountBalance = (forceRefresh) => {
  const { user } = useContext(AppContext)
  const [isLoading, setLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [balance, setBalance] = useState(null)

  const loadBalance = async (type) => {
    const token =
      type === 'CELO'
        ? await kit.contracts.getGoldToken()
        : await kit.contracts.getStableToken(type)

    const tokenBalanceBig = await token.balanceOf(kit.defaultAccount)
    const tokenBalance = tokenBalanceBig.dividedBy(BN(10).pow(18)).toString()
    const result = { currency: type, walletBalance: tokenBalance }
    return result
  }

  const loadAllBalance = async () => {
    setIsSuccess(false)
    try {
      const currencyBalance = await Promise.all(
        Object.values(CURRENCIES).map(
          async (currency) => await loadBalance(currency.key)
        )
      )
      setBalance(currencyBalance)
      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsSuccess(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (user) {
      loadAllBalance()
    }
  }, [user, forceRefresh])

  return { balance, isLoading, isSuccess }
}

export default useAccountBalance
