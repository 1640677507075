import { useContext, useState, useEffect } from 'react'
import AppContext from '../AppContext'
import {
  useGetUserAccountLiquidationPrices,
  useGetUserAccountInfoStatus,
  useGetUserAccountInfoDeposit,
  useGetExchangeRates,
  useGetNewUserAccountInfoStatus
} from '../../api'
import BN from 'bignumber.js'
import { CURRENCIES, TRANSACTIONS } from '../../utils/constants'
import useAccountDebtData from './userAccountDebtData'
import userAccountIsCollateral from './userAccountIsCollateral'
import { retrieveExchangeRates } from '../../utils/currency'

const useNewAccountStatus = (
  value,
  currentCurrency,
  selectedCurrency,
  activityType
) => {
  const { user } = useContext(AppContext)
  const accountDebtData = useAccountDebtData()
  const collateralData = userAccountIsCollateral()
  const accountInfoDeposit = useGetUserAccountInfoDeposit(user.address)
  const currentAccountInfoStatus = useGetUserAccountInfoStatus(
    user.address,
    currentCurrency
  )
  const userAccountLiquidationPrices = useGetUserAccountLiquidationPrices(
    user.address
  )
  const exchangeRatesData = useGetExchangeRates()
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState()
  const [data, setData] = useState(null)

  useEffect(() => {
    setIsSuccess(
      accountInfoDeposit.isSuccess &&
        currentAccountInfoStatus.isSuccess &&
        userAccountLiquidationPrices.isSuccess &&
        exchangeRatesData.isSuccess &&
        accountDebtData.isSuccess
    )
  }, [
    accountInfoDeposit.isSuccess,
    currentAccountInfoStatus.isSuccess,
    userAccountLiquidationPrices.isSuccess,
    exchangeRatesData.isSuccess,
    accountDebtData.isSuccess
  ])

  useEffect(() => {
    setIsLoading(
      accountInfoDeposit.isLoading ||
        currentAccountInfoStatus.isLoading ||
        userAccountLiquidationPrices.isLoading ||
        exchangeRatesData.isLoading ||
        accountDebtData.isLoading
    )
  }, [
    accountInfoDeposit.isLoading,
    currentAccountInfoStatus.isLoading,
    userAccountLiquidationPrices.isLoading,
    exchangeRatesData.isLoading,
    accountDebtData.isLoading
  ])

  useEffect(() => {
    if (isSuccess && value && selectedCurrency?.text) {
      if (
        BN(value).isGreaterThan(0) &&
        currentAccountInfoStatus?.data?.interestRates
      ) {
        let newStatusCollateral = []
        accountInfoDeposit?.data?.data?.map((item) => {
          const getCollateralData =
            collateralData?.accountIsCollateralData.find(
              (x) => x.currency === CURRENCIES[item.currency.toLowerCase()].key
            )
          if (
            (item.amount === 0 &&
              selectedCurrency?.text ===
                CURRENCIES[item.currency.toLowerCase()].key &&
              activityType === TRANSACTIONS.DEPOSIT) ||
            getCollateralData.isCollateral
          ) {
            newStatusCollateral.push({
              key: item.currency,
              value: item.amount
            })
          }
        })
        let newStatusDebts = []
        accountDebtData?.accountDebtData?.map((item) => {
          newStatusDebts.push({
            key: item.currency,
            value: BN(item.originationFee).plus(item.debt)
          })
        })
        let newStatusCollateralAssets = []
        if (userAccountLiquidationPrices?.data?.data?.collateralAssets) {
          newStatusCollateralAssets = [
            ...userAccountLiquidationPrices?.data?.data?.collateralAssets
          ]
        }
        let newStatusLoans = [...newStatusDebts]
        let newStatusSums = {}
        currentAccountInfoStatus?.data?.interestRates.map((item) => {
          newStatusSums = {
            ...newStatusSums,
            [item.currency.toLowerCase()]: {
              totalCollateral: item.totalCollateral,
              totalDebt: item.totalDebt
            }
          }
        })
        const exchangeRates = retrieveExchangeRates(exchangeRatesData.data)
        const newData = Object.assign({}, currentAccountInfoStatus.data)

        // include only assets that are marked as collateral
        let collateralArray = newStatusCollateral.map((a) => a.key)
        newData.collateralAssets = newStatusCollateralAssets.filter((asset) =>
          collateralArray.includes(asset.priceCurrency)
        )
        const newAccountInfoStatus = useGetNewUserAccountInfoStatus(
          user.address,
          currentCurrency,
          selectedCurrency?.text,
          activityType,
          value,
          null,
          newStatusCollateral,
          newStatusDebts,
          newStatusCollateralAssets.filter((asset) =>
            collateralArray.includes(asset.priceCurrency)
          ),
          newStatusLoans,
          newStatusSums,
          newData,
          exchangeRates
        )
        if (newAccountInfoStatus) {
          const newResult = { ...newData }
          newResult.healthFactor = newAccountInfoStatus.healthFactor
          newResult.currentLTV = newAccountInfoStatus.ltv
          newResult.collateralAssets = newAccountInfoStatus.tempCollaterals
          newResult.collaterals = newAccountInfoStatus.collaterals
          newResult.state = newAccountInfoStatus.state
          newResult.remainingDebt = newAccountInfoStatus.totalDebt
          setData(newResult)
        }
      }
    }
  }, [isSuccess, value, selectedCurrency])

  return [{ isSuccess, isLoading, data }]
}

export default useNewAccountStatus
