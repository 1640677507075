import React, { useState } from 'react'
import MuiIcon from '@material-ui/core/Icon'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import QuestionMarkIcon from '../../assets/images/question-mark.svg'
import QuestionMarkIconDark from '../../assets/images/help_dark.svg'
import { isMobile } from '../../utils/browser'

const styles = (theme) => ({
  tooltipContainer: {
    position: 'relative'
  },
  tooltip: {
    display: isMobile ? 'none' : 'flex',
    flexDirection: 'column',
    padding: '16px',
    width: '500px',
    position: 'absolute',
    zIndex: 1,

    marginLeft: '-250px',
    marginTop: '15px',

    /* White */
    background: theme.palette.neutral.white,

    /* Dark Blue 10% */
    border: '1px solid rgba(46, 51, 56, 0.1)',
    boxSizing: 'border-box',

    /* Desktop/Help Card */
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: '16px',
    '&::after': {
      content: `''`,
      position: 'absolute',
      bottom: '100%',
      left: '47%',
      marginLeft: '-5px',
      borderWidth: '5px',
      borderStyle: 'solid',
      borderColor: 'transparent transparent white transparent'
    }
  },
  title: {
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '28px',
    color: theme.palette.text.primary,
    paddingBottom: '16px'
  },
  text: {
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.primary
  },
  loanTermsToolTip: {
    minWidth: '400px',
    marginTop: '27px'
  }
})

const InfoIcon = ({classes, mode, title, text, maxRight}) => {
  const {t} = useTranslation()
  const [isShown, setIsShown] = useState(false)

  return (
    <>
      <MuiIcon
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          marginLeft: maxRight ? 'auto' : '7px',
          cursor: 'pointer'
        }}
      >
        <img
          alt={'Additional info'}
          src={mode === 'dark' ? QuestionMarkIconDark : QuestionMarkIcon}
          width={16}
          height={16}
        ></img>
      </MuiIcon>
      {isShown && title && <Grid item className={classes.tooltipContainer}>
        <Box className={classNames(
          classes.tooltip,
          t('BORROW.LOAN_TERMS') === title ? classes.loanTermsToolTip : null
        )}>
          <Typography
            variant="body2"
            className={classes.title}
          >
            {title}
          </Typography>
          <Grid item className={classes.text}>
            {text}
          </Grid>
        </Box>
      </Grid> }
    </>
  )
}

export default withStyles(styles)(InfoIcon)
