import Constants from 'expo-constants';

let config = require('./dev').default

if (Constants.manifest.extra.isMainNet) {
	if (Constants.manifest.extra.isTestBackend) {
		config = require('./staging').default
	} else {
  		config = require('./prod').default
	}
}

export default config
