import { Grid, TextField, Typography } from '@material-ui/core'
import MuiIcon from '@material-ui/core/Icon'
import { makeStyles } from '@material-ui/core/styles'
import * as PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { Field } from 'react-final-form'
import SwitchIcon from '../../assets/images/switch.svg'

const useStyles = makeStyles(theme => ({
  rootContainer: {
    '& > *:not(:last-child)': {
      marginRight: '8px'
    }
  },
  input: {
    fontSize: '32px',
    lineHeight: '24px',
    color: theme.palette.text.primary,
    padding: 0,
    '&.rightAlign': {
      textAlign: 'right'
    },
    '&.error': {
      color: theme.palette.common.error
    },
    '&::placeholder': {
      color: theme.palette.text.placeholder
    }
  },
  switchContainer: {
    width: 'auto',
    border: '1px solid',
    borderColor: theme.palette.neutral.grey1,
    borderRadius: '6px',
    padding: '4px 8px',
    cursor: 'pointer',
    '& > *:not(:last-child)': {
      marginRight: '8px'
    }
  },
  currencyLabel: {
    fontSize: '20px',
    fontFamily: 'Jost-700-Bold',
    color: (props) => theme.palette.common[props.currency]
  },
  switchIcon: {
    position: 'relative',
    top: '-3px'
  }
}))

const AmountInput = ({ name, currency, switchClick, validators, onValueChange, inputFirst, customExternalError }) => {

  const styleProps = { currency: currency.key }
  const classes = useStyles(styleProps)

  const textField = useCallback(({ input, meta }, customExternalError) => {
    const inputClasses = [classes.input]
    if (meta.error) {
      inputClasses.push('error')
    }
    if (!inputFirst) {
      inputClasses.push('rightAlign')
    }
    if (customExternalError !== undefined) {
      inputClasses.push('error')
    }

    return (
      <TextField
        type="number"
        placeholder={'0.00'}
        inputProps={{
          min: 0,
          className: inputClasses,
          inputMode: 'decimal'
        }}
        InputProps={{
          disableUnderline: true
        }}
        name={input.name}
        value={input.value}
        onChange={event => {
          if (onValueChange) {
            onValueChange(event.target.value)
          }
          input.onChange(event)
        }}
        onKeyDown={(event) => {
		  const splitted = (event.target.value + "").split(".")
		  const maxDigitLengthReached = splitted.length === 2 && splitted[1].length >= 18
          const alwaysAllowed = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'a', 'c', 'v', 'x']
            .includes(event.key)
          const isAllowed = ['Backspace', 'Delete', '.', ',', 'ArrowLeft', 'ArrowRight', 'a', 'c', 'v', 'x']
            .includes(event.key)
          const isNumber = !isNaN(Number(event.key))
          if (!(isAllowed || isNumber) || maxDigitLengthReached) {
            if (alwaysAllowed) {
              return
            }
            event.preventDefault()
          }
        }}
      />
    )
  }, [classes])

  const currencySwitch = (
    <Grid item>
      <Grid
        container
        wrap={'nowrap'}
        alignItems={'center'}
        className={classes.switchContainer}
        onClick={switchClick}
      >
        <MuiIcon>
          <img alt={currency.key} src={currency.icon} height={24} width={24}/>
        </MuiIcon>

        <Typography
          className={classes.currencyLabel}
        >
          {currency.text}
        </Typography>

        <MuiIcon
          className={classes.switchIcon}
        >
          <img alt={'Switch'} src={SwitchIcon}/>
        </MuiIcon>
      </Grid>
    </Grid>
  )
  const inputContainer = (
    <Grid item>
      <Field
        name={name}
        validate={validators}
        render={(any) => textField(any, customExternalError)}
      />
    </Grid>
  )
  return (
    <Grid
      container
      wrap={'nowrap'}
      alignItems={'center'}
      justify={'space-between'}
      className={classes.rootContainer}
    >
      {inputFirst &&
      <>
        {inputContainer}
        {currencySwitch}
      </>
      }
      {!inputFirst &&
      <>
        {currencySwitch}
        {inputContainer}
      </>
      }
    </Grid>
  )
}

AmountInput.propTypes = {
  name: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
  }).isRequired,
  switchClick: PropTypes.func,
  validators: PropTypes.func,
  onValueChange: PropTypes.func,
  inputFirst: PropTypes.bool,
  customExternalError: PropTypes.object
}

export default AmountInput
