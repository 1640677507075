import React from 'react'
import { SupportedProviders } from '@celo-tools/use-contractkit'
import { isMobile } from '../../utils/browser'

// Active Wallet Providers that are displayed in the Connection Wallet popup
const ACTIVE_PROVIDERS_MOBILE = [
  SupportedProviders.MetaMask,
  SupportedProviders.Valora,
  SupportedProviders.imToken
]
const ACTIVE_PROVIDERS_DESKTOP = [
  SupportedProviders.Ledger,
  SupportedProviders.CeloExtensionWallet,
  SupportedProviders.MetaMask
]

const handleCEW = (onClick) => () => {
  if (!window.celo) {
    const extensionUrl =
      'https://chrome.google.com/webstore/detail/celoextensionwallet/kkilomkmpmkbdnfelcpgckmpcaemjcdh?hl=en'
    window.open(extensionUrl, '_blank')
  } else {
    return onClick()
  }
}

const imTokenMobileAppFallback = () => {
  var now = new Date().valueOf()
  setTimeout(function () {
    if (new Date().valueOf() - now > 100) {
      return
    }
    window.location = 'https://token.im/download'
  }, 25)
  return (window.location = `imtokenv2://navigate/DappView?url=${window.location.origin}/#/`)
}

const handleProvider = (provider, onClick) => () => {
  localStorage.setItem('provider', provider)
  const isImToken = !!window.imToken || window?.ethereum?.isImToken
  const isMetamask = typeof window.ethereum !== 'undefined'
  if (provider === 'MetaMask' && !isMetamask) {
    return (window.location = `https://metamask.app.link/dapp/${window.location.host}/#/`)
  }
  if (provider === 'imToken' && !isImToken) {
    return imTokenMobileAppFallback()
  }
  return onClick()
}

const renderProvider = (walletProvider) => {
  const { provider } = walletProvider
  if (
    (isMobile && !ACTIVE_PROVIDERS_MOBILE.includes(provider.name)) ||
    (!isMobile && !ACTIVE_PROVIDERS_DESKTOP.includes(provider.name))
  ) {
    return null
  }

  const handleConnect =
    provider.name === SupportedProviders.CeloExtensionWallet
      ? handleProvider(provider.name, handleCEW(walletProvider.onClick))
      : handleProvider(provider.name, walletProvider.onClick)

  const providerDescription =
    (provider.name === 'MetaMask' || provider.name === 'imToken') &&
    typeof provider.description === 'string'
      ? provider.description.replace('Ubeswap', 'Moola')
      : provider.description

  return (
    <button
      className="tw-flex tw-flex-row tw-items-center tw-text-left tw-py-4 tw-pl-3 tw-pr-2 tw-w-80 md:tw-w-96 hover:tw-bg-gray-100 dark:hover:tw-bg-gray-700 tw-transition tw-rounded-md  focus:tw-outline-none"
      onClick={handleConnect}
    >
      <div className="tw-flex tw-flex-shrink-0 tw-mr-4 md:tw-mr-5 ">
        <span className="tw-my-auto">
          {typeof provider.icon === 'string' ? (
            <img
              src={provider.icon}
              alt={`${provider.name} logo`}
              style={{ height: '42px', width: '42px' }}
            />
          ) : (
            <provider.icon style={{ height: '42px', width: '42px' }} />
          )}
        </span>
      </div>
      <div>
        <div className="tw-pb-1 tw-font-medium dark:tw-text-gray-300">
          {provider.name}
        </div>
        <div className="tw-text-sm tw-text-gray-600 dark:tw-text-gray-400">
          {providerDescription}
        </div>
      </div>
    </button>
  )
}

export default renderProvider
