import * as PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import { Typography, withStyles } from '@material-ui/core'
import { Field } from 'react-final-form'

const styles = (theme) => ({
  root: {
    border: `1px solid ${theme.palette.neutral.grey1}`,
    borderRadius: '6px',
    '& > *': {
      width: '50%',
      height: '40px'
    }
  },
  rightItem: {
    borderRadius: '6px 0 0 6px'
  },
  leftItem: {
    borderRadius: '0 6px 6px 0'
  },
  label: {
    textAlign: 'center',
    position: 'relative',
    top: '8px',
    fontFamily: 'Jost-500-Medium'
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  }
})

const ButtonSwitch = ({ classes, options, name, onValueChange }) => {

  return (
    <Field
      name={name}
    >
      {({ input }) => (
        <Grid
          container
          className={classes.root}
        >
          {options.map((option, index) => {
            return (
              <Grid
                item
                key={option.value}
                className={classNames(
                  option.selectedLoanTerms && classes.selected,
                  index % 2 === 0 ? classes.rightItem : classes.leftItem,
                  option.disabled && classes.disabled
                )}
                onClick={() => {
                  if (!option.disabled) {
                    input.onChange(option.value)
                    if (onValueChange) {
                      onValueChange(option.value)
                    }
                  }
                }}
              >
                <Typography
                  className={classes.label}
                >
                  {option.label}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Field>
  )
}

ButtonSwitch.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    selectedLoanTerms: PropTypes.bool
  })),
  name: PropTypes.string.isRequired,
  onValueChange: PropTypes.func
}

export default withStyles(styles)(ButtonSwitch)
