import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 400px;
  margin: 60px;
`

export const TextBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin-bottom: 20px;
`