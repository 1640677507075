import React, { useContext } from 'react'
import withView from '../../components/HOCs/withView'
import useLogin from '../../components/hooks/useLogin'
import { Text } from 'react-native'
import Button from '@material-ui/core/Button'
import { Container, TextBlock } from './elements'
import AppContext from '../../components/AppContext'
import { CONTRACT_KIT } from '../../utils/constants'

const isIOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(window.navigator.platform) 
  || (window.navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const AboutValoraScreen  = () => {
  const context = useContext(AppContext)
  const login = useLogin()

  const getValora = () => {
    const appUrl = isIOS() 
      ? 'https://apps.apple.com/ua/app/valora-mobile-payments-app/id1520414263'
      : 'https://play.google.com/store/apps/details?id=co.clabs.valora'

    window.open(appUrl, '_blank')
  }

  return (
    <Container>
      <TextBlock>
        <Text>
          Valora is a global payments app that allows you to exchange and securely store value on your mobile phone. You can use Valora to send and receive money instantly (typically within seconds) to and from anyone around the world. You can also use Valora to pay for goods and services where Celo Dollars are accepted, and even save and store funds on Valora securely.
        </Text>
      </TextBlock>
      <Button color="secondary" variant="contained" onClick={getValora}>Get Valora</Button>
      {!context.user && (
        <Button color="secondary" variant="contained" onClick={() => login(CONTRACT_KIT)}>Connect Wallet</Button>
      )}
    </Container>
  )
}

export default withView(AboutValoraScreen)
