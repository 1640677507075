import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import BottomPopup from '../BottomPopup'
import CloseIcon from '../../assets/images/close.svg'
import { FIAT_CURRENCIES } from '../../utils/constants'
import InfoIcon from '../InfoIcon'
import {
  useGetUserAccountInfoBalance,
  useGetUserAccountInfoBorrow
} from '../../api'
import { formatNumber } from '../../utils/format'

const styles = (theme) => ({
  balanceBox: {
    left: '0px',
    top: '0px'
  },
  headerText: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    fontFamily: 'Jost-700-Bold'
  },
  headerValue: {
    fontSize: '20px',
    lineHeight: '28px',
    fontFamily: 'Jost-700-Bold',
    fontWeight: 700
  },
  greyOutColor: {
    color: theme.palette.text.placeholder
  },
  tooltip: {
	width: '250px',
	marginLeft: '-100px',
	'&::after': {
		left: '34%'
	}
  }
})

const ZERO_BALANCE = `0.00`

const BalanceBox = ({ t, classes, user, currentCurrency, forceRefresh }) => {
  const [showBalancePopup, setShowBalancePopup] = useState(null)
  const [accountBalance, setAccountBalance] = useState(null)
  const isBalanceZero = '$0.00' === accountBalance
  const accountInfoBorrow = useGetUserAccountInfoBorrow(
    user?.address,
    forceRefresh
  )

  const accountInfoBalance = useGetUserAccountInfoBalance(
    user?.address,
    currentCurrency,
    forceRefresh
  )

  const getUserDefaultCurrencySign = () => {
    // get the default currency selected by user from profile ?!
    if (currentCurrency === FIAT_CURRENCIES.eur.key) return '€'
    return '$'
  }

  useEffect(() => {
    let newBalance = `${getUserDefaultCurrencySign()}${ZERO_BALANCE}`
    if (
      accountInfoBalance.isSuccess &&
      accountInfoBalance.data?.status !== 'ERROR' &&
      accountInfoBalance.data?.balanceGrandTotal?.balance
    ) {
      newBalance = `${getUserDefaultCurrencySign()}${formatNumber(
        accountInfoBalance.data?.balanceGrandTotal?.balance,
        2
      )}`
    }
    setAccountBalance(newBalance)
  }, [
    accountInfoBalance.isSuccess,
    accountInfoBalance.data,
    accountInfoBorrow.isSuccess,
    accountInfoBorrow.data
  ])

  return (
    <>
      <Box pt={'16px'} pb={'16px'}>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.balanceBox}
          onClick={() => setShowBalancePopup(true)}
        >
          <Grid item>
            <Grid container alignItems={'center'}>
              <Typography variant="body2" className={classes.headerText}>
                {t('HOME.ACCOUNT_TAB.BALANCE')}
              </Typography>
              <InfoIcon
			  	classes={{tooltip: classes.tooltip}}
                title={t('HOME.ACCOUNT_TAB.BALANCE')}
                text={t('HOME.ACCOUNT_TAB.BALANCE.INFO')}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              className={classNames(
                classes.headerValue,
                isBalanceZero && classes.greyOutColor
              )}
            >
              {accountBalance}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {showBalancePopup && (
        <BottomPopup
          show={true}
          label={t('HOME.ACCOUNT_TAB.BALANCE')}
          onClose={() => setShowBalancePopup(null)}
          icon={CloseIcon}
          children={
            <Typography variant="body1">
              {t('HOME.ACCOUNT_TAB.BALANCE.INFO')}
            </Typography>
          }
          primaryButtonTitle={t('GENERAL.DONE')}
          primaryButtonClick={() => setShowBalancePopup(null)}
        />
      )}
    </>
  )
}

export default withTranslation()(withStyles(styles)(BalanceBox))

BalanceBox.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  currentCurrency: PropTypes.string
}
