import React from 'react'
import { useTheme } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { isMobile } from '../../utils/browser'

const styles = (theme) => ({
  root: {
    color: isMobile ? theme.palette.text.terms : theme.palette.text.termsDesktop,
    fontFamily: 'Jost-400-Book',
    padding: '4% 10%',
    fontSize: '16px',
    lineHeight: '24px'
  },
  title: {
    fontSize: '30px',
    textAlign: 'center',
    marginBottom: '10px',
    fontWeight: '600',
    fontFamily: 'EBGaramond-600-SemiBold'
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '20px',
    marginBottom: '50px',
    fontWeight: 'Normal',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px'
    }
  },
  bold: {
    fontWeight: 'Bold',
    fontSize: '24px',
    fontFamily: 'Jost-400-Book'
  },
  italic: {
    fontStyle: 'italic',
    fontFamily: 'Jost-400-Book'
  },
  link: {
    textDecoration: 'underline',
    color: isMobile ? theme.palette.text.terms : theme.palette.text.termsDesktop,
  },
  list: {
    paddingLeft: '50px',
    fontWeight: '600',
    '& li': {
      listStyleType: 'disc',
      fontSize: '18px',
      lineHeight: '24px',
      padding: '5px 0'
    },
    '& p': {
      fontSize: '16px',
      lineHeight: '24px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '30px'
    }
  },
  sectionTitle: {
    fontSize: '30px',
    lineHeight: '38px',
    textDecoration: 'none',
    fontFamily: 'EBGaramond-600-SemiBold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '28px'
    }
  },
  tableDataWrapper: {
    width: '100%',
    overflow: 'auto'
  },
  tableData: {
    width: '100%',
    borderCollapse: 'collapse',
    '& td': {
      border: '1px solid #EDEEEF',
      padding: '3px 10px'
    }
  },
  secondaryList: {
    paddingLeft: '50px',
    '& li': {
      listStyleType: 'disc',
      fontSize: '16px',
      lineHeight: '26px',
      padding: '5px 0',
      '& p': {
        [theme.breakpoints.down('sm')]: {
          margin: '5px 0'
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '30px'
    }
  },
  thirdList: {
    paddingLeft: '50px',
    '& li': {
      listStyleType: 'circle',
      fontSize: '16px',
      lineHeight: '26px',
      padding: '5px 0',
      color: isMobile ? theme.palette.text.terms : theme.palette.text.termsDesktop,
    },
    '& a': {
      color: isMobile ? theme.palette.text.terms : theme.palette.text.termsDesktop,
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        wordBreak: ' break-all'
      }
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '30px'
    }
  }
})

const Terms = ({ t, classes }) => {
  const theme = useTheme()

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>MOOLA Privacy Policy</h1>
      <h2 className={classes.subtitle}>Last Updated: March 24, 2021</h2>
      <ul className={classes.list}>
        <li>Overview</li>
        <li>Information We Collect</li>
        <li>How We Use the Information We Collect</li>
        <li>How We Share the Information We Collect</li>
        <li>Third Parties</li>
        <li>Your Privacy Rights – General Information</li>
        <li>How to Make Data Requests – Information for California Residents</li>
        <li>How to Make Data Requests – Information for EU Data Subjects</li>
        <li>Information for Nevada Residents</li>
        <li>Children’s Privacy</li>
        <li>Security</li>
        <li>Updates to This Privacy Policy</li>
        <li>Contact Information</li>
      </ul>
      <p className={classes.sectionTitle}>Overview</p>
      <p>This Privacy Policy describes MOOLA Inc.’s policies regarding the collection, use, and disclosure of
        information about you in connection with your use of our Services, including those offered through our website
        and the MOOLA application (<a className={classes.link} href='https://app.moola.market/'
                                      target='_blank'>app.moola.market</a>), available through Valora (collectively, the
        “Services”). The terms
        “we”, “us”, and “MOOLA” refers to, Inc., with its headquarters in San Francisco, California.</p>
      <p>When you use our Services, you consent to our collection, use, and disclosure of information about you as
        described in this Privacy Policy.</p>
      <p>A link to a Portable Document Format (PDF) version of the policy can be found here: <a className={classes.link}
                                                                                                href='https://moola.market/'
                                                                                                target='_blank'>https://moola.market/</a>
      </p>
      <p className={classes.sectionTitle}>Information We Collect</p>
      <p>MOOLA collects personal information to facilitate your use of MOOLA Services. “Personal information” means
        information that identifies or is reasonably capable of identifying an individual, directly or indirectly, and
        information that is being associated with an identified or reasonably identifiable individual.</p>
      <p>Personal Information That We Collect Directly From You</p>
      <p>MOOLA collects the following categories of information directly from individuals:</p>
      <p className={classes.italic}>Through Its Website</p>
      <div className={classes.tableDataWrapper}>
        <table cellPadding='0' cellSpacing='0' className={classes.tableData}>
          <tbody>
          <tr>
            <td><p className={classes.bold}>Category of Personal Information</p></td>
          </tr>
          <tr>
            <td><p>Email Address</p></td>
          </tr>
          <tr>
            <td><p>Information Provided to Moola using the Chat function on the Website, powered by Gist, whose Privacy
              Policy is available at: <a className={classes.link}
                                         href='https://getgist.com/privacy/'
                                         target='_blank'>https://getgist.com/privacy/</a>
            </p></td>
          </tr>
          </tbody>
        </table>
      </div>
      <p className={classes.italic}>Through Its Application Integration with the Valora Application</p>
      <p>You are responsible for the maintenance of the Account Key that you create with the Valora application (<a
        className={classes.link}
        href='https://valoraapp.com/privacy'
        target='_blank'>https://valoraapp.com/privacy</a>) to use <a className={classes.link}
                                                                     href='https://app.moola.market/'
                                                                     target='_blank'>app.moola.market</a>. When using
        the MOOLA application,
        you will be asked to provide the following information to Moola:</p>

      <table cellPadding='0' cellSpacing='0' className={classes.tableData}>
        <tbody>
        <tr>
          <td><p className={classes.bold}>Category of Personal Information</p></td>
        </tr>
        <tr>
          <td><p>Phone Number</p></td>
        </tr>
        <tr>
          <td><p>Valora Wallet Identification</p></td>
        </tr>
        </tbody>
      </table>
      <p>The following categories of information are displayed in the use of <a className={classes.link}
                                                                                href='https://app.moola.market/'
                                                                                target='_blank'>app.moola.market</a> through
        the Valora
        application, whose privacy policy is available at:</p>
      <div className={classes.tableDataWrapper}>
        <table cellPadding='0' cellSpacing='0' className={classes.tableData}>
          <tbody>
          <tr>
            <td><p className={classes.bold}>Category of Personal Information</p></td>
          </tr>
          <tr>
            <td><p>Valora cUSD Balance</p></td>
          </tr>
          <tr>
            <td><p>Valora CELO Balance</p></td>
          </tr>
          <tr>
            <td><p>MOOLA cUSD Balance</p></td>
          </tr>
          <tr>
            <td><p>Total cUSD Debt</p></td>
          </tr>
          <tr>
            <td><p>cUSD Deposit Annual Percentage Yield (APY)</p></td>
          </tr>
          <tr>
            <td><p>cUSD Borrow Type</p></td>
          </tr>
          <tr>
            <td><p>cUSD Borrow Annual Percentage Rate (APR)</p></td>
          </tr>
          <tr>
            <td><p>cUSD Origination Fees Incurred</p></td>
          </tr>
          <tr>
            <td><p>MOOLA CELO Balance</p></td>
          </tr>
          <tr>
            <td><p>Total CELO Debt</p></td>
          </tr>
          <tr>
            <td><p>CELO Deposit Annual Percentage Yield (APY)</p></td>
          </tr>
          <tr>
            <td><p>CELO Borrow Type</p></td>
          </tr>
          <tr>
            <td><p>CELO Borrow Annual Percentage Rate (APR)</p></td>
          </tr>
          <tr>
            <td><p>CELO Origination Fees Incurred</p></td>
          </tr>
          <tr>
            <td><p>Total Collateral Value (in units of CELO)</p></td>
          </tr>
          <tr>
            <td><p>Total Amount Borrowed (in units of CELO)</p></td>
          </tr>
          <tr>
            <td><p>Available to Borrow (in units of CELO)</p></td>
          </tr>
          <tr>
            <td><p>Liquidation Threshold</p></td>
          </tr>
          <tr>
            <td><p>Maximum Loan to Value</p></td>
          </tr>
          <tr>
            <td><p>Health Factor</p></td>
          </tr>
          </tbody>
        </table>
      </div>
      <p className={classes.bold}>Accuracy and Retention of Personal Information</p>
      <p>MOOLA takes reasonable and practicable steps to ensure that your Personal Information held by us is accurate
        with regard to the purposes for which it is to be used, and is not kept longer than is necessary for the
        fulfillment of the purpose for which it is to be used.</p>
      <p className={classes.sectionTitle}>How We Use the Information We Collect</p>
      <p>The Personal Information we collect and the practices described above are done in an effort to provide you with
        the best experience possible, protect you from risks related to improper use and fraud, and help us maintain and
        improve MOOLA’s offerings. We may use your Personal Information to:</p>
      <ul className={classes.secondaryList}>
        <li><p><b>Provide you with MOOLA Services.</b> MOOLA uses your Personal Information to provide you with our
          Services.</p></li>
        <li><p><b>Comply with legal and regulatory requirements.</b> MOOLA processes your Personal Information as
          required by applicable laws and regulations. </p></li>
        <li><p><b>Protect the security and integrity of our services.</b> MOOLA uses your Personal Information to
          further our security practices related to personal information. </p></li>
        <li><p><b>Provide you with customer support.</b> MOOLA processes your Personal Information anytime that you
          reach out to our Customer Support team with issues arising from your account.</p></li>
        <li><p><b>Optimize and enhance our Services</b> MOOLA uses your Personal Information to understand how our
          products and services are being used to help us improve our services and develop new products.</p></li>
        <li><p><b>Market our products to you.</b> MOOLA may contact you with information about our and Services. We will
          only do so with your permission, which can be revoked at any time.</p></li>
        <li><p><b>Other business purposes.</b> MOOLA may use your information for additional purposes if that purpose is
          disclosed to you before we collect the information or if we obtain your consent.</p></li>
      </ul>
      <p className={classes.sectionTitle}>How We Share the Information We Collect</p>
      <p>MOOLA will not share your Personal Information with third parties, except as described below.</p>
      <ul className={classes.secondaryList}>
        <li><p><b>Third-Party Service Providers.</b> MOOLA may share your Personal Information with third-party service
          providers for business or commercial purposes. Your Personal Information may be shared so that they can
          provide us with services, including identity verification, fraud detection and prevention, security threat
          detection, payment processing, customer support, data analytics, Information Technology, advertising,
          marketing, data processing, network infrastructure, storage, transaction monitoring, and tax reporting. MOOLA
          may share your Personal Information with these service providers only so that they can provide us with
          services, and we prohibit our service providers from using or disclosing your Personal Information for any
          other purpose. Our third-party service providers are subject to strict confidentiality obligations.</p></li>
        <li><p><b>Third Party Vendors.</b> MOOLA utilizes the following the third-party applications, tools, and
          websites to provide its Services. Please review the links the privacy practices of these third parties below.
          MOOLA disclaims all liability arising from the use of these third-party applications, tools, and websites.</p>
          <ul className={classes.thirdList}>
            <li>Discord (<a href='https://discord.com/privacy'>https://discord.com/privacy</a>);</li>
            <li>Gist (<a href='https://getgist.com/privacy/'>https://getgist.com/privacy/</a>);</li>
            <li>LinkedIn (<a
              href='https://www.linkedin.com/legal/privacy-policy'>https://www.linkedin.com/legal/privacy-policy</a>);
            </li>
            <li>Twitter (<a href='https://twitter.com/en/privacy'>https://twitter.com/en/privacy</a>); and</li>
            <li>Valora (<a href='https://valoraapp.com/privacy'>https://valoraapp.com/privacy</a>).</li>
          </ul>
        </li>

        <li><p><b>Law Enforcement.</b> MOOLA may be compelled to share your Personal Information with law enforcement,
          government officials, and regulators.</p></li>
        <li><p><b>Corporate Transactions.</b> MOOLA may disclose Personal Information in the event of a proposed or
          consummated merger, acquisition, reorganization, asset sale, or similar corporate transaction, or in the event
          of a bankruptcy or dissolution.</p></li>
        <li><p><b>Professional Advisors.</b> MOOLA may share your Personal Information with our professional advisors,
          including legal, accounting, or other consulting services for purposes of audits or to comply with our legal
          obligations.</p></li>
        <li><p><b>Consent.</b> MOOLA may share or disclose your information with your consent.</p></li>
      </ul>
      <p>If MOOLA decides to modify the purpose for which your Personal Information is collected and used, we will amend
        this Privacy Policy.</p>
      <p className={classes.sectionTitle}>Your Privacy Rights – General Information</p>
      <p>MOOLA is required under certain domestic and international data laws to provide certain information to
        individuals about how MOOLA collects, uses, and shares your personal information, and about the rights and
        choices that you may have concerning your personal information. The following sections provide an overview of
        the personal information that MOOLA collects, uses, and shares; outlines the rights that you have concerning
        your data; and the actions that you can take to make certain requests concerning your personal information.</p>
      <p className={classes.bold}>Personal Information That MOOLA Collects, Uses and Shares</p>
      <p>For ease of reference, Moola has compiled the list below to demonstrate how we use the categories of personal
        information we have collected, used, and disclosed.</p>
      <p>The purposes for the collection, use, and sharing of information include:</p>

      <div className={classes.tableDataWrapper}>
        <table cellPadding='0' cellSpacing='0' className={classes.tableData}>
          <tbody>
          <tr>
            <td><b>Category of Personal Information</b></td>
            <td><b>How It Is Collected</b></td>
            <td><b>How It Is Used</b></td>
            <td><b>How It Is Shared</b></td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td>Through use of the application on your mobile device</td>
            <td>To allow your use of <a className={classes.link} href='https://app.moola.market/'
                                        target='_blank'>app.moola.market</a></td>
            <td>Moola does not sell or share information with third parties.</td>
          </tr>
          <tr>
            <td>Valora Wallet Identification</td>
            <td>Through use of the application on your mobile device</td>
            <td>To allow your use of <a className={classes.link} href='https://app.moola.market/'
                                        target='_blank'>app.moola.market</a></td>
            <td>Moola does not sell or share information with third parties.</td>
          </tr>
          </tbody>
        </table>
      </div>
      <p className={classes.sectionTitle}>How to Make Data Requests - Information for California Residents</p>
      <p>MOOLA is required under the California Consumer Privacy Act of 2018 (“CCPA”) to provide certain information to
        California residents about how we collect, use, and share their personal information, and about the rights and
        choices California residents may have concerning their personal information. For purposes of this section,
        “Personal Information” has the meaning provided in the CCPA.</p>
      <p className={classes.bold}>Rights Afforded to California Residents</p>
      <p>California consumers have the following rights under the CCPA with respect to their Personal Information.
        Please understand, however, that these rights are not absolute, and MOOLA may decline requests as permitted
        under applicable law.</p>
      <ul className={classes.secondaryList}>
        <li>
          <b>Right to Request Access.</b> You may submit a request that MOOLA disclose the categories and specific
          pieces of personal information that we have collected, used, or disclosed about you in the past twelve (12)
          months.
          <ul className={classes.thirdList}>
            <li>Without having to submit a request to access your personal information, you have the right to know what
              categories of personal information Moola has collected from consumers generally within the past 12 months,
              where we got it, why we collected it, and with whom we shared it.
            </li>
            <li>We have not “sold” personal information within the past 12 months, as that term is defined by the
              CCPA.
            </li>
          </ul>
        </li>
        <li>
          <b>Right to Request Deletion.</b> You may submit a request that MOOLA delete the personal information that we
          have about you.
        </li>
        <li>
          <b>Right to Opt out of Sale of Personal Information.</b> You have the right to opt out of the sale of your
          Personal Information. MOOLA does not sell your Personal Information.
        </li>
        <li>
          <b>Right Not to Receive Discriminatory Treatment.</b> You have the right to exercise the privacy rights
          conferred by the CCPA without discriminatory treatment.
        </li>
      </ul>
      <p>You may submit a request to access or delete your personal information by emailing us at <a
        className={classes.link} href='mailto:info@moola.market'>info@moola.market</a>.</p>
      <p>MOOLA is legally obligated to verify your identity when you submit a request. We may request additional
        information from you to verify your identity. If you submit a request to delete your personal information, you
        will also be required to confirm the deletion request by email.</p>
      <p>You may designate an authorized agent to make a request under the CCPA on your behalf. Your authorized agent
        must submit proof that you have provided them with power of attorney pursuant to Probate Code Sections 4000 to
        4465. MOOLA may deny a request from a purported authorized agent who does not provide proof of authorization to
        act on your behalf.</p>
      <p>Please understand that we are not obligated to provide you with a copy of your personal information more than
        twice in a twelve (12) month period pursuant to the CCPA.</p>
      <p className={classes.bold}>How to Make Data Requests – Information for California Residents</p>
      <p>To make a data request, you can elect to contact MOOLA through the following method:</p>
      <ul className={classes.secondaryList}>
        <li>Email the MOOLA Privacy Team at <a className={classes.link}
                                               href='mailto:info@moola.market'>info@moola.market</a>.
        </li>
      </ul>
      <p className={classes.bold}>What happens after I submit a request?</p>
      <p>Once we receive your request, Moola must first verify your identity. Moola will take commercially reasonable
        steps to verify your identity, including asking you to provide us with information to confirm your identity
        using the information we have on file. If you wish to designate an authorized agent to make a request on your
        behalf, please indicate that when submitting your request.</p>
      <p>Moola will make good faith efforts to respond to your request. There may be circumstances under the CCPA in
        which Moola cannot, or need not, respond to your request. For example, Moola is not required to delete personal
        information needed to complete the transaction for which the personal information was collected (such as
        completing a sale), to detect fraudulent or illegal activity, or for legal compliance purposes. Moola is also
        not required to provide access or deletion where your identity could not be verified, where you have exceeded
        the number of requests you can make within a twelve-month period, or where the information contains legally
        privileged material or would violate the rights of others. If Moola determines that your request should be
        restricted in any particular instance, we will provide you with an explanation of why that determination has
        been made and a contact point for any further inquiries. </p>
      <p>Any requests that can be processed by Moola will be done so in no more than forty-five (45) days from the date
        we receive your request, unless we notify you that an extension is required. In case of an extended completion
        period, Moola will process your request in no more than ninety (90) days from the date of your original request.
        Consumers are limited to two requests to know/access their personal information within a twelve (12) month
        period.</p>
      <p className={classes.sectionTitle}>Information for Subjects of the EU General Data Protection Regulation</p>
      <p>MOOLA is required under the European Union General Data Protection Regulation (“GDPR”) to provide certain
        information to EU data subjects about how we collect, use, and share their personal information, and about the
        rights and choices California residents may have concerning their personal information. For purposes of this
        section, “personal information” has the meaning provided in the GDPR.</p>
      <p className={classes.bold}>Rights Afforded to EU Data Subjects</p>
      <p>EU Data Subjects have the following rights under the GDPR with respect to their Personal Information. Please
        understand, however, that these rights are not absolute, and MOOLA may decline requests as permitted under
        applicable law.</p>
      <ul className={classes.secondaryList}>
        <li><b>Right to Request Access / Right to Be Informed.</b> You may submit a request that MOOLA disclose the
          categories and specific pieces of personal information that we have collected, used, or disclosed about you.
          <ul className={classes.thirdList}>
            <li>Without having to submit a request to access your personal information, you have the right to be
              informed (right to know) what categories of personal information MOOLA has collected from consumers
              generally, where we got it, why we collected it, and with whom we shared it.
            </li>
          </ul>
        </li>
        <li><b>Right to Request Erasure.</b> You may submit a request that MOOLA delete the personal information that we
          have about you.
        </li>
        <li><b>Right to Rectification (Correct or Update Your Personal Information).</b> You may submit a request that
          MOOLA correct or update personal information that MOOLA may have about you.
        </li>
        <li><b>Right to Data Portability.</b> You may submit a request that MOOLA provide you with a readily accessible,
          portable format of the personal information MOOLA may have collected, processed, or stored about you.
        </li>
        <li><b>Right to Object to the Processing.</b> You may submit a request to MOOLA to revoke your consent to the
          processing of the personal information MOOLA may have collected, processed, or stored about you.
        </li>
        <li><b>Right to Restrict Processing.</b> You may submit a request to MOOLA to restrict the processing of the
          personal information MOOLA may have collected, processed, or stored about you.
        </li>
      </ul>
      <p>You may submit a request to access or delete your personal information by emailing us at <a
        className={classes.link} href='mailto:info@moola.market'>info@moola.market</a>. </p>
      <p>MOOLA is legally obligated to verify your identity when you submit a request. We may request additional
        information from you to verify your identity. If you submit a request to delete your personal information, you
        will also be required to confirm the deletion request by email.</p>
      <p>You may designate an authorized agent to make a request under the CCPA on your behalf. Your authorized agent
        must submit proof that you have provided them with power of attorney pursuant to Probate Code Sections 4000 to
        4465. MOOLA may deny a request from a purported authorized agent who does not provide proof of authorization to
        act on your behalf.</p>
      <p>The right to complain to a data protection authority about the collection and use of Personal Information. For
        more information, please contact your local data protection authority. Contact details for data protection
        authorities in the EEA and UK are available <a className={classes.link}
                                                       href='https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm'
                                                       target='_blank'>here</a> and Switzerland are available <a
          className={classes.link}
          href='https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html'
          target='_blank'>here</a>. </p>
      <p>If Personal Information is collected or processed on the basis of consent, the data subject can withdraw their
        consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted
        prior to your withdrawal, nor will it affect the processing of your Personal Information conducted in reliance
        on lawful processing grounds other than consent.</p>
      <p className={classes.bold}>How to Make Data Requests (for EU Data Subjects)</p>
      <p>To make a data request, you can elect to contact MOOLA through the following method:</p>
      <ul className={classes.secondaryList}>
        <li>Email the MOOLA Privacy Team at <a className={classes.link}
                                               href='mailto:info@moola.market'>info@moola.market</a>.
        </li>
      </ul>
      <p className={classes.bold}>What happens after I submit a request?</p>
      <p>Once we receive your request, we must first verify your identity. We will take commercially reasonable steps to
        verify your identity, including asking you to provide us with information to confirm your identity using the
        information we have on file. If you wish to designate an authorized agent to make a request on your behalf,
        please indicate that when submitting your request.</p>
      <p>We will make good faith efforts to respond to your request. There may be circumstances under the GDPR in which
        we cannot, or need not, respond to your request. For example, we are not required to delete personal information
        needed to complete the transaction for which the personal information was collected (such as completing a sale),
        to detect fraudulent or illegal activity, or for legal compliance purposes. We are also not required to provide
        completion of a rights request where your identity could not be verified, or where the information contains
        legally privileged material or would violate the rights of others. If we determine that your request should be
        restricted in any particular instance, we will provide you with an explanation of why that determination has
        been made and a contact point for any further inquiries. </p>
      <p>Any requests that can be processed by us will be done so in no more than thirty (30) days from the date we
        receive your request, unless Moola notifies you that an extension is required. In case of an extended completion
        period, we will process your request in no more than ninety (90) days from the date of your original
        request.</p>
      <p className={classes.sectionTitle}>Information for Nevada Residents</p>
      <p>Nevada law (SB 220) requires website operators to provide a way for Nevada consumers to opt out of the sale of
        certain information that the website operator may collect about them. MOOLA does not sell your personal
        information to third parties as defined in Nevada law, and will not do so in the future without providing you
        with notice and an opportunity to opt-out of such sale as required by law. If you have any questions regarding
        our data privacy practices or our compliance with Nevada data privacy law, please contact us at <a
          className={classes.link} href='mailto:info@moola.market'>info@moola.market</a>. </p>
      <p className={classes.sectionTitle}>Children’s Privacy</p>
      <p>MOOLA Services are intended for general audiences and is not directed to children under 17. We do not knowingly
        collect personal information from children under 17. If you become aware that a child has provided us with
        personal information without parental consent, please contact us at <a className={classes.link}
                                                                               href='mailto:info@moola.market'>info@moola.market</a>.
        If Moola becomes aware
        that a child under 17 has provided us with personal information without parental consent, MOOLA takes steps to
        remove such information and terminate the child’s account.</p>
      <p className={classes.sectionTitle}>Security </p>
      <p>No security is foolproof, and the Internet is an insecure medium. Moola cannot guarantee absolute security, but
        we work hard to protect MOOLA and you from unauthorized access to or unauthorized alteration, disclosure, or
        destruction of Personal Information that we collect and store. </p>
      <p>MOOLA addresses its guarantees as to privacy and security in the various agreements and data processing
        addendum that it enters into contractually with its Customers.</p>
      <p className={classes.sectionTitle}>Updates to This Privacy Policy</p>
      <p>MOOLA may revise this Privacy Policy from time to time. The most current version of the Privacy Policy will
        govern our collection, use, and disclosure of information about you and will be located at <a
          className={classes.link}
          href='https://moola.market.'
          target='_blank'>https://moola.market.</a></p>
      <p>If MOOLA makes material changes to this Privacy Policy, we will notify you by email or by posting a notice on
        our website prior to or on the effective date of the changes. By continuing to access or use the services after
        those changes become effective, you acknowledge the revised Privacy Policy.</p>
      <p className={classes.sectionTitle}>Contact Information</p>
      <p>If you have questions or concerns regarding this policy or our processing of your Personal Information, please
        feel free to contact MOOLA through the following:</p>
      <p>Email: <a className={classes.link} href='mailto:info@moola.market'>info@moola.market</a></p>
    </div>
  )
}

export default (withStyles(styles)(Terms))
