import axios from 'axios'
import LendingPoolAddressesProvider from './abi/LendingPoolAddressProvider.json'
import MerkleDistributorAbi from './abi/Merkle.json'
import AToken from './abi/AToken.json'
import LendingPool from './abi/LendingPool.json'
import LendingPoolCore from './abi/LendingPoolCore.json'
import Oracle from './abi/Oracle.json'
import Web3 from 'web3'
import { newKitFromWeb3, StableToken } from '@celo/contractkit'
import config from './config'

axios.defaults.baseURL = config.apiUrl

export const provider = config.isTestnet
  ? 'https://alfajores-forno.celo-testnet.org'
  : 'https://forno.celo.org' // or 'wss://forno.celo.org/ws' (for websocket support)

export const web3 = new Web3(provider)
export const kit = newKitFromWeb3(web3)

let contractData = null
const initContract = async () => {
  const addressProvider = config.isTestnet
    ? new kit.web3.eth.Contract(
        LendingPoolAddressesProvider,
        '0x6EAE47ccEFF3c3Ac94971704ccd25C7820121483'
      ) // alfajores
    : new kit.web3.eth.Contract(
        LendingPoolAddressesProvider,
        '0x7AAaD5a5fa74Aec83b74C2a098FBC86E17Ce4aEA'
      ) // mainnet

  const CELO = await kit.contracts.getGoldToken()
  const cUSD = await kit.contracts.getStableToken()
  const cEUR = await kit.contracts.getStableToken(StableToken.cEUR)
  const mooToken = config.isTestnet
    ? new kit.web3.eth.Contract(
        AToken,
        '0x7258C9d1Da57db5aB57b9Ad5C7415F2B0D453376'
      )
    : new kit.web3.eth.Contract(
        AToken,
        '0x17700282592D6917F6A73D0bF8AcCf4D578c131e' //0x6437Adb4F60f7a14366a0E546A8B20041a824289
      )

  const lendingPool = new kit.web3.eth.Contract(
    LendingPool,
    await addressProvider.methods.getLendingPool().call()
  )
  const lendingPoolCore = new kit.web3.eth.Contract(
    LendingPoolCore,
    await addressProvider.methods.getLendingPoolCore().call()
  )
  const priceOracle = new kit.web3.eth.Contract(
    Oracle,
    await addressProvider.methods.getPriceOracle().call()
  )
  const merkleDistributor = config.isTestnet
    ? new kit.web3.eth.Contract(
        MerkleDistributorAbi,
        '0x9743862c25FfF681Dc4FC80f6daC0A57995df0F1'
      ) // alfajores
    : new kit.web3.eth.Contract(
        MerkleDistributorAbi,
        '0x6437Adb4F60f7a14366a0E546A8B20041a824289' //0x52B4f0b2E9bCAa650876A00B60aceE50280Ac5a4
      ) // mainnet

  const tokens = {
    CELO: CELO,
    cUSD: cUSD,
    cEUR: cEUR
  }
  const reserves = {
    CELO: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    cUSD: cUSD.contract.options.address,
    cEUR: cEUR.contract.options.address
  }

  contractData = {
    CELO,
    cUSD,
    cEUR,
    lendingPool,
    lendingPoolCore,
    tokens,
    reserves,
    priceOracle,
    merkleDistributor,
    mooToken
  }
}

export const getContract = async () => {
  if (!contractData) {
    await initContract()
  }
  return contractData
}
