import {
  CURRENCIES,
  FIAT_CURRENCIES,
  RISK_FACTOR_STATE,
  TRANSACTIONS
} from '../utils/constants'
import BN from 'bignumber.js'

export const getReserveData = async (currency) => {
  const data = {
    cusd: {
      currency: 'cusd',
      totalDeposited: 120000,
      availableLiquidity: 15000,
      totalBorrowStable: 10000,
      totalBorrowVariable: 213333,
      stableBorrowAPR: 22.75,
      variableBorrowAPR: 22.75,
      utilizationRate: 19,
      apy: 9.1
    },
    celo: {
      currency: 'celo',
      totalDeposited: 132222,
      availableLiquidity: 123233,
      totalBorrowStable: 12331,
      totalBorrowVariable: 87464,
      stableBorrowAPR: 13.75,
      variableBorrowAPR: 12.75,
      utilizationRate: 9,
      apy: 10.1
    }
  }
  return data[currency]
}

export const getStatisticsData = async (currency) => {
  const data = {
    activeUser: 16,
    currency: 'cEURO',
    dateTime: 1622127372.526095,
    status: 'OK',
    totalBorrowed: 108445.619873384,
    totalDeposited: 1571072.523091868
  }
  return data
}

export const getUserAccountInfoBalance = async (currency) => {
  const data = {
    status: 'OK',
    dateTime: '05-06-2021 19:14:53.473253',
    userPublicKey: '1',
    currency: 'cUSD',
    balanceGrandTotal: {
      balance: 95065
    }
  }
  return data
}

export const getUserAccountInfoDeposit = async () => {
  const data = {
    status: 'OK',
    dateTime: '05-06-2021 19:45:23.269340',
    userPublicKey: '1',
    data: [
      {
        currency: 'CELO',
        amount: 10001,
        interest: 14.5
      },
      {
        currency: 'cUSD',
        amount: 20000,
        interest: 14.5
      },
      {
        currency: 'cEUR',
        amount: 30000,
        interest: 14.5
      }
    ]
  }
  return data
}

export const getUserAccountInfoActivity = async (userAddress, currency, pagination) => {
  if (currency.toLowerCase() === 'usd') {
    return {
      status: 'OK',
      dateTime: 1627275213.552367,
      userPublicKey: 'fb557ea1e7a4da106aff8db5c4a631cfc726e01b',
      currency: 'Celo',
      pageNo: 0,
      pageSize: 20,
      activity: [
        {
          type: 'deposit',
          source: 'Valora Wallet',
          currency: 'celo',
          amount: 5,
          value: 20,
          timestamp: 1620624749,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'deposit',
          source: 'Valora Wallet',
          currency: 'CUSD',
          amount: 10.1,
          value: 11.02,
          timestamp: 1620527524,
          status: 'error',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'deposit',
          source: 'Valora Wallet',
          currency: 'Ceur',
          amount: 10.1,
          value: 11.02,
          timestamp: 1620527524,
          status: 'error',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'withdraw',
          source: 'Valora Wallet',
          currency: 'celo',
          amount: 9.01,
          value: 1,
          timestamp: 1620527524,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'withdraw',
          source: 'Valora Wallet',
          currency: 'cusd',
          amount: 9.01,
          value: 1,
          timestamp: 1620527524,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'withdraw',
          source: 'Valora Wallet',
          currency: 'cEUR',
          amount: 9.01,
          value: 1,
          timestamp: 1620527524,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'borrow',
          source: 'Valora Wallet',
          originationFeeAmount: 1,
          originationFeeValue: 0.2,
          currency: 'Celo',
          amount: 5,
          value: 20,
          timestamp: 1619922724,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'borrow',
          source: 'Valora Wallet',
          originationFeeAmount: 1,
          originationFeeValue: 0.2,
          currency: 'cusd',
          amount: 5,
          value: 20,
          timestamp: 1619922724,
          status: 'error',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'borrow',
          source: 'Valora Wallet',
          originationFeeAmount: 1,
          originationFeeValue: 0.2,
          currency: 'ceur',
          amount: 5,
          value: 20,
          timestamp: 1619922724,
          status: 'processing',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'repay',
          source: 'Valora Wallet',
          originationFeeAmount: 1,
          originationFeeValue: 0.2,
          currency: 'Celo',
          amount: 5,
          value: 20,
          timestamp: 1556827200,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'repay',
          source: 'Valora Wallet',
          originationFeeAmount: 1,
          originationFeeValue: 0.2,
          currency: 'cusd',
          amount: 5,
          value: 20,
          timestamp: 1556830800,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'repay',
          source: 'Valora Wallet',
          originationFeeAmount: 1,
          originationFeeValue: 0.2,
          currency: 'ceur',
          amount: 5,
          value: 20,
          timestamp: 1556746324,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'liquidate',
          currency: 'Celo',
          amount: 80,
          value: 409,
          liquidatorClaimed: 180,
          liquidatorClaimedValue: 922.50,
          penaltyPercentage: 15,
          liquidationPrice: 3.49,
          healthFactor: 200,
          timestamp: 1620624749,
          status: 'successful',
          details: 'View on explorer.celo.org'
        }
      ]
    }
  } else if (currency.toLowerCase() === 'eur') {
    return {
      status: 'OK',
      dateTime: '05-07-2021 11:30:18.450605',
      userPublicKey: 'asdd',
      activity: [
        {
          type: 'deposit',
          source: 'Valora Wallet',
          currency: 'celo',
          amount: 5,
          value: 15,
          timestamp: 1620624749,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'deposit',
          source: 'Valora Wallet',
          currency: 'CUSD',
          amount: 10.1,
          value: 4,
          timestamp: 1620527524,
          status: 'error',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'deposit',
          source: 'Valora Wallet',
          currency: 'Ceur',
          amount: 10.1,
          value: 7,
          timestamp: 1620527524,
          status: 'error',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'withdraw',
          source: 'Valora Wallet',
          currency: 'celo',
          amount: 2,
          value: 1,
          timestamp: 1620527524,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'withdraw',
          source: 'Valora Wallet',
          currency: 'cusd',
          amount: 9.01,
          value: 0.5,
          timestamp: 1620527524,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'withdraw',
          source: 'Valora Wallet',
          currency: 'cEUR',
          amount: 9.01,
          value: 2.23,
          timestamp: 1620527524,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'borrow',
          source: 'Valora Wallet',
          healthFactor: 200,
          liquidationPrice: 3.49,
          currency: 'Celo',
          amount: 5,
          value: 20,
          timestamp: 1619922724,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'borrow',
          source: 'Valora Wallet',
          healthFactor: 432423,
          liquidationPrice: 3.49,
          currency: 'cusd',
          amount: 5,
          value: 20,
          timestamp: 1619922724,
          status: 'error',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'borrow',
          source: 'Valora Wallet',
          healthFactor: 432423,
          liquidationPrice: 3.49,
          currency: 'ceur',
          amount: 5,
          value: 21,
          timestamp: 1619922724,
          status: 'processing',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'repay',
          source: 'Valora Wallet',
          healthFactor: 200,
          liquidationPrice: 3.49,
          currency: 'Celo',
          amount: 5,
          value: 25,
          timestamp: 1556827200,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'repay',
          source: 'Valora Wallet',
          healthFactor: 200,
          liquidationPrice: 3.49,
          currency: 'cusd',
          amount: 5,
          value: 27,
          timestamp: 1556830800,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'repay',
          source: 'Valora Wallet',
          healthFactor: 200,
          liquidationPrice: 3.49,
          currency: 'ceur',
          amount: 5,
          value: 29,
          timestamp: 1556746324,
          status: 'successful',
          details: 'View on explorer.celo.org',
          transactionId: '0x483297423894723984'
        },
        {
          type: 'liquidate',
          penaltyPercentage: 15,
          liquidatorClaimed: 80,
          healthFactor: 200,
          liquidationPrice: 3.49,
          currency: 'Celo',
          amount: 5,
          value: 30,
          timestamp: 1620624749,
          status: 'successful',
          details: 'View on explorer.celo.org'
        }
      ]
    }
  }
}

export const getUserAccountInfoRiskFactor = async (userPublicKey, currency) => {
  const data = {
    status: 'OK',
    dateTime: '05-07-2021 11:01:27.475244',
    userPublicKey: 'ad',
    healthfactor: 149.2,
    liquidationPrices: [
      {
        currency: CURRENCIES.celo.key,
        value: 12.1333,
        valueCurrency: CURRENCIES.cusd.key
      },
      {
        currency: CURRENCIES.celo.key,
        value: 10.5,
        valueCurrency: CURRENCIES.ceur.key
      },
      {
        currency: CURRENCIES.cusd.key,
        value: 3.5,
        valueCurrency: CURRENCIES.celo.key
      }
    ],
    scale: 'AVERAGE'
  }
  return data
}

export const getUserAccountInfoBorrow = async (userPublicKey) => {
  const data = {
    status: 'OK',
    dateTime: '05-06-2021 19:51:41.336254',
    userPublicKey,
    data: [
      {
        currency: 'CELO',
        amount: 10000,
        interest: 14.5
      },
      {
        currency: 'cUSD',
        amount: 20000,
        interest: 14.5
      },
      {
        currency: 'cEUR',
        amount: 30000,
        interest: 14.5
      }
    ]
  }
  return data
}

export const getUserAccountInfoMax = async (
  userPublicKey,
  currency,
  activityType
) => {
  let maxAmount = 40
  if (currency === CURRENCIES.celo.key) {
    maxAmount = 170
  }
  return {
    status: 'OK',
    dateTime: '05-06-2021 19:51:41.336254',
    userPublicKey,
    currency,
    maxAmount
  }
}

export const getUserAccountInfoDebt = async (userPublicKey, currency) => {
  return {
    status: 'OK',
    dateTime: '05-06-2021 19:51:41.336254',
    userPublicKey,
    debt: currency === CURRENCIES.celo.key ? 17 : 113
  }
}

export const getUserAccountInfoCurrencyDebt = async (
  userPublicKey,
  currency
) => {
  return {
    status: 'OK',
    dateTime: '05-06-2021 19:51:41.336254',
    userPublicKey,
    debt: currency === CURRENCIES.celo.key ? 17 : 113
  }
}

const exchangeRates = {
  cusd: {
    celo: 0.44143982179154195,
    ceur: 0.8398557988969365,
    cusd: 1
  },
  celo: {
    cusd: 2.2653144338940563,
    ceur: 1.9025374636308543,
    celo: 1
  },
  ceur: {
    cusd: 1.1906805922080865,
    celo: 0.5256138284349853,
    ceur: 1
  }
}
const collateral = [{ key: 'CELO', value: 0.5 }]
const debts = [
  { key: 'cUSD', value: 0.50002323232 },
  { key: 'cEUR', value: 0.200003212323 }
]
const loans = [
  { key: 'cUSD', value: 0.5 },
  { key: 'cEUR', value: 0.2 }
]

let collateralsDebts = [
  {
    currency: 'CELO',
    liquidationPrice: 4,
    currentPrice: 2.66,
    priceCurrency: 'cUSD'
  },
  {
    currency: 'CELO',
    liquidationPrice: 5.5,
    currentPrice: 2.24,
    priceCurrency: 'cEUR'
  }
]

let sums = {
  celo: {
    totalDebt: 0,
    totalCollateral: 0.5
  },
  cusd: {
    totalDebt: 0.50002323232,
    totalCollateral: 0
  },
  ceur: {
    totalDebt: 0.200003212323,
    totalCollateral: 0
  }
}

const convertoToCelo = (currency, amount) => {
  const result = BN(amount).multipliedBy(
    exchangeRates[currency.toLowerCase()].celo
  )
  return BN(amount).isGreaterThanOrEqualTo(0) ? result : 0
}

/*
 * Health Factor = (Total Deposits * Liquidation Threshold) / Total Debt
 * LTV = (Loan / Collateral) * 100
 */
const calculateRiskParams = (
  deposits,
  debts,
  collateralsDebts,
  loans,
  sums,
  liquidationThreshold,
  activityType,
  currency,
  amount
) => {
  // deposits = [{key: "CELO", value: 0.5}]
  // debts = [{key: "cUSD", value: 0.50002323232},{key: "cEUR", value: 0.200003212323}]
  // liquidationThreshold = 80
  // 1 cUSD = 0.381 CELO
  // 1 cEUR = 0.454
  if (!currency) return { healthFactor: 0, ltv: 0, tempCollaterals: [] }
  let tempCollateral = deposits.map((item) => ({
    ...item,
    value: BN(item.value)
  }))
  let tempDebts = debts.map((item) => ({ ...item, value: BN(item.value) }))
  let tempLoans = loans.map((item) => ({ ...item, value: BN(item.value) }))
  let tempDeposits = deposits.map((item) => ({
    ...item,
    value: BN(item.value)
  }))
  let tempSums = { ...sums }
  let tempCollaterals = collateralsDebts.map((item) => ({ ...item }))

  if (activityType === TRANSACTIONS.DEPOSIT) {
    const collateralFound = tempCollateral.find((item) => item.key === currency)
    if (collateralFound) {
      collateralFound.value = BN(collateralFound.value).plus(BN(amount))
      // calculate new liquidation prices for collataral
      tempCollaterals.map((item) => {
        if (item.currency.toLowerCase() === currency.toLowerCase()) {
          const coll = BN(
            tempSums[currency.toLowerCase()].totalCollateral
          ).plus(BN(amount))
          const debt = BN(tempSums[item.priceCurrency.toLowerCase()].totalDebt)
          const liquidationPrice = BN(debt).dividedBy(
            BN(coll).multipliedBy(BN(liquidationThreshold / 100))
          )
          item.liquidationPrice = liquidationPrice
        }
      })
    } else {
      tempCollateral.push({ key: currency, value: BN(amount) })
      tempDeposits.push({ key: currency, value: BN(amount) })
      tempSums[currency.toLowerCase()].totalCollateral = amount

      // calculate new liquidation prices for new collateral
      debts.map((item) => {
        const currentPrice =
          exchangeRates[currency.toLowerCase()][item.key.toLowerCase()]
        const liquidationPrice = BN(
          tempSums[item.key.toLowerCase()].totalDebt
        ).dividedBy(
          BN(amount).multipliedBy(BN(liquidationThreshold)).dividedBy(100)
        )
        const newItem = {
          currency: currency,
          currentPrice: currentPrice,
          priceCurrency: item.key,
          liquidationPrice: liquidationPrice
        }
        tempCollaterals.push(newItem)
      })
    }
  }

  if (activityType === TRANSACTIONS.WITHDRAW) {
    const collateralFound = tempCollateral.find((item) => item.key === currency)
    if (collateralFound) {
      collateralFound.value = BN(collateralFound.value).minus(BN(amount))

      if (BN(0).isGreaterThanOrEqualTo(collateralFound.value)) {
        // remove collateral debt pairs
        tempCollaterals = tempCollaterals.filter(
          (item) => item.currency.toLowerCase() !== currency.toLowerCase()
        )
        tempDeposits = tempDeposits.filter(
          (item) => item.key.toLowerCase() !== currency.toLowerCase()
        )
      } else {
        // calculate new liquidation prices for collataral
        tempCollaterals.map((item) => {
          if (item.currency.toLowerCase() === currency.toLowerCase()) {
            const coll = BN(
              tempSums[currency.toLowerCase()].totalCollateral
            ).minus(BN(amount))
            const debt = BN(
              tempSums[item.priceCurrency.toLowerCase()].totalDebt
            )
            const liquidationPrice = BN(debt).dividedBy(
              BN(coll).multipliedBy(BN(liquidationThreshold / 100))
            )
            item.liquidationPrice = liquidationPrice
          }
        })
      }
    }
  }

  if (activityType === TRANSACTIONS.BORROW) {
    const debtFound = tempDebts.find((item) => item.key === currency)
    if (debtFound) {
      debtFound.value = BN(debtFound.value).plus(BN(amount))

      // calculate new liquidation prices for collataral
      tempCollaterals.map((item) => {
        if (item.currency.toLowerCase() === currency.toLowerCase()) {
          const coll = BN(tempSums[currency.toLowerCase()].totalCollateral)
          const debt = BN(
            tempSums[item.priceCurrency.toLowerCase()].totalDebt.plus(
              BN(amount)
            )
          )
          const liquidationPrice = BN(debt).dividedBy(
            BN(coll).multipliedBy(BN(liquidationThreshold / 100))
          )
          item.liquidationPrice = liquidationPrice
        }
      })
    } else {
      tempDebts.push({ key: currency, value: BN(amount) })
      tempSums[currency.toLowerCase].totalDebt = BN(amount)
      tempDeposits.map((item) => {
        const debt = BN(tempSums[currency.toLowerCase()].totalDebt)
        const coll = BN(tempSums[item.key.toLowerCase()].totalCollateral)
        const liquidationPrice = BN(debt).dividedBy(
          BN(coll).multipliedBy(BN(liquidationThreshold / 100))
        )
        const currentPrice =
          exchangeRates[item.key.toLowerCase()][currency.toLowerCase()]
        const newItem = {
          currency: currency,
          priceCurrency: item.key,
          currentPrice: currentPrice,
          liquidationPrice: liquidationPrice
        }
        tempCollaterals.push(newItem)
      })
    }

    const loanFound = tempLoans.find((item) => item.key === currency)
    if (loanFound) {
      loanFound.value = BN(loanFound.value).plus(BN(amount))
    } else {
      tempLoans.push({ key: currency, value: BN(amount) })
    }
  }

  if (activityType === TRANSACTIONS.REPAY) {
    const debtFound = tempDebts.find((item) => item.key === currency)
    const loanFound = tempLoans.find((item) => item.key === currency)

    if (debtFound) {
      if (BN(amount).isGreaterThanOrEqualTo(BN(debtFound.value))) {
        // delete debt
        tempDebts = tempDebts.filter((debtAsset) => debtAsset.key !== currency)
        // delete loan
        tempLoans = tempLoans.filter((loanAsset) => loanAsset.key !== currency)
        // delete col/debt pair
        tempCollaterals = tempCollaterals.filter(
          (item) => item.priceCurrency.toLowerCase() !== currency.toLowerCase()
        )
      } else {
        // substract from existing debt
        debtFound.value = BN(debtFound.value).minus(BN(amount))
        loanFound.value = debtFound.value

        // calculate liquidation prices
        tempSums[currency.toLowerCase()].totalDebt = BN(
          tempSums[currency.toLowerCase()]
        ).minus(BN(amount))

        // const coll = BN(tempSums[currency.toLowerCase()].totalCollateral)
        // const debt = BN(
        //   tempSums[item.priceCurrency.toLowerCase()].totalDebt.plus(
        //     BN(amount)
        //   )
        // )
        // const liquidationPrice = BN(debt).dividedBy(
        //   BN(coll).multipliedBy(BN(liquidationThreshold / 100))
        // )
        // item.liquidationPrice = liquidationPrice
      }
    }
  }

  // convert deposits to CELO anc calculate sum of all deposits
  const totalCollateralCelo =
    tempCollateral && tempCollateral.length
      ? tempCollateral
          .map((collateralAsset) =>
            convertoToCelo(collateralAsset.key, collateralAsset.value)
          )
          .reduce((a, b) => BN(a).plus(BN(b)), 0)
      : 0

  // convert debts to CELO anc calculate sum of all debts
  const totalDebtsCelo =
    tempDebts && tempDebts.length
      ? tempDebts
          .map((debtAsset) => convertoToCelo(debtAsset.key, debtAsset.value))
          .reduce((a, b) => BN(a).plus(b), 0)
      : 0

  // convert debts to CELO anc calculate sum of all debts
  const totalLoansCelo =
    tempLoans && tempLoans.length
      ? tempLoans
          .map((loanAsset) => convertoToCelo(loanAsset.key, loanAsset.value))
          .reduce((a, b) => BN(a).plus(b), 0)
      : 0

  if (totalDebtsCelo <= 0) throw new Error('Total Debts must be greater than 0')

  const healthFactor = totalCollateralCelo
    .multipliedBy(liquidationThreshold)
    .dividedBy(totalDebtsCelo)

  const ltv = totalLoansCelo.dividedBy(totalCollateralCelo).multipliedBy(100)

  return { healthFactor, ltv, tempCollaterals }
}

export const getUserAccountInfoStatus = async (
  userPublicKey,
  currentCurrency,
  selectedCurrency,
  activityType,
  amount,
  loanTerms
) => {
  const hasInterestRate = [TRANSACTIONS.BORROW, TRANSACTIONS.REPAY].includes(
    activityType
  )
  const currentAccountStatus = {
    status: 'OK',
    dateTime: 1621490344.936294,
    userPublicKey,
    healthFactor: selectedCurrency === CURRENCIES.celo.key ? 150 : 300,
    currentLTV: 50,
    maximumLTV: 75,
    liquidationThreshold: 80,
    interestRate: 19,
    interestRateMode: hasInterestRate ? loanTerms || 2 : undefined,
    collateral: 'cEUR',
    collateralAssets: [
      {
        currency: 'cEUR',
        liquidationPrice: 4,
        currentPrice: 4.02,
        priceCurrency: 'CELO'
      },
      {
        currency: 'cEUR',
        liquidationPrice: 5.5,
        currentPrice: 1.42,
        priceCurrency: 'cUSD'
      }
    ],
    remainingDebt: currentCurrency === FIAT_CURRENCIES.usd.key ? 17 : 113,
    state: 'RISKY'
  }
  const newAccountStatus = {
    status: 'OK',
    dateTime: 1621490344.936294,
    userPublicKey,
    healthFactor: selectedCurrency === CURRENCIES.celo.key ? 200 : 400,
    currentLTV: 60,
    maximumLTV: 75,
    liquidationThreshold: 80,
    interestRate: 19,
    interestRateMode: hasInterestRate ? loanTerms || 1 : undefined,
    collateralAssets: [
      {
        currency: 'cEUR',
        liquidationPrice: 3.2,
        currentPrice: 4.5,
        priceCurrency: 'CELO'
      },
      {
        currency: 'cEUR',
        liquidationPrice: 4.4,
        currentPrice: 2.3,
        priceCurrency: 'cUSD'
      },
      {
        currency: 'CELO',
        liquidationPrice: 4.4,
        currentPrice: 2.3,
        priceCurrency: 'cUSD'
      }
    ],
    remainingDebt: currentCurrency === FIAT_CURRENCIES.usd.key ? 44 : 200,
    state: 'AVERAGE'
  }

  const riskParams = calculateRiskParams(
    collateral,
    debts,
    collateralsDebts,
    loans,
    sums,
    80,
    activityType,
    selectedCurrency,
    amount
  )
  currentAccountStatus.healthFactor = riskParams.healthFactor
  currentAccountStatus.currentLTV = riskParams.ltv
  newAccountStatus.healthFactor = riskParams.healthFactor
  newAccountStatus.currentLTV = riskParams.ltv
  newAccountStatus.collateralAssets = riskParams.tempCollaterals
  return activityType && BN(amount).isGreaterThan(0)
    ? newAccountStatus
    : currentAccountStatus
}

export const getFee = async (currency, activityType, amount) => {
  let fee = 0.3
  if (currency === CURRENCIES.celo.key) {
    fee = 0.03
  }
  return {
    status: 'OK',
    dateTime: '05-06-2021 19:51:41.336254',
    currency,
    fee
  }
}

export const getAccountCurrencyStatus = userPublicKey => {
  if (false) {
    return {
      status: 'OK',
      dateTime: 1621490344.936294,
      userPublicKey: '8A045C8DcB7977425aA5A13887477d0DD4c2c28E',
      conversionCurrency: 'cUSD',  // the currency specified as input parameter to this method
      data: [
        {
          currency: 'CELO',
          depositAmount: {
            value: 10000,             // value in object currency.
            convertedValue: 42342     // value converted to input conversion currency
          },
          depositInterest: 14.5,
          forwardingInterest: {
            type: 'community',
            name: 'imparctMarket Trazendo Algeria',
            address: '0x15e1b70f2b9563029812d510d67be0796823edd1',		            // Another eg. would be “imparctMarket Trazendo Algeria” => resulting in first row “impactMarket” and the second row will be “Trazendo Algeria”, but for the moment this could be ignored, since we have a separate story for implementing the forwarding interest.
          },
          borrowAmount: {
            value: 7.6,                                                               // value in object currency.
            convertedValue: 29.98                                                     // value converted to input conversion currency
          },
          debtAmount: {
            value: 8,                                                                 // value in object currency.
            convertedValue: 33.12                                                     // value converted to input conversion currency
          },
          borrowMode: 'STABLE',                                                       // possible values STABLE, VARIABLE
          borrowInterest: 8.7,
          originationFee: {
            value: 3,                                                                 // value in object currency.
            convertedValue: 14.31                                                     // value converted to input conversion currency
          },
          availableToBorrow: {
            value: 8,                                                                 // value in object currency.
            convertedValue: 33.4                                                      // value converted to input conversion currency
          },
          collateralEnabled: true,                                                    // possible values true and false, and represents if the 'Is Collateral' rows is enabled or disabled.
          isCollateral: true,                                                         // possible values true and false, and represents the state of toggle switch.
        },
        {
          currency: 'cUSD',
          depositAmount: {
            value: 1000,                                                             // value in object currency.
            convertedValue: 5000                                                     // value converted to input conversion currency
          },
          depositInterest: 14.5,
          forwardingInterest: {
            type: 'community',                                                        // type can be 'wallet' or 'community'
            name: 'imparctMarket Trazendo Algeria',                                   // can be null (if type is wallet) or comunity name string
            address: '0x15e1b70f2b9563029812d510d67be0796823edd1',		                // Another eg. would be “imparctMarket Trazendo Algeria” => resulting in first row “impactMarket” and the second row will be “Trazendo Algeria”, but for the moment this could be ignored, since we have a separate story for implementing the forwarding interest.
          },
          borrowAmount: {
            value: 1100,                                                               // value in object currency.
            convertedValue: 5500                                                     // value converted to input conversion currency
          },
          debtAmount: {
            value: 8,                                                                 // value in object currency.
            convertedValue: 33.12                                                     // value converted to input conversion currency
          },
          borrowMode: 'STABLE',                                                       // possible values STABLE, VARIABLE
          borrowInterest: 8.7,
          originationFee: {
            value: 3,                                                                 // value in object currency.
            convertedValue: 14.31                                                     // value converted to input conversion currency
          },
          availableToBorrow: {
            value: 8,                                                                 // value in object currency.
            convertedValue: 33.4                                                      // value converted to input conversion currency
          },
          collateralEnabled: true,                                                    // possible values true and false, and represents if the 'Is Collateral' rows is enabled or disabled.
          isCollateral: true,                                                         // possible values true and false, and represents the state of toggle switch.
        },
        {
          currency: 'cEUR',
          depositAmount: {
            value: 100,                                                             // value in object currency.
            convertedValue: 500                                                     // value converted to input conversion currency
          },
          depositInterest: 14.5,
          forwardingInterest: {
            type: 'community',
            name: 'imparctMarket Trazendo Algeria',
            address: '0x15e1b70f2b9563029812d510d67be0796823edd1',		                // Another eg. would be “imparctMarket Trazendo Algeria” => resulting in first row “impactMarket” and the second row will be “Trazendo Algeria”, but for the moment this could be ignored, since we have a separate story for implementing the forwarding interest.
          },
          borrowAmount: {
            value: 150,                                                               // value in object currency.
            convertedValue: 750                                                     // value converted to input conversion currency
          },
          debtAmount: {
            value: 8,                                                                 // value in object currency.
            convertedValue: 33.12                                                     // value converted to input conversion currency
          },
          borrowMode: 'STABLE',                                                       // possible values STABLE, VARIABLE
          borrowInterest: 8.7,
          originationFee: {
            value: 3,                                                                 // value in object currency.
            convertedValue: 14.31                                                     // value converted to input conversion currency
          },
          availableToBorrow: {
            value: 8,                                                                 // value in object currency.
            convertedValue: 33.4                                                      // value converted to input conversion currency
          },
          collateralEnabled: true,                                                    // possible values true and false, and represents if the 'Is Collateral' rows is enabled or disabled.
          isCollateral: true,                                                         // possible values true and false, and represents the state of toggle switch.
        }
      ]
    }
  } else {
    return {
      status: 'OK',
      dateTime: 1621490344.936294,
      userPublicKey: '8A045C8DcB7977425aA5A13887477d0DD4c2c28E',
      conversionCurrency: 'cUSD',  // the currency specified as input parameter to this method
      data: [
        {
          currency: 'CELO',
          depositAmount: {
            value: 0,             // value in object currency.
            convertedValue: 0     // value converted to input conversion currency
          },
          depositInterest: 14.5,
          forwardingInterest: {
            type: 'community',
            name: 'imparctMarket Trazendo Algeria',
            address: '0x15e1b70f2b9563029812d510d67be0796823edd1',		            // Another eg. would be “imparctMarket Trazendo Algeria” => resulting in first row “impactMarket” and the second row will be “Trazendo Algeria”, but for the moment this could be ignored, since we have a separate story for implementing the forwarding interest.
          },
          borrowAmount: {
            value: 100,                                                               // value in object currency.
            convertedValue: 500                                                    // value converted to input conversion currency
          },
          debtAmount: {
            value: 100,                                                                 // value in object currency.
            convertedValue: 500                                                     // value converted to input conversion currency
          },
          borrowMode: 'STABLE',                                                       // possible values STABLE, VARIABLE
          borrowInterest: 8.7,
          originationFee: {
            value: 0,                                                                 // value in object currency.
            convertedValue: 0                                                     // value converted to input conversion currency
          },
          availableToBorrow: {
            value: 0,                                                                 // value in object currency.
            convertedValue: 0                                                      // value converted to input conversion currency
          },
          collateralEnabled: true,                                                    // possible values true and false, and represents if the 'Is Collateral' rows is enabled or disabled.
          isCollateral: true,                                                         // possible values true and false, and represents the state of toggle switch.
        },
        {
          currency: 'cUSD',
          depositAmount: {
            value: 10,                                                             // value in object currency.
            convertedValue: 50                                                     // value converted to input conversion currency
          },
          depositInterest: 14.5,
          forwardingInterest: {
            type: 'community',                                                        // type can be 'wallet' or 'community'
            name: 'imparctMarket Trazendo Algeria',                                   // can be null (if type is wallet) or comunity name string
            address: '0x15e1b70f2b9563029812d510d67be0796823edd1',		                // Another eg. would be “imparctMarket Trazendo Algeria” => resulting in first row “impactMarket” and the second row will be “Trazendo Algeria”, but for the moment this could be ignored, since we have a separate story for implementing the forwarding interest.
          },
          borrowAmount: {
            value: 0,                                                               // value in object currency.
            convertedValue: 0                                                     // value converted to input conversion currency
          },
          debtAmount: {
            value: 0,                                                                 // value in object currency.
            convertedValue: 0                                                     // value converted to input conversion currency
          },
          borrowMode: 'STABLE',                                                       // possible values STABLE, VARIABLE
          borrowInterest: 8.7,
          originationFee: {
            value: 0,                                                                 // value in object currency.
            convertedValue: 0                                                     // value converted to input conversion currency
          },
          availableToBorrow: {
            value: 0,                                                                 // value in object currency.
            convertedValue: 0                                                      // value converted to input conversion currency
          },
          collateralEnabled: true,                                                    // possible values true and false, and represents if the 'Is Collateral' rows is enabled or disabled.
          isCollateral: true,                                                         // possible values true and false, and represents the state of toggle switch.
        },
        {
          currency: 'cEUR',
          depositAmount: {
            value: 100,                                                             // value in object currency.
            convertedValue: 500                                                     // value converted to input conversion currency
          },
          depositInterest: 14.5,
          forwardingInterest: {
            type: 'community',
            name: 'imparctMarket Trazendo Algeria',
            address: '0x15e1b70f2b9563029812d510d67be0796823edd1',		                // Another eg. would be “imparctMarket Trazendo Algeria” => resulting in first row “impactMarket” and the second row will be “Trazendo Algeria”, but for the moment this could be ignored, since we have a separate story for implementing the forwarding interest.
          },
          borrowAmount: {
            value: 150,                                                               // value in object currency.
            convertedValue: 750                                                     // value converted to input conversion currency
          },
          debtAmount: {
            value: 8,                                                                 // value in object currency.
            convertedValue: 33.12                                                     // value converted to input conversion currency
          },
          borrowMode: 'STABLE',                                                       // possible values STABLE, VARIABLE
          borrowInterest: 8.7,
          originationFee: {
            value: 3,                                                                 // value in object currency.
            convertedValue: 14.31                                                     // value converted to input conversion currency
          },
          availableToBorrow: {
            value: 8,                                                                 // value in object currency.
            convertedValue: 33.4                                                      // value converted to input conversion currency
          },
          collateralEnabled: true,                                                    // possible values true and false, and represents if the 'Is Collateral' rows is enabled or disabled.
          isCollateral: true,                                                         // possible values true and false, and represents the state of toggle switch.
        }
      ]
    }
  }
}
