import React, { useContext, useState, useEffect } from 'react'
import { Box, Grid, useTheme } from '@material-ui/core'
import classNames from 'classnames'
import ActionLayer from '../../components/ActionLayer'
import ErrorIcon from '../../assets/images/alert-fill.svg'
import { WhitePage } from '../../utils/elements'
import { withTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AlertIcon from '../../assets/images/alert-circle.svg'
import MuiIcon from '@material-ui/core/Icon'
import userAccountIsCollateral from '../../components/hooks/userAccountIsCollateral'
import { useHistory } from 'react-router-dom'
import AccountWallet from '../../components/AccountWallet'
import BalanceBox from '../../components/BalanceBox'
import AppContext from '../../components/AppContext'
import { useGetSettingsCurrency } from '../../api'
import useAccountBalance from '../../components/hooks/useAccountBalance'
import { EventRegister } from 'react-native-event-listeners'
import { DESKTOP_ACTION_SUCCESS } from '../../utils/constants'

const styles = (theme) => ({
  fullHeight: {
    height: '100%'
  },
  error: {
    color: theme.palette.common.error,
    marginTop: '15px',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    '& > *:first-child': {
      marginRight: '10px'
    },
    '& p': {
      lineHeight: '20px'
    }
  }
})

const NoCollateral = ({ t, classes }) => {
  const theme = useTheme()
  const { user } = useContext(AppContext)
  const { data: currentCurrency } = useGetSettingsCurrency()
  const [walletBalances, setWalletBalances] = useState([])
  const [refreshAccount, setRefreshAccount] = useState(Date.now())

  useEffect(() => {
    const actionSuccessListener = EventRegister.addEventListener(
      DESKTOP_ACTION_SUCCESS,
      () => {
        setRefreshAccount(Date.now())
      }
    )
    return () => {
      EventRegister.removeEventListener(actionSuccessListener)
    }
  }, [])

  const { balance: accountBalance, isSuccess: accountBalanceIsSuccess } =
    useAccountBalance(refreshAccount)
  useEffect(() => {
    if (accountBalance && accountBalance.length > 0) {
      setWalletBalances(accountBalance)
    }
  }, [accountBalanceIsSuccess])

  const collateralData = userAccountIsCollateral(refreshAccount)
  const hasCollateralFunds =
    collateralData &&
    collateralData.accountIsCollateralData?.find((item) => item.isCollateral)

  const history = useHistory()

  const content = ({ form }) => {
    return (
      <Box className={classes.wrapper}>
        <Grid
          container
          justify={'space-between'}
          alignItems={'center'}
          onClick={() => {}}
        ></Grid>

        <Grid container className={classes.error} wrap={'nowrap'}>
          <MuiIcon>
            <img src={AlertIcon} height={20} width={20} />
          </MuiIcon>

          <Typography>
            {t('COLLATERAL.ERROR.INFO')} <b>{t('COLLATERAL.ACCOUNT_TAB')}</b>{' '}
            {t('COLLATERAL.ON')} <b>{t('COLLATERAL.HOME_PAGE')}</b>
          </Typography>
        </Grid>

        <BalanceBox
          user={user}
          currentCurrency={currentCurrency}
          forceRefresh={refreshAccount}
        />

        <AccountWallet
          showNoDeposits={false}
          user={user}
          currentCurrency={currentCurrency}
          walletBalances={walletBalances}
          forceRefresh={refreshAccount}
        />
      </Box>
    )
  }

  return (
    <WhitePage theme={theme}>
      <Box className={classNames(classes.fullHeight)}>
        <ActionLayer
          icon={ErrorIcon}
          title={t('GENERAL.ERROR')}
          subtitle={t('COLLATERAL.ERROR')}
          content={content}
          disabledButton={!hasCollateralFunds}
          buttonLabel={
            !hasCollateralFunds
              ? t('COLLATERAL.BUTTON.DISABLED')
              : t('COLLATERAL.BUTTON')
          }
          buttonClick={() =>
            hasCollateralFunds ? history.push('/borrow') : {}
          }
        />
      </Box>
    </WhitePage>
  )
}

export default withTranslation()(withStyles(styles)(NoCollateral))
