import { Box, Grid, Typography } from '@material-ui/core'
import MuiIcon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BackIcon from '../../assets/images/back.svg'
import CloseIcon from '../../assets/images/close.svg'
import { isMobile } from '../../utils/browser'
import BottomPopup from '../BottomPopup'
import Button from '../Button'
import InfoIcon from '../InfoIcon'

const styles = (theme) => ({
  wrapper: {
    minHeight: '500px',
    padding: '16px',
    overflow: 'auto',
    paddingBottom: '100px'
  },
  footer: {
    position: 'absolute',
    bottom: '16px',
    width: 'calc(100% - 32px)',
    marginTop: 'auto',
    '& button:disabled': {
      color: '#cccccc !important',
      backgroundColor: '#efefef !important'
    }
  },
  fixedFooter: {
    position: 'sticky',
    bottom: '0',
    width: '100%',
  },
  fixedFooterMobile: {
    position: 'fixed',
    bottom: '16px',
    width: 'calc(100% - 32px)',
  },
  title: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '28px',
    marginTop: '10px',
    marginBottom: '40px',
    wordBreak: 'break-word'
  },
  content: {
    maxWidth: '800px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '48px'
  },
  contentLeft: {
    fontSize: '16px',
    lineHeight: '20px'
  },
  contentRight: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.neutral.lightGrey,
    wordBreak: 'break-word'
  },
  contentRightGreen: {
    color: theme.palette.neutral.lightGreen,
    fontFamily: 'Jost-600-Semi'
  },
  contentRightRed: {
    color: theme.palette.neutral.lightRed,
    fontFamily: 'Jost-600-Semi'
  },
  contentRightYellow: {
    color: theme.palette.common.gold,
    fontFamily: 'Jost-600-Semi'
  },
  rowItem: {
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  totalValue: {
    fontFamily: 'Jost-700-Bold',
    color: theme.palette.text.primary
  },
  separator: {
    marginBottom: '48px'
  },
  buttonSeparator: {
    marginBottom: '12px',
    bottom: '60px'
  },
  container: {
    display: 'flex',
    '& > * ': {
      flexGrow: '1'
    },
    '& > *:first-child ': {
      marginRight: '32px'
    }
  }
})

const ConfirmationAndroid = ({
  classes,
  content,
  back,
  confirmation,
  isLoading,
  approvedTransaction
}) => {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmClicked, setConfirmClicked] = useState(false)
  const scaleToClass = useRef({
    0: 'contentRightGreen',
    1: 'contentRightYellow',
    2: 'contentRightRed'
  })

  const resetConfirmationButtonState = useCallback(
    debounce(() => setConfirmClicked(false), 2000),
    []
  )

  const confirmationButtonClicked = () => {
    if (!confirmClicked) {
      setConfirmClicked(true)
      confirmation()
      resetConfirmationButtonState()
    }
  }

  return (
    <Grid
      className={classes.wrapper}
      container
      direction={'column'}
      wrap={'nowrap'}
    >
      <Box>
        <MuiIcon onClick={back}>
          <img alt={'Go back'} src={BackIcon} width={32} height={32}></img>
        </MuiIcon>
      </Box>
      <Box display="flex">
        <Typography variant={'body2'} className={classes.title}>
          {content.message}
        </Typography>
      </Box>
      <div className={!isMobile ? classes.container : ''}>
        <Box className={classes.content}>
          {content.items.map((item, index) => (
            <React.Fragment key={`f${index}`}>
              <Grid
                key={index}
                container
                direction="row"
                alignItems={'center'}
                justify="space-between"
                onClick={() => {
                  if (item.info) {
                    setSelectedItem(item)
                  }
                }}
              >
                <Grid item className={classes.rowItem}>
                  <Grid container direction="row" alignItems={'center'}>
                    <Typography variant="body2" className={classes.contentLeft}>
                      {item.text}
                    </Typography>
                    {item.info && (
                      <InfoIcon title={item.text} text={item.info} />
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    className={classNames(
                      classes.contentRight,
                      item.green && classes.contentRightGreen,
                      item.isTotal && classes.totalValue
                    )}
                  >
                    {item.value}
                  </Typography>
                </Grid>
              </Grid>
              {item.divider && (
                <Box mb={2}>
                  <Divider key={`divider${index}`} />
                </Box>
              )}
            </React.Fragment>
          ))}
        </Box>
        <Box className={classes.content}>
          {content.additionalItems &&
            content.additionalItems.map((item, index) => (
              <React.Fragment key={`f${index}`}>
                <Grid
                  key={index}
                  container
                  direction="row"
                  alignItems={'center'}
                  justify="space-between"
                  onClick={() => {
                    if (item.info) {
                      setSelectedItem(item)
                    }
                  }}
                >
                  <Grid item className={classes.rowItem}>
                    <Grid container direction="row" alignItems={'center'}>
                      <Typography
                        variant="body2"
                        className={classes.contentLeft}
                      >
                        {item.text}
                      </Typography>
                      {item.info && (
                        <InfoIcon
                          title={item.text}
                          text={item.info?.content || item.info}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      className={classNames(
                        classes.contentRight,
                        item.scale >= 0 &&
                          classes[scaleToClass.current[item.scale]],
                        item.isTotal && classes.totalValue
                      )}
                    >
                      {item.value}
                    </Typography>
                  </Grid>
                </Grid>
                {item.divider && (
                  <Box mb={2}>
                    <Divider key={`divider${index}`} />
                  </Box>
                )}
              </React.Fragment>
            ))}
        </Box>
      </div>

      {selectedItem && (
        <BottomPopup
          show={true}
          label={selectedItem.text}
          onClose={() => setSelectedItem(null)}
          icon={CloseIcon}
          children={selectedItem.info}
          primaryButtonTitle={t('GENERAL.DONE')}
          primaryButtonClick={() => setSelectedItem(null)}
        />
      )}
      <Box
        className={classNames(
          classes.footer, classes.buttonSeparator,
          isMobile ? classes.fixedFooterMobile : classes.fixedFooter
        )} >
        <Button
          label={
            !approvedTransaction ? t('GENERAL.APPROVE') : t('GENERAL.APPROVED')
          }
          onClick={confirmationButtonClicked}
          disabled={approvedTransaction}
        />
      </Box>

      <Box
        className={classNames(
          classes.footer,
          isMobile ? classes.fixedFooterMobile : classes.fixedFooter
        )} >
        <Button
          label={t('GENERAL.CONFIRM_VALORA')}
          onClick={confirmationButtonClicked}
          disabled={!approvedTransaction}
        />
      </Box>
    </Grid>
  )
}

ConfirmationAndroid.propTypes = {
  classes: PropTypes.object,
  back: PropTypes.func,
  confirmation: PropTypes.func
}

export default withStyles(styles)(ConfirmationAndroid)
