import { useEffect, useContext, useState } from 'react'
import AppContext from '../AppContext'
import { getContract } from '../../root'
import BN from 'bignumber.js'

const useAvailableBorrowStable = (
  txType = 'CELO',
  amount,
  reserveStableBorrowRateEnabled
) => {
  const context = useContext(AppContext)
  const [isLoading, setLoading] = useState(true)
  const [availableBorrowStable, setAvailableBorrowStable] = useState(false)

  const loadData = async () => {
    if (
      reserveStableBorrowRateEnabled !== true ||
      amount === null ||
      amount === undefined ||
      amount === ''
    ) {
      setAvailableBorrowStable(false)
      setLoading(false)
      return
    }

    const { lendingPoolCore, reserves } = await getContract()
    const canBorrowStable = await lendingPoolCore.methods
      .isUserAllowedToBorrowAtStable(
        reserves[txType],
        context.user.address,
        BN(10).pow(18).multipliedBy(amount).toFixed(0)
      )
      .call()

    setAvailableBorrowStable(canBorrowStable)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [txType, amount, reserveStableBorrowRateEnabled])

  return [{ availableBorrowStable, isLoading }]
}

export default useAvailableBorrowStable
