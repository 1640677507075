import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import { Box, Typography, Grid, useTheme } from '@material-ui/core'
import MuiIcon from '@material-ui/core/Icon'
import Button from '../Button'
import CloseIcon from '../../assets/images/close.svg'
import SuccessIcon from '../../assets/images/success.svg'
import ExternalLinkIcon from '../../assets/images/external-link.svg'
import config from '../../config'
import { CURRENCIES, DESKTOP_ACTION_SUCCESS, MOOLA_CURRENCIES } from '../../utils/constants'
import { WhitePage } from '../../utils/elements'
import { isMobile } from '../../utils/browser'
import { EventRegister } from 'react-native-event-listeners'
import { formatNumberWithoutRounding } from '../../utils/format'

const styles = (theme) => ({
  wrapper: {
    minHeight: '350px',
    padding: '16px',
    overflow: 'auto'
  },
  footer: {
    position: 'absolute',
    bottom: '16px',
    width: 'calc(100% - 32px)'
  },
  content: {
    marginBottom: '48px'
  },
  successIcon: {
    marginTop: isMobile ? '10px' : '0',
    marginBottom: '24px',
    marginRight: '16px'
  },
  title: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '28px',
    marginBottom: '8px'
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    wordBreak: 'break-word'
  },
  externalButton: {
    marginBottom: '10px'
  },
  desktopMessageContainer: {
    display: 'flex',
    marginTop: '40px'
  }
})

const ConfirmationSuccess = ({ classes }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const theme = useTheme()
  const { txType, txCurrency, txValue, txHash } = useParams()
  const type = txType === 'redeem' ? 'withdraw' : txType

  useEffect(() => {
    if (!isMobile) EventRegister.emit(DESKTOP_ACTION_SUCCESS)
  }, [])

  const labels = {
    deposit: (currency, value) =>
      t('CONFIRMATION.SUCCESS.DEPOSIT', {
        value,
        currency,
        moolaCurrency: MOOLA_CURRENCIES[currency]
      }),
    withdraw: (currency, value) =>
      t('CONFIRMATION.SUCCESS.WITHDRAW', {
        value,
        currency,
        moolaCurrency: MOOLA_CURRENCIES[currency]
      }),
    borrow: (currency, value) =>
      t('CONFIRMATION.SUCCESS.BORROW', {
        value,
        currency
      }),
    repay: (currency, value) =>
      t('CONFIRMATION.SUCCESS.REPAY', {
        value,
        currency
      }),
    claim: (currency, value) => t('CONFIRMATION.SUCCESS.CLAIM'),
    setCollateral: (currency, value) =>
      t('COLLATERAL.CONFIRMATION', {
        asset: txCurrency,
        type: t('COLLATERAL.ADDED')
      }),
    removeCollateral: (currency, value) =>
      t('COLLATERAL.CONFIRMATION', {
        asset: txCurrency,
        type: t('COLLATERAL.REMOVED')
      })
  }
  const openTransaction = useCallback(() => {
    window.open(config.explorerLink + txHash, '_blank')
  }, [])

  return (
    <WhitePage theme={theme}>
      <Grid
        className={classes.wrapper}
        container
        direction={'column'}
        wrap={'nowrap'}
      >
        <Box>
          <MuiIcon onClick={() => history.push('/home/account')}>
            <img alt={'Close'} src={CloseIcon} width={32} height={32} />
          </MuiIcon>
        </Box>
        <div className={!isMobile ? classes.desktopMessageContainer : ''}>
          <Box className={classes.successIcon}>
            <MuiIcon className={classes.successIcon}>
              <img
                alt={'Transaction successful'}
                src={SuccessIcon}
                width={52}
                height={52}
              />
            </MuiIcon>
          </Box>
          <Box className={classes.content}>
            <Typography variant={'body2'} className={classes.title}>
              {t('GENERAL.TRANSACTION_SENT')}
            </Typography>
            <Typography variant={'body1'} className={classes.subtitle}>
              {labels[type]?.(txCurrency, formatNumberWithoutRounding(txValue, CURRENCIES[txCurrency.toLowerCase()]?.decimals))}
            </Typography>
          </Box>
        </div>
        <Box className={classes.footer}>
          <Box className={classes.externalButton}>
            <Button
              label={t('GENERAL.VIEW_ON_CELO_EXPLORER')}
              variant="outlined"
              endIcon={ExternalLinkIcon}
              onClick={openTransaction}
            />
          </Box>
          <Button
            label={t('GENERAL.DONE')}
            onClick={() => history.push(isMobile ? '/home/account' : '/')}
          />
        </Box>
      </Grid>
    </WhitePage>
  )
}

ConfirmationSuccess.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(ConfirmationSuccess)
