import styled from 'styled-components'

export const Container = styled.div`
	width: 100%;
	overflow: hidden;
`

export const List = styled.div`
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
  	&::-webkit-scrollbar {
    display: none;
  }
`