import { useEffect, useContext, useState } from 'react'
import AppContext from '../AppContext'
import { print } from '../../utils'
import { getContract } from '../../root'
import { CURRENCIES } from '../../utils/constants'
import BN from 'bignumber.js'

const useAccountWithdrawData = () => {
  const { user } = useContext(AppContext)
  const [isLoading, setLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const [accountWithdrawData, setAccountWithdrawData] = useState(null)

  const loadAllWithdraw = async () => {
    try {
      const debts = await Promise.all(
        Object.values(CURRENCIES).map(
          async (currency) => await loadWithdraw(currency.key)
        )
      )
      setAccountWithdrawData(debts)
      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsSuccess(false)
    }
    setLoading(false)
  }

  const loadWithdraw = async (txType) => {
    const { lendingPool, reserves } = await getContract()
    const data = await lendingPool.methods
      .getUserReserveData(reserves[txType], user.address)
      .call()
    const parsedData = {
      currency: txType,
      withdraw: BN(print(data.currentATokenBalance))
    }
    return parsedData
  }

  useEffect(() => {
    if (user) {
      loadAllWithdraw()
    }
  }, [user])

  return { accountWithdrawData, isLoading, isSuccess }
}

export default useAccountWithdrawData
