import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import MuiIcon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import * as PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import BackIcon from '../../assets/images/back.svg'
import Button from '../Button'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { isMobile } from '../../utils/browser'
import classNames from 'classnames'

const styles = (theme) => ({
  wrapper: {
    height: '100%',
    padding: '16px',
    overflow: 'auto'
  },
  backIcon: {
    marginBottom: '10px',
    cursor: 'pointer'
  },
  footer: {
    marginTop: 'auto',
    '& button:disabled': {
      color: '#cccccc !important',
      backgroundColor: '#efefef !important'
    }
  },
  fixedFooter: {
    position: 'sticky',
    bottom: '0',
    width: '100%',
  },
  fixedFooterMobile: {
    position: 'fixed',
    bottom: '16px',
    width: 'calc(100% - 32px)',
  },
  icon: {
    marginTop: isMobile ? '10px' : '0',
    marginBottom: '24px',
	marginRight: '16px'
  },
  title: {
    fontFamily: 'Jost-700-Bold',
    fontSize: '24px',
    lineHeight: '28px',
    marginBottom: '8px'
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    marginBottom: '24px'
  },
  divider: {
    marginBottom: '16px'
  },
  desktopMessageContainer: {
	  display: 'flex',
	  marginTop: '40px'
  }
})

const ActionLayer = ({
                       t,
                       classes,
                       icon,
                       title,
                       subtitle,
                       content,
                       buttonClick,
                       buttonLabel,
                       disabledButton = false,
                       homeOnBack = false,
                       customExternalError
                     }) => {
  const history = useHistory()

  const getButtonLabel = (formState) => {
    if (formState.pristine) {
      return t('GENERAL.SELECT_AMOUNT')
    }

    const erroneousFields = Object.keys(formState.errors)
    const touchedFields = Object.keys(formState.touched).filter((field) => formState.touched[field])
    const dirtyFields = Object.keys(formState.dirtyFields).filter((field) => formState.dirtyFields[field])
    const anyErroneousFieldsDirty = erroneousFields.some((field) => {
      return touchedFields.includes(field) || dirtyFields.includes(field)
    })

    if (formState.invalid) {
      if (anyErroneousFieldsDirty) {
        return t('GENERAL.RESOLVE_ERRORS')
      }
      return t('GENERAL.SELECT_AMOUNT')
    }

    if (customExternalError) {
      return t('GENERAL.RESOLVE_ERRORS')
    }

    return t('GENERAL.NEXT')
  }

  return (
    <Form
      onSubmit={buttonClick}
      initialValues={{}}
      render={({ handleSubmit, form }) => {
        return (
          <Grid
            className={classes.wrapper}
            container
            direction={'column'}
            wrap={'nowrap'}
          >
            <Box className={classes.backIcon}>
              <MuiIcon onClick={() => homeOnBack ? history.push('/') : history.goBack()}>
                <img
                  alt={'Go back'}
                  src={BackIcon}
                  width={32}
                  height={32}
                />
              </MuiIcon>
            </Box>
			<div className={!isMobile ? classes.desktopMessageContainer : ''}>
				<Box className={classes.icon}>
					<MuiIcon>
						<img alt={title} src={icon} width={52} height={52}></img>
					</MuiIcon>
				</Box>
				<Box className={classes.content}>
					<Typography variant={'body2'} className={classes.title}>
						{title}
					</Typography>
					{subtitle && (
						<Typography variant={'body1'} className={classes.subtitle}>
						{subtitle}
						</Typography>
					)}
				</Box>
			</div>
			<Divider className={classes.divider}/>
			{content({ form })}

            <Box
              className={classNames(
                classes.footer,
                isMobile ? classes.fixedFooterMobile : classes.fixedFooter
              )} >
              <Button
                label={buttonLabel || getButtonLabel(form.getState())}
                onClick={handleSubmit}
                disabled={disabledButton || form.getState().invalid || customExternalError !== undefined}
              />
            </Box>
          </Grid>
        )
      }}
    />
  )
}

ActionLayer.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  content: PropTypes.any,
  buttonClick: PropTypes.func,
  disabledButton: PropTypes.bool,
  homeOnBack: PropTypes.bool,
  customExternalError: PropTypes.object
}

export default withTranslation()(withStyles(styles)(ActionLayer))
