import { LOAN_TERMS, RISK_FACTOR_STATE, SIGNS } from './constants'
import {
  CollateralInfo,
  CurrentLTVInfo,
  CurrentPriceInfo,
  DebtAssetsInfo,
  HealthScoreInfo,
  InterestRateInfo,
  InterestRateModeInfo,
  LiquidationPriceInfo,
  LiquidationThresholdInfo,
  MaximumLTVInfo,
  NewLTVInfo
} from '../components/InfoContent'

import { cCurrencyFormatter, getHealthFactor } from './index'
import React from 'react'
import { formatNumber } from './format'

const formatCurrency = (currency) => {
  return currency === 'Celo' ? 'CELO' : currency
}

const getLTVValue = (value) => {
  if (value <= 0.1) {
    return `<0.1${SIGNS.PERCENTAGE}`
  }

  return `${value?.toFixed(1)}${SIGNS.PERCENTAGE}`
}

export const getNewAccountStatusData = (
  t,
  classes,
  currentCurrency,
  newAccountStatus,
  selectedCurrency,
  interestRateMode
) => {
  const healthFactor = getHealthFactor(newAccountStatus)
  const newAccountStatusData = {
    content: [
      {
        text: t('GENERAL.HEALTH_FACTOR'),
        value: `${healthFactor} ${t('GENERAL.' + newAccountStatus?.state)}`,
        scale: RISK_FACTOR_STATE[newAccountStatus?.state],
        info: {
          content: <HealthScoreInfo classes={classes} />
        }
      },
      {
        text: t('GENERAL.NEW_LTV'),
        value: getLTVValue(newAccountStatus?.currentLTV),
        info: {
          content: <NewLTVInfo classes={classes} />
        }
      },
      {
        text: t('GENERAL.MAXIMUM_LTV'),
        value: `${newAccountStatus?.maximumLTV}%`,
        info: {
          content: <MaximumLTVInfo />
        }
      },
      {
        text: t('GENERAL.LIQUIDATION_THRESHOLD'),
        value: `${newAccountStatus?.liquidationThreshold}%`,
        info: {
          content: <LiquidationThresholdInfo />
        }
      }
    ]
  }

  const collaterals = newAccountStatus?.collaterals

  const debtAssets = newAccountStatus?.collateralAssets
    ?.map((collateralAsset) => formatCurrency(collateralAsset.priceCurrency))
    // filter unique values
    .filter((currency, index, self) => self.indexOf(currency) === index)
    .join(' & ')

  let collateralAssetsCount = 0
  if (collaterals) {
    collateralAssetsCount = collaterals.split('&').length
  }
  let debtAssetsCount = 0
  if (debtAssets) {
    debtAssetsCount = debtAssets.split('&').length
  }

  newAccountStatusData.content.push({
    text:
      collateralAssetsCount > 1
        ? t('GENERAL.COLLATERAL_plural')
        : t('GENERAL.COLLATERAL'),
    value: collaterals,
    info: {
      content: <CollateralInfo />
    }
  })

  newAccountStatus?.collateralAssets?.length > 0 &&
    newAccountStatusData.content.push({
      text:
        debtAssetsCount > 1
          ? t('GENERAL.DEBT_ASSETS_plural')
          : t('GENERAL.DEBT_ASSETS'),
      value: debtAssets,
      info: {
        content: <DebtAssetsInfo />
      }
    })

  newAccountStatus?.collateralAssets?.length > 0 &&
    newAccountStatus?.collateralAssets?.forEach((collateralAsset) => {
      if (
        collateralAsset.currency.toLowerCase() !==
        collateralAsset.priceCurrency.toLowerCase()
      ) {
        newAccountStatusData.content.push({
          text: t('GENERAL.CURRENT_CURRENCY_PRICE', {
            currency: formatCurrency(collateralAsset.currency)
          }),
          value: cCurrencyFormatter(collateralAsset.priceCurrency)(
            formatNumber(collateralAsset.currentPrice, 2)
          ),
          info: {
            content: <CurrentPriceInfo />
          }
        })
        newAccountStatusData.content.push({
          text: t('GENERAL.CURRENCY_LIQUIDATION_PRICE', {
            currency: formatCurrency(collateralAsset.currency)
          }),
          value: cCurrencyFormatter(collateralAsset.priceCurrency)(
            formatNumber(collateralAsset.liquidationPrice, 2)
          ),
          info: {
            content: <LiquidationPriceInfo />
          }
        })
      }
    })

  if (interestRateMode) {
    newAccountStatusData.content.push({
      text: t('GENERAL.INTEREST_RATE_MODE'),
      value: t(`GENERAL.INTEREST_RATE_MODE.${interestRateMode}`),
      info: {
        content: <InterestRateModeInfo />
      }
    })
    if (newAccountStatus?.interestRates?.length) {
      const rateValue = newAccountStatus.interestRates.find(
        (rate) =>
          rate.currency.toLowerCase() === selectedCurrency?.text.toLowerCase()
      )
      newAccountStatusData.content.push({
        text: t('GENERAL.INTEREST_RATE'),
        value: `${
          interestRateMode === LOAN_TERMS.STABLE
            ? rateValue?.stableInterestRate
            : rateValue?.variableInterestRate
        }% ${t('GENERAL.APR')}`,
        info: {
          content: <InterestRateInfo />
        }
      })
    }
  }

  return newAccountStatusData
}

export const getCurrentAccountStatusData = (
  t,
  classes,
  currentCurrency,
  currentAccountStatus,
  liquidationPrices,
  interestRateMode
) => {
  const healthFactor = getHealthFactor(currentAccountStatus)

  const rateValue = currentAccountStatus?.interestRates?.find(
    (item) => item.currency.toLowerCase() === currentCurrency?.toLowerCase()
  )

  const currentAccountStatusData = {
    content: [
      {
        text: t('GENERAL.HEALTH_FACTOR'),
        value: `${healthFactor} ${t('GENERAL.' + currentAccountStatus?.state)}`,
        scale: RISK_FACTOR_STATE[currentAccountStatus?.state],
        info: {
          content: <HealthScoreInfo classes={classes} />
        }
      },
      {
        text: t('GENERAL.CURRENT_LTV'),
        value: getLTVValue(currentAccountStatus?.currentLTV),
        info: {
          content: <CurrentLTVInfo classes={classes} />
        }
      },
      {
        text: t('GENERAL.MAXIMUM_LTV'),
        value: `${currentAccountStatus?.maximumLTV}%`,
        info: {
          content: <MaximumLTVInfo />
        }
      },
      {
        text: t('GENERAL.LIQUIDATION_THRESHOLD'),
        value: `${currentAccountStatus?.liquidationThreshold}%`,
        info: {
          content: <LiquidationThresholdInfo />
        }
      }
    ]
  }

  const collaterals = liquidationPrices?.data.collateralAssets
    ?.map((collateralAsset) => formatCurrency(collateralAsset.currency))
    // filter unique values
    .filter((currency, index, self) => self.indexOf(currency) === index)
    .join(' & ')

  const debtAssets = liquidationPrices?.data.collateralAssets
    ?.map((collateralAsset) => formatCurrency(collateralAsset.priceCurrency))
    // filter unique values
    .filter((currency, index, self) => self.indexOf(currency) === index)
    .join(' & ')

  let collateralAssetsCount = 0
  if (collaterals) {
    collateralAssetsCount = collaterals.split('&').length
  }
  let debtAssetsCount = 0
  if (debtAssets) {
    debtAssetsCount = debtAssets.split('&').length
  }

  currentAccountStatusData.content.push(
    {
      text:
        collateralAssetsCount > 1
          ? t('GENERAL.COLLATERAL_plural')
          : t('GENERAL.COLLATERAL'),
      value: collaterals,
      info: {
        content: <CollateralInfo />
      }
    },
    {
      text:
        debtAssetsCount > 1
          ? t('GENERAL.DEBT_ASSETS_plural')
          : t('GENERAL.DEBT_ASSETS'),
      value: debtAssets,
      info: {
        content: <DebtAssetsInfo />
      }
    }
  )

  liquidationPrices?.data.collateralAssets?.forEach((collateralAsset) => {
    if (
      collateralAsset.currency.toLowerCase() !==
      collateralAsset.priceCurrency.toLowerCase()
    ) {
      currentAccountStatusData.content.push({
        text: t('GENERAL.CURRENT_CURRENCY_PRICE', {
          currency: formatCurrency(collateralAsset.currency)
        }),
        value: cCurrencyFormatter(collateralAsset.priceCurrency)(
          formatNumber(collateralAsset.currentPrice, 2)
        ),
        info: {
          content: <CurrentPriceInfo />
        }
      })
      currentAccountStatusData.content.push({
        text: t('GENERAL.CURRENCY_LIQUIDATION_PRICE', {
          currency: formatCurrency(collateralAsset.currency)
        }),
        value: cCurrencyFormatter(collateralAsset.priceCurrency)(
          formatNumber(collateralAsset.liquidationPrice, 2)
        ),
        info: {
          content: <LiquidationPriceInfo />
        }
      })
    }
  })

  if (interestRateMode) {
    currentAccountStatusData.content.push({
      text: t('GENERAL.INTEREST_RATE_MODE'),
      value: t(`GENERAL.INTEREST_RATE_MODE.${interestRateMode}`),
      info: {
        content: <InterestRateModeInfo />
      }
    })
    currentAccountStatusData.content.push({
      text: t('GENERAL.INTEREST_RATE'),
      value: `${
        interestRateMode === LOAN_TERMS.STABLE
          ? rateValue?.stableInterestRate
          : rateValue?.variableInterestRate
      }% ${t('GENERAL.APR')}`,
      info: {
        content: <InterestRateInfo />
      }
    })
  }

  return currentAccountStatusData
}
