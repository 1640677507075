import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiIcon from '@material-ui/core/Icon'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CloseIcon from '../../assets/images/close.svg'
import ChevronRightArrow from '../../assets/images/chevron-right-arrow.svg'
import { PROVIDERS } from '../../utils/constants'
import useLogin from '../hooks/useLogin'

const styles = (theme) => ({
  wrapper: {
    height: 'calc(100% - 32px)',
    backgroundColor: theme.palette.common.white,
    padding: '16px',
    overflow: 'auto'
  },
  title: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '28px',
    marginBottom: '8px',
    paddingTop: '5px'
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.text.primary
  },
  content: {
    paddingTop: '30px'
  },
  provider: {
    cursor: 'pointer',
    '& > *:not(:last-child)': {
      marginRight: '16px'
    }
  },
  name: {
    fontFamily: 'Jost-500-Medium'
  },
  providerLink: {
    position: 'relative',
    top: '4px',
    marginLeft: 'auto'
  }
})

const ConnectWallet = ({ classes }) => {
  const history = useHistory()
  const login = useLogin()

  const [providers, setProviders] = useState(PROVIDERS)

  useEffect(() => {
    setValoraHandler()
  }, [])

  const setValoraHandler = () => {
    const updatedProviders = [...providers]
    const valoraIndex = updatedProviders.findIndex(
      (provider) => provider.name === 'Valora'
    )
    if (valoraIndex !== -1) {
      updatedProviders[valoraIndex] = {
        ...updatedProviders[valoraIndex],
        selectHandler: () => login('VALORA')
      }
    }

    setProviders(updatedProviders)
  }

  const providerRow = (provider, key) => (
    <Grid
      container
      key={key}
      alignItems={'center'}
      className={classes.provider}
      onClick={() => provider.selectHandler()}
    >
      <Grid item>
        <MuiIcon>
          <img
            alt={provider.name}
            src={provider.icon}
            width={40}
            height={40}
          ></img>
        </MuiIcon>
      </Grid>
      <Grid item>
        <Typography className={classes.name}>{provider.name}</Typography>
      </Grid>
      <Grid item className={classes.providerLink}>
        <MuiIcon>
          <img
            alt={'Open provider'}
            src={ChevronRightArrow}
            width={24}
            height={24}
          ></img>
        </MuiIcon>
      </Grid>
    </Grid>
  )

  return (
    <Box className={classes.wrapper}>
      <MuiIcon onClick={() => history.push('/')}>
        <img alt={'Close'} src={CloseIcon} width={32} height={32}></img>
      </MuiIcon>
      <Typography className={classes.title}>Connect a Wallet</Typography>
      <Typography className={classes.subtitle}>
        First select your preferred wallet provider. What’s a wallet?
      </Typography>
      <Box className={classes.content}>
        {providers.map((provider, index) => providerRow(provider, index))}
      </Box>
    </Box>
  )
}

export default withStyles(styles)(ConnectWallet)
