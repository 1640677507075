import { Box, Grid, Typography, withStyles } from '@material-ui/core'
import MuiButton from '@material-ui/core/Button'
import MuiCard from '@material-ui/core/Card'
import MuiCardContent from '@material-ui/core/CardContent'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { EventRegister } from 'react-native-event-listeners'

import {
	useGetUserAccountInfoBorrow,
	useGetUserAccountInfoRiskFactor,
	useGetUserAccountLiquidationPrices
} from '../../api'
import CloseIcon from '../../assets/images/close.svg'
import {
	cCurrencyFormatter,
	currencyFormatter,
	formatByCurrency
} from '../../utils'
import { isMobile } from '../../utils/browser'
import {
	CURRENCIES,
	RISK_FACTOR_STATE,
	TRANSACTIONS,
	TRANSACTION_STATUS,
  	ACCOUNT_PRICES_TAB_SWITCH
} from '../../utils/constants'
import { formatLocaleString, formatNumber } from '../../utils/format'
import AccountActivities from '../AccountActivities'
import AccountWallet from '../AccountWallet'
import BalanceBox from '../BalanceBox'
import BottomPopup from '../BottomPopup'
import PopupList from '../BottomPopup/popupList'
import useAccountBalance from '../hooks/useAccountBalance'
import { HealthScoreInfo } from '../InfoContent'
import InfoIcon from '../InfoIcon'
import { Alert } from '@material-ui/lab'

const styles = (theme) => ({
  root: {
    overflow: 'auto',
    height: 'calc(100% - 66px)',
    padding: '0 16px'
  },
  marginBottom: {
    marginBottom: '16px'
  },
  paddingTop: {
    paddingTop: '16px'
  },
  paddingBottom: {
    paddingBottom: '16px'
  },
  activity: {
    textColor: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '20px'
  },
  desktopHFCard: {
    background: '#FFFFFF',
    border: '1px solid #EDEEEF',
    borderRadius: '8px',
	padding: '8px 0'
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  contentItem: {
    marginBottom: '16px'
  },
  contentLeft: {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Jost-500-Medium',
  },
  contentRight: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.neutral.lightGrey,
    fontFamily: 'Jost-600-Semi',
  },
  contentRightGreen: {
    color: theme.palette.neutral.lightGreen,
  },
  contentRightRed: {
    color: theme.palette.neutral.lightRed,
  },
  contentRightYellow: {
    color: theme.palette.common.gold,
  },
  contentRightGreyOut: {
    color: theme.palette.text.placeholder
  },
  leftGrid: {
    width: '50%',
  },
  rightGrid: {
    width: '50%',
  },
  leftButton: {
    height: '40px',
    width: '100%',
    borderRadius: '5px 0px 0px 5px',

    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Jost-500-Medium',
  },
  rightButton: {
    height: '40px',
    width: '100%',
    borderRadius: '0px 5px 5px 0px',

    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Jost-500-Medium',
  },
  activeButton: {
    background: theme.palette.primary.main,
    color: theme.palette.neutral.white,
    '&:hover': {
      background: theme.palette.primary.main
    }
  },
  inactiveButton: {
    background: theme.palette.neutral.white,
    color: theme.palette.text.primary,
  },
  pricesSection: {
    margin: '17px 0px',
    borderRadius: '5px',
    border: '1px solid rgba(46, 51, 56, 0.1)'
  }
})

const HomeAccount = ({
  t,
  classes,
  user,
  currency,
  mooToken,
  setRiskFactorValue,
  forceRefresh
}) => {
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [hasDebtValue, setHasDebt] = useState(false)
  const [riskFactor, setRiskFactor] = useState(null)
  const [liquidationPrices, setLiquidationPrices] = useState(null)
  const [currenciesCurrentPrice, setCurrenciesCurrentPrice] = useState(null)
  const [walletBalances, setWalletBalances] = useState([])
  const [apiError, setApiError] = useState(false)
  const [showLiquidationPrice, setShowLiquidationPrice] = useState(true)

  const accountInfoRiskFactor = useGetUserAccountInfoRiskFactor(
    user?.address,
    currency,
    forceRefresh
  )
  const { balance: accountBalance, isSuccess: accountBalanceIsSuccess } =
    useAccountBalance(forceRefresh)

  const accountInfoBorrow = useGetUserAccountInfoBorrow(
    user?.address,
    forceRefresh
  )
  let userAccountLiquidationPrices = useGetUserAccountLiquidationPrices(
    user?.address,
    forceRefresh
  )


  useEffect(() => {
    if (userAccountLiquidationPrices?.isError|| accountInfoRiskFactor?.isError|| accountInfoBorrow?.isError) {
      setApiError(true)
    }
  }, [userAccountLiquidationPrices, accountInfoRiskFactor, accountInfoBorrow])



  useEffect(() => {
    if (accountBalance && accountBalance.length > 0) {
      setWalletBalances(accountBalance)
    }
  }, [accountBalance, accountBalanceIsSuccess])

  useEffect(() => {
    if (accountInfoBorrow.isSuccess) {
      const hasAnyDebt = accountInfoBorrow.data?.data?.some((borrowInfo) => {
        return borrowInfo.amount !== 0
      })
      setHasDebt(hasAnyDebt)
    }
  }, [accountInfoBorrow.isSuccess, accountInfoBorrow.data])

  useEffect(() => {
    if (hasDebtValue && accountInfoRiskFactor.isSuccess) {
      const newRiskFactor = {
        content: []
      }
      newRiskFactor.content.push({
        text: t('HOME.ACCOUNT_TAB.HEALTH_FACTOR_TITLE'),
        value: `${
          accountInfoRiskFactor?.data?.healthfactor < 100000
            ? formatLocaleString(
                accountInfoRiskFactor?.data?.healthfactor,
                2,
                2
              )
            : '>100,000'
        } • ${t('GENERAL.' + accountInfoRiskFactor?.data?.state)}`,
        info: {
          content: <HealthScoreInfo classes={classes} />
        },
        scale: RISK_FACTOR_STATE[accountInfoRiskFactor?.data?.state]
      })

      accountInfoRiskFactor?.data?.liquidationPrices?.forEach(
        (liquidationPrice) => {
          newRiskFactor.content.push({
            text: t('HOME.ACCOUNT_TAB.CURRENCY_LIQUIDATION_PRICE_TITLE', {
              currency: liquidationPrice.currency
            }),
            value: cCurrencyFormatter(liquidationPrice.valueCurrency)(
              formatNumber(liquidationPrice.value, CURRENCIES[liquidationPrice.currency?.toLowerCase()]?.decimals)
            ),
            scale: RISK_FACTOR_STATE[accountInfoRiskFactor?.data?.scale]
          })
        }
      )
      setRiskFactor(newRiskFactor)
      setRiskFactorValue(newRiskFactor.content)
    } else {
      setRiskFactor(null)
    }
  }, [
    hasDebtValue,
    accountInfoRiskFactor.isSuccess,
    accountInfoRiskFactor.data,
	t
  ])

  useEffect(() => {
    if (hasDebtValue && userAccountLiquidationPrices.isSuccess) {
      if (
        userAccountLiquidationPrices.data?.data?.collateralAssets?.length > 0
      ) {
        const liquidationPricesContent = {
          content: []
        }
		const currenciesPricesContent = {
			content: []
		}
        userAccountLiquidationPrices.data?.data?.collateralAssets.map(
          (item) => {
            if (item.currency === item.priceCurrency) {
              return
            }

            formatCeloText(item)
            liquidationPricesContent.content.push({
              text: t('GENERAL.CURRENCY_LIQUIDATION_PRICE', {
                currency: item.currency
              }),
              value: cCurrencyFormatter(item.priceCurrency)(
                formatNumber(item.liquidationPrice, CURRENCIES[item.currency?.toLowerCase()]?.decimals)
              )
            })
			if (item.currency.toLowerCase() !== item.priceCurrency.toLowerCase()) {
				currenciesPricesContent.content.push({
					text: t('GENERAL.CURRENT_CURRENCY_PRICE', {
						currency: item.currency
					}),
					value: cCurrencyFormatter(item.priceCurrency)(
						formatNumber(item.currentPrice, CURRENCIES[item.currency?.toLowerCase()]?.decimals)
					)
				})
			}
          }
        )
        setLiquidationPrices(liquidationPricesContent)
		setCurrenciesCurrentPrice(currenciesPricesContent)

      }
    } else {
      setLiquidationPrices(null)
	  setCurrenciesCurrentPrice(null)
    }
  }, [
    hasDebtValue,
    userAccountLiquidationPrices.isSuccess,
    userAccountLiquidationPrices.data,
	t
  ])

  const formatCeloText = (celoText) => {
    if (
      celoText.currency.toLowerCase() === CURRENCIES.celo.text.toLowerCase()
    ) {
      celoText.currency = CURRENCIES.celo.text
    }
  }

  const scaleToClass = useRef({
    0: 'contentRightGreen',
    1: 'contentRightYellow',
    2: 'contentRightRed',
    3: 'contentRightGreyOut'
  })


  const getStatus = (status) => {
    switch (status) {
      case TRANSACTION_STATUS.PROCESSING:
        return t('GENERAL.PROCESSING')
      case TRANSACTION_STATUS.SUCCESSFUL:
        return t('GENERAL.SUCCESSFUL')
      case TRANSACTION_STATUS.ERROR:
        return t('GENERAL.ERROR')
    }
    return t('GENERAL.NOT_APPLICABLE')
  }

  const isRepayOrBorrowAction = (action) =>
    TRANSACTIONS.BORROW === action || TRANSACTIONS.REPAY === action

  const getPopupContent = (activity) => {
    return (
      <PopupList
        type={activity.type}
        sourceTitle={activity.sourceTitle}
        source={activity.source}
        amountText={activity.amountText}
        amount={`${formatNumber(activity.amount, CURRENCIES[activity.currency?.toLowerCase()]?.decimals)} ${activity.currency}`}
        value={currencyFormatter(currency)(activity.value)}
        originationFeeAmount={
          isRepayOrBorrowAction(activity.type)
            ? `${activity.originationFeeAmount} ${activity.currency}`
            : null
        }
        originationFeeValue={
          isRepayOrBorrowAction(activity.type)
            ? formatByCurrency(currency, activity.originationFeeValue)
            : null
        }
        liquidatorClaimedAmount={
          activity.liquidatorClaimedAmount
            ? activity.liquidatorClaimedAmount
            : null
        }
        liquidatorClaimedValue={
          activity.liquidatorClaimedValue
            ? currencyFormatter(currency)(activity.liquidatorClaimedValue)
            : null
        }
        liquidationPenalty={activity.liquidationPenalty}
        liquidationPrice={
          activity.liquidationPrice
            ? `${activity.liquidationPrice} ${activity.currency} / ${
                CURRENCIES[activity.claimedCurrency?.toLowerCase()]?.text ?? ''
              }`
            : null
        }
        healthFactor={activity.healthFactor}
        time={activity.timestampDetails}
        status={getStatus(activity.status)}
        transactionId={`0x${activity.transactionId}`}
      />
    )
  }

  const getPopupLabel = (type) => {
    switch (type) {
      case TRANSACTIONS.DEPOSIT:
        return `${t('HOME.ACCOUNT_TAB.ACTIVITY.DEPOSIT_INFO')}`
      case TRANSACTIONS.WITHDRAW:
        return `${t('HOME.ACCOUNT_TAB.ACTIVITY.WITHDRAWAL_INFO')}`
      case TRANSACTIONS.BORROW:
        return `${t('HOME.ACCOUNT_TAB.ACTIVITY.BORROW_INFO')}`
      case TRANSACTIONS.REPAY:
        return `${t('HOME.ACCOUNT_TAB.ACTIVITY.REPAYMENT_INFO')}`
      case TRANSACTIONS.LIQUIDATE:
        return `${t('HOME.ACCOUNT_TAB.ACTIVITY.LIQUIDATION_INFO')}`
    }
  }

  const healthFactorScoreInfo = <HealthScoreInfo classes={classes} />

  const handleLiquidationAndCurrentPriceSection = (value) => {
    setShowLiquidationPrice(value)
    EventRegister.emit(ACCOUNT_PRICES_TAB_SWITCH)
  }

  const liquidationPriceSection = <>
    {apiError && <Alert className={classes.error} severity='error' onClose={() => setApiError(false)}>{t('GENERAL.API_ERROR')}</Alert>}
    {liquidationPrices && showLiquidationPrice && liquidationPrices.content.map((item, index) => {
      return <React.Fragment key={`f${index}`}>
        <Box p={2} pb={0}>
          <Grid
            key={index}
            container
            direction="row"
            justify="space-between"
            className={classes.contentItem}
          >
            <Grid item>
              <Grid
                container
                direction={'row'}
                alignItems={'center'}
              >
                <Typography
                  variant="body2"
                  className={classes.contentLeft}
                >
                  {item.text}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                className={classNames(
                  classes.contentRight,
                  item.scale >= 0 &&
                  classes[scaleToClass.current[item.scale]]
                )}
              >
                {item.value}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    })
    }
  </>

  const currenciesCurrentPriceSection = <>
    { currenciesCurrentPrice && !showLiquidationPrice && currenciesCurrentPrice.content.map((item, index) => {
      return <React.Fragment key={`f${index}`}>
        <Box p={2} pb={0}>
          <Grid
            key={index}
            container
            direction="row"
            justify="space-between"
            className={classes.contentItem}
          >
            <Grid item>
              <Grid
                container
                direction={'row'}
                alignItems={'center'}
              >
                <Typography
                  variant="body2"
                  className={classes.contentLeft}
                >
                  {item.text}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                className={classNames(
                  classes.contentRight,
                  item.scale >= 0 &&
                  classes[scaleToClass.current[item.scale]]
                )}
              >
                {item.value}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    })
    }
  </>

  return (
    <>
      <Box className={!isMobile ? classes.root : ''}>
        {selectedActivity && (
          <BottomPopup
            show={true}
            label={getPopupLabel(selectedActivity.type)}
            onClose={() => setSelectedActivity(null)}
            icon={CloseIcon}
            children={getPopupContent(selectedActivity)}
          />
        )}
        <BalanceBox
          user={user}
          currentCurrency={currency}
          forceRefresh={forceRefresh}
        />

		{hasDebtValue &&
		<Box style={{'padding': '8px 0px'}}>
          <Grid
            container
            direction="row"
            justify="space-between"
          >
            <Grid item>
              <Grid
                container
                direction={'row'}
                alignItems={'center'}
                onClick={() => {
                  setSelectedItem({
                    text: t('HOME.ACCOUNT_TAB.HEALTH_FACTOR_TITLE'),
                    info: {
                      content: healthFactorScoreInfo
                    }
                  })
                }}
              >
                <Typography
                  variant="body2"
                  className={classes.contentLeft}
                >
                  {t('HOME.ACCOUNT_TAB.HEALTH_FACTOR_TITLE')}
                </Typography>
                <InfoIcon
                  title={t('HOME.ACCOUNT_TAB.HEALTH_FACTOR_TITLE')}
                  text={healthFactorScoreInfo}
                />
              </Grid>
            </Grid>
            <Grid item>
              {riskFactor && riskFactor.content[0] &&
              <Typography
                variant="body1"
                className={classNames(
                  classes.contentRight,
                  riskFactor.content[0].scale >= 0 &&
                  classes[scaleToClass.current[riskFactor.content[0].scale]]
                )}
              >
                {riskFactor.content[0].value}
              </Typography>
              }
            </Grid>
          </Grid>
        </Box>}

        {hasDebtValue &&
          <Grid item>
            <Grid container
                  direction="row"
                  alignItems="center"
                  className={classes.pricesSection}
            >
              <Grid item className={classes.leftGrid}>
                <MuiButton
                  className={classNames(
                    classes.leftButton,
                    showLiquidationPrice ? classes.activeButton : classes.inactiveButton
                  )}
                  onClick={() => handleLiquidationAndCurrentPriceSection(true)}
                >
                  { t('GENERAL.LIQUIDATION_PRICE') }
                </MuiButton>
              </Grid>
              <Grid item className={classes.rightGrid}>
                <MuiButton
                  className={classNames(
                    classes.rightButton,
                    showLiquidationPrice ? classes.inactiveButton : classes.activeButton
                    )}
                  onClick={() => handleLiquidationAndCurrentPriceSection(false)}
                >
                  { t('GENERAL.CURRENT_PRICE') }
                </MuiButton>
              </Grid>
            </Grid>

            <MuiCard className={classes.desktopHFCard} variant="outlined">
              <MuiCardContent className={classes.content}>
                {liquidationPriceSection}
                {currenciesCurrentPriceSection}
              </MuiCardContent>
            </MuiCard>
          </Grid>
        }
        <AccountWallet
          mooToken={mooToken}
          user={user}
          currency={currency}
          walletBalances={walletBalances}
          forceRefresh={forceRefresh}
        />
        <Grid
          p={'16px'}
          item
          className={classNames(classes.paddingTop, classes.paddingBottom)}
        >
          <Typography className={classes.activity}>
            {t('HOME.ACCOUNT_TAB.ACTIVITY')}
          </Typography>
        </Grid>
        <AccountActivities
          setSelectedActivity={setSelectedActivity}
          user={user}
          currency={currency}
          forceRefresh={forceRefresh}
        />
      </Box>
      {selectedItem && (
        <BottomPopup
          show={true}
          label={selectedItem.text}
          onClose={() => setSelectedItem(null)}
          icon={CloseIcon}
          children={selectedItem.info.content}
          primaryButtonTitle={t('GENERAL.DONE')}
          primaryButtonClick={() => setSelectedItem(null)}
        />
      )}
    </>
  )
}

export default withTranslation()(withStyles(styles)(HomeAccount))

HomeAccount.propTypes = {
  classes: PropTypes.object,
  currency: PropTypes.string,
  mooToken: PropTypes.object,
  setRiskFactorValue: PropTypes.func,
  user: PropTypes.object,
  forceRefresh: PropTypes.number
}
