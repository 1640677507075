import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin: 0 auto 50px;
  max-width: 540px;
`;

export const Container = styled.div`
  background-color: #1f2224;
`;

export const ContainerWhite = styled.div`
  background-color: #fff;
  padding: 16px;
`;

export const AmountContainer = styled(Box)`
  margin-bottom: 50px;
  
  & > * {
    margin-bottom: 40px;
  }
`;
