export default {
  isTestnet: false,
  explorerLink: 'https://explorer.celo.org/tx/',
  apiUrl: 'https://mooapi-t.herokuapp.com',
  gtmId: 'UA-183381698-2',
  sentryDsn:
    'https://b54bb1d2399a4d168da7cf5d2cdfee7a@o916281.ingest.sentry.io/5857614',
  urlV1: 'https://staging-v1.moola.market',
  urlV2: 'https://staging.moola.market'
}
